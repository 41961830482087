import React from 'react'
import moment from 'moment'
import KebabMenu from '../Common/KebabMenu'

const NoteItem = React.forwardRef((props, ref) => {
  return (
    <div className='note_item' ref={ref}>
      <span>
        <label>{moment(props.date.seconds, 'X').format('Do MMMM YYYY')}</label>
        <p>{props.note}</p>
      </span>

      <KebabMenu
        context='edit_delete'
        onDelete={props.onDelete}
        onEdit={props.onSelect}
      />
    </div>
  )
})

export default NoteItem
