import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { getImgUrl, validateEmail } from '../../util'

import { fb } from '../../config/firebase'
import { editClient, deleteClient, createClient } from '../../actions/clients'

Modal.setAppElement('#app')

class ModalUserInfoForm extends React.Component {
  state = {
    showError: false,
    errorMessage: '',
    showSuccess: false,
    successMessage: '',
    isLoading: false
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      showError: false
    })
  }

  handleSubmit = e => {
    e.preventDefault()
  }

  handleSubmit = e => {
    e.preventDefault()

    if (this.state.email && !validateEmail(this.state.email)) {
      this.error('Invalid email address')
      return
    }

    this.setState({
      isLoading: 'submit'
    })

    const data = { ...this.state }

    delete data.showError
    delete data.errorMessage
    delete data.showSuccess
    delete data.successMessage
    delete data.isLoading

    if (this.props.editUserData) {
      this.handleEditUser(data)
    } else {
      this.handleCreateUser(data)
    }
  }

  handleCreateUser = data => {
    data.trainers = [this.state.trainer || this.props.activeUserId]
    data.type = this.props.newUserType === 'trainer' ? ['trainer', 'client'] : ['client']
    data.img_url = getImgUrl(data.first_name, data.last_name, data.type)

    fb.functions().httpsCallable('createUser')(data)
      .then(res => {
        console.log(res)
        fb.analytics().logEvent('new_user_created')

        if (this.props.newUserType === 'trainer') {
          data.trainers = [res.data.uid]
        }

        this.props.dispatch(createClient({ id: res.data.uid, client: data }))
          .then(() => this.success())
          .catch(err => this.error(err.message))
      })
      .catch(err => {
        this.error(err.message + ' - An account with this  email already exists.  Please contact the admin for assistance.')
        throw err
      })

  }

  handleEditUser = data => {
    this.props.dispatch(editClient({ clientId: this.props.editUserData.id, client: data }))
      .then(() => this.success())
      .catch(err => this.error(err.message))
  }

  handleDeleteAccount = () => {
    if (!window.confirm('Are you sure you want to delete this user? This action cannot be undone')) return

    this.setState({
      isLoading: 'delete'
    })

    this.props.dispatch(deleteClient(this.props.editUserData.id))
      .then(() => this.success())
      .catch(err => this.error(err.message))
  }

  handleResetPassword = e => {
    this.setState({
      isLoading: 'reset'
    })

    fb.auth().sendPasswordResetEmail(this.props.editUserData.email)
      .then(() => {
        this.setState({
          showSuccess: true,
          successMessage: 'Email sent',
          isLoading: null
        })

        if (this.props.editUserData.email === this.props.activeUserEmail) {
          fb.auth().signOut()
        }
      })
      .catch(err => this.error(err.message))
  }

  success = () => {
    this.setState({
      isLoading: null
    })
    this.props.onCloseModal()
    this.props.updateUserList()
  }

  error = err => {
    this.setState({
      isLoading: null,
      showError: true,
      errorMessage: err
    })
  }

  render () {
    const editUser = { ...this.props.editUserData }
    return (
      <Modal
        isOpen={this.props.showModal}
        onRequestClose={this.props.onCloseModal}
        className='modal'
        overlayClassName='modal_overlay'
      >
        <div className='modal_wrapper'>
          <span>
            <span className='modal_header'>
              {
                this.props.newUserType === 'client' && !this.props.editUserData &&
                  <h3>Add Client</h3>
              }
              {
                this.props.newUserType === 'trainer' && !this.props.editUserData &&
                  <h3>Add Coach</h3>
              }
              {
                this.props.editUserData &&
                  <h3>Edit User</h3>
              }

              <i className='fas fa-times' onClick={this.props.onCloseModal} />
            </span>

            <form
              className='modal_content'
              onSubmit={this.handleSubmit}
            >

              <label htmlFor='first_name'>First Name</label>
              <input
                type='text'
                name='first_name'
                onChange={this.handleChange}
                defaultValue={editUser.first_name}
                required
              />

              <label htmlFor='last_name'>Last Name</label>
              <input
                type='text'
                name='last_name'
                onChange={this.handleChange}
                defaultValue={editUser.last_name}
                required
              />

              <label htmlFor='email'>Email Address</label>
              <input
                type='email'
                name='email'
                onChange={this.handleChange}
                defaultValue={editUser.email}
                required
                disabled={this.props.editUserData}
              />

              <label htmlFor='phone'>Phone Number</label>
              <input
                type='number'
                name='phone'
                onChange={this.handleChange}
                defaultValue={editUser.phone}
              />

              {/* <label htmlFor='subscription_type'>Account Type</label>
              <select
                name='subscription_type'
                defaultValue={editUser.subscription_type || 'FREE'}
                onChange={this.handleChange}
              >
                <option value='FREE'>Free</option>
                <option value='FREEMIUM'>Freemium</option>
                <option value='PRO'>Pro</option>
              </select> */}

              {
                this.props.activeUserType.includes('admin') && !this.props.editUserData && this.props.newUserType.includes('client') &&
                  <>
                    <label htmlFor='trainer'>Assign to Trainer</label>
                    <select
                      defaultValue={this.props.activeUserId}
                      onChange={this.handleChange}
                      name='trainer'
                    >
                      {
                        this.props.coaches.map(coach => (
                          <option
                            key={coach.id}
                            value={coach.id}
                          >
                            {coach.first_name} {coach.last_name}
                          </option>
                        ))
                      }
                    </select>
                  </>
              }

            </form>

            <p className='ui_error'>{this.state.showError && this.state.errorMessage}</p>

            <p>{this.state.showSuccess && this.state.successMessage}</p>
          </span>

          <span className='modal_buttons'>
            {
              this.props.editUserData &&
                <>
                  <button
                    className='button_primary'
                    type='button'
                    onClick={this.handleResetPassword}
                  >
                    {this.state.isLoading === 'reset' ? <i className='fas fa-spinner fa-spin' /> : 'Reset Password'}
                  </button>
                  {
                    this.props.activeUserType.includes('admin') &&
                      <button
                        className='button_warning'
                        type='button'
                        onClick={this.handleDeleteAccount}
                      >
                        {this.state.isLoading === 'delete' ? <i className='fas fa-spinner fa-spin' /> : 'Delete Account'}

                      </button>
                  }
                </>
            }

            <button
              onClick={this.handleSubmit}
              className='button_primary'
              value='Submit'
            >
              {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}

            </button>
          </span>
        </div>
      </Modal>
    )
  }
}

function mapStateToProps (globalState) {
  const coaches = globalState.clients.filter(user => user.type.includes('trainer'))

  return {
    activeUserId: globalState.user.auth.uid,
    activeUserType: globalState.user.info.type,
    activeUserEmail: globalState.user.auth.email,
    coaches: coaches
  }
}

export default connect(mapStateToProps)(ModalUserInfoForm)
