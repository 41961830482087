import React, { useState } from 'react'

const CreateCheckItem = props => {
  const [item, updateItem] = useState('')

  const addItem = () => {
    props.onNewItem(item)
    updateItem('')
  }

  return (
    <div>
      <span className='row'>
        <input
          type='text'
          name='newMilestone'
          style={{ flex: 1 }}
          placeholder='Add new item'
          value={item}
          onChange={e => updateItem(e.target.value)}
        />

        <button
          type='button'
          id='kebab_milestone'
          onClick={addItem}
        >
          <i className='far fa-lg fa-check-circle' />
        </button>

        <button
          type='button'
          id='kebab_milestone'
          onClick={() => updateItem('')}
        >
          <i className='far fa-lg fa-times-circle' />
        </button>
      </span>
    </div>
  )
}

export default CreateCheckItem
