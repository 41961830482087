import React, { Component } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

import Section from '../Programs/Program/Section'
import ModalAddItem from '../Programs/Program/ModalAddItem'

import { fb } from '../../config/firebase'
import { createRecord, deleteRecord } from '../../actions/exerciseRecords'
import { renameImage, uploadImage } from '../../util'

class LogWorkoutForm extends Component {
  state = {
    activeProgram: null,
    phases: [],
    sections: [],
    isLoading: null,
    showError: false,
    errorMessage: '',
    activeModal: null
  }

  componentDidMount () {
    this.setState({
      activeProgram: this.props.activeProgram,
      activeSection: _.cloneDeep(this.props.activeSection)
    })
  }

  handleChange = e => {
    const activeSection = { ...this.state.activeSection }

    if (e.target.name === 'desc') {
      activeSection.note = e.target.value
    } else {
      activeSection[e.target.name] = e.target.value
    }

    this.setState({
      activeSection
    })
  }

  handleAssignItem = ({ items }) => {
    const activeSection = { ...this.state.activeSection }

    items.forEach(id => {
      const itemKey = uuid()
      // Get array of exercise metrics associated with this exercise
      const metricIds = this.props.globalExercises.find(gExercise => gExercise.id === id).metrics

      // Create metric object for each one
      const metrics = this.props.globalExerciseMetrics.reduce((res, metric) => {
        if (metricIds.includes(metric.id)) {
          res.push({
            name: metric.name,
            id: metric.id,
            value: [null]
          })
        }

        return res
      }, [])

      // Create new item
      const newItem = {
        id: id,
        key: itemKey,
        notes: '',
        index: 0,
        metrics,
        sets: { num: 1, display: true }
      }

      activeSection.content.push(newItem)

      const newGroup = {
        id: uuid(),
        name: null,
        index: activeSection.groups.length,
        items: [itemKey]
      }

      activeSection.groups.push(newGroup)
    })

    this.setState({
      activeSection
    })
  }

  handleUnassignItem = key => {
    const activeSection = { ...this.state.activeSection }

    activeSection.content = activeSection.content.filter(item => item.key !== key)

    this.setState({
      activeSection
    }, () => this.handleRemoveFromGroup(key))
  }

  handleRemoveFromGroup = key => {
    const activeSection = { ...this.state.activeSection }

    const groupIndex = activeSection.groups.findIndex(group => group.items.includes(key))

    const group = activeSection.groups[groupIndex]

    if (group.items.length === 1) {
      activeSection.groups = activeSection.groups.filter(group => !group.items.includes(key))
    } else {
      activeSection.groups[groupIndex].items = group.items.filter(item => item !== key)
    }

    this.setState({
      activeSection
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState({
      isLoading: 'submit'
    })

    // Validate data here
    const valid = this.state.activeSection.content.every(exercise => {
      return exercise.metrics.every(metric => {
        const m = this.props.globalExerciseMetrics.find(m => m.id === metric.id)
        const unit = this.props.units.find(unit => unit.id === m.unitOfMeasurementId)

        const regex = new RegExp(unit.regex)

        return metric.value.every(value => {
          return value === null || value.toString().match(regex)
        })
      })
    })

    if (!valid) {
      this.error('Invalid values')
      return
    }

    if (this.state.activeSection.imageURL) {
      const name = uuid()
      const image = renameImage(this.state.activeSection.imageURL, name)

      uploadImage('records', image)
        .then(url => {
          this.setState({
            imageURL: url,
            imageName: name
          }, () => this.createRecord())
        })
    } else {
      this.createRecord()
    }

    if (this.props.editData.recordId) {
      this.props.editData.recordId.forEach(id => {
        this.props.dispatch(deleteRecord(id))
      })
    }
  }

  createRecord = () => {
    const workoutKey = uuid()
    const date = new fb.firestore.Timestamp.now() // eslint-disable-line

    this.state.activeSection.content.forEach((item, count) => {
      const setKey = uuid()

      const coachNotes = item.notes || ''

      item.metrics.forEach((metric, m) => {
        const group = this.state.activeSection.groups.find(group => group.items.includes(item.key))

        metric.value.forEach((value, i) => {
          const exerciseMetrics = _.cloneDeep(metric)
          exerciseMetrics.value = value

          const e = this.props.activeSection.content.find(e => e.key === item.key)
          if (e) {
            exerciseMetrics.placeholder = e.metrics[m].value[i]
          } else exerciseMetrics.placeholder = ''

          const record = {
            date: this.props.editData.date ? new Date(this.props.editData.date.seconds * 1000) : date,
            userId: this.props.clientId,
            exerciseId: item.id,
            exerciseMetrics: [exerciseMetrics],
            note: this.state.activeSection.note || this.state.activeSection.desc || null,
            workoutKey,
            groupIndex: group.index,
            groupKey: group.id,
            groupName: group.name,
            exerciseIndex: item.index,
            exerciseKey: item.key,
            setIndex: i,
            setKey,
            programId: this.state.activeProgram.id,
            sectionId: this.state.activeSection.id,
            title: this.state.activeSection.title !== undefined && this.state.activeSection.title.includes(`${this.state.activeProgram.header.title}: `) ? this.state.activeSection.title : `${this.state.activeProgram.header.title}: ${this.state.activeSection.title}`,
            coachNotes: this.props.editData.coachNotes || coachNotes
          }

          if (this.state.imageURL) {
            record.imageURL = this.state.imageURL
            record.imageName = this.state.imageName
          }

          this.props.dispatch(createRecord(record))
            .then(() => {
              if (count === this.state.activeSection.content.length - 1) this.props.onCloseModal()
            })
            .catch(err => this.error(err.message))
        })
      })
    })
  }

  error = err => {
    this.setState({
      showError: true,
      errorMessage: err
    })
  }

  render () {
    return (
      <>
        <form className='modal_content' onSubmit={this.handleSubmit}>
          {
            this.state.activeSection &&
              <DragDropContext onDragEnd={this.handleDragEnd}>
                <Droppable droppableId='sections' direction='horizontal'>
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className='program_sections_container'
                      style={{ justifyContent: 'center' }}
                    >
                      <Section
                        key={this.state.activeSection.id}
                        index={0}
                        {...this.state.activeSection}
                        programType='Exercise'
                        globalExercises={this.props.globalExercises}
                        globalFood={this.props.globalFood}
                        onChange={this.handleChange}
                        onDelete={() => null}
                        onModal={({ modal, sectionId }) => this.setState({ activeModal: modal })}
                        onUnassignItem={this.handleUnassignItem}
                        displayCopyModal={false}
                        context={this.props.editData.id ? 'diary_edit' : 'diary_create'}
                      />
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
          }
        </form>

        <p className='ui_error'>{this.state.showError && this.state.errorMessage}</p>
        <div className='modal_buttons'>
          <button
            onClick={this.handleSubmit}
            className='button_primary'
          >
            {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
          </button>
        </div>

        <ModalAddItem
          showModal={this.state.activeModal === 'addItem'}
          onCloseModal={() => this.setState({ activeModal: null })}
          sectionId={this.state.activeSection && this.state.activeSection.id}
          programType='Exercise'
          onAssignItem={this.handleAssignItem}
        />
      </>
    )
  }
}

function mapStateToProps (globalState, ownProps) {
  const clientPrograms = globalState.programs.filter(program => program.users.includes(ownProps.clientId))

  return {
    clientPrograms,
    sections: globalState.programSections,
    globalExercises: globalState.exercises,
    globalExerciseMetrics: globalState.exerciseMetrics,
    units: globalState.units
  }
}

export default connect(mapStateToProps)(LogWorkoutForm)
