import { db } from '../config/firebase'

export const RECEIVE_UNITS = 'RECEIVE_UNITS'

export const receiveUnits = () => {
  return dispatch => {
    return db.collection('unitsOfMeasurement').get()
      .then(querySnapshot => {
        const units = []
        querySnapshot.forEach(snap => {
          units.push({ id: snap.id, ...snap.data() })
        })

        return dispatch({
          type: RECEIVE_UNITS,
          units: units.sort((a, b) => a.unit.localeCompare(b.unit))
        })
      })
      .catch(err => err)
  }
}
