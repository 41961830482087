import React from 'react'
import KebabMenu from '../Common/KebabMenu'

const MetricItem = React.forwardRef((props, ref) => {
  const unit = props.units.find(unit => unit.id === props.unitOfMeasurementId)

  return (
    <div className='metric_list_item box' onClick={() => props.onSelect(props.id)} ref={ref}>
      <p className='title'>{props.name}</p>

      <p className='unit'>{`${unit.unit} ${unit.abbreviation && `(${unit.abbreviation})`}`}</p>

      <div className='assigned'>
        {
          props.users.map((id, i) => {
            const user = props.clients.find(client => client.id === id)
            if (user !== undefined && i < 4) {
              return (
                <img
                  key={i}
                  src={user.img_url}
                  alt={user.first_name}
                  title={`${user.first_name} ${user.last_name}`}
                  style={{ marginLeft: `${i === 0 ? 0 : -10}px`, zIndex: `${5 - i}` }}
                />
              )
            }
          })
        }
        {
          props.users.length > 4 &&
            <label>{`+${props.users.length - 4} more`}</label>
        }
      </div>

      <span className='overflow'>
        <KebabMenu
          context='edit_delete'
          onEdit={() => props.onSelect(props.id)}
          onDelete={() => props.onDelete(props.id)}
        />
      </span>
    </div>
  )
})

export default MetricItem
