const packageJson = require('../../package.json')

console.log(process.env.ENVIRONMENT)
console.log(packageJson.version)

if (process.env.ENVIRONMENT === 'production') {
  module.exports = require('./prod')
} else {
  module.exports = require('./dev')
}
