import { fb, db } from '../config/firebase'
import { REMOVE_PROGRAM_FROM_COLLECTION } from './collections'

export const RECEIVE_PROGRAMS = 'RECEIVE_PROGRAMS'
export const RECEIVE_CLIENT_PROGRAMS = 'RECEIVE_CLIENT_PROGRAMS'
export const CREATE_PROGRAM = 'CREATE_PROGRAM'
export const EDIT_PROGRAM = 'EDIT_PROGRAM'
export const DELETE_PROGRAM = 'DELETE_PROGRAM'
export const REMOVE_CLIENT_PROGRAMS = 'REMOVE_CLIENT_PROGRAMS'
export const ASSIGN_PROGRAM = 'ASSIGN_PROGRAM'
export const UNASSIGN_PROGRAM = 'UNASSIGN_PROGRAM'
export const UPDATE_PHASE = 'UPDATE_PHASE'
export const ADD_PHASE = 'ADD_PHASE'
export const REMOVE_OWNER = 'REMOVE_OWNER'
export const CLIENT_ASSIGN_PROGRAM = 'CLIENT_ASSIGN_PROGRAM'

export const receiveAllPrograms = () => {
  return dispatch => {
    return db.collection('programs')
      .get()
      .then(querySnapshot => {
        const programs = []

        querySnapshot.forEach(snap => {
          programs.push({ ...snap.data(), id: snap.id })
        })

        return dispatch({
          type: RECEIVE_PROGRAMS,
          programs
        })
      })
      .catch(err => err)
  }
}

export const receiveUserPrograms = trainer => {
  return dispatch => {
    return db.collection('programs').where('ownerId', 'array-contains-any', [trainer, 'global'])
      .get()
      .then(querySnapshot => {
        const programs = []

        querySnapshot.forEach(snap => {
          programs.push({ ...snap.data(), id: snap.id })
        })

        return dispatch({
          type: RECEIVE_PROGRAMS,
          programs
        })
      })
      .catch(err => err)
  }
}

export const receiveClientPrograms = (clientId, userId) => {
  return dispatch => {
    return db.collection('programs').where('users', 'array-contains', clientId)
      .get()
      .then(querySnapshot => {
        const programs = []

        querySnapshot.forEach(snap => {
          programs.push({ ...snap.data(), id: snap.id })
        })

        return dispatch({
          type: RECEIVE_CLIENT_PROGRAMS,
          programs,
          clientId,
          userId
        })
      })
  }
}

export const removeClientPrograms = userId => {
  return ({
    type: REMOVE_CLIENT_PROGRAMS,
    userId
  })
}

export const createProgram = (program) => {
  const programRef = db.collection('programs').doc()

  return dispatch => {
    const batch = db.batch()

    program.collections && program.collections.forEach(collection => {
      const collectionRef = db.collection('collections').doc(collection)
      batch.update(collectionRef, {
        programs: fb.firestore.FieldValue.arrayUnion(programRef.id)
      })
    })

    batch.set(programRef, program)

    return batch.commit().then(() => {
      program.id = programRef.id

      return dispatch({
        type: CREATE_PROGRAM,
        program
      })
    })
      .catch(err => err)
  }
}

export const editProgram = ({ programId, program, toAdd, toRemove }) => {
  const programRef = db.collection('programs').doc(programId)
  return dispatch => {
    const batch = db.batch()

    toAdd && toAdd.forEach(add => {
      const addRef = db.collection('collections').doc(add)
      batch.update(addRef, {
        programs: fb.firestore.FieldValue.arrayUnion(programRef.id)
      })
    })

    toRemove && toRemove.forEach(remove => {
      const removeRef = db.collection('collections').doc(remove)
      batch.update(removeRef, {
        programs: fb.firestore.FieldValue.arrayRemove(programRef.id)
      })
    })

    batch.update(programRef, program)

    return batch.commit().then(() => {
      return dispatch({
        type: EDIT_PROGRAM,
        programId,
        program
      })
    })
      .catch(err => err)
  }
}

export const getPhases = programId => {
  return dispatch => {
    return db.collection('programs').doc(programId).get()
      .then(docRef => {
        return docRef.data().phases
      })
  }
}

export const deleteProgram = programId => {
  return dispatch => {
    return db.collection('programs').doc(programId).delete()
      .then(() => {
        return dispatch({
          type: DELETE_PROGRAM,
          programId
        })
      })
      .then(() => {
        return db.collection('programSections').where('programId', '==', programId)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(snap => {
              db.collection('programSections').doc(snap.id).delete()
            })
          })
      })
      .then(() => {
        return db.collection('collections').where('programs', 'array-contains', programId)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(snap => {
              db.collection('collections').doc(snap.id).update({
                programs: fb.firestore.FieldValue.arrayRemove(programId)
              })
            })
              .then(() => {
                return dispatch({
                  type: REMOVE_PROGRAM_FROM_COLLECTION,
                  programId
                })
              })
          })
      })
      .catch(err => err)
  }
}

export const updateOwners = ({ programId, ownerId }) => {
  return dispatch => {
    return db.collection('programs').doc(programId).update({
      ownerId: ownerId
    })
  }
}

export const assignProgram = ({ programId, users }) => {
  return dispatch => {
    return db.collection('programs').doc(programId).update({
      users
    })
      .then(() => {
        return dispatch({
          type: ASSIGN_PROGRAM,
          users,
          programId
        })
      })
      .catch(err => err)
  }
}

export const clientAssignProgram = ({ programId, clientId }) => {
  return dispatch => {
    return db.collection('programs').doc(programId).update({
      users: fb.firestore.FieldValue.arrayUnion(clientId)
    })
      .then(() => {
        return dispatch({
          type: CLIENT_ASSIGN_PROGRAM,
          programId,
          clientId
        })
      })
  }
}

export const unassignProgram = ({ clientId, programId }) => {
  return dispatch => {
    return db.collection('programs').doc(programId).update({
      users: fb.firestore.FieldValue.arrayRemove(clientId)
    })
      .then(() => {
        return dispatch({
          type: UNASSIGN_PROGRAM,
          clientId,
          programId
        })
      })
      .catch(err => err)
  }
}

export const updatePhase = ({ programId, phaseId, sectionId, phaseData }) => {
  return dispatch => {
    return db.collection('programs').doc(programId)
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.data()
        const phaseIndex = data.phases.findIndex(phase => phase.id === phaseId)

        if (sectionId) {
          const sections = data.phases[phaseIndex].sections
          data.phases[phaseIndex].sections = [...sections, sectionId]
        }

        if (phaseData) {
          data.phases[phaseIndex] = Object.assign(data.phases[phaseIndex], phaseData)
        }

        db.collection('programs').doc(programId).update({
          ...data
        })
          .then(() => {
            return dispatch({
              type: UPDATE_PHASE,
              programId,
              phases: data.phases
            })
          })
      })
  }
}

export const deletePhase = ({ programId, phaseId }) => {
  return dispatch => {
    return db.collection('programs').doc(programId)
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.data()

        data.phases = data.phases.filter(phase => phase.id !== phaseId)

        db.collection('programs').doc(programId).update({
          ...data
        })
          .then(() => {
            return dispatch({
              type: EDIT_PROGRAM,
              data
            })
          })
      })
  }
}

export const addPhase = ({ programId, phase }) => {
  return dispatch => {
    return db.collection('programs').doc(programId).update({
      phases: fb.firestore.FieldValue.arrayUnion(phase)
    })
  }
}

export const removeOwner = ({ programId, userId }) => {
  return dispatch => {
    return db.collection('programs').doc(programId).update({
      ownerId: fb.firestore.FieldValue.arrayRemove(userId)
    })
      .then(() => {
        return dispatch({
          type: DELETE_PROGRAM,
          programId
        })
      })
  }
}
