import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'

import { startSubscription } from '../../../actions/stripe'

const PlanButton = ({ price, activeSubscription, freePro, userDetails }) => {
  const [loadCheckout, setLoadCheckout] = useState(false)
  const dispatch = useDispatch()

  const handleCheckout = (priceId) => {
    setLoadCheckout(priceId)

    const success = `${window.location.origin}/#/checkout_success/${userDetails.id}`

    dispatch(
      startSubscription(
        priceId,
        userDetails.id,
        success,
        window.location.origin
      )
    )
  }

  return (
    <Button
      key={price.id}
      variant='outlined'
      fullWidth
      onClick={() => handleCheckout(price.id)}
      disabled={Boolean(activeSubscription)}
      endIcon={
        loadCheckout === price.id && (
          <i className='fas fa-spinner fa-spin' style={{ marginLeft: 6 }} />
        )
      }
    >
      {activeSubscription?.price.id === price.id
        ? 'Current Plan'
        : `$${price.unit_amount / 100} / ${price.interval}`}
    </Button>
  )
}

export default PlanButton
