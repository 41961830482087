import React from 'react'
import { uploadImage, imageValidation, renameImage } from '../../../util'

const Hero = props => {
  const handleImageSelect = id => {
    const elem = document.getElementById(id)
    if (elem && document.createEvent) {
      const evt = document.createEvent('MouseEvents')
      evt.initEvent('click', true, false)
      elem.dispatchEvent(evt)
    }
  }

  const handleSelectImage = e => {
    const image = e.target.files[0]

    const valid = imageValidation(image)

    if (typeof valid === 'string') {
      window.alert(valid)
    }

    const renamedImage = renameImage(image, props.componentId)

    uploadImage('lessons', renamedImage)
      .then(url => {
        const change = { target: { name: 'imageURL', value: url } }

        props.onChange(change, props.componentId)
      })
  }

  return (
    <>
      <div className='lesson_hero_component'>
        <div className='image_container' onClick={() => handleImageSelect(`file_picker_${props.componentId}`)}>
          {
            props.data.imageURL &&
              <img
                src={props.data.imageURL}
              />
          }
        </div>

        <div className='text_preview'>
          <h1>{props.data.title}</h1>
          <h3>{props.data.subtitle}</h3>
          <p>{props.data.text}</p>
        </div>

        <div className='text_input'>
          <label htmlFor='title'>Title</label>
          <input
            type='text'
            name='title'
            placeholder='Title'
            defaultValue={props.data.title}
            onChange={e => props.onChange(e, props.componentId)}
          />

          <label htmlFor='subtitle'>Subtitle</label>
          <input
            type='text'
            name='subtitle'
            placeholder='subtitle'
            defaultValue={props.data.subtitle}
            onChange={e => props.onChange(e, props.componentId)}
          />

          <label htmlFor='text'>Description</label>
          <input
            type='text'
            name='text'
            defaultValue={props.data.text}
            placeholder='Description'
            onChange={e => props.onChange(e, props.componentId)}
          />
        </div>

        <input
          type='file'
          id={`file_picker_${props.componentId}`}
          style={{ display: 'none' }}
          onChange={handleSelectImage}
        />

      </div>
    </>
  )
}

export default Hero
