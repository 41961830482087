import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import lunr from "lunr";

import TopBar from "../Nav/TopBar";
import Search from "../Common/Search";
import UserList from "./UserList";
import ModalUserInfoForm from "./ModalUserInfoForm";
import UserDetails from "./UserDetails/DialogContainer";

import { getClientMetrics } from "../../actions/metrics";
import { receiveMilestones, clearMilestones } from "../../actions/milestones";
import { receiveGoals, clearGoal } from "../../actions/goals";
import { clientLogListener, clientRecordListener } from "../../config/firebase";
import { receiveClientPrograms } from "../../actions/programs";
import { receiveClientNotes } from "../../actions/notes";

const UserListContainer = (props) => {
  const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState();
  const [searchIdx, setSearchIdx] = useState();
  const [addUser, showAddUser] = useState(false);

  useEffect(() => {
    props.dispatch(clearMilestones());
    props.dispatch(clearGoal());

    createSearchIndex()
    setUserList(props.users);
  }, []);

  const createSearchIndex = () => {
    const documents = props.users

    const searchIdx = lunr(function () {
      this.ref("id");
      this.field("first_name");
      this.field("last_name");
      this.field("email");

      documents.forEach(function (doc) {
        this.add(doc);
      }, this);
    });

    setSearchIdx(searchIdx)
  }

  const handleSelect = (clientId) => {
    clientLogListener(clientId);
    clientRecordListener(clientId);
    props.dispatch(receiveMilestones(clientId));
    props.dispatch(getClientMetrics(clientId));
    props.dispatch(receiveClientPrograms(clientId, props.activeUserId));
    props.dispatch(receiveClientNotes(clientId));
    props.dispatch(receiveGoals(clientId)).then(() => {
      props.history.push(
        `${props.type === "client" ? "/clients" : "/coaches"}/${clientId}/Goals`
      );
    });
  };

  const handleEdit = (userData) => {
    setUserData(userData);
  };

  return (
    <div className="container_col">
      <TopBar
        title={props.type === "client" ? "Clients" : "Coaches"}
        tabs={[]}
      />

      <Search
        data={props.users}
        searchIdx={searchIdx}
        setFilteredData={(data) => setUserList(data)}
      />

      <UserList
        users={userList}
        activeUserType={props.activeUserType}
        onSelect={handleSelect}
        onEdit={handleEdit}
      />

      <div className="ui_bottom_bar">
        <button onClick={() => showAddUser(true)} className="button_primary">
          {`Add ${props.type === "client" ? "Client" : "Coach"}`}
        </button>
      </div>

      <ModalUserInfoForm
        showModal={addUser}
        onCloseModal={() => showAddUser(false)}
        newUserType={props.type}
        updateUserList={createSearchIndex}
        editUserData={userData}
      />

      <UserDetails
        key={userData?.id}
        open={Boolean(userData)}
        onClose={() => setUserData(null)}
        userDetails={userData || {}}
      />
    </div>
  );
};

function mapStateToProps(globalState, ownProps) {
  const name = `${globalState.user.info.first_name} ${globalState.user.info.last_name}`;

  let users = [];

  if (ownProps.location.pathname === "/clients") {
    users = globalState.clients.filter((user) => {
      if (user.trainers.includes(globalState.user.auth.uid)) return user;

      if (!user.type.includes("trainer")) return user;
    });
  } else {
    users = globalState.clients.filter((user) => user.type.includes("trainer"));
  }

  return {
    authName: globalState.user.auth.displayName,
    activeUserId: globalState.user.auth.uid,
    activeUserType: globalState.user.info.type,
    activeUserName: name,
    activeUserImg: globalState.user.info.img_url,
    users: users,
    type: ownProps.location.pathname === "/clients" ? "client" : "trainer",
  };
}

export default connect(mapStateToProps)(UserListContainer);
