import { RECEIVE_COACHES } from '../actions/coaches'

const initialState = []

const coaches = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_COACHES:
      return action.coaches

    default:
      return state
  }
}

export default coaches
