import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import lunr from "lunr";
import Modal from "react-modal";

import AssignExistingExercise from "./AssignExistingExercise";
import ExerciseForm from "../../Exercises/ExerciseForm";
import AssignExistingFood from "./AssignExistingFood";
import FoodForm from "../../Food/FoodForm";
import Search from "../../Common/Search";
import ExerciseTagSelectors from "../../Util/ExerciseTagSelectors";
import AssignExistingLesson from "./AssignExistingLesson";

const ModalAddItem = (props) => {
  const [page, setPage] = useState(1);
  const [type, setType] = useState();
  const [search, setSearch] = useState();
  const [exerciseList, setExerciseList] = useState([]);

  useEffect(() => {
    console.log('effect')
    setType(props.programType);
    setExerciseList(props.exercises);
    createSearchIndex();
  }, []);

  const handleClose = () => {
    setPage(1);

    props.onCloseModal();
  };

  const createSearchIndex = () => {
    const documents = props.exercises.map((exercise) => {
      // const movement = exercise.tag_movement
      //   .toString()
      //   .replace(/[\,\[\]]/g, " ")
      //   .trim();

      // const muscles = exercise.tag_muscle
      //   .toString()
      //   .replace(/[\,\[\]]/g, " ")
      //   .trim();

      return {
        id: exercise.id,
        name: exercise.name,
        // tag_movement: movement,
        // tag_muscle: muscles,
      };
    });

    const searchIdx = lunr(function () {
      this.ref("id");
      this.field("name");
      // this.field("tag_movement");
      // this.field("tag_muscle");

      documents.forEach(function (doc) {
        this.add(doc);
      }, this);
    });

    // console.log(searchIdx)

    setSearch(searchIdx);
  };

  return (
    <Modal
      isOpen={props.showModal}
      onRequestClose={handleClose}
      className="modal"
      overlayClassName="modal_overlay"
    >
      <div className="modal_wrapper" style={{ height: 95 + "vh" }}>
        <span className="modal_header">
          <h3>{page === 1 ? `Add ${type}` : `Create ${type}`}</h3>
          <i className="fas fa-times" onClick={handleClose} />
        </span>

        {page === 1 && (
          <Search
            data={props.exercises}
            searchIdx={search}
            setFilteredData={(data) => setExerciseList(data)}
          />
        )}

        <div className="modal_content">
          {page === 1 && type === "Exercise" && (
            <AssignExistingExercise
              onCloseModal={handleClose}
              onChangePage={() => setPage(page === 1 ? 2 : 1)}
              onAssignExercise={props.onAssignItem}
              exerciseList={exerciseList}
            />
          )}

          {page === 2 && type === "Exercise" && (
            <ExerciseForm
              onCloseModal={handleClose}
              onChangePage={() => setPage(page === 1 ? 2 : 1)}
              onAssignExercise={props.onAssignItem}
            />
          )}

          {page === 1 && type === "Nutrition" && (
            <AssignExistingFood
              onCloseModal={handleClose}
              onChangePage={() => setPage(page === 1 ? 2 : 1)}
              onAssignFood={props.onAssignItem}
              searchFilter={search}
            />
          )}

          {page === 2 && type === "Nutrition" && (
            <FoodForm
              onCloseModal={handleClose}
              onChangePage={() => setPage(page === 1 ? 2 : 1)}
              onAssignFood={props.onAssignItem}
            />
          )}

          {page === 1 && type === "Lesson" && (
            <AssignExistingLesson
              assignedLessons={props.assignedLessons}
              onCloseModal={handleClose}
              onChangePage={() => setPage(page === 1 ? 2 : 1)}
              onAssignLesson={props.onAssignItem}
              searchFilter={search}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

function mapStateToProps(globalState) {
  return {
    exercises: globalState.exercises.sort((a, b) =>
      a.name.localeCompare(b.name)
    ),
  };
}

export default connect(mapStateToProps)(ModalAddItem);
