import React from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const CustomCheckbox = ({
  label,
  name,
  value,
  onChange
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={value || false}
          onChange={onChange}
          color='primary'
        />
      }
      label={label}
    />
  )
}

export default CustomCheckbox
