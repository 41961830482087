import {
  GET_PRODUCTS
} from '../actions/stripe'

const initialState = []

const stripe = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return action.products

    default:
      return state
  }
}

export default stripe
