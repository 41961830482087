import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fb } from '../../config/firebase'
import CreateCheckItem from './CreateCheckItem'
import { createMetric, editMetric } from '../../actions/metrics'

import TextField from '@material-ui/core/TextField'
import CustomSelect from '../Common/CustomSelect'
import MenuItem from '@material-ui/core/MenuItem'
import CustomCheckbox from '../Common/CustomCheckbox'
import CustomAutocomplete from '../Common/CustomAutocomplete'

class MetricForm extends Component {
  state = {
    ownerId: [],
    users: [],
    checklist: [],
    isLoading: null,
    showError: false,
    errorMessage: '',
    displayChecklist: false
  }

  componentDidMount () {
    if (this.props.selectedMetric) {
      this.setState({
        checklist: this.props.selectedMetric.checklist || [],
        displayChecklist: this.props.selectedMetric.checklist,
        users: this.props.selectedMetric.users,
        ownerId: this.props.selectedMetric.ownerId
      })
    }
  }

  handleChange = e => {
    if (e.target.name === 'global') {
      let owner = this.props.selectedMetric ? this.props.selectedMetric.ownerId : [this.props.activeUserId]
      if (e.target.checked) owner.push('global')
      else owner = owner.filter(id => id !== 'global')

      this.setState({
        ownerId: owner
      })
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        showError: false
      })
    }
  }

  handleSelectUnits = e => {
    const unit = this.props.units.find(unit => unit.id === e.target.value)

    this.setState({
      unitOfMeasurementId: unit.id,
      displayChecklist: unit.type === 'CHECKLIST'
    })
  }

  handleNewItem = item => {
    this.setState({
      checklist: { ...this.state.checklist, [item]: false }
    })
  }

  handleDeleteItem = item => {
    const list = { ...this.state.checklist }

    delete list[item]

    this.setState({
      checklist: list
    })
  }

  handleSelectTag = tags => {
    if (tags === null) {
      this.setState({
        users: []
      })
    } else {
      const values = tags.map(tag => tag.value)

      this.setState({
        users: values
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState({
      isLoading: 'submit'
    })

    const metric = { ...this.state }
    delete metric.displayChecklist
    delete metric.isLoading
    delete metric.showError
    delete metric.errorMessage
    if (metric.checklist.length === 0) delete metric.checklist

    if (this.props.selectedMetric) {
      this.editMetric(metric)
    } else {
      this.createMetric(metric)
    }
  }

  createMetric = metric => {
    if (metric.ownerId.length === 0) metric.ownerId = [this.props.activeUserId]
    metric.users = this.props.userId ? [this.props.userId] : []
    metric.creatorName = this.props.activeUserName
    metric.unitOfMeasurementId = this.state.unitOfMeasurementId
    metric.dateCreated = new fb.firestore.Timestamp.now() // eslint-disable-line

    if (metric.name) {
      this.props.dispatch(createMetric(metric))
        .then(() => this.success(metric))
        .catch(err => this.error(err.message))
    } else {
      this.error('Metric must have a name')
    }
  }

  editMetric = metric => {
    metric.lastUpdated = new fb.firestore.Timestamp.now() // eslint-disable-line

    this.props.dispatch(editMetric(this.props.selectedMetric.id, metric))
      .then(() => this.success())
      .catch(err => this.error(err.message))
  }

  success = metric => {
    if (this.props.onUpdateList) this.props.onUpdateList()
    this.props.onSuccess()
    this.props.onCloseModal(metric)
  }

  error = err => {
    this.setState({
      isLoading: null,
      showError: true,
      errorMessage: err
    })
  }

  render () {
    return (
      <>
        <form onSubmit={this.handleSubmit} className='modal_content'>
          <TextField
            label='Metric'
            name='name'
            onChange={this.handleChange}
            defaultValue={this.props.selectedMetric?.name}
            required
          />

          <CustomSelect
            label='Unit of Measurement'
            name='unitOfMeasurementId'
            value={this.props.selectedMetric?.unitOfMeasurementId || this.state.unitOfMeasurementId || ''}
            onChange={this.handleSelectUnits}
            disabled={this.props.selectedMetric}
            required
          >
            {
              this.props.units.map((unit, i) => (
                <MenuItem key={i} value={unit.id}>
                  {unit.unit}
                </MenuItem>
              ))
            }
          </CustomSelect>

          {
            this.state.displayChecklist &&
              <>
                {
                  Object.keys(this.state.checklist).map((check, i) => (
                    <span key={i} className='metric_checklist_container'>
                      <p>{check}</p>

                      <button
                        type='button'
                        id='kebab_milestone'
                        onClick={() => this.handleDeleteItem(check)}
                      >
                        <i className='far fa-lg fa-times-circle' />
                      </button>
                    </span>
                  ))
                }
                <CreateCheckItem
                  onNewItem={this.handleNewItem}
                />
              </>
          }

          {
            this.props.activeUserType.includes('admin') && !this.state.displayChecklist &&
              <CustomCheckbox
                name='global'
                label='Global Metric'
                value={this.props.selectedMetric?.ownerId.includes('global')}
                onChange={this.handleChange}
              />
          }

          {
            this.props.clients &&
              <>
                <CustomAutocomplete
                  label='Assign to client'
                  name='users'
                  onChange={this.handleChange}
                  value={this.state.users.map(id => {
                    const client = this.props.clients.find(client => client.id === id)
                    return ({
                      name: `${client?.first_name} ${client?.last_name}`, // eslint-disable-line
                      value: id
                    })
                  }) || []}
                  options={this.props.clients.map(client => (
                    {
                      name: `${client.first_name} ${client.last_name}`,
                      value: client.id
                    }
                  ))}
                />

                {/* <CustomSelect
                  label='Assign to client'
                  name='users'
                  onChange={this.handleChange}
                  value={this.state.users || []}
                  multiple
                >
                  {
                    this.props.clients.map(client => (
                      <MenuItem key={client.id} value={client.id}>
                        {`${client.first_name} ${client.last_name}`}
                      </MenuItem>
                    ))
                  }
                </CustomSelect> */}
              </>
          }

          <p className='ui_error'>{this.state.showError && this.state.errorMessage}</p>

        </form>
        <div className='modal_buttons'>
          {
            this.props.clientId &&
              <button
                type='button'
                className='button_navigational'
                onClick={this.props.onChangePage}
              >
                <span className='row'>
                  <i className='fas fa-chevron-left' />
                  Back
                </span>
              </button>
          }
          <button
            onClick={this.handleSubmit}
            className='button_primary'
            disabled={(this.state.unitOfMeasurementId === undefined && !this.props.selectedMetric) || (this.state.displayChecklist && this.state.checklist.length < 1)}
          >
            {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
          </button>
        </div>
      </>
    )
  }
}

function mapStateToProps (globalState) {
  return {
    units: globalState.units,
    activeUserId: globalState.user.auth.uid,
    activeUserType: globalState.user.info.type
  }
}

export default connect(mapStateToProps)(MetricForm)
