import React, { useState, useEffect } from 'react'
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd'

import { onDragEnd } from '../../../util'

import KebabMenu from '../../Common/KebabMenu'
import ExerciseItem from './ExerciseItem'
import FoodItem from './FoodItem'

const SectionGroup = props => {
  const [activeModal, setActiveModal] = useState(null)
  const [items, setItems] = useState(props.items)
  const [showHeader, toggleShowHeader] = useState(false)

  useEffect(() => {
    setItems(props.items)
  }, [props.items])

  useEffect(() => {
    toggleShowHeader(props.items.length > 1 || props.group.name !== null)
  }, [props.items])

  useEffect(() => {
    if (props.showHeader === props.group.id) toggleShowHeader(true)
  }, [props.showHeader])

  const handleChangeName = e => {
    const groups = [...props.groups]
    const index = groups.findIndex(group => group.id === props.group.id)

    groups[index].name = e.target.value === '' ? null : e.target.value

    const update = {
      target: {
        name: 'groups',
        value: groups
      }
    }

    props.onChange(update, props.sectionId)
  }

  const handleChangeContent = ({ e, itemKey, item }) => {
    const content = [...props.content]
    const index = content.findIndex(item => item.key === itemKey)

    if (e) content[index][e.target.name] = e.target.value
    else if (item) content[index] = item

    const change = { target: { name: 'content', value: content } }

    props.onChange(change, props.sectionId)
  }

  const handleDeleteGroup = e => {
    props.group.items.forEach(item => {
      props.onUnassignItem(item, props.sectionId)
    })
  }

  const handleCopyGroup = () => {

  }

  const handleDragEnd = res => {
    if (res.destination === null) {
      return
    }

    if (res.destination.index === res.source.index) {
      return
    }

    const sorted = onDragEnd(res, items)

    sorted.forEach(item => handleChangeContent({ item, itemKey: item.key }))
  }

  return (
    <Draggable
      draggableId={props.group.id}
      index={props.index}
    >
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
        >

          <div className='program_group' style={!showHeader ? { marginTop: -30 + 'px' } : undefined}>

            <i
              className='fas fa-grip-vertical grip'
              style={!showHeader ? { position: 'relative', top: 58 + 'px', left: 2 + 'px' } : undefined}
              {...provided.dragHandleProps}
            />

            {
              showHeader &&
                <>
                  <input
                    type='text'
                    name='name'
                    placeholder={props.items.length > 2 ? 'Circuit' : 'Superset'}
                    defaultValue={props.group.name}
                    onChange={handleChangeName}
                  />

                  {
                    props.context === 'program' &&
                      <KebabMenu
                        context='section_group'
                        onCopy={handleCopyGroup}
                        onUngroup={() => props.onRemoveFromGroup(props.group.items, props.group.id)}
                        onDelete={handleDeleteGroup}
                      />
                  }
                </>
            }
          </div>

          <div style={{ display: 'flex', direction: 'row' }}>
            {
              showHeader &&
                <hr className='ui_group_link' />
            }

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={props.group.id}>
                {provided => (
                  <div
                    style={{ width: 100 + '%' }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {
                      items.sort((a, b) => a.index - b.index).map(item => {
                        if (item.metrics) {
                          const itemDetails = props.globalExercises.find(exercise => exercise.id === item.id)

                          if (itemDetails !== undefined) {
                            return (
                              <ExerciseItem
                                key={item.key}
                                itemKey={item.key}
                                item={item}
                                itemDetails={itemDetails}
                                groupId={props.group.id}
                                groupLength={props.group.items.length}
                                sectionId={props.sectionId}
                                selected={props.selected}
                                onChange={handleChangeContent}
                                onUnassignItem={props.onUnassignItem}
                                onSelectItem={props.onSelectItem}
                                onRemoveFromGroup={props.onRemoveFromGroup}
                                onAddHeader={() => toggleShowHeader(true)}
                                showModal={activeModal === 'editItem'}
                                onModal={(modal) => setActiveModal(modal)}
                                context={props.context}
                              />
                            )
                          }
                        } else {
                          const itemDetails = props.globalFood.find(food => food.id === item.id)

                          return (
                            <FoodItem
                              key={item.key}
                              itemKey={item.key}
                              item={item}
                              groupId={props.group.id}
                              groupLength={props.groupLength}
                              sectionId={props.sectionId}
                              itemDetails={itemDetails}
                              selected={props.selected}
                              onChange={handleChangeContent}
                              onUnassignItem={props.onUnassignItem}
                              onSelectItem={props.onSelectItem}
                              onRemoveFromGroup={props.onRemoveFromGroup}
                              onAddHeader={() => toggleShowHeader(true)}
                              onModal={(modal) => setActiveModal(modal)}
                            />
                          )
                        }
                      })
                    }
                    {provided.placeholder}

                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <hr />

        </div>
      )}
    </Draggable>
  )
}

export default SectionGroup
