import { db } from '../config/firebase'

export const RECEIVE_GOALS = 'RECEIVE_GOAL'
export const UPDATE_GOAL = 'UPDATE_GOAL'
export const CREATE_GOAL = 'CREATE_GOAL'
export const CLEAR_GOAL = 'CLEAR_GOAL'

export const receiveGoals = clientId => {
  return dispatch => {
    return db.collection('clientGoals').where('userId', '==', clientId).get()
      .then(querySnapshot => {
        const goals = []
        querySnapshot.forEach(snap => {
          goals.push({ ...snap.data(), id: snap.id })
        })

        return dispatch({
          type: RECEIVE_GOALS,
          goals
        })
      })
  }
}

export const updateGoal = ({ id, goal }) => {
  return dispatch => {
    return db.collection('clientGoals').doc(id).update({
      goal
    })
      .then(() => {
        return dispatch({
          type: UPDATE_GOAL,
          id,
          goal
        })
      })
      .catch(err => err)
  }
}

export const createGoal = goal => {
  return dispatch => {
    return db.collection('clientGoals').add({
      ...goal
    })
      .then(docRef => {
        goal.id = docRef.id
        return dispatch({
          type: CREATE_GOAL,
          goal
        })
      })
  }
}

export const clearGoal = () => {
  return {
    type: CLEAR_GOAL
  }
}
