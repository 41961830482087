import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import * as yup from 'yup'

import { establishListeners } from '../../util'
import { fb } from '../../config/firebase'
import { loginUser, logoutUser } from '../../actions/user'

import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const Login = (props) => {
  const { values, touched, errors, handleChange, handleSubmit, isSubmitting } =
    props

  const params = useParams()

  useEffect(() => {
    fb.auth().onAuthStateChanged((user) => {
      if (user) {
        props.dispatch(loginUser(user)).then(({ info, user }) => {
          console.log('user: ', info)
          const listeners = establishListeners(user.uid, info.type)
          listeners.then(() => {
            if (info.type.includes('trainer')) {
              !window.location.hash.includes('register') &&
                props.history.replace('/clients')
            } else {
              !window.location.hash.includes('register') &&
                props.history.replace(`/profile/${user.uid}`)
            }
          })
        })
      } else {
        fb.analytics().logEvent('user_logged_out')
        props.dispatch(logoutUser())
      }
    })
  }, [])

  const confirmation = params.userId

  return (
    <div className='container_col'>
      <div className='ui_oni_bar'>
        <img src='/img/Logo-Colour-White-small.png' />
      </div>

      <div className='login'>
        <form onSubmit={handleSubmit}>
          {confirmation && <p>Please log in to access your account details</p>}
          <TextField
            name='email'
            label='Email'
            value={values.email}
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            name='password'
            label='Password'
            type='password'
            value={values.password}
            onChange={handleChange}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
          />
          {!confirmation && (
            <FormControlLabel
              control={
                <Switch
                  name='remember'
                  value={values.remember}
                  onChange={handleChange}
                  color='primary'
                />
              }
              label='Remember Me'
              labelPlacement='start'
            />
          )}

          <p className='ui_error'>{errors.submit}</p>

          <button
            className='button_primary'
            onClick={handleSubmit}
            style={{ width: '100%', marginLeft: 0 }}
          >
            {isSubmitting ? <i className='fas fa-spinner fa-spin' /> : 'Login'}
          </button>

          <Link to='/recover' style={{ textAlign: 'center' }}>
            I forgot!
          </Link>

          {!confirmation && (
            <div style={{ marginTop: 16, textAlign: 'center' }}>
              <Typography variant='body1'>
                Don't have an account? <Link to='/register'>Sign Up</Link>
              </Typography>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

const formikEnhancer = withFormik({
  mapPropsToValues: (props) => ({
    remember: false,
    email: '',
    password: ''
  }),
  validationSchema: () => {
    return yup.lazy((values) => {
      return yup.object().shape(
        Object.assign({
          email: yup
            .string()
            .email()
            .required('please enter your email address'),
          password: yup.string().required('please enter your password'),
          remember: yup.boolean()
        })
      )
    })
  },
  handleSubmit: ({ ...payload }, { props, setSubmitting, setErrors }) => {
    fb.auth()
      .setPersistence(
        payload.remember
          ? fb.auth.Auth.Persistence.SESSION
          : fb.auth.Auth.Persistence.LOCAL
      )
      .then(() => {
        fb.auth()
          .signInWithEmailAndPassword(payload.email, payload.password)
          .catch((err) => {
            console.log(err)
            setErrors({ submit: 'Login failed. Please try again' })
            setSubmitting(false)
          })
      })
      .catch((err) => {
        console.log(err)
        setErrors({ submit: 'Login failed. Please try again' })
        setSubmitting(false)
      })
  }
})

export default formikEnhancer(connect()(Login))
