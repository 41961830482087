import React from 'react'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  planItem: {
    minHeight: 200,
    padding: 12
  },
  activePlan: {
    outline: '2px solid black'
  }
}))

const PlanBox = ({
  name,
  description,
  // activeSubscription,
  // freePro = false,
  active = false
}) => {
  const classes = useStyles()

  return (
    <Paper className={`${classes.planItem} ${active && classes.activePlan}`}>
      <Typography variant='h6'>{name}</Typography>
      <Typography>{description}</Typography>
    </Paper>
  )
}

export default PlanBox
