import React, { useState } from 'react'
import AddComponent from './AddComponent'

const TextInput = props => {
  const [marked, setMark] = useState(false)

  return (
    <>
      <div
        className='lesson_input_component'
        style={marked ? { backgroundColor: 'rgba(234, 68, 57, 0.5)' } : undefined}
      >
        <label htmlFor='label'>Question:</label>
        <input
          type='text'
          name='label'
          defaultValue={props.data.label}
          onChange={e => props.onChange(e, props.componentId)}
        />

      </div>
      <AddComponent
        onMenu={props.onMenu}
        onDelete={props.onDelete}
        onMark={bool => setMark(bool)}
        componentId={props.componentId}
      />
    </>
  )
}

export default TextInput

export const defaultTextInput = {
  component: 'text_input',
  componentId: null,
  data: {
    label: ''
  }
}
