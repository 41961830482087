import React, { Component } from 'react'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'
import lunr from 'lunr'

import { deleteExercise } from '../../actions/exercises'

import Search from '../Common/Search'
import ExerciseTagSelectors from '../Util/ExerciseTagSelectors'
import ExerciseList from './ExerciseList'
import ExerciseForm from './ExerciseForm'
import ModalWrapper from '../Common/ModalWrapper'
import TopBar from '../Nav/TopBar'
import Toast from '../Common/Toast'

class ExerciseListContainer extends Component {
  state = {
    isLoading: false,
    displayModalExercise: false,
    tag_movement: [],
    tag_muscle: [],
    showFilter: false,
    exerciseList: [],
    showToast: false,
  }

  componentDidMount () {
    this.createSearchIndex()

    this.setState({
      exerciseList: this.props.exercises,
    })
  }

  createSearchIndex = () => {
    const documents = this.props.exercises.map(exercise => {
      const movement = exercise.tag_movement.toString().replace(/[\,\[\]]/g, ' ').trim()

      const muscles = exercise.tag_muscle.toString().replace(/[\,\[\]]/g, ' ').trim()

      return ({
        id: exercise.id,
        name: exercise.name,
        tag_movement: movement,
        tag_muscle: muscles
      })
    })
  
    const searchIdx = lunr(function () {
      this.ref('id')
      this.field('name')
      this.field('tag_movement')
      this.field('tag_muscle')
  
      documents.forEach(function(doc) {
        this.add(doc)
      }, this)
    })

    this.setState({
      searchIdx
    })
  }

  handleModal = e => {
    this.setState({
      [e]: !this.state[e],
      selectedExercise: undefined
    })
  }

  handleSelect = id => {
    const exercise = this.props.exercises.find(exercise => exercise.id === id)

    this.setState({
      selectedExercise: exercise,
      displayModalExercise: true
    })
  }

  handleDelete = id => {
    if (!window.confirm('Are you sure you want to delete this exercise? This action cannot be undone')) return

    this.props.dispatch(deleteExercise(id))
      .then(() => this.handleSearch(this.state.searchFilter))
  }

  // handleSelectTag = (e) => {
  //   this.setState({
  //     [e.target.name]: e.target.value
  //   }, () => this.handleSearch(this.state.searchFilter))
  // }

  render () {
    return (
      <div className='container_col'>
        <TopBar
          title='Exercises'
          tabs={[]}
        />

        {/* <Search
          onChange={regex => this.handleSearch(regex)}
          onShowFilter={() => this.setState({ showFilter: !this.state.showFilter })}
          filterActive
        /> */}

        <Search
          data={this.props.exercises}
          searchIdx={this.state.searchIdx}
          setFilteredData={data => this.setState({ exerciseList: data })}
        />

        {
          this.state.showFilter &&
            <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
              <ExerciseTagSelectors
                onChange={this.handleSelectTag}
                dataMovement={this.state.tag_movement}
                dataMuscle={this.state.tag_muscle}
              />
            </div>
        }

        <div className='exercise_list_item'>
          <label style={{ gridColumn: '1 / span 2' }}>Exercise Name</label>
          <label className='movement'>Movement Pattern</label>
          <label className='muscle'>Body Area</label>
        </div>

        <ExerciseList
          exercises={this.state.exerciseList}
          activeUserType={this.props.activeUserType}
          onSelect={this.handleSelect}
          onDelete={this.handleDelete}
        />

        <div className='ui_bottom_bar'>
          <button
            onClick={() => this.handleModal('displayModalExercise')}
            className='button_primary'
          >
            {this.state.isLoading ? <i className='fas fa-spinner fa-spin' /> : 'Create Exercise'}
          </button>
        </div>

        <ModalWrapper
          key={uuid()}
          modalHeader={this.state.selectedExercise ? 'Edit exercise' : 'Create exercise'}
          video_url={this.state.selectedExercise && this.state.selectedExercise.video_url}
          showModal={this.state.displayModalExercise}
          onCloseModal={() => this.handleModal('displayModalExercise')}
        >
          <ExerciseForm
            onCloseModal={() => this.handleModal('displayModalExercise')}
            selectedExercise={this.state.selectedExercise}
            onUpdateList={this.createSearchIndex}
            onSuccess={() => this.setState({ showToast: true })}
          />
        </ModalWrapper>

        <Toast
          showToast={this.state.showToast}
          message='Exercise Created'
          onClose={() => this.setState({ showToast: false })}
        />
      </div>
    )
  }
}

function mapStateToProps (globalState) {
  return {
    exercises: globalState.exercises.sort((a, b) => a.name.localeCompare(b.name)),
    exerciseMetrics: globalState.exerciseMetrics,
    activeUserType: globalState.user.info.type,
    activeUserId: globalState.user.auth.uid,
    activeUserName: globalState.user.auth.displayName
  }
}

export default connect(mapStateToProps)(ExerciseListContainer)
