import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { fb } from '../../../config/firebase'
import { v4 as uuid } from 'uuid'

import { onDragEnd } from '../../../util'

import Section from './Section'
import ModalAddItem from './ModalAddItem'

const SectionContainer = props => {
  const [sections, updateSections] = useState([])
  const [activeModal, setActiveModal] = useState(null)
  const [activeSectionId, setActiveSectionId] = useState(null)
  const [drawer, toggleDrawer] = useState(props.drawer)

  useEffect(() => {
    updateSections(props.sections)
  }, [props.sections])

  useEffect(() => {
    toggleDrawer(props.drawer)
  }, [props.drawer])

  const handleModal = ({ modal, sectionId }) => {
    setActiveSectionId(sectionId)
    setActiveModal(modal)
  }

  const handleCreateSection = () => {
    const newSection = {
      id: uuid(),
      index: sections.length,
      programId: props.programId,
      phaseId: props.activePhaseId,
      dateCreated: new fb.firestore.Timestamp.now(), // eslint-disable-line
      content: [],
      groups: []
    }

    props.onChangeSection(newSection)
  }

  const handleChangeSection = (e, sectionId) => {
    const section = sections.find(s => s.id === sectionId)

    section[e.target.name] = e.target.value

    props.onChangeSection(section)
  }

  const handleAssignItem = ({ items, type }) => {
    const section = sections.find(s => s.id === activeSectionId)

    items.forEach(item => {
      const itemKey = uuid()

      switch (type) {
        case 'Exercise': {
          // Get array of exercise metrics associated with this exercise
          const metricIds = props.globalExercises.find(gExercise => gExercise.id === item).metrics

          // Create metric object for each one
          const metrics = props.globalExerciseMetrics.reduce((res, metric) => {
            if (metricIds.includes(metric.id)) {
              res.push({
                name: metric.name,
                id: metric.id,
                value: [null]
              })
            }

            return res
          }, [])

          // Create new item
          const newItem = {
            id: item,
            key: itemKey,
            notes: '',
            index: 0,
            metrics,
            sets: { num: 1, display: true }
          }

          section.content.push(newItem)

          break
        }

        case 'Food': {
          const newItem = {
            id: item,
            key: itemKey,
            portion: '',
            notes: '',
            index: 0
          }

          section.content.push(newItem)

          break
        }
      }

      // Add new item to group
      const newGroup = {
        id: uuid(),
        name: null,
        index: section.groups.length,
        items: [itemKey]
      }

      section.groups.push(newGroup)
    })

    props.onChangeSection(section)
  }

  const handleUnassignItem = (itemKey, sectionId) => {
    // Remove item from content
    const section = sections.find(s => s.id === sectionId)
    const group = section.groups.find(group => group.items.includes(itemKey))

    let index = 0
    section.content = section.content.filter(item => {
      if (item.key === itemKey) index = item.index
      else return true
    })

    // Update index for other group items
    section.content.forEach(content => {
      if (group.items.includes(content.key) && content.index > index) {
        content.index -= 1
      }
    })

    // Remove item from group, or delete group if empty
    if (group.items.length === 1) {
      section.groups = section.groups.filter(g => g.id !== group.id)
    } else {
      const index = section.groups.findIndex(g => g === group)
      group.items = group.items.filter(key => key !== itemKey)
      section.groups[index] = group
    }

    // Update index for other groups
    section.groups.forEach((group, i) => {
      group.index = i
    })

    props.onChangeSection(section)
  }

  const handleDragEnd = res => {
    if (res.destination === null) {
      return
    }

    if (res.destination.index === res.source.index) {
      return
    }

    const sorted = onDragEnd(res, sections)

    sorted.forEach(section => {
      props.onChangeSection(section)
    })
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='sections' direction='horizontal'>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className='program_sections_container'
            style={{ width: `calc(100vw - ${drawer ? '250px' : '50px'})` }}
          >

            {
              sections.sort((a, b) => a.index - b.index).map((section, i) => {
                return (
                  <Section
                    key={section.id}
                    index={i}
                    {...section}
                    programType={props.programType}
                    globalExercises={props.globalExercises}
                    globalExerciseMetrics={props.globalExerciseMetrics}
                    globalFood={props.globalFood}
                    onChange={handleChangeSection}
                    onDelete={props.onDeleteSection}
                    onModal={handleModal}
                    onUnassignItem={handleUnassignItem}
                    displayCopyModal={props.displayCopyModal}
                    context='program'
                  />
                )
              })
            }
            {provided.placeholder}

            <div style={{ width: 33 + 'vw', display: 'flex', justifyContent: 'center' }}>
              <button
                className='button_primary'
                onClick={handleCreateSection}
              >
                  Add Section
              </button>
            </div>

            <ModalAddItem
              key={uuid()}
              showModal={activeModal === 'addItem'}
              onCloseModal={() => setActiveModal(null)}
              sectionId={activeSectionId}
              programType={props.programType}
              onAssignItem={handleAssignItem}
            />
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function mapStateToProps (globalState) {
  return {
    globalExercises: globalState.exercises,
    globalExerciseMetrics: globalState.exerciseMetrics,
    globalFood: globalState.food
  }
}

export default connect(mapStateToProps)(SectionContainer)
