import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import CustomCheckbox from '../../Common/CustomCheckbox'

const AssignExistingProgram = props => {
  const [addedPrograms, setAddedPrograms] = useState([])

  useEffect(() => {
    setAddedPrograms(props.addedPrograms)
  }, [])

  const handleChange = e => {
    if (e.target.checked) {
      const newProgram = props.programs.find(p => p.id === e.target.name)
      setAddedPrograms([...addedPrograms, newProgram])
    } else {
      setAddedPrograms(addedPrograms.filter(p => p.id !== e.target.name))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    props.assignPrograms(addedPrograms)
  }

  return (
    <>
      <form onSubmit={handleSubmit} className='modal_content'>
        {
          props.programs.map(program => {
            return (
              <label htmlFor={program.id} key={program.id}>
                <div
                  className='program_section_item'
                  style={{ paddingBottom: 0, margin: 0 }, addedPrograms.includes(program.id) ? { backgroundColor: 'rgb(218, 225, 243)' } : undefined} // eslint-disable-line
                >
                  <CustomCheckbox
                    name={program.id}
                    value={Boolean(addedPrograms.find(p => p.id === program.id))}
                    onChange={handleChange}
                  />

                  <img src={program.header.image_url} />

                  <p className='header'>{program.header.title}</p>
                </div>
              </label>
            )
          })
        }
      </form>

      <div className='modal_buttons'>
        <button
          className='button_primary'
          onClick={handleSubmit}
        >
          {`Add programs (${addedPrograms.length}) to collection`}
        </button>
      </div>
    </>
  )
}

function mapStateToProps (globalState) {
  return {
    programs: globalState.programs.sort((a, b) => a.header.title.localeCompare(b.header.title))
  }
}

export default connect(mapStateToProps)(AssignExistingProgram)
