import React from 'react'

import Grid from '@material-ui/core/Grid'
import CustomAutocomplete from '../Common/CustomAutocomplete'

const ExerciseTagSelectors = props => {
  const movement = ['Squat', 'Hinge', 'Lunge', 'Single Leg', 'Horizontal Push', 'Horizontal Pull', 'Overhead Push', 'Overhead Pull', 'Single Arm', 'Rotation', 'Miscellaneous']

  const muscles = ['Shoulders', 'Neck', 'Upper Back', 'Mid Back', 'Lower Back', 'Chest', 'Biceps', 'Triceps', 'Forearms', 'Wrist', 'Core - Abdominals', 'Core - Obliques', 'Core - Transverse Abdominis', 'Hip Flexors', 'Glutes', 'Quads', 'Hamstrings', 'Adductors', 'Calves', 'Shin', 'Ankle', 'Foot']

  return (

    <Grid container spacing={2}>
      <Grid item xs={6}>
        <CustomAutocomplete
          label='Movement Pattern'
          name='tag_movement'
          value={props.dataMovement?.map(m => ({
            name: m, value: m
          }))}
          onChange={props.onChange}
          options={movement.map(m => ({
            name: m, value: m
          }))}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomAutocomplete
          label='Body Area'
          name='tag_muscle'
          value={props.dataMuscle?.map(m => ({
            name: m, value: m
          }))}
          onChange={props.onChange}
          options={muscles.map(m => ({
            name: m, value: m
          }))}
        />
      </Grid>
    </Grid>
  )
}

export default ExerciseTagSelectors
