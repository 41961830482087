import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import AddComponent from './AddComponent'

const Text = props => {
  const [marked, setMark] = useState(false)

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link']
    ]
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  const handleChange = e => {
    const change = { target: { name: 'text', value: e } }

    props.onChange(change, props.componentId)
  }

  return (
    <>
      <div
        className='lesson_text_component'
        style={marked ? { backgroundColor: 'rgba(234, 68, 57, 0.5)' } : undefined}
      >
        <ReactQuill
          theme='snow'
          modules={modules}
          formats={formats}
          onChange={handleChange}
          defaultValue={props.data.text}
        />

      </div>
      <AddComponent
        onMenu={props.onMenu}
        onDelete={props.onDelete}
        onMark={bool => setMark(bool)}
        componentId={props.componentId}
      />
    </>
  )
}

export default Text

export const defaultText = {
  component: 'text',
  componentId: null,
  data: {
    text: ''
  }
}
