import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { v4 as uuid } from 'uuid'
import { onDragEnd } from '../../../util'

import Phase from './Phase'

const PhaseContainer = props => {
  const [phases, updatePhases] = useState([])

  useEffect(() => {
    updatePhases(props.phases)
  }, [props.phases])

  const handleCreatePhase = () => {
    const newPhase = {
      index: props.phases.length,
      id: uuid(),
      name: `Week ${props.phases.length + 1}`
    }

    props.onChangePhase(newPhase)
  }

  const handleDragEnd = res => {
    if (res.destination === null) {
      return
    }

    if (res.destination.index === res.source.index) {
      return
    }

    const sorted = onDragEnd(res, phases)

    sorted.forEach(phase => props.onChangePhase(phase))
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='phases' direction='horizontal'>
        {provided => (
          <div
            className='program_phase_container'
            ref={provided.innerRef} {...provided.droppableProps}
          >
            {
              phases.sort((a, b) => a.index - b.index).map(phase => (
                <Phase
                  key={phase.id}
                  phase={phase}
                  activeProgramId={props.programId}
                  isActive={props.activePhaseId === phase.id}
                  onClick={() => props.onSelectPhase(phase.id)}
                  onChangePhase={props.onChangePhase}
                  onDeletePhase={props.onDeletePhase}
                  displayCopyModal={props.displayCopyModal}
                />
              ))
            }
            {provided.placeholder}

            <button
              onClick={handleCreatePhase}
            >
              <i className='fas fa-plus' />
            </button>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default PhaseContainer
