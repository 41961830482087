import React, { useState, useEffect } from 'react'

const Distance = props => {
  const [kilometres, setKilometres] = useState(0)
  const [metres, setMetres] = useState(0)
  const [centimetres, setCentimetres] = useState(0)
  const [millimetres, setMillimetres] = useState(0)

  const handleChange = e => {
    switch (e.target.name) {
      case 'kilometres':
        setKilometres(e.target.value)
        break

      case 'metres':
        setMetres(e.target.value)
        break

      case 'centimetres':
        setCentimetres(e.target.value)
        break

      case 'millimetres':
        setMillimetres(e.target.value)
        break

      default:
        break
    }
  }

  // split value into different units
  useEffect(() => {
    let x = props.value

    setKilometres((x - (x % 1000000)) / 1000000 || '')
    x = (x % 1000000)

    setMetres((x - (x % 1000)) / 1000 || '')
    x = (x % 1000)

    setCentimetres((x - (x % 10)) / 10 || '')
    x = (x % 10)

    setMillimetres(x || '')
  }, [])

  // convert to mm
  useEffect(() => {
    const distance = (kilometres * 1000000) + (metres * 1000) + (centimetres * 10) + (millimetres * 1)

    if (distance !== 0) props.onChange({ target: { name: props.name, value: distance } })
  }, [kilometres, metres, centimetres, millimetres])

  return (
    <>
      <input
        name='kilometres'
        placeholder='km'
        value={kilometres}
        onChange={handleChange}
        disabled={props.disabled}
      />

      <input
        name='metres'
        placeholder='m'
        value={metres}
        onChange={handleChange}
        disabled={props.disabled}
      />

      <input
        name='centimetres'
        placeholder='cm'
        value={centimetres}
        onChange={handleChange}
        disabled={props.disabled}
      />

      <input
        name='millimetres'
        placeholder='mm'
        value={millimetres}
        onChange={handleChange}
        disabled={props.disabled}
      />
    </>
  )
}

export default Distance
