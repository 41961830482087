import React, { useState } from 'react'
import Modal from 'react-modal'

import ImageGallery from './ImageGallery'
import ModalWrapper from './ModalWrapper'

const ModalWrapperImage = ({ children, ...props }) => {
  const [image, setImage] = useState(props.image || 'https://firebasestorage.googleapis.com/v0/b/oni-nutrition-test.appspot.com/o/images%2Fexercises%2FK1S_4571Kelvin.jpg?alt=media&token=f69727ed-beb1-431c-aa13-783350e71c2d')

  const [selectImage, toggleSelectImage] = useState(false)
  const [type, setType] = useState('Exercise')

  const handleSelectImage = e => {
    setImage(e.target.value)
    toggleSelectImage(false)
  }

  return (
    <Modal
      isOpen={props.showModal}
      onRequestClose={props.onCloseModal}
      className='modal'
      overlayClassName='modal_overlay'
    >
      <img
        src={image}
        className='modal_img'
      />

      <span className='button_edit_image' onClick={() => toggleSelectImage(true)}>
        <i className='fas fa-pencil-alt' />
      </span>

      <div className='modal_wrapper'>
        <span className='modal_header'>
          <h3>{props.modalHeader}</h3>
          <i className='fas fa-times' onClick={props.onCloseModal} />
        </span>

        {React.cloneElement(children, {
          selectedImage: image,
          setProgramType: (type) => setType(type)
        })}
      </div>

      <ModalWrapper
        showModal={selectImage}
        onCloseModal={() => toggleSelectImage(false)}
        header='Select Image'
      >
        <div className='modal_content'>
          <ImageGallery
            show={selectImage}
            onChange={handleSelectImage}
            path={type === 'Nutrition' ? '/images/nutrition/' : '/images/exercises/'}
          />
        </div>

      </ModalWrapper>
    </Modal>
  )
}

export default ModalWrapperImage
