import React from 'react'
import moment from 'moment'
import KebabMenu from '../Common/KebabMenu'
import { Draggable } from 'react-beautiful-dnd'

const CollectionItem = props => {
  return (
    <Draggable draggableId={props.id} index={props.index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className='collection_list_item box'
          onClick={() => props.onSelect(props.id)}
        >
          <i
            className='fas fa-grip-vertical grip handle'
            {...provided.dragHandleProps}
          />
          <img className='image' src={props.image_url} />
          <p className='name'>{props.name}</p>
          <p className='number'>{props.programs.length}</p>
          <p className='visible'>{props.visible ? 'Yes' : 'No'}</p>
          <p className='lastUpdated'>{props.lastUpdated ? moment(props.lastUpdated.seconds, 'X').fromNow() : 'Never'}</p>
          <span className='overflow'>
            <KebabMenu
              context='edit_delete'
              onEdit={() => props.onSelect(props.id)}
              onDelete={() => props.onDelete(props.id)}
            />
          </span>
        </div>
      )}
    </Draggable>
  )
}

export default CollectionItem
