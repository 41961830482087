import {
  RECEIVE_EXERCISES,
  ADD_EXERCISE,
  EDIT_EXERCISE,
  DELETE_EXERCISE,
  ASSIGN_EXERCISE,
  UNASSIGN_EXERCISE
} from '../actions/exercises'

const initialState = []

const exercises = (state = initialState, action) => {
  const index = state.findIndex(exercise => exercise.id === action.exerciseId)

  switch (action.type) {
    case RECEIVE_EXERCISES:
      return action.exercises

    case ADD_EXERCISE:
      return [...state, action.exercise]

    case DELETE_EXERCISE:
      return state.filter(exercise => exercise.id !== action.exerciseId)

    case EDIT_EXERCISE:
      state[index] = Object.assign(state[index], action.exercise)

      return state

    case ASSIGN_EXERCISE:
      state[index].sections = [...state[index].sections, action.sectionId]

      return state

    case UNASSIGN_EXERCISE:
      state[index].sections = state[index].sections.filter(sectionId => sectionId !== action.sectionId)

      return state

    default:
      return state
  }
}

export default exercises
