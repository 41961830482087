import React, { Component } from 'react'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { loadNewLesson } from '../../../actions/activeLesson'
import { createLesson } from '../../../actions/lessons'
import { fb } from '../../../config/firebase'
import { uploadLesson } from '../../../util'

import TextField from '@material-ui/core/TextField'

class LessonForm extends Component {
  state = {
    title: '',
    image_url: '',
    subtitle: '',
    isLoading: null
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState({
      isLoading: 'submit'
    })

    const lessonData = {
      dateCreated: new fb.firestore.Timestamp.now(), // eslint-disable-line
      ownerId: [this.props.activeUserId, 'global'],
      creatorName: this.props.activeUserName,
      creatorImg: this.props.activeUserImg,
      title: this.state.title,
      subtitle: this.state.subtitle,
      image_url: this.state.image_url
    }

    this.props.dispatch(createLesson(lessonData))
      .then(doc => {
        const lesson = {
          id: doc.lesson.id,
          pages: [{
            pageId: uuid(),
            content: [{
              component: 'hero',
              componentId: uuid(),
              data: {
                imageURL: this.state.image_url,
                title: this.state.title,
                subtitle: this.state.subtitle
              }
            }]
          }, {
            pageId: uuid(),
            content: [{
              component: 'image_text_overlay',
              componentId: uuid(),
              data: {
                imageURL: '',
                text: 'Add text'
              }
            }]
          }]
        }

        uploadLesson(JSON.stringify(lesson, 0, 2), doc.lesson.id)
          .then(() => {
            this.props.dispatch(loadNewLesson(lesson))
            this.props.history.push(`/lessons/${doc.lesson.id}/Lesson`)
          })
      })
  }

  render () {
    return (
      <>
        <form className='modal_content' onSubmit={this.handleSubmit}>
          <TextField
            label='Title'
            name='title'
            onChange={this.handleChange}
            required
          />
        </form>

        <div className='modal_buttons'>
          <button
            onClick={this.handleSubmit}
            className='button_primary'
          >
            {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
          </button>
        </div>
      </>
    )
  }
}

function mapStateToProps (globalState) {
  return {
    activeUserId: globalState.user.auth.uid,
    activeUserName: globalState.user.auth.displayName,
    activeUserImg: globalState.user.auth.photoURL
  }
}

export default connect(mapStateToProps)(LessonForm)
