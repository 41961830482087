import {
  RECEIVE_CLIENT_NOTES,
  CREATE_NOTE,
  UPDATE_NOTE,
  DELETE_NOTE
} from '../actions/notes'

const initialState = []

const notes = (state = initialState, action) => {
  const index = state.findIndex(note => note.id === action.noteId)

  switch (action.type) {
    case RECEIVE_CLIENT_NOTES:
      return action.notes

    case CREATE_NOTE:
      return [...state, action.note]

    case UPDATE_NOTE: {
      state[index].note = action.note.note

      return state
    }

    case DELETE_NOTE:
      return state.filter(note => note.id !== action.noteId)

    default:
      return state
  }
}

export default notes
