import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import moment from 'moment'
import prettyMilliseconds from 'pretty-ms'
import prettyMillimeters from '../../../prettymm'

const LineGraph = ({ data, unit }) => {
  // Get the time difference between the first and last entry to scale xAxis ticks
  const longest = data.reduce((pending, cur, index, arr) => arr[pending].data.length > cur.data.length ? pending : index, 0)
  const set = data[longest].data

  let tickDiff = 'every day'

  if (set.length !== 0) {
    const difference = moment(set[set.length - 1].x, 'YYYY-MM-DD').diff(moment(set[0].x, 'YYYY-MM-DD'), 'days')
    const fortnights = Math.ceil(difference / 14)
    tickDiff = fortnights > 1 ? `every ${fortnights} days` : 'every day'
  }

  return (
    <ResponsiveLine
      data={data}
      defs={[
        {
          id: 'gradient',
          type: 'linearGradient',
          colors: [
            { offset: 0, color: '#5c68f2' },
            { offset: 100, color: '#FFFFFF' }
          ]
        }
      ]}
      margin={{ top: 50, right: data.length > 1 ? 80 : 40, bottom: 100, left: 60 }}
      xScale={{ type: 'time', format: '%Y-%m-%d' }}
      xFormat='time:%Y-%m-%d'
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
      tooltip={({ point }) => (
        <div
          style={{
            background: 'white',
            padding: '9px 12px',
            border: '1px solid #ccc'
          }}
        >
          <div style={{ fontSize: 'small' }}>{moment(point.data.xFormatted, 'YYYY-MM-DD').format('Do MMMM YYYY')}</div>
          <div
            style={{
              color: point.serieColor,
              padding: '3px 0',
              fontSize: 'small'
            }}
          >
            {
              point.data.tooltip &&
                <ul style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
                  {point.data.tooltip.map((tip, i) => (
                    <li key={i}><strong>{tip}</strong></li>
                  ))}
                </ul>
            }
          </div>
        </div>
      )}
      curve='monotoneX'
      axisTop={null}
      axisRight={null}
      axisBottom={{
        format: '%d %b %Y',
        orient: 'bottom',
        tickSize: 5,
        tickValues: tickDiff,
        tickPadding: 10,
        tickRotation: -45
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 10,
        tickRotation: -45,
        legend: unit && (unit.unit || unit.name),
        legendOffset: -50,
        legendPosition: 'middle',
        format: value => {
          if (unit.type === 'TIME' || ['Time', 'Rest'].includes(unit.name)) return prettyMilliseconds(value, { unitCount: 1 })
          else if (unit.name === 'Distance') {
            return prettyMillimeters(value, { unitCount: 1 })
          } else return value
        }
      }}
      colors={{ scheme: 'nivo' }}
      fill={[
        { match: '*', id: 'gradient' }
      ]}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabel='y'
      pointLabelYOffset={-12}
      useMesh
      legends={data.length > 1 ? [
        {
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ]
        }
      ] : undefined}
    />
  )
}

export default LineGraph
