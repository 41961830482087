import { combineReducers } from 'redux'
import { LOGOUT_USER } from '../actions/user'

import user from './user'
import clients from './clients'
import coaches from './coaches'
import metrics from './metrics'
import units from './units'
import logs from './logs'
import goals from './goals'
import milestones from './milestones'
import programs from './programs'
import programSections from './programSections'
import food from './food'
import exercises from './exercises'
import exerciseMetrics from './exerciseMetrics'
import exerciseRecords from './exerciseRecords'
import ui from './ui'
import notes from './notes'
import lessons from './lessons'
import activeLesson from './activeLesson'
import collections from './collections'
import stripe from './stripe'

const appReducers = combineReducers({
  user,
  clients,
  coaches,
  metrics,
  units,
  logs,
  goals,
  milestones,
  programs,
  programSections,
  food,
  exercises,
  exerciseMetrics,
  exerciseRecords,
  ui,
  notes,
  lessons,
  activeLesson,
  collections,
  stripe
})

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined
  }

  return appReducers(state, action)
}
