import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import KebabMenu from '../../Common/KebabMenu'

const LessonItem = props => {
  return (
    <Draggable draggableId={props.id} index={props.index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className='program_lesson_item' onClick={props.onSelect}>
            <i
              className='fas fa-grip-vertical grip'
              style={{ gridArea: 'drag' }}
              {...provided.dragHandleProps}
            />
            <img src={props.image_url} />
            <p style={{ gridArea: 'title' }}>{props.title}</p>

            <span style={{ gridArea: 'overflow' }}>
              <KebabMenu
                context='edit_delete'
                onDelete={props.onDelete}
                onEdit={props.onSelect}
              />
            </span>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default LessonItem
