import React, { useState, useEffect } from 'react'
import moment from 'moment'
import prettyMilliseconds from 'pretty-ms'
import prettyMillimeters from '../../prettymm'
import KebabMenu from '../Common/KebabMenu'

const DiaryItem = React.forwardRef((props, ref) => {
  const [expand, toggleExpand] = useState(false)

  const handleSelect = (e, data) => {
    if (props.programId) props.onSelect('workout', data)
    else if (props.exerciseId) props.onSelect('exercise')
    else if (props.metricId) props.onSelect('metric')
    else props.onSelect('note')
  }

  const handleExpand = e => {
    e.stopPropagation()
    toggleExpand(!expand)
  }

  useEffect(() => {
    props.expandItem(props.index, ref.current.clientHeight)
  }, [expand])

  return (
    <div ref={ref} style={{ pointerEvents: 'none' }}>
      <div className='user_diary_item' onClick={handleSelect} style={{ pointerEvents: 'auto' }}>
        <p className='date'>{moment(props.date.seconds, 'X').format('DD MMM YYYY')}</p>

        {
          props.category === 'metric' &&
            <>
              <span className='icon'>
                <i className='far fa-chart-bar' />
              </span>

              <p className='type'>{props.type}</p>

              {
                (props.unitType === 'Time' || props.unitType === 'Rest') &&
                  <p className='value'>{prettyMilliseconds(Number(props.value), { verbose: false, unitCount: 2 })}</p>
              }

              {
                (props.unitType === 'Distance' || props.unitType === 'Height') &&
                  <p className='value'>{prettyMillimeters(Number(props.value), { verbose: false, unitCount: 2 })}</p>
              }

              {
                !['Time', 'Rest', 'Distance', 'Height'].includes(props.unitType) &&
                  <p className='value'>{props.value}</p>
              }

            </>
        }

        {
          props.category === 'exercise' &&
            <>
              <span className='icon'>
                <i className='fas fa-dumbbell' />
              </span>

              <p className='type'>{props.globalExercises.find(exercise => exercise.id === props.exerciseId).name}
              </p>
              <div className='value'>
                {
                  props.exerciseMetrics.map((metric, i) => {
                    let value = metric.value
                    if (!value.length) value = [value]

                    if (metric.name === 'Time' || metric.name === 'Rest') {
                      value = value.map(v => prettyMilliseconds(Number(v), { verbose: false, unitCount: 2 }))
                    }

                    if (metric.name === 'Distance' || metric.name === 'Height') {
                      value = value.map(v => prettyMillimeters(Number(v), { verbose: false, unitCount: 2 }))
                    }

                    return (
                      <p key={i}>{`${metric.name}: ${value}`}</p>
                    )
                  })
                }
              </div>
            </>
        }

        {
          props.category === 'workout' &&
            <>
              <span className='icon' onClick={handleExpand}>
                <i className={`fas fa-chevron-right ${expand ? 'expanded' : undefined}`} />
              </span>
              <p className='type'>{props.title}</p>
              <p className='note'>{props.desc}</p>
            </>
        }

        {
          props.category === 'note' &&
            <>
              <span className='icon'>
                <i className='far fa-clipboard' />
              </span>
              <p className='type'>{props.type}</p>
            </>
        }

        <p className='note'>{props.note}</p>

        <span className='overflow'>
          <KebabMenu
            context='edit_delete'
            onEdit={() => handleSelect(props.id)}
            onDelete={() => props.onDelete(props.id)}
          />
        </span>
      </div>

      {
        expand &&
        props.content.map((exercise, i) => {
          const exerciseDetails = props.globalExercises.find(e => e.id === exercise.id)
          const group = props.groups.find(g => g.items.includes(exercise.key))

          return (
            <div className='user_diary_item' key={i} style={{ pointerEvents: 'none' }} onClick={e => handleSelect(e, exercise)}>
              <p className='type' style={{ alignSelf: 'start' }}>{group.name && group.name + ':'} {exerciseDetails.name}</p>

              <span className='value'>
                {
                  exercise.metrics.map((m, i) => {
                    return (
                      <span key={i}>
                        <p key={i}>{m.name}:</p>

                        <span>
                          {
                            m.value.map((value, i) => {
                              let v = value

                              if (m.name === 'Time' || m.name === 'Rest') {
                                v = prettyMilliseconds(Number(value), { verbose: false, unitCount: 2 })
                              }

                              if (m.name === 'Distance' || m.name === 'Height') {
                                v = prettyMillimeters(Number(value), { verbose: false, unitCount: 2 })
                              }

                              return (
                                <p key={i}>{v}</p>
                              )
                            })
                          }
                        </span>
                      </span>
                    )
                  })
                }
              </span>

              <p className='note'>{exercise.notes}</p>

            </div>
          )
        })
      }
    </div>
  )
})

export default DiaryItem
