import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { uploadLesson } from '../../../util'
import TopBar from '../../Nav/TopBar'
import PageContainer from './PageContainer'
import { fb } from '../../../config/firebase'
import { editLesson } from '../../../actions/lessons'
import { clearLesson } from '../../../actions/activeLesson'

class LessonContainer extends Component {
  state = {
    lessonDetails: {},
    lesson: {},
    activeTab: 'Lesson',
    isEditing: false,
    isLoading: false
  }

  componentDidMount () {
    this.setState({
      lessonDetails: this.props.lessonDetails,
      lesson: this.props.lesson
    })
  }

  componentWillUnmount () {
    this.props.dispatch(clearLesson())
  }

  handleScroll = direction => {
    const div = document.getElementsByClassName('program_sections_container')[0]
    const width = div.offsetWidth

    div.scrollBy({
      left: direction === 'left' ? -(width / 3) : width / 3,
      behavior: 'smooth'
    })
  }

  handleChangeLesson = lesson => {
    this.setState({
      lesson,
      isEditing: true
    })
  }

  handleSave = e => {
    e.preventDefault()
    this.setState({
      isLoading: 'submit'
    })

    uploadLesson(JSON.stringify(this.state.lesson, 0, 2), this.state.lesson.id)
      .then(() => {
        const hero = this.state.lesson.pages[0].content[0]

        const details = {
          title: hero.data.title,
          subtitle: hero.data.subtitle,
          image_url: hero.data.imageURL,
          lastUpdated: new fb.firestore.Timestamp.now() // eslint-disable-line
        }

        this.props.dispatch(editLesson({ lessonId: this.state.lesson.id, lesson: details }))
          .then(() => {
            this.setState({
              isLoading: null,
              isEditing: false
            })
          })
      })
  }

  render () {
    return (
      <div className='container_col'>
        <TopBar
          title={this.state.lessonDetails.title}
          subtitle={this.state.lessonDetails.subtitle}
          img_url={this.state.lessonDetails.image_url}
          tabs={[]}
        />

        {
          this.state.activeTab === 'Lesson' &&
            <>
              <button
                className='button_scroll_y left'
                style={{ left: `calc(${this.props.drawer ? '250px' : '50px'} - 25px - 1rem)` }}
                onClick={() => this.handleScroll('left')}
              >
                <i className='fas fa-lg fa-chevron-left' />
              </button>

              <button
                className='button_scroll_y right'
                onClick={() => this.handleScroll('right')}
              >
                <i className='fas fa-lg fa-chevron-right' />
              </button>
            </>
        }

        <PageContainer
          lesson={this.state.lesson}
          onChangeLesson={this.handleChangeLesson}
          drawer={this.props.drawer}
        />

        <button
          className='button_float_save'
          onClick={this.handleSave}
          disabled={!this.state.isEditing}
        >
          {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : <i className='fas fa-lg fa-check' />}
        </button>
      </div>
    )
  }
}

function mapStateToProps (globalState, ownProps) {
  const lessonDetails = _.cloneDeep(globalState.lessons.find(lesson => lesson.id === ownProps.match.params.lessonId))

  return {
    lessonDetails,
    lesson: globalState.activeLesson,
    drawer: globalState.ui.drawer,
    activeUserId: globalState.user.auth.uid,
    activeUserName: globalState.user.auth.displayName,
    activeUserImg: globalState.user.auth.photoURL
  }
}

export default connect(mapStateToProps)(LessonContainer)
