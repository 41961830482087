import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import * as yup from 'yup'

import { getImgUrl } from '../../util'
import { fb } from '../../config/firebase'
import { createClient } from '../../actions/clients'
import { getProducts, startSubscription } from '../../actions/stripe'

import RegisterForm from './RegisterForm'
import RegisterUpgrade from './RegisterUpgrade'
import { Typography } from '@material-ui/core'

const Register = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    validateForm
  } = props

  const [page, setPage] = useState(0)

  useEffect(() => {
    props.dispatch(getProducts())
    console.log(window.location)
  }, [])

  const changePage = () => {
    validateForm()
      .then(res => {
        if (Object.keys(res).length === 0) {
          setPage(1)
        }
      })
  }

  return (
    <div className='container_col'>
      <div className='ui_oni_bar'>
        <img src='/img/Logo-Colour-White-small.png' />
      </div>

      <div className='login'>
        <form onSubmit={handleSubmit}>
          {
            page === 0 &&
              <RegisterForm
                values={values}
                touched={touched}
                errors={errors}
                handleChange={handleChange}
                onChangePage={changePage}
              />
          }

          {
            page === 1 &&
              <>
                <RegisterUpgrade handleChange={handleChange} />
                <span className='ui_button_bar'>
                  <button
                    className='button_navigational'
                    onClick={() => setPage(0)}
                    type='button'
                    disabled={isSubmitting}
                  >
                    Back
                  </button>
                  <button
                    className='button_primary'
                    onClick={!isSubmitting ? handleSubmit : undefined}
                    type='submit'
                  >
                    {isSubmitting ? <i className='fas fa-spinner fa-spin' /> : 'Register'}
                  </button>
                </span>
              </>
          }
        </form>

        <Typography>{errors.bigOne}</Typography>
      </div>
    </div>
  )
}

const formikEnhancer = withFormik({
  mapPropsToValues: props => ({

  }),
  validationSchema: () => {
    return yup.lazy(values => {
      return yup.object().shape(
        Object.assign({
          first_name: yup.string().required('Please enter your first name'),
          last_name: yup.string().required('Please enter your last name'),
          email: yup.string().email('Invalid email').required('Please enter an email address'),
          password: yup
            .string()
            .min(6, 'Password must be at least 6 characters long')
            .test('is-same-as-confirm', 'Passwords must match', val => val === values.passwordConf)
            .required('Please enter a password'),
          passwordConf: yup.string(),
          priceId: yup.string()
        })
      )
    })
  },
  handleSubmit: ({ passwordConf, password, priceId, ...payload }, { props, setSubmitting, setErrors }) => {
    const user = {
      ...payload,
      trainers: [],
      type: ['client'],
      img_url: getImgUrl(payload.first_name, payload.last_name, ['client'])
    }

    fb.functions().httpsCallable('createUser')({ ...user, password })
      .then(res => {
        props.dispatch(createClient({ id: res.data.uid, client: user }))
          .then(() => {
            fb.auth().signInWithEmailAndPassword(payload.email, password)
              .then(() => {
                if (priceId) {
                  const success = `${window.location.origin}/#/checkout_success/${res.data.uid}`
                  const redirect = `${window.location.origin}/#/profile/${res.data.uid}`

                  props.dispatch(startSubscription(priceId, res.data.uid, success, redirect))
                } else {
                  window.location.href = `${window.location.origin}/#/profile/${res.data.uid}`
                  setSubmitting(false)
                }
              })
          })
      })
      .catch(err => {
        setSubmitting(false)
        setErrors({ bigOne: 'Registration failed.  Please try again' })
        console.log(err)
      })
  }
})

export default connect()(formikEnhancer(Register))
