import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const ConfirmationDialog = ({
  open,
  onClose,
  title,
  text,
  affirmativeAction,
  affirmativeText = 'Confirm',
  negativeAction,
  negativeText = 'Cancel'
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        {
          negativeAction &&
            <Button onClick={negativeAction}>{negativeText}</Button>
        }
        {
          affirmativeAction &&
            <Button onClick={affirmativeAction}>{affirmativeText}</Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
