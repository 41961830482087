import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { fb } from '../../config/firebase'
import Select from 'react-select'
import ExerciseMetrics from '../Util/ExerciseMetrics'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { imageValidation, renameImage, uploadImage } from '../../util'

import { createRecord, deleteRecord } from '../../actions/exerciseRecords'

class LogExerciseForm extends Component {
  state = {
    date: new fb.firestore.Timestamp.now(), // eslint-disable-line
    globalExerciseMetrics: [],
    exerciseMetrics: [],
    note: '',
    setNum: 1,
    showError: false,
    errorMessage: '',
    isLoading: null,
    displayExerciseModal: false
  }

  componentDidMount () {
    if (this.props.editData.id) {
      const metrics = _.cloneDeep(this.props.editData.exerciseMetrics)

      let setNum = 1

      console.log(metrics)

      metrics.forEach(metric => {
        if (metric.value === null) metric.value = []
        if (typeof metric.value === 'object' && metric.value.length > setNum) setNum = metric.value.length
      })

      this.setState({
        date: new fb.firestore.Timestamp.fromMillis(this.props.editData.date.seconds * 1000), // eslint-disable-line
        exerciseId: this.props.editData.exerciseId,
        exerciseMetrics: metrics,
        note: this.props.editData.note,
        setNum: setNum,
        recordId: this.props.editData.recordId
      })
    }
  }

  handleChange = (e, id) => {
    switch (e.target.name) {
      case 'date': {
        const timestamp = new fb.firestore.Timestamp.fromDate(new Date(e.target.value)) // eslint-disable-line
        this.setState({
          date: timestamp
        })

        break
      }

      case 'metrics': {
        this.setState({
          exerciseMetrics: e.target.value,
          showError: false
        })

        break
      }

      case 'exerciseId': {
        const exercise = this.props.exercises.find(exercise => exercise.id === e.target.value)

        const exerciseMetrics = []

        exercise.metrics.forEach(metric => {
          exerciseMetrics.push({
            id: metric,
            name: this.props.globalExerciseMetrics.find(m => m.id === metric).name,
            value: []
          })
        })

        this.setState({
          exercise,
          exerciseMetrics,
          exerciseId: e.target.value,
          showError: false
        })
        break
      }

      default: {
        this.setState({
          [e.target.name]: e.target.value,
          showError: false
        })
      }
    }
  }

  handleImage = e => {
    const valid = imageValidation(e.target.files[0])

    if (valid !== null) {
      this.setState({
        errorMessage: valid,
        showError: true
      })
    } else {
      this.setState({
        image: e.target.files[0]
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState({
      isLoading: 'submit'
    })

    if (!this.state.exerciseId) {
      this.error('Select an exercise')
      return
    }

    const data = { ...this.state }
    delete data.showError
    delete data.errorMessage
    delete data.isLoading
    delete data.displayExerciseModal
    delete data.globalExerciseMetrics
    delete data.exercise

    // // Remove empty values
    data.exerciseMetrics = [...this.state.exerciseMetrics].filter(m => {
      if (m.value.length > 0) return m
    })

    // Validate input
    const valid = data.exerciseMetrics.every(metric => {
      const unitId = this.props.globalExerciseMetrics.find(m => m.id === metric.id).unitOfMeasurementId

      const regex = new RegExp(this.props.unitsOfMeasurement.find(unit => unit.id === unitId).regex)

      if (typeof metric.value === 'object') {
        return metric.value.every(v => (v.toString().match(regex)))
      } else return metric.value.toString().match(regex)
    })

    if (!valid) {
      this.error('Invalid format')
      return
    }

    if (this.state.image) {
      const name = uuid()
      const image = renameImage(this.state.image, name)

      uploadImage('records', image)
        .then(url => {
          data.imageURL = url
          data.imageName = name
          this.handleCreateRecord(data)
        })
    } else {
      this.handleCreateRecord(data)
    }

    if (this.props.editData.id) {
      this.handleDeleteRecord(false)
    }
  }

  handleCreateRecord = data => {
    const setKey = uuid()

    data.exerciseMetrics.forEach((metric, count) => {
      metric.value.forEach((value, i) => {
        const record = {
          date: data.date,
          exerciseId: data.exerciseId,
          exerciseMetrics: [{
            id: metric.id,
            value: value,
            name: metric.name
          }],
          note: data.note,
          setKey: setKey,
          setIndex: i,
          userId: this.props.clientId
        }

        if (data.imageURL) {
          record.imageURL = data.imageURL
          record.imageName = data.imageName
        }

        this.props.dispatch(createRecord(record))
          .then(docRef => {
            record.id = docRef.id

            if (this.props.graphMetric === undefined) {
              this.props.onSortData({ data: [...this.props.exerciseRecords, record], tags: [this.props.graphMetric] })
            }

            if (count === data.exerciseMetrics.length - 1 && i === metric.value.length - 1) {
              this.props.onCloseModal()
            }
          })
          .catch(err => this.error(err.message))
      })
    })
  }

  handleDeleteRecord = (close = true) => {
    this.setState({
      isLoading: 'delete'
    })

    const data = this.props.exerciseRecords.filter(record => !this.state.recordId.includes(record.id))
    this.props.onSortData({
      data,
      tags: [this.props.graphMetric]
    })

    this.state.recordId.forEach((id, i) => {
      this.props.dispatch(deleteRecord(id))
        .then(() => {
          if (i === this.state.recordId.length - 1 && close) {
            this.props.onCloseModal()
          }
        })
        .catch(err => this.error(err))
    })
  }

  error = err => {
    this.setState({
      isLoading: false,
      showError: true,
      errorMessage: err
    })
  }

  render () {
    const { editData } = this.props
    return (
      <>
        <form onSubmit={this.handleSubmit} className='modal_content'>
          <label htmlFor='date'>Date</label>
          <input
            type='date'
            name='date'
            defaultValue={moment(this.state.date.seconds, 'X').format('YYYY-MM-DD')}
            onChange={this.handleChange}
            required
          />

          <label htmlFor='exercise'>Exercise</label>
          <Select
            options={this.props.exercises.map(exercise => ({
              label: exercise.name,
              value: exercise.id
            }))}
            onChange={res => this.handleChange({ target: { name: 'exerciseId', value: res.value } })}
            defaultValue={this.props.exercises.map(exercise => {
              if (editData.exerciseId === exercise.id) {
                return { label: exercise.name, value: editData.exerciseId }
              }
            })}
            isSearchable
            disabled={this.props.editData.id}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: 'black'
              }
            })}
          />

          {
            this.state.exerciseId &&
              <ExerciseMetrics
                exerciseMetrics={this.state.exerciseMetrics}
                onChange={metrics => this.setState({ exerciseMetrics: metrics })}
                onChangeSet={(sets) => this.setState({ setNum: sets })}
                sets={{ num: this.state.setNum, display: true }}
                flag_accuracy
              />
          }

          <span className='modal_image_form'>
            {
              this.props.editData.imageURL &&
                <span style={{ textAlign: 'center' }}>
                  <a href={this.props.editData.imageURL} target='_blank' rel='noopener noreferrer'>View Image</a>
                </span>
            }

            <span className='col'>
              <label htmlFor='image'>Attach Image</label>
              <input
                type='file'
                name='image'
                onChange={this.handleImage}
              />
            </span>
          </span>

          <label htmlFor='note'>Notes</label>
          <textarea
            name='note'
            onChange={this.handleChange}
            defaultValue={this.state.note}
          />

          <p className='ui_error'>{this.state.showError && this.state.errorMessage}</p>

        </form>

        <div className='modal_buttons'>
          {/* {
            editData.id &&
              <button
                type='button'
                className='button_warning'
                onClick={this.handleDeleteRecord}
              >
                {this.state.isLoading === 'delete' ? <i className='fas fa-spinner fa-spin' /> : 'Delete Log'}
              </button>
          } */}
          <button
            className='button_primary'
            onClick={this.handleSubmit}
          >
            {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
          </button>
        </div>
      </>
    )
  }
}

export default connect()(LogExerciseForm)
