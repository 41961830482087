import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { fb } from '../../config/firebase'
import TextField from '@material-ui/core/TextField'

class CreateNewPassword extends Component {
  state = {
    showError: false,
    errorMessage: '',
    showSuccess: false,
    successMessage: '',
    isLoading: null
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      showError: false
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState({
      isLoading: 'submit'
    })

    if (this.state.new_password !== this.state.confirm_password) {
      this.error('Passwords do not match')
      return
    }

    fb.auth().verifyPasswordResetCode(this.props.actionCode)
      .then(() => {
        fb.auth().confirmPasswordReset(this.props.actionCode, this.state.confirm_password)
          .then(() => {
            this.setState({
              showSuccess: true,
              successMessage: 'Password reset successful',
              isLoading: null
            })
          })
          .catch(err => this.error(err.message))
      })
      .catch(err => this.error(err.message))
  }

  error = err => {
    this.setState({
      showError: true,
      errorMessage: err,
      isLoading: null
    })
  }

  render () {
    return (
      <div className='container_col'>
        <div className='ui_oni_bar'>
          <img src='/img/Logo-Colour-White-small.png' />
        </div>

        <div className='login'>
          <form onSubmit={this.handleSubmit}>
            <TextField
              name='new_password'
              label='New Password'
              type='password'
              onChange={this.handleChange}
              required
            />

            <TextField
              name='confirm_password'
              label='Confirm new password'
              type='password'
              onChange={this.handleChange}
              required
            />

            {
              this.state.showError &&
                <p className='ui_error'>{this.state.errorMessage}</p>
            }
            {
              this.state.showSuccess &&
                <p className='ui_success'>{this.state.successMessage}</p>
            }

            <span className='ui_button_bar'>
              <button
                className='button_primary'
                onClick={this.handleSubmit}
              >
                {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Save new password'}

              </button>

            </span>
          </form>
        </div>
      </div>
    )
  }
}

export default CreateNewPassword
