import React, { Component } from 'react'
import { connect } from 'react-redux'

class AssignExistingLesson extends Component {
  state = {
    lessons: [],
    searchFilter: new RegExp('(?:)', 'gi')
  }

  handleSearch = e => {
    this.setState({
      searchFilter: e.target.value
    })
  }

  handleChange = e => {
    let lessons = [...this.state.lessons]

    if (e.target.checked) {
      lessons.push(e.target.value)
    } else {
      lessons = lessons.filter(l => l !== e.target.value)
    }

    this.setState({
      lessons
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    this.props.onAssignLesson({ items: this.state.lessons, type: 'Lesson' })

    this.props.onCloseModal()
  }

  render () {
    return (
      <>
        <form onSubmit={this.handleSubmit} className='modal_content'>
          {
            this.props.lessons.map(lesson => {
              if (lesson.title.match(this.props.searchFilter)) {
                return (
                  <label htmlFor={lesson.id} key={lesson.id}>
                    <div
                      className='program_section_item'
                      style={{ paddingBottom: 0, margin: 0 }, this.state.lessons.includes(lesson.id) ? { backgroundColor: 'rgb(218, 225, 243)' } : undefined} // eslint-disable-line
                    >
                      <input
                        type='checkbox'
                        name={lesson.name}
                        value={lesson.id}
                        id={lesson.id}
                        onChange={this.handleChange}
                        defaultChecked={this.state.lessons.includes(lesson.id)}
                      />

                      <img src={lesson.image_url} />

                      <p className='header'>{lesson.title}</p>
                    </div>
                  </label>
                )
              }
            })
          }
        </form>

        <div className='modal_buttons'>
          <button
            className='button_primary'
            onClick={this.handleSubmit}
          >
            {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : `Add lessons (${this.state.lessons.length}) to program`}
          </button>
        </div>
      </>
    )
  }
}

function mapStateToProps (globalState, ownProps) {
  const lessons = globalState.lessons.filter(lesson => !ownProps.assignedLessons.includes(lesson.id))

  return {
    lessons: lessons.sort((a, b) => a.title.localeCompare(b.title))
  }
}

export default connect(mapStateToProps)(AssignExistingLesson)
