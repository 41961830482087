import React, { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import AddComponent from './AddComponent'

const Radio = props => {
  const [labels, setLabels] = useState([])
  const [marked, setMark] = useState(false)

  useEffect(() => {
    if (props.data.label.length === 0) {
      setLabels(['', ''])
    } else {
      setLabels(props.data.label)
    }
  }, [])

  const handleSetLabel = ({ label, index }) => {
    labels[index] = label
    setLabels(labels)

    const change = { target: { name: 'label', value: labels } }
    props.onChange(change, props.componentId)
  }

  const handleRemoveOption = index => {
    if (labels.length > 2) {
      const newLabels = [...labels]
      newLabels.splice(index, 1)
      setLabels(newLabels)

      const change = { target: { name: 'label', value: newLabels } }
      props.onChange(change, props.componentId)
    }
  }

  return (
    <>
      <div
        className='lesson_radio_component'
        style={marked ? { backgroundColor: 'rgba(234, 68, 57, 0.5)' } : undefined}
      >
        <label htmlFor='title'>Title</label>
        <input
          type='text'
          name='title'
          onChange={e => { props.onChange(e, props.componentId) }}
          defaultValue={props.data.title}
          onFocus={e => e.target.select()}
        />

        {
          labels.map((label, i) => {
            return (
              <span className='row' key={i}>
                <input
                  type='radio'
                  name={uuid()}
                  value={i}
                />
                <input
                  type='text'
                  value={label || ''}
                  placeholder='Add option'
                  onChange={e => handleSetLabel({ label: e.target.value, index: i })}
                />

                <i className='fas fa-trash' onClick={() => handleRemoveOption(i)} />
              </span>
            )
          })
        }

        <button
          type='button'
          onClick={() => setLabels([...labels, ''])}
        >
          Add option
        </button>

      </div>
      <AddComponent
        onMenu={props.onMenu}
        onDelete={props.onDelete}
        onMark={bool => setMark(bool)}
        componentId={props.componentId}
      />
    </>
  )
}

export default Radio

export const defaultRadio = {
  component: 'radio',
  componentId: null,
  data: {
    title: 'Untitled Question',
    label: []
  }
}
