import React, { useState } from 'react'
import { connect } from 'react-redux'

import UserSelect from './UserSelect'

const AssignForm = props => {
  const [isLoading, setLoading] = useState(null)
  const [showError, toggleError] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [userList, setUserList] = useState([])

  const handleSubmit = e => {
    e.preventDefault()

    setLoading('submit')

    props.onAssign(userList)
      .then(() => success())
      .catch(err => error(err))
  }

  const success = () => {
    setLoading(false)
    props.onCloseModal()
  }

  const error = err => {
    toggleError(true)
    setErrMsg(err.message)
    setLoading(null)
  }

  return (
    <div className='modal_content'>
      <UserSelect
        users={props.clients}
        checkedUsers={props.active.users}
        onChange={e => setUserList(e)}
      />

      <p className='ui_error'>{showError && errMsg}</p>

      <div className='modal_buttons'>
        <button
          onClick={handleSubmit}
          className='button_primary'
        >
          {isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}

        </button>
      </div>
    </div>
  )
}

function mapStateToProps (globalState) {
  return {
    clients: globalState.clients,
    coaches: globalState.coaches
  }
}

export default connect(mapStateToProps)(AssignForm)
