import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { logoutUser } from '../../actions/user'
import { fb, unsubscribe } from '../../config/firebase'

import PersonIcon from '@material-ui/icons/Person'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark'
import KitchenIcon from '@material-ui/icons/Kitchen'
import AssessmentIcon from '@material-ui/icons/Assessment'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'
import BookIcon from '@material-ui/icons/Book'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

const Drawer = props => {
  const location = useLocation()
  const split = location.pathname.split('/')
  const [activeTab, setActiveTab] = useState(split[1] || 'clients')
  const handleLogout = () => {
    unsubscribe(props.type)

    fb.auth()
      .signOut()
      .then(() => {
        props.dispatch(logoutUser())
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const hidden = location.pathname.includes('profile') | location.pathname.includes('checkout')

  return (
    props.authed !== null && !hidden &&
      <div
        className={props.drawer ? 'drawer-open' : 'drawer-closed'}
      >
        <div className='drawer-header'>
          <img src='/img/Logo-Colour-White-small.png' />
          <svg
            className='SideBurgerIcon-image'
            viewBox='0 0 50 32'
            onClick={props.onToggleDrawer}
          >
            <path d='M49,4H19c-0.6,0-1-0.4-1-1s0.4-1,1-1h30c0.6,0,1,0.4,1,1S49.6,4,49,4z' />
            <path d='M49,16H19c-0.6,0-1-0.4-1-1s0.4-1,1-1h30c0.6,0,1,0.4,1,1S49.6,16,49,16z' />
            <path d='M49,28H19c-0.6,0-1-0.4-1-1s0.4-1,1-1h30c0.6,0,1,0.4,1,1S49.6,28,49,28z' />
            <path d='M8.1,22.8c-0.3,0-0.5-0.1-0.7-0.3L0.7,15l6.7-7.8c0.4-0.4,1-0.5,1.4-0.1c0.4,0.4,0.5,1,0.1,1.4L3.3,15l5.5,6.2   c0.4,0.4,0.3,1-0.1,1.4C8.6,22.7,8.4,22.8,8.1,22.8z' />
          </svg>
        </div>

        <div
          className={
            props.drawer ? 'drawer-content-open' : 'drawer-content-closed'
          }
        >
          <ul className='drawer_nav_items'>
            <li
              className={`drawer_nav_item ${
                activeTab === 'clients' ? 'active' : undefined
              }`}
              onClick={() => setActiveTab('clients')}
            >
              <Link to='/clients'>
                <PersonIcon />
                Clients
              </Link>
            </li>

            {props.type.includes('admin') && (
              <li
                className={`drawer_nav_item ${
                  activeTab === 'coaches' ? 'active' : undefined
                }`}
                onClick={() => setActiveTab('coaches')}
              >
                <Link to='/coaches'>
                  <PersonIcon />
                  Coaches
                </Link>
              </li>
            )}

            <li
              className={`drawer_nav_item ${
                activeTab === 'programs' ? 'active' : undefined
              }`}
              onClick={() => setActiveTab('programs')}
            >
              <Link to='/programs'>
                <AssignmentIcon />
                Programs
              </Link>
            </li>

            {props.type.includes('admin') && (
              <li
                className={`drawer_nav_item ${
                  activeTab === 'collections' ? 'active' : undefined
                }`}
                onClick={() => setActiveTab('collections')}
              >
                <Link to='/collections'>
                  <CollectionsBookmarkIcon />
                  Collections
                </Link>
              </li>
            )}
          </ul>

          <hr />

          <ul className='drawer_nav_items'>
            <li
              className={`drawer_nav_item ${
                activeTab === 'exercises' ? 'active' : undefined
              }`}
              onClick={() => setActiveTab('exercises')}
            >
              <Link to='/exercises'>
                <FitnessCenterIcon />
                Exercises
              </Link>
            </li>

            <li
              className={`drawer_nav_item ${
                activeTab === 'food' ? 'active' : undefined
              }`}
              onClick={() => setActiveTab('food')}
            >
              <Link to='/food'>
                <KitchenIcon />
                Food
              </Link>
            </li>

            {props.type.includes('admin') && (
              <li
                className={`drawer_nav_item ${
                  activeTab === 'lessons' ? 'active' : undefined
                }`}
                onClick={() => setActiveTab('lessons')}
              >
                <Link to='/lessons'>
                  <BookIcon />
                  Lessons
                </Link>
              </li>
            )}

            <li
              className={`drawer_nav_item ${
                activeTab === 'metrics' ? 'active' : undefined
              }`}
              onClick={() => setActiveTab('metrics')}
            >
              <Link to='/metrics'>
                <AssessmentIcon />
                Metrics
              </Link>
            </li>
          </ul>
          <hr />
        </div>

        <ul className='drawer_nav_items'>
          <li className='drawer_nav_item'>
            <Link to='/' onClick={handleLogout}>
              <ExitToAppIcon />
              Logout
            </Link>
          </li>
        </ul>
      </div>
  )
}

function mapStateToProps (globalState) {
  return {
    type: globalState.user.info.type,
    authed: globalState.user.auth.uid
  }
}

export default connect(mapStateToProps)(Drawer)
