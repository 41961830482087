import { db } from '../config/firebase'

export const GET_SECTIONS = 'GET_SECTIONS'
export const CREATE_SECTION = 'CREATE_SECTION'
export const EDIT_SECTION = 'EDIT_SECTION'
export const DELETE_SECTION = 'DELETE_SECTION'
export const DELETE_ALL_SECTIONS = 'DELETE_ALL_SECTIONS'
export const CLEAR_SECTIONS = 'CLEAR_SECTIONS'

export const getSections = programId => {
  return dispatch => {
    return db.collection('programSections').where('programId', '==', programId)
      .get()
      .then(querySnapShot => {
        const sections = []
        querySnapShot.forEach(snap => {
          sections.push({ ...snap.data(), id: snap.id })
        })

        return dispatch({
          type: GET_SECTIONS,
          sections
        })
      })
      .catch(err => err)
  }
}

export const getNumSections = phaseId => {
  return dispatch => {
    return db.collection('programSections').where('phaseId', '==', phaseId)
      .get()
      .then(querySnapShot => {
        return querySnapShot.size
      })
  }
}

export const createSection = section => {
  return dispatch => {
    return db.collection('programSections').add({
      ...section
    })
      .then(docRef => {
        section.id = docRef.id

        return dispatch({
          type: CREATE_SECTION,
          section
        })
      })
      .catch(err => err)
  }
}

export const editSection = ({ sectionId, section }) => {
  return dispatch => {
    return db.collection('programSections').doc(sectionId)
      .update({
        ...section
      })
      .then(res => {
        return dispatch({
          type: EDIT_SECTION,
          sectionId,
          section
        })
      })
  }
}

export const deleteSection = sectionId => {
  return dispatch => {
    return db.collection('programSections').doc(sectionId).delete()
      .then(() => {
        return dispatch({
          type: DELETE_SECTION,
          sectionId
        })
      })
      .catch(err => err)
  }
}
