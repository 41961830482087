import React from 'react'
import Dialog from '@material-ui/core/Dialog'

const ModalWrapper = ({ children, ...props }) => {
  return (
    <Dialog
      open={props.showModal}
      onClose={props.onCloseModal}
      maxWidth='md'
      fullWidth
    >
      <div className='modal_wrapper'>
        {
          props.video_url &&
            <div className='ui_video_container'>
              <iframe
                className='video'
                src={`https://www.youtube.com/embed/${props.video_url}`}
                frameBorder='0'
              />
            </div>
        }
        <div className='modal_header'>
          <h3>{props.modalHeader}</h3>
          <i className='fas fa-times' onClick={props.onCloseModal} />
        </div>

        {children}
      </div>
    </Dialog>
  )
}

export default ModalWrapper
