import { db, fb } from '../config/firebase'

export const RECEIVE_EXERCISES = 'RECEIVE_EXERCISES'
export const ADD_EXERCISE = 'ADD_EXERCISE'
export const DELETE_EXERCISE = 'DELETE_EXERCISE'
export const EDIT_EXERCISE = 'EDIT_EXERCISE'
export const ASSIGN_EXERCISE = 'ASSIGN_EXERCISE'
export const UNASSIGN_EXERCISE = 'UNASSIGN_EXERCISE'

export const receiveExercises = () => {
  return dispatch => {
    return db.collection('exercises').get()
      .then(querySnapshot => {
        const exercises = []

        querySnapshot.forEach(snap => {
          exercises.push({ ...snap.data(), id: snap.id, type: 'Exercise' })
        })

        return dispatch({
          type: RECEIVE_EXERCISES,
          exercises
        })
      })
  }
}

export const getExercise = exerciseId => {
  return dispatch => {
    return db.collection('exercises').doc(exerciseId)
      .get()
      .then(docRef => {
        const exercise = docRef.data()
        exercise.id = docRef.id
        exercise.type = 'Exercise'

        return dispatch({
          type: ADD_EXERCISE,
          exercise
        })
      })
  }
}

export const createExercise = exercise => {
  return dispatch => {
    return db.collection('exercises').add({
      ...exercise
    })
      .then(docRef => {
        exercise.id = docRef.id
        exercise.type = 'Exercise'

        return dispatch({
          type: ADD_EXERCISE,
          exercise
        })
      })
  }
}

export const deleteExercise = exerciseId => {
  return dispatch => {
    return db.collection('exercises').doc(exerciseId).delete()
      .then(() => {
        return db.collection('exerciseRecords').where('exerciseId', '==', exerciseId)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(snap => {
              snap.ref.delete()
            })
          })
      })
      .then(() => {
        return dispatch({
          type: DELETE_EXERCISE,
          exerciseId
        })
      })
  }
}

export const editExercise = (exerciseId, exercise) => {
  return dispatch => {
    return db.collection('exercises').doc(exerciseId).update({
      ...exercise
    })
      .then(() => {
        return dispatch({
          type: EDIT_EXERCISE,
          exerciseId,
          exercise
        })
      })
  }
}

export const addExercise = (exerciseId, sectionId) => {
  return dispatch => {
    return db.collection('exercises').doc(exerciseId).update({
      sections: fb.firestore.FieldValue.arrayUnion(sectionId)
    })
      .then(() => {
        return dispatch({
          type: ASSIGN_EXERCISE,
          exerciseId,
          sectionId
        })
      })
  }
}

export const removeExercise = (exerciseId, sectionId) => {
  return dispatch => {
    return db.collection('exercises').doc(exerciseId).update({
      sections: fb.firestore.FieldValue.arrayRemove(sectionId)
    })
      .then(() => {
        return dispatch({
          type: UNASSIGN_EXERCISE,
          exerciseId,
          sectionId
        })
      })
  }
}
