import { db } from '../config/firebase'

export const RECEIVE_RECORDS = 'RECEIVE_RECORDS'

export const receiveRecords = exerciseRecords => {
  return {
    type: RECEIVE_RECORDS,
    exerciseRecords
  }
}

export const createRecord = exerciseRecord => {
  return dispatch => {
    return db.collection('exerciseRecords').add({
      ...exerciseRecord
    })
      .then(res => res)
      .catch(err => err)
  }
}

export const editRecord = ({ recordId, data }) => {
  return dispatch => {
    return db.collection('exerciseRecords').doc(recordId).update({
      ...data
    })
      .catch(err => err)
  }
}

export const deleteRecord = recordId => {
  return dispatch => {
    return db.collection('exerciseRecords').doc(recordId).delete()
  }
}
