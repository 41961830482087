import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList as List } from 'react-window'
import _ from 'lodash'
import ModalWrapper from '../Common/ModalWrapper'
import DiaryForm from './DiaryForm'

import DiaryItem from './DiaryItem'
import LogWorkoutForm from './LogWorkoutForm'
import LogMetricForm from './LogMetricForm'
import LogExerciseForm from './LogExerciseForm'
import LogNoteForm from './LogNoteForm'

import { deleteLog } from '../../actions/logs'
import { deleteRecord } from '../../actions/exerciseRecords'
import { deleteNote } from '../../actions/notes'

const ProfileDiary = props => {
  const listRef = useRef({})
  const rowHeights = useRef({})
  const [list, setList] = useState([])
  const [modal, setModal] = useState(null)
  const [editData, setEditData] = useState({})
  const [activeMetric, setActiveMetric] = useState({})
  const [activeMetricUnit, setActiveMetricUnit] = useState({})
  const [activeProgram, setActiveProgram] = useState({})
  const [activeSection, setActiveSection] = useState({})

  useEffect(() => {
    setList(props.records)
  }, [props.records])

  useEffect(() => {
    if (props.editData) {
    }
  }, [props.editData])

  function getRowHeight (index) {
    return rowHeights.current[index] || 58
  }

  const row = ({ index, style }) => {
    const rowRef = useRef({})

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight)
      }
    }, [rowRef])

    let type = ''
    let unitType = ''
    let category = ''

    if (list[index].metricId) {
      const metric = props.metrics.find(metric => metric.id === list[index].metricId)

      if (metric) {
        const unit = props.unitsOfMeasurement.find(unit => unit.id === metric.unitOfMeasurementId)

        type = metric.name
        unitType = unit.unit
        category = 'metric'
      }
    } else if (list[index].programId) category = 'workout'
    else if (list[index].exerciseId) category = 'exercise'
    else {
      category = 'note'
      type = 'Note'
    }

    return (
      <div key={index} style={style}>
        <DiaryItem
          key={list[index.id]}
          {...list[index]}
          index={index}
          ref={rowRef}
          type={type}
          unitType={unitType}
          category={category}
          globalExercises={props.globalExercises}
          programs={props.programs}
          expandItem={setRowHeight}
          onSelect={(category, item) => handleSelect({ category, item: item || list[index] })}
          onDelete={handleDelete}
        />
      </div>
    )
  }

  const setRowHeight = (index, size) => {
    listRef.current.resetAfterIndex(0)
    rowHeights.current = { ...rowHeights.current, [index]: size }
  }

  const handleSelect = ({ category, item }) => {
    setEditData(item)

    if (category === 'metric') {
      const metric = props.metrics.find(metric => item.metricId === metric.id)

      const unit = props.unitsOfMeasurement.find(unit => unit.id === metric.unitOfMeasurementId)

      setActiveMetric(metric)
      setActiveMetricUnit(unit)
    }

    if (category === 'workout') {
      setActiveSection(item)

      const program = props.clientPrograms.find(program => program.id === item.programId)

      setActiveProgram(program)
    }

    setModal(category)
  }

  const handleDelete = id => {
    const item = props.records.find(record => record.id === id)

    if (item.metricId) {
      props.dispatch(deleteLog(id))
        .then(() => {
          setList(list.filter(record => record !== item))
        })
    } else if (item.exerciseId) {
      props.dispatch(deleteRecord(id))
        .then(() => {
          setList(list.filter(record => record !== item))
        })
    } else if (item.programId) {
      item.recordId.forEach(i => {
        props.dispatch(deleteRecord(i))
      })

      setList(list.filter(record => record !== item))
    } else {
      props.dispatch(deleteNote(id))
        .then(() => {
          setList(list.filter(record => record !== item))
        })
    }
  }

  const handleCloseModal = () => {
    setEditData({})
    setModal(null)
  }

  return (
    <div className='user_profile_container' style={{ flex: 1, paddingRight: 0 }}>
      <div className='user_diary_item' style={{ height: 57 + 'px', fontWeight: 'bold', overflowX: 'hidden' }}>
        <p className='date'>Date</p>
        <p className='type'>Type</p>
        <p className='value'>Value</p>
        <p className='note'>Note</p>
      </div>

      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height - 65}
            itemCount={list.length}
            itemSize={getRowHeight}
            ref={listRef}
            width={width}
          >
            {row}
          </List>
        )}
      </AutoSizer>

      <ModalWrapper
        modalHeader={editData.id ? 'Edit metric' : 'Log metric'}
        showModal={modal === 'metric'}
        onCloseModal={handleCloseModal}
      >
        <LogMetricForm
          onCloseModal={handleCloseModal}
          onUpdateData={() => null}
          clientId={props.clientId}
          activeMetric={activeMetric}
          activeMetricUnit={activeMetricUnit}
          editData={editData}
        />
      </ModalWrapper>

      <ModalWrapper
        modalHeader={editData.id ? 'Edit exercise' : 'Log exercise'}
        showModal={modal === 'exercise'}
        onCloseModal={handleCloseModal}
      >
        <LogExerciseForm
          onCloseModal={handleCloseModal}
          editData={editData}
          exercises={props.globalExercises}
          graphMetric={undefined}
          exerciseRecords={props.records}
          clientId={props.clientId}
          globalExerciseMetrics={props.exerciseMetrics}
          unitsOfMeasurement={props.unitsOfMeasurement}
          onSortData={() => null}
        />
      </ModalWrapper>

      <ModalWrapper
        modalHeader={editData.id ? 'Edit workout' : 'Log workout'}
        showModal={modal === 'workout'}
        onCloseModal={handleCloseModal}
      >
        <LogWorkoutForm
          clientId={props.clientId}
          onCloseModal={handleCloseModal}
          activeProgram={activeProgram}
          activeSection={activeSection}
          editData={editData}
        />
      </ModalWrapper>

      <ModalWrapper
        modalHeader={editData.id ? 'Edit note' : 'Create note'}
        showModal={modal === 'note'}
        onCloseModal={handleCloseModal}
      >
        <LogNoteForm
          clientId={props.clientId}
          onCloseModal={handleCloseModal}
          editData={editData}
        />
      </ModalWrapper>

      <ModalWrapper
        modalHeader='Create entry'
        showModal={props.activeModal}
        onCloseModal={props.onModal}
      >
        <DiaryForm
          metrics={props.clientMetrics}
          programs={props.clientPrograms}
          sections={props.programSections}
          unitsOfMeasurement={props.unitsOfMeasurement}
          setActiveMetric={metric => setActiveMetric(metric)}
          setActiveMetricUnit={unit => setActiveMetricUnit(unit)}
          setActiveProgram={program => setActiveProgram(program)}
          setActiveSection={section => setActiveSection(section)}
          onModal={modal => setModal(modal)}
          onCloseModal={props.onModal}
        />
      </ModalWrapper>
    </div>
  )
}

function mapStateToProps (globalState, ownProps) {
  const recordsCopy = _.cloneDeep(globalState.exerciseRecords)

  const exerciseRecords = []
  const workoutRecords = recordsCopy.reduce((acc, res) => {
    // Push single exercise logs
    if (!res.workoutKey) {
      exerciseRecords.push(res)
      return acc
    }

    const key = res.workoutKey

    const data = {
      date: res.date,
      title: res.title,
      userId: res.userId,
      programId: res.programId,
      id: res.sectionId,
      imageURL: res.imageURL,
      recordId: [],
      content: [],
      groups: []
    }

    res.exerciseMetrics.forEach(metric => {
      metric.value = [metric.value]
      metric.placeholder = [metric.placeholder]
    })

    // If existing record has matching workout key
    if (acc[key]) {
      // Check for processed records with matching setKey
      const exercise = acc[key].content.find(exercise => exercise.key === res.exerciseKey)

      if (exercise === undefined) {
        acc[key].content.push({
          id: res.exerciseId,
          index: res.exerciseIndex,
          key: res.exerciseKey,
          metrics: res.exerciseMetrics,
          notes: res.coachNotes,
          sets: {
            display: true,
            num: 1
          }
        })

        acc[key].recordId.push(res.id)

        const groupIndex = acc[key].groups.findIndex(g => g.id === res.groupKey)

        if (groupIndex === -1) {
          acc[key].groups.push({
            id: res.groupKey,
            index: res.groupIndex,
            items: [res.exerciseKey]
          })
        } else {
          acc[key].groups[groupIndex].items.push(res.exerciseKey)
        }
        return acc
      }

      // Combine sets for each exercise
      res.exerciseMetrics.forEach(metric => {
        // Check for matching metrics
        const setMetric = exercise.metrics.find(m => m.id === metric.id)

        if (setMetric === undefined) {
          // Add new metric to set
          exercise.metrics.push(...res.exerciseMetrics)
        } else {
          // Add new value to exiting metric
          setMetric.value.splice(res.setIndex, 0, ...metric.value)
          setMetric.placeholder.splice(res.setIndex, 0, ...metric.placeholder)
          exercise.sets = { display: true, num: setMetric.value.length }
        }

        acc[key].recordId.push(res.id)
      })
    } else {
      // Create new exercise if key doesn't exist
      data.content = [{
        id: res.exerciseId,
        index: res.exerciseIndex,
        key: res.exerciseKey,
        metrics: res.exerciseMetrics,
        notes: res.coachNotes,
        sets: {
          display: true,
          num: 1
        }
      }]

      data.groups = [{
        id: res.groupKey,
        index: res.groupIndex,
        items: [res.exerciseKey],
        name: res.groupName
      }]

      data.recordId = [res.id]
      data.desc = res.note

      acc[key] = data
    }

    return acc
  }, {})

  for (const key in workoutRecords) {
    workoutRecords[key].content.sort((a, b) => {
      const aIndex = workoutRecords[key].groups.find(g => g.items.includes(a.key)).index
      const bIndex = workoutRecords[key].groups.find(g => g.items.includes(b.key)).index

      if (aIndex === bIndex) {
        return a.index - b.index
      } else {
        return aIndex - bIndex
      }
    })
  }

  const singleRecords = []
  const setRecords = exerciseRecords.reduce((acc, res) => {
    if (!res.setKey) {
      singleRecords.push(res)
      return acc
    }

    res.exerciseMetrics.forEach(metric => {
      if (typeof metric.value !== 'object') metric.value = [metric.value]
    })

    const key = res.setKey

    if (acc[key]) {
      res.exerciseMetrics.forEach(metric => {
        const setMetric = acc[key].exerciseMetrics.find(m => m.id === metric.id)

        if (setMetric === undefined) {
          acc[key].exerciseMetrics.push(metric)
        } else {
          setMetric.value.splice(res.setIndex, 0, ...metric.value)
        }

        acc[key].recordId.push(res.id)
      })
    } else {
      res.recordId = [res.id]
      acc[key] = res
    }

    return acc
  }, {})

  for (const key in setRecords) {
    setRecords[key].exerciseMetrics.sort((a, b) => a.setIndex - b.setIndex)
  }

  const records = [...globalState.logs, ...singleRecords, ...Object.values(setRecords), ...Object.values(workoutRecords), ...globalState.notes].sort((a, b) => b.date.seconds - a.date.seconds)

  const clientMetrics = globalState.metrics.filter(metric => metric.users.includes(ownProps.clientId))

  const clientPrograms = globalState.programs.filter(program => program.users.includes(ownProps.clientId) && program.header.program_type === 'Exercise')

  return {
    records,
    globalExercises: globalState.exercises,
    metrics: globalState.metrics,
    clientMetrics,
    programs: globalState.programs,
    programSections: globalState.programSections,
    clientPrograms,
    exerciseMetrics: globalState.exerciseMetrics,
    unitsOfMeasurement: globalState.units
  }
}

export default connect(mapStateToProps)(ProfileDiary)
