import React, { Component } from "react";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import lunr from "lunr";

import { deleteFood } from "../../actions/food";

import Search from "../Common/Search";
import FoodList from "./FoodList";
import FoodForm from "./FoodForm";
import ModalWrapper from "../Common/ModalWrapper";
import TopBar from "../Nav/TopBar";
import Toast from "../Common/Toast";
import ConfirmDialog from "../Common/ConfirmationDialog";

class FoodListContainer extends Component {
  state = {
    isLoading: false,
    displayModalFood: false,
    showFilter: false,
    foodList: [],
    showToast: false,
    confirmTitle: null,
    confirmText: null,
    confirmAction: null,
  };

  componentDidMount() {
    this.createSearchIndex()

    this.setState({
      foodList: this.props.food,
      searchIdx: searchIdx,
    });
  }

  createSearchIndex = () => {
    const documents = this.props.food

    const searchIdx = lunr(function () {
      this.ref("id");
      this.field("name");

      documents.forEach(function (doc) {
        this.add(doc);
      }, this);
    });

    this.setState({
      searchIdx
    })
  }

  handleModal = (e) => {
    this.setState({
      [e]: !this.state[e],
      selectedFood: undefined,
    });
  };

  handleSelect = (id) => {
    const food = this.props.food.find((food) => food.id === id);

    this.setState({
      selectedFood: food,
      displayModalFood: true,
    });
  };

  handleDelete = (id) => {
    const food = this.props.food.find((food) => food.id === id);

    if (food.sections.length === 0) {
      this.setState({
        confirmTitle: "Delete food?",
        confirmText:
          "Are you sure you want to delete this food? This action cannot be undone",
        confirmAction: () => {
          this.props.dispatch(deleteFood(id)).then(() => {
            this.handleSearch(this.state.searchFilter);
            this.handleCloseConfirm();
          });
        },
      });
    } else {
      this.setState({
        confirmTitle: "Cannot delete",
        confirmText:
          "This food is used in a program.\nPlease remove from all programs before deleting.",
        confirmAction: null,
      });
    }
  };

  handleCloseConfirm = () => {
    this.setState({
      confirmTitle: null,
      confirmText: null,
      confirmAction: null,
    });
  };

  render() {
    return (
      <div className="container_col">
        <TopBar title="Food" tabs={[]} />

        <Search
          data={this.props.food}
          searchIdx={this.state.searchIdx}
          setFilteredData={(data) => this.setState({ foodList: data })}
        />

        <div className="food_list_item">
          <label style={{ gridColumn: "1 / span 2" }}>Food Name</label>
        </div>

        <FoodList
          food={this.state.foodList}
          onSelect={this.handleSelect}
          onDelete={this.handleDelete}
        />

        <div className="ui_bottom_bar">
          <button
            onClick={() => this.handleModal("displayModalFood")}
            className="button_primary"
          >
            {this.state.isLoading ? (
              <i className="fas fa-spinner fa-spin" />
            ) : (
              "Create Food"
            )}
          </button>
        </div>

        <ModalWrapper
          key={uuid()}
          modalHeader={this.state.selectedFood ? "Edit food" : "Create food"}
          showModal={this.state.displayModalFood}
          onCloseModal={() => this.handleModal("displayModalFood")}
        >
          <FoodForm
            onCloseModal={() => this.handleModal("displayModalFood")}
            selectedFood={this.state.selectedFood}
            onUpdateList={this.createSearchIndex}
            onSuccess={() => this.setState({ showToast: true })}
          />
        </ModalWrapper>

        <Toast
          showToast={this.state.showToast}
          message="Food Created"
          onClose={() => this.setState({ showToast: false })}
        />

        <ConfirmDialog
          open={Boolean(this.state.confirmTitle)}
          onClose={this.handleCloseConfirm}
          title={this.state.confirmTitle}
          text={this.state.confirmText}
          affirmativeAction={this.state.confirmAction}
          negativeAction={this.handleCloseConfirm} // eslint-disable-line
        />
      </div>
    );
  }
}

function mapStateToProps(globalState) {
  return {
    food: globalState.food.sort((a, b) => a.name.localeCompare(b.name)),
    activeUserType: globalState.user.info.type,
    activeUserId: globalState.user.auth.uid,
    activeUserName: globalState.user.auth.displayName,
  };
}

export default connect(mapStateToProps)(FoodListContainer);
