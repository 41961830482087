import { RECEIVE_LOGS } from '../actions/logs'

const initialState = [{ userId: null }]

const logs = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_LOGS:
      return action.logs
    default:
      return state
  }
}

export default logs
