import React, { Component } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import CollectionItem from './CollectionItem'

class CollectionList extends Component {
  render () {
    return (
      <div>
        <DragDropContext onDragEnd={this.props.onDragEnd}>
          <Droppable droppableId='collection'>
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {
                  this.props.collections.map((collection, i) => (
                    <CollectionItem
                      {...collection}
                      key={collection.id}
                      index={i}
                      onSelect={this.props.onSelect}
                      onDelete={this.props.onDelete}
                    />
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
}

export default CollectionList
