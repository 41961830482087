import React, { Component } from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import MetricForm from '../Metrics/MetricForm'
import AssignExistingMetric from './AssignExistingMetric'

Modal.setAppElement('#app')

class ModalAssignMetricToClient extends Component {
  state = {
    page: 1,
    units: []
  }

  handleChangePage = () => {
    const currentPage = this.state.page

    this.setState({
      page: currentPage === 1 ? 2 : 1
    })
  }

  render () {
    return (
      <Modal
        isOpen={this.props.showModal}
        onRequestClose={this.props.onCloseModal}
        className='modal'
        overlayClassName='modal_overlay'
      >

        <div className='modal_wrapper'>
          <span className='modal_header'>
            <h3>{this.state.page === 1 ? 'Assign metric' : 'Create metric'}</h3>
            <i className='fas fa-times' onClick={() => this.props.onCloseModal()} />
          </span>

          {
            this.state.page === 1 &&
              <AssignExistingMetric
                clientId={this.props.client.id}
                onRequestClose={metric => this.props.onCloseModal(metric)} onChangePage={() => this.setState({ page: 2 })}
              />

          }
          {
            this.state.page === 2 &&
              <MetricForm
                userId={this.props.client.id}
                activeUserId={this.props.activeUserId}
                activeUserName={this.props.activeUserName}
                onCloseModal={metric => this.props.onCloseModal(metric)} onChangePage={() => this.setState({ page: 1 })}
                onSuccess={() => null}
              />
          }
        </div>

      </Modal>
    )
  }
}

function mapStateToProps (globalState) {
  return {
    activeUserType: globalState.user.info.type,
    activeUserId: globalState.user.auth.uid,
    activeUserName: globalState.user.auth.displayName,
    userUID: globalState.user.auth.uid,
    metrics: globalState.metrics,
    units: globalState.units
  }
}

export default connect(mapStateToProps)(ModalAssignMetricToClient)
