import React, { Component } from 'react'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'
import lunr from 'lunr'

import { deleteMetric } from '../../actions/metrics'

import Search from '../Common/Search'
import MetricForm from './MetricForm'
import MetricList from './MetricList'
import ModalWrapper from '../Common/ModalWrapper'
import TopBar from '../Nav/TopBar'
import Toast from '../Common/Toast'
import ConfirmDialog from '../Common/ConfirmationDialog'

class MetricListContainer extends Component {
  state = {
    isLoading: false,
    displayModalMetric: false,
    showFilter: false,
    metricList: [],
    showToast: false,
    confirmTitle: null,
    confirmText: null,
    confirmAction: null
  }

  componentDidMount () {
    this.createSearchIndex()

    this.setState({
      metricList: this.props.metrics,
    })
  }

  createSearchIndex = () => {
    const documents = this.props.metrics.map((metric) => {
      const unit = this.props.units.find(unit => unit.id === metric.unitOfMeasurementId)

      return {
        id: metric.id,
        name: metric.name,
        unit: unit.unit
      };
    });

    const searchIdx = lunr(function () {
      this.ref("id");
      this.field("name");
      this.field("unit")

      documents.forEach(function (doc) {
        this.add(doc);
      }, this);
    });

    this.setState({
      searchIdx
    })
  }

  handleModal = e => {
    this.setState({
      [e]: !this.state[e],
      selectedMetric: undefined
    })
  }

  handleSelect = id => {
    const metric = this.props.metrics.find(metric => metric.id === id)

    this.setState({
      selectedMetric: metric,
      displayModalMetric: true
    })
  }

  handleDelete = id => {
    const metric = this.props.metrics.find(metric => metric.id === id)

    if (metric.users.length === 0) {
      this.setState({
        confirmTitle: 'Delete metric?',
        confirmText: 'Are you sure you want to delete this metric? This action cannot be undone',
        confirmAction: () => {
          this.props.dispatch(deleteMetric(id))
            .then(() => {
              this.handleSearch(this.state.searchFilter)
              this.handleCloseConfirm()
            })
        }
      })
    } else {
      this.setState({
        confirmTitle: 'Cannot delete',
        confirmText: 'This metric has been assigned to a client.\nPlease unassign before deleting.',
        confirmAction: null
      })
    }
  }

  handleSearch = regex => {
    const metrics = this.props.metrics.filter(metric => metric.name.match(regex))

    this.setState({
      metricList: metrics,
      searchFilter: regex
    })
  }

  handleCloseConfirm = () => {
    this.setState({
      confirmTitle: null,
      confirmText: null,
      confirmAction: null
    })
  }

  render () {
    return (
      <div className='container_col'>
        <TopBar
          title='Metrics'
          tabs={[]}
        />

        <Search
          data={this.props.metrics}
          searchIdx={this.state.searchIdx}
          setFilteredData={data => this.setState({ metricList: data })}
        />

        <div className='metric_list_item'>
          <label style={{ gridColumn: '1 / span 2' }}>Metric Name</label>
          <label className='assigned'>Assigned</label>
          <label className='unit'>Unit</label>
        </div>

        <MetricList
          metrics={this.state.metricList}
          units={this.props.units}
          clients={this.props.clients}
          onSelect={this.handleSelect}
          onDelete={this.handleDelete}
        />

        <div className='ui_bottom_bar'>
          <button
            onClick={() => this.handleModal('displayModalMetric')}
            className='button_primary'
          >
            {this.state.isLoading ? <i className='fas fa-spinner fa-spin' /> : 'Create Metric'}
          </button>
        </div>

        <ModalWrapper
          key={uuid()}
          modalHeader={this.state.selectedMetric ? 'Edit metric' : 'Create metric'}
          showModal={this.state.displayModalMetric}
          onCloseModal={() => this.handleModal('displayModalMetric')}
        >
          <MetricForm
            selectedMetric={this.state.selectedMetric}
            activeUserId={this.props.activeUserId}
            activeUserName={this.props.activeUserName}
            activeUserType={this.props.activeUserType}
            clients={this.props.clients}
            onCloseModal={() => this.handleModal('displayModalMetric')}
            onUpdateList={() => this.handleSearch(this.state.searchFilter)}
            onSuccess={() => this.setState({ showToast: true })}
          />
        </ModalWrapper>

        <Toast
          showToast={this.state.showToast}
          message='Metric Created'
          onClose={() => this.setState({ showToast: false })}
        />

        <ConfirmDialog
          open={Boolean(this.state.confirmTitle)}
          onClose={this.handleCloseConfirm}
          title={this.state.confirmTitle}
          text={this.state.confirmText}
          affirmativeAction={this.state.confirmAction}
          negativeAction={this.handleCloseConfirm} // eslint-disable-line

        />

      </div>
    )
  }
}

function mapStateToProps (globalState) {
  return {
    metrics: globalState.metrics.sort((a, b) => a.name.localeCompare(b.name)),
    units: globalState.units,
    clients: globalState.clients,
    activeUserType: globalState.user.info.type,
    activeUserId: globalState.user.auth.uid,
    activeUserName: globalState.user.auth.displayName
  }
}

export default connect(mapStateToProps)(MetricListContainer)
