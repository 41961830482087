import React, { useState } from 'react'
import { processYoutubeURL } from '../../../util'
import AddComponent from './AddComponent'

const Video = props => {
  const [marked, setMark] = useState(false)

  const handleChange = e => {
    const url = processYoutubeURL(e.target.value)

    const change = { target: { name: 'videoURL', value: url } }

    props.onChange(change, props.componentId)
  }

  return (
    <>
      <div
        className='lesson_video_component'
        style={marked ? { backgroundColor: 'rgba(234, 68, 57, 0.5)' } : undefined}
      >
        <label htmlFor='videoURL'>Youtube URL:</label>
        <input
          type='text'
          name='videoURL'
          defaultValue={props.data.videoURL !== '' ? `https://www.youtube.com/embed/${props.data.videoURL}` : ''}
          onChange={handleChange}
        />

        <div className='ui_video_container'>
          <iframe
            className='video'
            src={`https://www.youtube.com/embed/${props.data.videoURL}`}
            frameBorder='0'
          />
        </div>
      </div>

      <AddComponent
        onMenu={props.onMenu}
        onDelete={props.onDelete}
        onMark={bool => setMark(bool)}
        componentId={props.componentId}
      />
    </>
  )
}

export default Video

export const defaultVideo = {
  component: 'video',
  componentId: null,
  data: {
    videoURL: ''
  }
}
