import React, { Component } from 'react'
import { connect } from 'react-redux'

class AssignExistingExercise extends Component {
  state = {
    exercises: [],
    tag_movement: [],
    tag_muscle: [],
    searchFilter: new RegExp('(?:)', 'gi'),
    showFilter: false
  }

  handleSearch = e => {
    this.createSearchIndex()

    this.setState({
      searchFilter: e.target.value
    })
  }



  handleChange = e => {
    let exercises = [...this.state.exercises]

    if (e.target.checked) {
      exercises.push(e.target.value)
    } else {
      exercises = exercises.filter(f => f !== e.target.value)
    }

    this.setState({
      exercises
    })
  }

  // handleSelectTag = (e) => {
  //   this.setState({
  //     [e.target.name]: e.target.value
  //   })
  // }

  handleSubmit = e => {
    e.preventDefault()

    this.props.onAssignExercise({ items: this.state.exercises, type: 'Exercise' })
    this.props.onCloseModal()
  }

  render () {
    return (
      <>
        <form onSubmit={this.handleSubmit} className='modal_content'>
          {
            this.props.exerciseList.map(exercise => (
                  <label htmlFor={exercise.id} key={exercise.id}>
                    <div
                      className='program_section_item'
                      style={{ paddingBottom: 0, margin: 0 }, this.state.exercises.includes(exercise.id) ? { backgroundColor: 'rgb(218, 225, 243)' } : undefined} // eslint-disable-line
                    >
                      <input
                        type='checkbox'
                        name={exercise.name}
                        value={exercise.id}
                        id={exercise.id}
                        onChange={this.handleChange}
                        defaultChecked={this.state.exercises.includes(exercise.id)}
                      />

                      <img src={exercise.thumbnail_url} />

                      <p className='header'>{exercise.name}</p>
                    </div>
                  </label>
                )
            )
          }
        </form>

        <div className='modal_buttons'>
          <button
            className='button_primary'
            onClick={this.handleSubmit}
          >
            {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : `Add Exercises (${this.state.exercises.length}) to Workout`}
          </button>

          <button
            className='button_navigational'
            type='button'
            onClick={this.props.onChangePage}
          >
            <span className='row'>
            Add new exercise
              <i className='fas fa-chevron-right' />
            </span>
          </button>
        </div>
      </>
    )
  }
}

function mapStateToProps (globalState) {
  return {
    exercises: globalState.exercises.sort((a, b) => a.name.localeCompare(b.name))
  }
}

export default connect(mapStateToProps)(AssignExistingExercise)
