import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Draggable } from 'react-beautiful-dnd'

import KebabMenu from '../../Common/KebabMenu'

const Phase = props => {
  const [edit, toggleEdit] = useState(false)
  const [phase, namePhase] = useState(props.phase.name)

  const handleSubmit = e => {
    e.preventDefault()
    toggleEdit(false)

    const phase = props.phase
    phase.name = e.target.value

    props.onChangePhase(phase)
  }

  const handleEdit = () => {
    toggleEdit(true)
  }

  return (
    <Draggable draggableId={props.phase.id} index={props.phase.index}>
      {provided => (
        <div
          className={`program_phase ${props.isActive ? 'active' : undefined}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <i
            className='fas fa-grip-vertical grip'
            {...provided.dragHandleProps}
          />
          {
            !edit &&
              <p onClick={props.onClick}>{phase}</p>
          }

          {
            edit &&
              <form onSubmit={handleSubmit}>
                <input
                  onChange={e => namePhase(e.target.value)}
                  onBlur={handleSubmit}
                  name='phase'
                  value={phase}
                  autoFocus
                />
                <input
                  onClick={handleSubmit}
                  hidden
                />
              </form>
          }

          <KebabMenu
            context='phase'
            onEdit={handleEdit}
            onCopy={() => props.displayCopyModal({ type: 'phase', id: props.phase.id })}
            onDelete={() => props.onDeletePhase(props.phase.id)}
          />
        </div>

      )}
    </Draggable>
  )
}

export default connect()(Phase)
