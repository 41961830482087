import React, { useEffect } from 'react'

import Typography from '@material-ui/core/Typography'

import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { redirectCheckout } from '../../actions/stripe'

const CheckoutRedirect = () => {
  const query = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const sessionId = new URLSearchParams(query.search).get('sessionId')

    dispatch(redirectCheckout(sessionId))
  }, [])

  return (
    <div className='container_col'>
      <div className='ui_oni_bar'>
        <img src='/img/Logo-Colour-White-small.png' />
      </div>

      <div className='login'>
        <Typography variant='h6'>Redirecting to checkout</Typography>
        <span style={{ marginTop: 24 }}>
          <i className='fas fa-spinner fa-spin' style={{ fontSize: '2rem' }} />
        </span>
      </div>
    </div>
  )
}

export default CheckoutRedirect
