import React, { Component } from 'react'

class EditFood extends Component {
  state = {
    portion: '',
    notes: ''
  }

  componentDidMount () {
    this.setState({
      ...this.props.item
    })
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const item = { ...this.state }

    this.props.onChange({ itemKey: this.props.item.key, item })
    this.props.onCloseModal()
  }

  render () {
    return (
      <>
        <form className='modal_content' onSubmit={this.handleSubmit}>
          <label htmlFor='portion'>Portions</label>
          <input
            name='portion'
            defaultValue={this.state.portion}
            onChange={this.handleChange}
          />

          <label htmlFor='notes'>Notes</label>
          <textarea
            name='notes'
            onChange={this.handleChange}
            rows={5}
            defaultValue={this.state.notes}
          />
        </form>

        <div className='modal_buttons'>
          <button
            className='button_primary'
            onClick={this.handleSubmit}
          >
          Submit
          </button>
        </div>
      </>
    )
  }
}

export default EditFood
