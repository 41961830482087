import React, { Component } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { fb } from '../../config/firebase'
import { connect } from 'react-redux'
import Milestone from './Milestone'
import { editMilestone, deleteMilestone, createMilestone } from '../../actions/milestones'

class MilestoneContainer extends Component {
  state = {
    activeMilestones: [],
    completedMilestones: [],
    desc: '',
    note: ''
  }

  componentDidMount () {
    const activeMilestones = this.props.milestones.filter(milestone => !milestone.achieved)
    const completedMilestones = this.props.milestones.filter(milestone => milestone.achieved)

    this.setState({
      activeMilestones: activeMilestones.sort((a, b) => a.index - b.index),
      completedMilestones: completedMilestones.sort((a, b) => a.index - b.index)
    })
  }

  componentWillUnmount () {
    this.updateMilestoneDB()
  }

  // Handles changes in new milestone form
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  // Called from milestone component - handles editing description or date
  handleEdit = ({ id, update, achieved }) => {
    const arr = achieved ? 'completedMilestones' : 'activeMilestones'
    const milestones = this.state[arr]

    const editMile = milestones.find(milestone => milestone.id === id)
    const editIndex = milestones.indexOf(editMile)

    milestones[editIndex] = Object.assign(editMile, update)

    this.props.dispatch(editMilestone(milestones[editIndex]))
      .then(() => {
        this.setState({
          [arr]: milestones
        })
      })
      .catch(err => err)
  }

  // Called from milestone component - deletes a milestone
  handleDelete = ({ id, achieved }) => {
    const arr = achieved ? 'completedMilestones' : 'activeMilestones'

    const deletedMile = this.state[arr].find(milestone => milestone.id === id)

    this.props.dispatch(deleteMilestone(deletedMile.id))
      .catch(err => err)
  }

  handleCancelCreateMilestone = e => {
    this.setState({
      desc: '',
      note: ''
    })
  }

  // Submit method of new milestone form
  handleNewMilestone = e => {
    e.preventDefault()

    const newMilestone = {
      desc: this.state.desc,
      note: this.state.note,
      achieved: false,
      userId: this.props.clientId,
      index: 0,
      dateCreated: new fb.firestore.Timestamp.now() //eslint-disable-line
    }

    this.props.dispatch(createMilestone(newMilestone))
      .catch(err => err)
  }

  // Figures out what was dropped and where
  handleDragEnd = res => {
    const { draggableId, source, destination } = res

    if (destination === null) {
      return
    }

    if (destination.index === source.index) {
      return
    }

    const movedStone = this.state[source.droppableId].find(milestone => milestone.id === draggableId)

    movedStone.achieved = destination.droppableId === 'completedMilestones'

    source.droppableId === 'activeMilestones' && movedStone.achieved ? movedStone.timeAchieved = new fb.firestore.Timestamp.now() : null //eslint-disable-line

    const updatedStones = { ...this.state }

    updatedStones[source.droppableId].splice(source.index, 1)
    updatedStones[destination.droppableId].splice(destination.index, 0, movedStone)

    this.setState({
      ...updatedStones
    }, () => this.updateMilestoneDB())
  }

  // Called from milestone component - handles updating when checked
  handleCheck = ({ id, checked }) => {
    const source = checked ? 'activeMilestones' : 'completedMilestones'
    const destination = checked ? 'completedMilestones' : 'activeMilestones'

    const movedStone = this.state[source].find(milestone => milestone.id === id)
    const movedStoneIndex = this.state[source].indexOf(movedStone)

    movedStone.achieved = checked
    movedStone.timeAchieved = checked ? new fb.firestore.Timestamp.now() : null // eslint-disable-line

    this.updateMilestoneArray(source, destination, movedStoneIndex, 0, movedStone)
  }

    // Adds rearranged arrays back into state
    updateMilestoneArray = (sourceArray, destinationArray, sourceIndex, destinationIndex, milestone) => {
      const updatedStones = { ...this.state }

      updatedStones[sourceArray].splice(sourceIndex, 1)
      updatedStones[destinationArray].splice(destinationIndex, 0, milestone)

      this.setState({
        ...updatedStones
      }, () => this.updateMilestoneDB())
    }

  // Update db to reflect new state
  updateMilestoneDB = () => {
    const activeMilestones = [...this.state.activeMilestones]
    activeMilestones.forEach((milestone, i) => {
      milestone.index = i + 1

      this.props.dispatch(editMilestone(milestone))
    })

    const completedMilestones = [...this.state.completedMilestones]
    completedMilestones.forEach((milestone, i) => {
      milestone.index = i + 1

      this.props.dispatch(editMilestone(milestone))
        .catch(err => err)
    })
  }

  render () {
    return (
      <div className='user_milestones_container'>
        <h1>Milestones</h1>
        <p>{`Complete: ${this.state.completedMilestones.length}/${this.state.completedMilestones.length + this.state.activeMilestones.length}`}</p>

        {
          this.state.activeMilestones.length > 0 &&
            <div className='user_milestone_item'>
              <span />
              <p><strong>Milestone</strong></p>
              <p><strong>Note</strong></p>
              <span />
              <span />
              <span />
            </div>
        }

        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId='activeMilestones'>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {
                  this.state.activeMilestones.map((milestone, i) => (
                    <Milestone
                      key={milestone.id}
                      index={i}
                      milestone={milestone}
                      onCheck={this.handleCheck}
                      onChange={this.handleChange}
                      onEdit={this.handleEdit}
                      onDelete={this.handleDelete}
                    />
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <div className='user_milestone_item'>
            <form onSubmit={this.handleNewMilestone} className='name'>
              <input
                type='text'
                name='desc'
                id='desc'
                value={this.state.desc}
                placeholder='Add new milestone'
                onChange={this.handleChange}
                style={{ margin: 0 }}
              />
            </form>

            <form onSubmit={this.handleNewMilestone} className='note'>
              <input
                type='text'
                name='note'
                id='note'
                value={this.state.note}
                placeholder='Add note'
                onChange={this.handleChange}
                style={{ margin: 0 }}
              />
            </form>

            <span className='accept'>
              <i className='fas fa-check' onClick={this.handleNewMilestone} />
            </span>

            <span className='cancel'>
              <i className='fas fa-trash' onClick={this.handleCancelCreateMilestone} />
            </span>
          </div>

          {
            this.state.completedMilestones.length > 0 &&

              <div className='user_milestone_item'>
                <span />
                <p><strong>Milestone</strong></p>
                <p><strong>Note</strong></p>
                <p><strong>Date Achieved</strong></p>
                <span />
                <span />
              </div>
          }

          <Droppable droppableId='completedMilestones'>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {
                  this.state.completedMilestones.map((milestone, i) => (
                    <Milestone
                      key={milestone.id}
                      index={i}
                      milestone={milestone}
                      onCheck={this.handleCheck}
                      onChange={this.handleChange}
                      onEdit={this.handleEdit}
                      onDelete={this.handleDelete}
                    />
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

      </div>

    )
  }
}

export default connect()(MilestoneContainer)
