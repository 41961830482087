import React from 'react'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import * as yup from 'yup'
import { v4 as uuid } from 'uuid'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import Grid from '@material-ui/core/Grid'
import CustomSelect from '../../Common/CustomSelect'
import CustomCheckbox from '../../Common/CustomCheckbox'
import CustomAutocomplete from '../../Common/CustomAutocomplete'

import { fb } from '../../../config/firebase'
import { createProgram, editProgram } from '../../../actions/programs'
import { addProgramToCollection } from '../../../actions/collections'
import { arrayDiffs } from '../../../util'

const ProgramForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting
  } = props

  return (
    <>
      <form onSubmit={handleSubmit} className='modal_content' style={{ overflowX: 'hidden' }}>
        <CustomSelect
          label='Program Type'
          name='program_type'
          value={values.program_type || ''}
          error={touched.program_type && Boolean(errors.program_type)}
          helperText={touched.program_type && errors.program_type}
          onChange={handleChange}
          required
          multiple={false}
        >
          <MenuItem value='Exercise'>Exercise</MenuItem>
          <MenuItem value='Nutrition'>Nutrition</MenuItem>
          <MenuItem value='Lesson'>Lesson</MenuItem>
        </CustomSelect>

        <TextField
          name='title'
          label='Program Name'
          value={values.title || ''}
          onChange={handleChange}
          error={touched.title && Boolean(errors.title)}
          helperText={touched.title && errors.title}
          required
        />

        {
          props.activeUserType?.includes('admin') &&
            <div>
              <CustomCheckbox
                label='Global'
                name='global'
                value={values.global}
                onChange={handleChange}
              />

              <Grid container style={{ minHeight: 116 }}>
                <Grid item xs={6}>
                  <CustomCheckbox
                    label='Discoverable'
                    name='discoverable'
                    value={values.discoverable}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={6}>
                  {
                    values.discoverable &&
                      <FormControl>
                        <RadioGroup
                          name='discoverableType'
                          value={values.discoverableType || 'PRO'}
                          onChange={handleChange}
                        >
                          <FormControlLabel value='PRO' control={<Radio />} label='Pro' />
                          <FormControlLabel value='FREEMIUM' control={<Radio />} label='Freemium' />
                        </RadioGroup>
                      </FormControl>
                  }
                </Grid>
              </Grid>
            </div>
        }

        <TextField
          name='desc'
          label='Description'
          value={values.desc}
          onChange={handleChange}
          error={Boolean(errors.desc)}
          helperText={errors.desc}
          multiline
        />

        <CustomAutocomplete
          label='Assign to Client'
          name='users'
          value={values.users?.map(value => {
            const user = props.clients.find(c => c.id === value)
            return ({ name: `${user?.first_name} ${user?.last_name}`, value }) // eslint-disable-line
          })}
          onChange={handleChange}
          options={props.clients.map(client => (
            {
              name: `${client.first_name} ${client.last_name}`,
              value: client.id
            }
          ))}
        />

        {
          props.activeUserType?.includes('admin') &&
            <CustomAutocomplete
              label='Collections'
              name='collections'
              value={values.collections?.map(value => {
                const collection = props.collections.find(c => c.id === value)
                return { name: collection?.name, value }
              })}
              onChange={handleChange}
              options={props.collections.map(collection => (
                {
                  name: collection.name,
                  value: collection.id
                }
              ))}
            />
        }
      </form>

      <div className='modal_buttons'>
        <button
          onClick={handleSubmit}
          className='button_primary'
          type='submit'
        >
          {isSubmitting ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}

        </button>
      </div>
    </>
  )
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ activeProgram }) => ({
    title: activeProgram?.header?.title,
    desc: activeProgram?.header?.desc,
    program_type: activeProgram?.header?.program_type, // eslint-disable-line
    ownerId: activeProgram?.ownerId,
    discoverable: activeProgram?.discoverable || false,
    discoverableType: activeProgram?.discoverableType || 'PRO',
    collections: activeProgram?.collections || [],
    users: activeProgram?.users || [],
    global: activeProgram?.ownerId?.includes('global')
  }),
  validationSchema: () => {
    return yup.lazy(values => {
      return yup.object().shape(
        Object.assign({
          title: yup.string().required('Program name is required'),
          program_type: yup.string().required('Program type is required'),
          desc: yup.string(),
          image_url: yup.string(),
          users: yup.array().of(yup.string()),
          collections: yup.array().of(yup.string()),
          global: yup.boolean(),
          discoverable: yup.boolean(),
          discoverableType: yup.string().nullable()
        })
      )
    })
  },
  handleSubmit: ({ ...payload },
    { props, setSubmitting, setErrors }) => {
    const addCollection = (toAdd, programId) => {
      toAdd.forEach(collectionId => {
        props.dispatch(addProgramToCollection({ collectionId, programId }))
      })
    }

    const header = {
      title: payload.title,
      desc: payload.desc || '',
      image_url: props.selectedImage,
      program_type: payload.program_type
    }

    if (!payload.users) payload.users = []
    if (payload.program_type === 'Nutrition') payload.details = {}
    if (payload.program_type === 'Lesson') payload.lessons = []

    delete payload.title
    delete payload.desc
    delete payload.image_url
    delete payload.program_type

    if (!payload.discoverable) {
      payload.discoverable = false
      payload.discoverableType = null
    }

    if (payload.collections?.length && !payload.discoverable) {
      payload.discoverable = true
      payload.discoverableType = 'PRO'
    }

    let ownerId = props.activeProgram?.ownerId || [props.activeUserId]
    if (payload.global && !ownerId.includes('global')) ownerId.push('global')
    else if (!payload.global) ownerId = ownerId.filter(id => id !== 'global')
    delete payload.global

    const program = {
      ...payload,
      header,
      ownerId
    }

    if (props.activeProgramId) {
      program.lastUpdated = new fb.firestore.Timestamp.now() // eslint-disable-line
      delete program.dateCreated

      const { toAdd, toRemove } = arrayDiffs(props.activeProgram.collections, payload.collections)

      props.dispatch(editProgram({
        programId: props.activeProgramId,
        program,
        toAdd,
        toRemove
      }))
        .then(() => {
          setSubmitting(false)
          props.onCloseModal && props.onCloseModal()
        })
    } else {
      program.creatorName = props.activeUserName
      program.creatorImg = props.activeUserImg
      program.phases = [{
        name: 'Week 1',
        id: uuid(),
        index: 0
      }]
      program.dateCreated = fb.firestore.Timestamp.now() // eslint-disable-line

      props.dispatch(createProgram(program))
        .then(res => {
          if (payload.collections) {
            addCollection(payload.collections, res.program.id)
          }

          setSubmitting(false)
          props.history.push(`/programs/${res.program.id}/Program`)
        })
        .catch(err => setErrors(err))
    }
  },
  displayName: 'Create Program'
})

function mapStateToProps (globalState, ownProps) {
  let clients = globalState.clients.filter(client => client.trainers.includes(globalState.user.auth.uid))

  if (globalState.user.info.type.includes('admin')) clients = globalState.clients

  const assignedCollections = globalState.collections.filter(collection => collection.programs.includes(ownProps.program?.id))

  return {
    clients,
    collections: globalState.collections,
    assignedCollections,
    activeUserId: globalState.user.auth.uid,
    activeUserName: globalState.user.auth.displayName,
    activeUserImg: globalState.user.auth.photoURL,
    activeUserType: globalState.user.info.type
  }
}

export default connect(mapStateToProps)(formikEnhancer(ProgramForm))
