import React, { Component } from 'react'
import { connect } from 'react-redux'
import { assignMetric } from '../../actions/metrics'

class AssignExistingMetric extends Component {
  state = {
    showError: false,
    errorMessage: ''
  }

  handleChange = e => {
    const metric = this.props.metrics.find(metric => metric.name === e.target.value)

    this.setState({
      metric: metric,
      showError: false
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState({
      isLoading: 'submit'
    })

    this.props.dispatch(assignMetric({ metricId: this.state.metric.id, clientId: this.props.clientId }))
      .then(() => {
        this.setState({
          isLoading: null
        })

        this.props.onRequestClose(this.state.metric)
      })
      .catch(err => {
        this.setState({
          showError: true,
          errorMessage: err.message
        })
      })
  }

  render () {
    return (
      <>
        <form onSubmit={this.handleSubmit} className='modal_content'>
          <label htmlFor='metric'>Existing Metrics</label>
          <select
            name='metric'
            defaultValue='placeholder'
            onChange={this.handleChange}
          >
            <option value='placeholder' disabled hidden>Select a Metric</option>
            {
              this.props.metrics.map(metric => {
                if (!metric.users.includes(this.props.client.id)) {
                  return <option key={metric.id} name={metric}>{metric.name}</option>
                }
              })
            }
          </select>

          <p className='ui_error'>{this.state.showError && this.state.errorMessage}</p>

        </form>

        <div className='modal_buttons'>
          <button
            onClick={this.handleSubmit}
            className='button_primary'
            disabled={this.state.metric === undefined}
          >
            {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
          </button>
          <button
            type='button'
            className='button_navigational'
            onClick={this.props.onChangePage}
          >
            <span className='row'>
              Custom Metric
              <i className='fas fa-chevron-right' />
            </span>
          </button>
        </div>
      </>
    )
  }
}

function mapStateToProps (globalState, ownProps) {
  const client = globalState.clients.find(client => client.id === ownProps.clientId)

  return {
    client,
    metrics: globalState.metrics,
    activeUserId: globalState.user.auth.uid,
    activeUserType: globalState.user.info.type
  }
}

export default connect(mapStateToProps)(AssignExistingMetric)
