import React, { Component } from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { fb } from '../../config/firebase'
import { updateGoal, createGoal } from '../../actions/goals'

class ModalGoalForm extends Component {
  state = {
    isLoading: null,
    showError: false,
    errorMessage: ''
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState({
      isLoading: 'submit'
    })

    if (this.props.id) {
      this.props.dispatch(updateGoal({ id: this.props.id, goal: this.state.goal }))
        .then(() => {
          this.setState({
            isLoading: null
          })
          this.props.onCloseModal()
        })
    } else {
      const goal = {
        goal: this.state.goal,
        userId: this.props.clientId,
        active: true,
        timeCreated: new fb.firestore.Timestamp.now() //eslint-disable-line
      }

      this.props.dispatch(createGoal(goal))
        .then(() => {
          this.setState({
            isLoading: null
          })
          this.props.onCloseModal()
        })
        .catch(err => {
          this.setState({
            showError: true,
            errorMessage: err.message,
            isLoading: false
          })
        })
    }
  }

  render () {
    return (
      <Modal
        isOpen={this.props.showModal}
        onRequestClose={this.props.onCloseModal}
        className='modal'
        overlayClassName='modal_overlay'
      >

        <div className='modal_wrapper'>
          <span className='modal_header'>
            <h3>{this.props.goal ? 'Edit Goal' : 'Create a goal'}</h3>
            <i className='fas fa-times' onClick={() => this.props.onCloseModal('displayAddLogModal')} />
          </span>

          <form onSubmit={this.handleSubmit} className='modal_content'>

            <label htmlFor='goal'>Goal</label>
            <textarea
              rows='5'
              name='goal'
              defaultValue={this.props.goal}
              onChange={this.handleChange}
            />

            <p className='ui_error'>{this.state.showError && this.state.errorMessage}</p>

          </form>

          <div className='modal_buttons'>
            <button
              onClick={this.handleSubmit}
              className='button_primary'
            >
              {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
            </button>
          </div>
        </div>

      </Modal>
    )
  }
}

export default connect()(ModalGoalForm)
