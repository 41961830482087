import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

const CustomSelect = ({
  label,
  name,
  value,
  error,
  helperText,
  required,
  multiple,
  onChange,
  children
}) => {
  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel
        htmlFor={name}
        error={error}
        required={required}
        variant='outlined'
      >
        {label}
      </InputLabel>

      <Select
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        variant='outlined'
        error={error}
        multiple={multiple}
        aria-describedby={label}
        fullWidth
      >
        {children}
      </Select>

      <FormHelperText id={label} error={error}>
        {helperText}
      </FormHelperText>
    </FormControl>
  )
}

export default CustomSelect
