import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { fb } from '../../config/firebase'
import moment from 'moment'

import { createNote, updateNote } from '../../actions/notes'

const LogNoteForm = props => {
  const [note, setNote] = useState('')
  const [date, setDate] = useState('')
  const [isLoading, setLoading] = useState(null)

  useEffect(() => {
    setNote(props.editData.note)
    setDate(props.editData.id ? moment(props.editData.date.seconds, 'X').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    setLoading('submit')

    const n = {
      note,
      date: new fb.firestore.Timestamp.fromDate(new Date(date)) //eslint-disable-line
    }

    if (props.editData.id) {
      props.dispatch(updateNote(n, props.editData.id))
        .then(() => props.onCloseModal())
    } else {
      n.userId = props.clientId // eslint-disable-line

      props.dispatch(createNote(n))
        .then(() => props.onCloseModal())
    }
  }

  return (
    <>
      <form className='modal_content' onSubmit={handleSubmit}>
        <input
          type='date'
          name='date'
          defaultValue={date}
          onChange={e => setDate(e.target.value)}
          required
        />
        <textarea
          onChange={e => { setNote(e.target.value) }}
          defaultValue={props.editData.note}
        />
      </form>

      <div className='modal_buttons'>
        <button
          onClick={handleSubmit}
          className='button_primary'
        >
          {isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
        </button>
      </div>
    </>
  )
}

export default connect()(LogNoteForm)
