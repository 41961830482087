import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createFood, editFood } from '../../actions/food'
import { fb } from '../../config/firebase'
import { uploadImage } from '../../util'
import ImageGallery from '../Common/ImageGallery'

import TextField from '@material-ui/core/TextField'

class FoodForm extends Component {
  defaultIndex = 16

  state = {
    isLoading: null,
    showError: false,
    errorMessage: '',
    selectImage: false
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      showError: false
    })
  }

  handleImage = e => {
    this.setState({
      showError: false
    })

    const image = e.target.files[0]

    if (!image.type.includes('image')) {
      this.setState({
        errorMessage: 'File must be an image',
        showError: true
      })

      return
    }

    const filesize = ((image.size / 1024) / 1024).toFixed(4)
    if (filesize > 1) {
      this.setState({
        errorMessage: 'File is too big.  File must be less than 1MB',
        showError: true
      })
      return
    }

    this.setState({
      image: image
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState({
      isLoading: 'submit'
    })

    const food = { ...this.state }
    delete food.isLoading
    delete food.showError
    delete food.errorMessage

    if (food.image) {
      uploadImage('nutrition', food.image)
        .then(url => {
          food.image_url = url

          delete food.image

          if (this.props.selectedFood) {
            this.editFood(food)
          } else {
            this.createFood(food)
          }
        })
        .catch(err => this.error(err))
    } else {
      if (this.props.selectedFood) {
        this.editFood(food)
      } else {
        this.createFood(food)
      }
    }
  }

  createFood = food => {
    food.dateCreated = new fb.firestore.Timestamp.now() // eslint-disable-line
    food.ownerId = [this.props.activeUserId, 'global']
    food.sections = []

    this.props.dispatch(createFood(food))
      .then(({ food }) => {
        if (this.props.onAssignFood) {
          this.props.onAssignFood([food.id])
        }

        this.success()
      })
      .catch(err => this.error(err))
  }

  editFood = food => {
    food.lastUpdated = new fb.firestore.Timestamp.now() // eslint-disable-line

    this.props.dispatch(editFood(this.props.selectedFood.id, food))
      .then(() => this.success())
      .catch(err => this.error(err))
  }

  success = () => {
    this.props.onUpdateList()
    this.props.onSuccess()
    this.props.onCloseModal()
  }

  error = err => {
    this.setState({
      isLoading: null,
      showError: true,
      errorMessage: err.message
    })
  }

  render () {
    return (
      <>
        <form onSubmit={this.handleSubmit} className='modal_content'>
          <TextField
            label='Food'
            name='name'
            onChange={this.handleChange}
            defaultValue={this.props.selectedFood?.name}
            required
          />

          <input
            type='file'
            id='image'
            name='image'
            onChange={this.handleImage}
          />

          <span className='ui_image_selection'>
            <img
              src={(this.props.selectedFood && this.props.selectedFood.image_url) || this.state.image_url || 'https://via.placeholder.com/200x200.webp?text=Food+Image'}
            />

            <button
              type='button'
              onClick={() => this.setState({ selectImage: !this.state.selectImage })}
            >
            Select Image
            </button>
          </span>

          <ImageGallery
            show={this.state.selectImage}
            onChange={this.handleChange}
            path='images/nutrition/'
          />

          <p className='ui_error'>{this.state.showError && this.state.errorMessage}</p>
        </form>

        <div className='modal_buttons'>
          {
            this.props.onChangePage &&
              <button
                className='button_navigational'
                type='button'
                onClick={this.props.onChangePage}
              >
                <span className='row'>
                  <i className='fas fa-chevron-left' />
            Back
                </span>
              </button>
          }
          <button
            className='button_primary'
            onClick={this.handleSubmit}
            disabled={(!this.props.selectedFood && this.state.name === undefined) || this.state.name === ''}
          >
            {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
          </button>

        </div>
      </>
    )
  }
}

function mapStateToProps (globalState) {
  return {
    activeUserId: globalState.user.auth.uid,
    activeUserName: globalState.user.auth.displayName
  }
}

export default connect(mapStateToProps)(FoodForm)
