import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { prepareRecordGraph } from '../../util'
import { deleteRecord } from '../../actions/exerciseRecords'

import ModalWrapper from '../Common/ModalWrapper'
import LogExerciseForm from './LogExerciseForm'
import Filter from '../Common/Filter'
import LineGraph from './graphs/LineGraph'
import ProfileRecordsList from './ProfileRecordsList'

class ProfileRecords extends Component {
  state = {
    exerciseRecords: [],
    editData: {},
    graphMetric: undefined,
    availableTags: [],
    displayAddRecordModal: false,
    collapse: false
  }

  componentDidMount () {
    this.setState({
      exerciseRecords: this.props.exerciseRecords
    })
  }

  handleSetSort = header => {
    const sortDirection = this.state.activeSort === header ? !this.state.sortDirection : false

    this.setState({
      activeSort: header,
      sortDirection
    }, () => this.handleSortData({
      data: this.state.exerciseRecords,
      tags: [this.state.graphMetric]
    }))
  }

  handleSortData = ({ data, tags }) => {
    // let sorted = []
    // if (this.state.activeSort === 'date') {
    //   sorted = this.dateSort(data)
    // }

    // if (this.state.activeSort === 'exercise') {
    //   sorted = this.exerciseSort(data)
    // }

    // Adjust the available metrics for filtering based on filtered data
    const availableTags = data.reduce((acc, res) => {
      res.exerciseMetrics.forEach(m => {
        const metric = this.props.exerciseMetrics.find(metric => metric.id === m.id)

        if (!acc.includes(metric)) acc.push(metric)
      })

      return acc
    }, [])

    const graphMetric = tags ? this.props.exerciseMetrics.find(metric => metric.id === tags[0]) : undefined

    let records = data

    if (graphMetric === undefined) {
      records = this.handleGroupSets(data)
    }

    this.setState({
      exerciseRecords: records,
      graphMetric,
      availableTags
    })
  }

  handleGroupSets = data => {
    const exerciseRecords = []

    const recordsCopy = _.cloneDeep(data)
    const setRecords = recordsCopy.reduce((acc, res) => {
      if (!res.setKey) {
        exerciseRecords.push(res)
        return acc
      }

      res.exerciseMetrics.forEach(metric => {
        if (metric.value === null) metric.value = []
        else if (typeof metric.value !== 'object') metric.value = [metric.value]
      })

      const key = res.setKey

      if (acc[key]) {
        res.exerciseMetrics.forEach(metric => {
          const setMetric = acc[key].exerciseMetrics.find(m => m.id === metric.id)

          if (setMetric === undefined) {
            acc[key].exerciseMetrics.push(metric)
          } else {
            setMetric.value.splice(res.setIndex, 0, ...metric.value)
          }

          acc[key].recordId.push(res.id)
        })
      } else {
        res.recordId = [res.id]
        acc[key] = res
      }

      return acc
    }, {})

    for (const key in setRecords) {
      setRecords[key].exerciseMetrics.sort((a, b) => a.setIndex - b.setIndex)
    }

    return [...exerciseRecords, ...Object.values(setRecords)].sort((a, b) => b.date.seconds - a.date.seconds)
  }

  handleModal = data => {
    this.setState({
      editData: data || {}
    }, () => this.props.onModal())
  }

  error = err => {
    console.log(err)
  }

  handleDelete = id => {
    this.props.dispatch(deleteRecord(id))
      .then(() => {
        this.setState({
          exerciseRecords: this.state.exerciseRecords.filter(record => record.id !== id)
        })
      })
  }

  render () {
    const graphData = this.state.graphMetric ? prepareRecordGraph(this.state.exerciseRecords, this.state.graphMetric, this.props.exercises) : undefined

    return (
      <div className='user_profile_container'>
        <h1>Exercise Records</h1>
        {
          !this.state.collapse &&
            <>
              <Filter
                type='exercise'
                data={this.props.exerciseRecords}
                categories={this.props.assignedExercises}
                exerciseMetrics={this.props.assignedMetrics}
                availableTags={this.state.availableTags}
                onChange={data => this.handleSortData(data)}
              />

              {
                graphData && graphData.length > 0 && this.state.exerciseRecords.length > 0 &&
                  <div className='user_graph_container'>
                    <LineGraph
                      data={graphData}
                      unit={this.props.assignedMetrics.find(metric => metric.id === this.state.graphMetric.id)}
                      exercises={this.props.exercises}
                    />
                  </div>
              }
            </>
        }

        <ProfileRecordsList
          records={this.state.exerciseRecords}
          exercises={this.props.exercises}
          onModal={this.handleModal}
          onDelete={this.handleDelete}
        />

        <ModalWrapper
          modalHeader={this.state.editData.id ? 'Edit record' : 'Create record'}
          showModal={this.props.activeModal}
          onCloseModal={this.handleModal}
        >
          <LogExerciseForm
            onCloseModal={this.handleModal}
            editData={this.state.editData}
            exercises={this.props.exercises}
            graphMetric={this.state.graphMetric ? this.state.graphMetric.id : undefined}
            exerciseRecords={this.state.exerciseRecords}
            onSortData={this.handleSortData}
            clientId={this.props.clientId}
            globalExerciseMetrics={this.props.exerciseMetrics}
            unitsOfMeasurement={this.props.unitsOfMeasurement}
          />
        </ModalWrapper>

      </div>
    )
  }
}

function mapStateToProps (globalState) {
  const assignedExercises = globalState.exerciseRecords.reduce((acc, res) => {
    const exercise = globalState.exercises.find(e => e.id === res.exerciseId)

    if (!acc.includes(exercise)) acc.push(exercise)

    return acc
  }, [])

  const assignedMetrics = globalState.exerciseRecords.reduce((acc, res) => {
    res.exerciseMetrics.forEach(metric => {
      const m = globalState.exerciseMetrics.find(m => m.id === metric.id)

      if (!acc.includes(m)) acc.push(m)
    })

    return acc
  }, [])

  return {
    exercises: globalState.exercises,
    assignedExercises,
    exerciseMetrics: globalState.exerciseMetrics,
    assignedMetrics,
    exerciseRecords: globalState.exerciseRecords,
    activeUserId: globalState.user.auth.uid,
    activeUserName: globalState.user.auth.displayName,
    activeUserType: globalState.user.info.type,
    unitsOfMeasurement: globalState.units
  }
}

export default connect(mapStateToProps)(ProfileRecords)
