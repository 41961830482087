import React from 'react'

const NutritionDetails = props => {
  const data = props.details

  return (
    <div className='box col'>
      <label htmlFor='targets'>Daily Targets</label>
      <input
        type='text'
        name='daily_targets'
        defaultValue={data.daily_targets}
        onChange={props.onChange}
      />

      <h4>Macronutrients</h4>
      <div className='program_nutrition_details'>

        <label htmlFor='protein_g'>Protein (g)</label>
        <label htmlFor='carbs_g'>Carbohydrates (g)</label>
        <label htmlFor='fats_g'>Fats (g)</label>
        <span />

        <input
          type='text'
          name='protein_g'
          defaultValue={data.protein_g}
          onChange={props.onChange}

        />

        <input
          type='text'
          name='carbs_g'
          defaultValue={data.carbs_g}
          onChange={props.onChange}

        />

        <input
          type='text'
          name='fats_g'
          defaultValue={data.fats_g}
          onChange={props.onChange}

        />

      </div>

      <h4>Hand Portions</h4>
      <div className='program_nutrition_details'>

        <label htmlFor='protein_h'>Protein</label>
        <label htmlFor='carbs_h'>Carbohydrates</label>
        <label htmlFor='vege_h'>Vegetables</label>
        <label htmlFor='fats_h'>Fats</label>

        <input
          type='text'
          name='protein_h'
          defaultValue={data.protein_h}
          onChange={props.onChange}

        />

        <input
          type='text'
          name='carbs_h'
          defaultValue={data.carbs_h}
          onChange={props.onChange}

        />

        <input
          type='text'
          name='vege_h'
          defaultValue={data.vege_h}
          onChange={props.onChange}

        />

        <input
          type='text'
          name='fats_h'
          defaultValue={data.fats_h}
          onChange={props.onChange}

        />

      </div>
    </div>
  )
}

export default NutritionDetails
