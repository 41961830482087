import React, { useState } from 'react'
import { HashRouter as Router, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'

import Login from './Auth/Login'
import Register from './Auth/Register'
import ClientListContainer from './Users/UserListContainer'
import ClientProfile from './Users/UserDashboard'
import ResetPassword from './Auth/ResetPassword'
import PasswordRecovery from './Auth/PasswordRecovery'
import EmailActionHandler from './Auth/EmailActionHandler'
import ProgramListContainer from './Programs/List/ProgramListContainer'
import ProgramContainer from './Programs/Program/ProgramContainer'
import MetricListContainer from './Metrics/MetricListContainer'
import ExerciseListContainer from './Exercises/ExerciseListContainer'
import FoodListContainer from './Food/FoodListContainer'
import LessonListContainer from './Lessons/List/LessonListContainer'
import LessonContainer from './Lessons/Lesson/LessonContainer'
import CollectionListContainer from './Collections/CollectionListContainer'
import CollectionContainer from './Collections/CollectionContainer'
import Drawer from './Nav/Drawer'
import StandaloneContainer from './Users/UserDetails/StandaloneContainer'

import { establishListeners } from '../util'

import '../styles/style.scss'
import ProtectedRoute from './Auth/ProtectedRoute'
import CheckoutRedirect from './Util/CheckoutRedirect'
import CheckoutSuccess from './Util/CheckoutSuccess'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#5c68f2'
    },
    error: {
      main: '#f25c68'
    }
  },
  overrides: {
    MuiFormControl: {
      root: {
        marginBottom: 16
      }
    },
    MuiSelect: {
      selectMenu: {
        whiteSpace: 'none'
      }
    },
    MuiInputBase: {
      root: {
        backgroundColor: 'white'
        // border: '1px solid black'
      }
    },
    MuiFormGroup: {
      root: {
        alignItems: 'flex-start'
      }
    },
    MuiButton: {
      outlined: {
        borderRadius: 500,
        marginTop: '0.5rem',
        marginBottom: '0.5rem'
      },
      contained: {
        borderRadius: 500,
        backgroundColor: '#5c68f2',
        color: 'white',
        fontSize: '1rem',
        padding: '1rem',
        margin: '1rem',
        textTransform: 'none'
      },
      label: {
        whiteSpace: 'pre-line'
      }
    }
  },
  props: {
    MuiTextField: {
      variant: 'outlined'
    }
  }
})

const App = props => {
  window.onload = () => {
    const split = window.location.hash.split('/')

    let activeClient = null

    if (split.includes('clients')) {
      activeClient = split[split.indexOf('clients') + 1]
    }

    establishListeners(props.uid, props.type, activeClient)
  }

  const [showDrawer, toggle] = useState(true)
  const handleToggleDrawer = () => {
    // props.dispatch(toggleDrawer(!showDrawer))
    toggle(!showDrawer)
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ProtectedRoute
          path='/'
          Render={rest => (
            <Drawer
              drawer={showDrawer}
              onToggleDrawer={handleToggleDrawer}
              type={props.type}
              {...rest}
            />
          )}
        />
        <Route
          exact path='/'
          component={Login}
        />
        <Route
          exact path='/login/:userId'
          component={Login}
        />
        <Route
          exact path='/register'
          component={Register}
        />
        <Route
          exact path='/recover'
          component={PasswordRecovery}
        />
        <Route
          path='/profile/:userId'
          component={StandaloneContainer}
        />
        <Route
          exact path='/checkout'
          component={CheckoutRedirect}
        />

        <Route
          exact path='/checkout_success/:userId'
          component={CheckoutSuccess}
        />

        <div className={showDrawer ? 'main-open' : 'main-closed'}>
          <ProtectedRoute
            isAuth={props.isAuth}
            component={ClientListContainer}
            exact path='/clients'
          />
          <ProtectedRoute
            isAuth={props.isAuth}
            exact path='/coaches'
            component={ClientListContainer}
          />
          <ProtectedRoute
            isAuth={props.isAuth}
            exact path='/programs'
            component={ProgramListContainer}
          />
          <ProtectedRoute
            isAuth={props.isAuth}
            exact path='/programs/:programId/:programPage'
            component={ProgramContainer}
          />
          <ProtectedRoute
            isAuth={props.isAuth}
            exact path='/lessons'
            component={LessonListContainer}
          />
          <ProtectedRoute
            isAuth={props.isAuth}
            exact path='/lessons/:lessonId/Lesson'
            component={LessonContainer}
          />
          <ProtectedRoute
            isAuth={props.isAuth}
            exact path='/collections'
            component={CollectionListContainer}
          />
          <ProtectedRoute
            isAuth={props.isAuth}
            exact path='/collections/:collectionId/:collectionPage'
            component={CollectionContainer}
          />
          <ProtectedRoute
            isAuth={props.isAuth}
            exact path='/food'
            component={FoodListContainer}
          />
          <ProtectedRoute
            isAuth={props.isAuth}
            exact path='/metrics'
            component={MetricListContainer}
          />
          <ProtectedRoute
            isAuth={props.isAuth}
            exact path='/exercises'
            component={ExerciseListContainer}
          />
          <ProtectedRoute
            isAuth={props.isAuth}
            path='/clients/:clientId/:profilePage'
            component={ClientProfile}
          />
          <ProtectedRoute
            isAuth={props.isAuth}
            exact path='/coaches/:clientId/:profilePage'
            component={ClientProfile}
          />
        </div>

        <Route
          exact path='/reset-password/:userId'
          component={ResetPassword}
        />
        <Route
          path='/user-management'
          component={EmailActionHandler}
        />
      </Router>
    </ThemeProvider>
  )
}

function mapStateToProps (globalState) {
  const isAuth = globalState.user.auth.uid !== null

  return {
    uid: globalState.user.auth.uid,
    type: globalState.user.info.type,
    isAuth
  }
}

export default connect(mapStateToProps)(App)
