import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProgramListItem from '../Programs/List/ProgramListItem'
import { getSections } from '../../actions/programSections'
import { clientAssignProgram, unassignProgram } from '../../actions/programs'
import ModalWrapper from '../Common/ModalWrapper'
import AssignExistingProgram from './AssignExistingProgram'
import ModalWrapperImage from '../Common/ModalWrapperImage'
import ProgramForm from '../Programs/List/ProgramForm'

class ProfileProgram extends Component {
  state = {
    displayCreateProgramModal: false,
    clientPrograms: []
  }

  componentDidMount () {
    this.setState({
      clientPrograms: this.props.clientPrograms
    })
  }

  handleSelectProgram = id => {
    this.props.dispatch(getSections(id))
      .then(() => {
        this.props.history.push({ pathname: `/programs/${id}/Program` })
      })
  }

  handleUnassign = id => {
    this.props.dispatch(unassignProgram({ clientId: this.props.clientId, programId: id }))
      .then(() => {
        this.setState({
          clientPrograms: this.state.clientPrograms.filter(p => p.id !== id)
        })
      })
  }

  handleAssign = id => {
    const program = this.props.otherPrograms.find(program => program.id === id)

    this.props.dispatch(clientAssignProgram({ clientId: this.props.clientId, programId: id }))
      .then(() => {
        this.setState({
          clientPrograms: [...this.state.clientPrograms, program]
        }, () => this.props.onModal('assignProgramModal'))
      })
  }

  render () {
    return (
      <div className='user_profile_container'>
        <h1>Programs</h1>
        {
          this.state.clientPrograms.map(program => (
            <ProgramListItem
              restricted
              key={program.id}
              {...program}
              clients={this.props.clients}
              creator={this.props.clients.find(user => program.ownerId.includes(user.id))}
              onSelect={() => this.handleSelectProgram(program.id)}
              onUnassign={() => this.handleUnassign(program.id)}
              onEdit={() => this.handleSelectProgram(program.id)}
              activeUserType={this.props.activeUserType}
            />
          ))
        }

        <ModalWrapperImage
          modalHeader='Create Program'
          showModal={this.props.activeModal === 'programModal'}
          history={this.props.history}
        >
          <ProgramForm
            activeUserType={this.props.activeUserType}
            clientId={this.props.client.id}
            history={this.props.history}
          />

        </ModalWrapperImage>

        <ModalWrapper
          showModal={this.props.activeModal === 'assignProgramModal'}
          modalHeader='Assign Program'
          onCloseModal={() => this.props.onModal('assignProgramModal')}
        >
          <AssignExistingProgram
            clientId={this.props.client.id}
            otherPrograms={this.props.otherPrograms}
            onCloseModal={() => this.props.onModal('assignProgramModal')}
            onAssign={this.handleAssign}
          />
        </ModalWrapper>
      </div>
    )
  }
}

function mapStateToProps (globalState, ownProps) {
  const client = globalState.clients.find(client => client.id === ownProps.clientId)

  const clientPrograms = globalState.programs.filter(program => program.users.includes(client.id))
  const otherPrograms = globalState.programs.filter(program => !program.users.includes(client.id))

  return {
    client,
    clientPrograms,
    otherPrograms,
    clients: globalState.clients,
    activeUserType: globalState.user.info.type
  }
}

export default connect(mapStateToProps)(ProfileProgram)
