import React from 'react'
import ResetPassword from './ResetPassword'
import FinaliseAccount from './FinaliseAccount'

class EmailActionHandler extends React.Component {
  state = {
    mode: null
  }

  componentDidMount () {
    const url = this.props.location.search

    const index = url.indexOf('?')
    const sub = url.substring(index + 1)
    const split = sub.split('&')

    const params = {}

    split.forEach(p => {
      const subsplit = p.split('=')
      params[subsplit[0]] = decodeURIComponent(subsplit[1])
    })

    this.setState({
      ...params
    })
  }

  render () {
    return (
      <div>
        {
          this.state.mode === 'welcome' &&
            <FinaliseAccount uid={this.state.oobCode} />
        }
        {
          this.state.mode === 'resetPassword' &&
            <ResetPassword actionCode={this.state.oobCode} />
        }
        {
          this.state.mode === 'verifyEmail' &&
            <h1>Email Verified!</h1>
        }
      </div>
    )
  }
}

export default EmailActionHandler
