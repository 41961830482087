import { storage } from '../config/firebase'

export const LOAD_LESSON = 'LOAD_LESSON'
export const CLEAR_LESSON = 'CLEAR_LESSON'

// Load the lesson from cloud storage
export const setActiveLesson = lessonId => {
  return dispatch => {
    return storage.ref('lessons').child(lessonId).getDownloadURL()
      .then(url => {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest() // eslint-disable-line
          xhr.responseType = 'text'
          xhr.onload = event => {
            resolve(JSON.parse(xhr.responseText))
          }
          xhr.open('GET', url)
          xhr.send()
        })
      })
      .then(res => {
        return dispatch({
          type: LOAD_LESSON,
          lesson: res
        })
      })
  }
}

export const loadNewLesson = lesson => {
  return {
    type: LOAD_LESSON,
    lesson
  }
}

export const clearLesson = () => {
  return {
    type: CLEAR_LESSON
  }
}
