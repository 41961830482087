import React, { useState } from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import PlanBox from '../Common/PlanBox'

const RegisterUpgrade = props => {
  const [activeSubscription, setActiveSubscription] = useState()

  const handleSelectPlan = ({ priceId, productId }) => {
    if (productId) {
      setActiveSubscription({
        product: {
          id: productId
        },
        price: {
          id: priceId
        }
      })
    } else {
      setActiveSubscription(null)
    }

    props.handleChange({ target: { name: 'priceId', value: priceId } })
  }

  return (
    <div>
      <Grid container spacing={2}>
        <PlanBox
          name='Freemium'
          description="It's Free!"
          activeSubscription={activeSubscription}
          onSelectPlan={handleSelectPlan}
          sm={6}

        />
        {
          props.plans.map(({ id, product, prices }) => (
            <PlanBox
              key={id}
              productId={id}
              name={product.name}
              description={product.description}
              prices={prices}
              activeSubscription={activeSubscription}
              onSelectPlan={handleSelectPlan}
              sm={6}
            />
          ))
        }
      </Grid>
    </div>
  )
}

function mapStateToProps (globalState) {
  return {
    plans: globalState.stripe
  }
}

export default connect(mapStateToProps)(RegisterUpgrade)
