import React from 'react'
import moment from 'moment'

import KebabMenu from '../../Common/KebabMenu'

const LessonListItem = React.forwardRef((props, ref) => {
  return (
    <div
      className='lesson_list_item box'
      onClick={() => props.onSelect(props.id)}
      ref={ref}
    >
      <img className='image' src={props.image_url} />

      <p className='title'>{props.title}</p>

      <img className='creatorImg' src={props.creatorImg} />

      <p className='creatorName'>{props.creatorName}
      </p>

      <p className='updated'>{props.lastUpdated ? moment(props.lastUpdated.seconds, 'X').fromNow() : 'Never'}</p>

      <span className='overflow'>
        <KebabMenu
          context='edit_delete'
          owner={props.ownerId.includes(props.activeUserId) || props.activeUserType.includes('admin')}
          onSelect={() => props.onSelect(props.id)}
          onEdit={() => props.onEdit(props.id)}
          onDelete={() => props.onDelete(props.id)}
        />
      </span>

      {
        props.loading &&
          <div className='ui_loading_overlay'>
            <span>
              <i className='fas fa-spinner fa-lg fa-spin' />
            </span>
          </div>
      }
    </div>
  )
})

export default LessonListItem
