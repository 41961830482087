import React from 'react'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import * as yup from 'yup'
import { fb } from '../../config/firebase'

import { createCollection, editCollection } from '../../actions/collections'
import { arrayDiffs } from '../../util'

import TextField from '@material-ui/core/TextField'
import CustomCheckbox from '../Common/CustomCheckbox'
import CustomAutocomplete from '../Common/CustomAutocomplete'

const CollectionForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting
  } = props

  return (
    <>
      <form onSubmit={handleSubmit} className='modal_content' style={{ overFlowX: 'hidden' }}>
        <TextField
          name='name'
          label='Collection Name'
          value={values.name || ''}
          onChange={handleChange}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
          required
        />

        {
          props.activeUserType.includes('admin') &&
            <div>
              <CustomCheckbox
                label='Visible'
                name='visible'
                value={values.visible || false}
                onChange={handleChange}
              />
            </div>
        }

        <TextField
          name='desc'
          label='Description'
          value={values.desc || ''}
          onChange={handleChange}
          error={Boolean(errors.desc)}
          helperText={errors.desc}
          multiline
        />

        <CustomAutocomplete
          label='Add Programs'
          name='programs'
          value={values.programs?.map(value => {
            const program = props.programs.find(p => p.id === value)
            return { name: program?.header.title, value }
          })}
          onChange={handleChange}
          options={props.programs.map(program => (
            {
              name: program.header.title,
              value: program.id
            }
          ))}
        />
      </form>

      <div className='modal_buttons'>
        <button
          onClick={handleSubmit}
          className='button_primary'
          type='submit'
        >
          {isSubmitting ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
        </button>
      </div>
    </>
  )
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ activeCollection }) => ({
    name: activeCollection?.name,
    visible: activeCollection?.visible || false,
    desc: activeCollection?.desc || '',
    programs: activeCollection?.programs || []
  }),
  validationSchema: () => {
    return yup.lazy(values => {
      return yup.object().shape(
        Object.assign({
          name: yup.string().required('Collection name is required'),
          visible: yup.boolean(),
          desc: yup.string(),
          programs: yup.array().of(yup.string())
        })
      )
    })
  },
  handleSubmit: ({ ...payload },
    { props, setSubmitting, setErrors }) => {
    const ownerId = props.activeCollection?.ownerId || [props.activeUserId]

    const collection = {
      ...payload,
      ownerId,
      image_url: props.selectedImage,
      index: props.activeCollection?.index || props.index
    }

    if (props.activeCollectionId) {
      const { toRemove, toAdd } = arrayDiffs(props.activeCollection.programs, payload.programs)

      collection.lastUpdated = fb.firestore.Timestamp.now() //eslint-disable-line

      props.dispatch(editCollection({ collectionId: props.activeCollectionId, collection, toRemove, toAdd }))
        .then(() => {
          setSubmitting(false)
          props.onCloseModal && props.onCloseModal()
        })
    } else {
      collection.dateCreated = fb.firestore.Timestamp.now() // eslint-disable-line

      props.dispatch(createCollection(collection))
        .then((res) => {
          props.onCloseModal && props.onCloseModal()
          props.history.push(`/collections/${res.collection.id}/Collection`)
        })
    }
  }
})

function mapStateToProps (globalState) {
  return ({
    programs: globalState.programs,
    index: globalState.collections.length,
    activeUserId: globalState.user.auth.uid,
    activeUserType: globalState.user.info.type
  })
}

export default connect(mapStateToProps)(formikEnhancer(CollectionForm))
