import { db } from '../config/firebase'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'

export const loginUser = (user, claim) => {
  return dispatch => {
    return db.collection('users').doc(user.uid)
      .get()
      .then(doc => {
        const info = doc.data()

        const subscription = new Promise((resolve, reject) => {
          doc.ref.collection('subscriptions')
            .where('status', '==', 'active')
            .onSnapshot(snapshot => {
              const data = snapshot.docs[0]?.data()

              if (data) {
                const product = new Promise((resolve, reject) => {
                  data.product.get()
                    .then(res => resolve({ id: res.id, ...res.data() }))
                })

                const price = new Promise((resolve, reject) => {
                  data.price.get()
                    .then(res => resolve({ id: res.id, ...res.data() }))
                })

                const promise = Promise.all([product, price])

                return promise.then(([product, price]) => {
                  data.product = product
                  data.price = price
                  resolve(data)
                })
              }

              return resolve()
            })
        })

        return subscription.then(res => {
          info.subscription = res
          info.claim = claim

          delete info.subscription?.prices

          console.log('info: ', info)

          return dispatch({
            type: LOGIN_USER,
            user,
            info
          })
        })
      })
  }
}

export const logoutUser = () => {
  return {
    type: LOGOUT_USER
  }
}

export const createUser = user => {
  return dispatch => {
    return db.collection('users').add({
      ...user
    })
      .then(res => {
        console.log(res)
      })
  }
}
