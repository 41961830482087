import React from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import debounce from 'lodash.debounce'


const Search = ({ data, searchIdx, setFilteredData }) => {
  const debouncedChangeHandler = (...args) => {
    const debounced = debounce(...args)
    return function(e) {
      e.persist()
      return debounced(e)
    }
  }

  const changeHandler = (e) => {
    const search = searchIdx.search(`${e.target.value}*`)

    const filteredData = []
    
    search.forEach(s => {
      const result = data.find(d => d.id === s.ref)
      filteredData.push(result)
    })

    if (e.target.value === "") setFilteredData(data)
    else setFilteredData(filteredData)
  }

  return (
    <div className='search_container'>
      <TextField
        name='search'
        label='Search'
        style={{ marginBottom: 0 }}
        onChange={debouncedChangeHandler(changeHandler, 500 )}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <i className='fas fa-search' />
            </InputAdornment>
          )
        }}
      />

      {/* {
        filterActive &&
          <span className='filter_wrapper'>
            <i className='fas fa-sort' />
            <i className='fas fa-filter' onClick={props.onShowFilter} />
          </span>
      } */}
    </div>
  )
}

export default Search
