import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const CustomSelect = ({
  label,
  name,
  value = [],
  error,
  helperText,
  onChange,
  options = []
}) => (
  <FormControl style={{ width: '100%' }}>
    <Autocomplete
      options={options}
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => option.value === value.value}
      renderInput={params =>
        <TextField
          {...params}
          label={label} variant='outlined'
        />}
      value={value}
      onChange={(e, v) => {
        const val = v.map(v => v.value)
        onChange({ target: { name: name, value: val } })
      }}
      blurOnSelect
      multiple
      filterSelectedOptions
      disableClearable
      limitTags={3}
    />

    <FormHelperText id={label} error={error}>
      {helperText}
    </FormHelperText>
  </FormControl>
)

export default CustomSelect
