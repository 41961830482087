import { db } from '../config/firebase'
import { loadStripe } from '@stripe/stripe-js'

export const GET_PRODUCTS = 'GET_PRODUCTS'

export const getProducts = () => {
  return dispatch => {
    return db.collection('products')
      .where('active', '==', true)
      .get()
      .then(querySnapShot => {
        const products = []

        querySnapShot.forEach(snap => {
          products.push({
            id: snap.id,
            product: snap.data(),
            prices: []
          })

          const prices = new Promise((resolve, reject) => {
            snap.ref.collection('prices').get()
              .then(doc => {
                const p = []
                doc.forEach(doc => {
                  p.push({ ...doc.data(), id: doc.id })
                })

                resolve(p)
              })
          })

          prices.then(res => {
            const index = products.findIndex(i => i.id === snap.id)
            products[index].prices = [...res]
          })
        })

        return dispatch({
          type: GET_PRODUCTS,
          products
        })
      })
  }
}

export const startSubscription = (price, uid, success, cancel) => {
  return dispatch => {
    return db.collection('users')
      .doc(uid)
      .collection('checkout_sessions')
      .add({
        price,
        success_url: success,
        cancel_url: cancel,
        allow_promotion_codes: true
      })
      .then(res => {
        res.onSnapshot(snap => {
          const { error, sessionId } = snap.data()
          if (error) {
            console.log(error.message)
          }

          if (sessionId) {
            const checkout = new Promise((resolve, reject) => {
              const res = loadStripe(process.env.ENVIRONMENT === 'production' ? process.env.STRIPE_PROD_KEY : process.env.STRIPE_DEV_KEY)

              resolve(res)
            })

            checkout.then(stripe => {
              stripe.redirectToCheckout({ sessionId })
            })
          }
        })
      })
  }
}

export const redirectCheckout = (sessionId) => {
  return dispatch => {
    const checkout = new Promise((resolve, reject) => {
      const res = loadStripe(process.env.ENVIRONMENT === 'production' ? process.env.STRIPE_PROD_KEY : process.env.STRIPE_TEST_KEY)

      resolve(res)
    })

    checkout.then(stripe => {
      stripe.redirectToCheckout({ sessionId })
    })
  }
}
