import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'

const ModalCopy = props => {
  const [program, setProgram] = useState(props.programId)
  const [phase, setPhase] = useState()
  const [availablePhases, setAvailablePhases] = useState([])

  useEffect(() => {
    setProgram(props.programId)

    const selectedProgram = props.programs.find(p => p.id === props.programId)

    setAvailablePhases(selectedProgram.phases)
  }, [])

  useEffect(() => {
    if (program === props.programId) setAvailablePhases(props.currentPhases)
  }, [props.currentPhases])

  useEffect(() => {
    if (program === props.programId) setAvailablePhases(props.currentPhases)
    else {
      const selectedProgram = props.programs.find(p => p.id === program)

      if (selectedProgram !== undefined) {
        setAvailablePhases(selectedProgram.phases)
      }
    }
  }, [program])

  const handleSubmit = e => {
    e.preventDefault()

    switch (props.copyType) {
      case 'section': {
        props.onCopySection({ programId: program, phaseId: phase })
        props.onCloseModal()
        break
      }

      case 'phase': {
        props.onCopyPhase({ programId: program })
        break
      }

      default:
        break
    }
  }

  return (
    <Modal
      isOpen={props.showModal}
      onRequestClose={props.onCloseModal}
      className='modal'
      overlayClassName='modal_overlay'
    >
      <div className='modal_wrapper'>
        <span className='modal_header'>
          <h3>Copy item</h3>
          <i className='fas fa-times' onClick={props.onCloseModal} />
        </span>

        <form className='modal_content' onSubmit={handleSubmit}>
          <label htmlFor='program'>Program</label>
          <select
            name='program_copy'
            defaultValue={program}
            onChange={(e) => setProgram(e.target.value)}
          >
            {
              props.programs.map(program => {
                if (program.ownerId.includes(props.activeUserId)) {
                  return (
                    <option key={program.id} value={program.id}>{program.header.title}</option>
                  )
                }
              })
            }
          </select>

          {
            props.copyType !== 'phase' &&
              <>
                <label htmlFor='phase'>Phase</label>
                <select
                  name='phase_copy'
                  defaultValue='placeholder'
                  onChange={(e) => setPhase(e.target.value)}
                >
                  <option value='placeholder' disabled hidden>Select a phase</option>

                  {
                    availablePhases && availablePhases.map(phase => (
                      <option key={phase.id} value={phase.id}>{phase.name}</option>
                    ))
                  }
                </select>
              </>
          }

          <div className='modal_buttons'>
            <button
              onClick={handleSubmit}
              className='button_primary'
              disabled={props.copyType !== 'phase' && phase === undefined && !props.isCopying}
            >
              {props.isCopying ? <i className='fas fa-spinner fa-spin' /> : 'Copy Item'}
            </button>
          </div>

        </form>

      </div>
    </Modal>
  )
}

export default ModalCopy
