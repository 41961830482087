import React, { Component } from 'react'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import prettyMilliseconds from 'pretty-ms'

import ModalWrapper from '../Common/ModalWrapper'
import ModalAssignMetricToClient from './ModalAssignMetricToClient'
import LogMetricForm from './LogMetricForm'
import Filter from '../Common/Filter'
import LineGraph from './graphs/LineGraph'
import PieGraph from './graphs/PieGraph'

import { unassignMetric } from '../../actions/metrics'
import { prepareMetricGraph } from '../../util'
import prettyMillimeters from '../../prettymm'

class ProfileProgress extends Component {
  state = {
    clientLogs: [],
    editLogData: {},
    clientMetrics: [],
    activeMetric: {},
    activeMetricUnit: {},
    activeSort: 'date',
    sortDirection: false,
    displayAddMetricModal: false,
    displayAddLogModal: false
  }

  componentDidMount () {
    this.setState({
      clientLogs: this.props.clientLogs,
      clientMetrics: this.props.clientMetrics
    }, () => {
      if (this.props.clientMetrics.length > 0) {
        this.getUnit(this.props.clientMetrics[0])
      }
    })
  }

  handleSetSort = header => {
    if (this.state.activeSort === header) {
      this.setState({
        sortDirection: !this.state.sortDirection
      }, () => this.handleSortData({ data: this.state.clientLogs }))
    } else {
      this.setState({
        activeSort: header,
        sortDirection: false
      }, () => this.handleSortData({ data: this.state.clientLogs }))
    }
  }

  handleSortData = ({ data, category }) => {
    if (category !== 'all' && category !== undefined) {
      const activeMetric = this.state.clientMetrics.find(metric => metric.id === category)

      this.getUnit(activeMetric)
      data = data.filter(data => data.metricId === category)
    }

    let sorted = []
    if (this.state.activeSort === 'date') sorted = this.dateSort(data)

    this.setState({
      clientLogs: this.dateSort(sorted)
    })
  }

  dateSort = data => {
    if (this.state.sortDirection) {
      return data.sort((a, b) => moment(a.date.seconds, 'X') - moment(b.date.seconds, 'X'))
    } else {
      return data.sort((a, b) => moment(b.date.seconds, 'X') - moment(a.date.seconds, 'X'))
    }
  }

  handleRemoveMetric = () => {
    this.props.dispatch(unassignMetric({ metricId: this.state.activeMetric.id, clientId: this.props.client.id }))
      .then(() => {
        this.setState({
          clientMetrics: this.state.clientMetrics.filter(metric => metric.id !== this.state.activeMetric.id)
        })
        if (this.props.clientMetrics.length > 0) {
          this.getUnit(this.props.clientMetrics[0])
        }
      })
      .catch(err => err)
  }

  getUnit = (activeMetric) => {
    if (activeMetric) {
      const activeMetricUnit = this.props.units.find(unit => unit.id === activeMetric.unitOfMeasurementId)

      this.setState({
        activeMetric,
        activeMetricUnit
      })
    }
  }

  handleMetricModal = metric => {
    if (metric) {
      this.setState({
        clientMetrics: [...this.state.clientMetrics, metric],
        activeMetric: this.state.clientMetrics.length === 0 ? metric : this.state.activeMetric
      })
    }

    this.setState({
      displayAddMetricModal: !this.state.displayAddMetricModal
    })
  }

  handleLogModal = id => {
    if (!this.state.activeMetric.id) return

    const editLogData = this.props.clientLogs.find(log => log.id === id) || {}

    this.getUnit(this.state.activeMetric)

    this.setState({
      editLogData
    }, () => this.props.onModal())
  }

  render () {
    const graphData = prepareMetricGraph(this.state.activeMetric, this.state.activeMetricUnit, this.state.clientLogs)

    return (
      <div className='user_profile_container'>
        <h1>Progress</h1>

        <Filter
          type='metric'
          data={this.props.clientLogs}
          categories={this.state.clientMetrics}
          defaultCategory={this.props.clientMetrics[0]}
          onChange={data => this.handleSortData(data)}
        />

        <button
          type='button'
          onClick={() => this.handleMetricModal()}
          title='Assign new metric'
        >
          <i className='fas fa-plus' />
        </button>

        <button
          type='button'
          onClick={this.handleRemoveMetric}
          title='Unassign selected metric'
        >
          <i className='fas fa-minus' />
        </button>

        {
          this.state.activeMetricUnit.graph_type === 'line' &&
            <div className='user_graph_container'>
              <LineGraph
                data={graphData}
                unit={this.state.activeMetricUnit}
              />
            </div>
        }
        {
          this.state.activeMetricUnit.graph_type === 'pie' &&
            <div className='user_graph_container'>
              <PieGraph
                data={graphData}
              />
            </div>
        }

        <table className='user_metric_table'>
          <thead>
            <tr>
              <th onClick={() => this.handleSetSort('date')}>
            Date
                {
                  this.state.activeSort === 'date' &&
                    <i className={`fas fa-caret-${this.state.sortDirection ? 'up' : 'down'}`} />
                }
              </th>

              {
                this.state.activeMetricUnit.graph_type !== null &&
                  <th>
                    {this.state.activeMetricUnit.unit}
                  </th>
              }

              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.clientLogs.map((log, i) => {
                let value = log.value

                if (this.state.activeMetricUnit.type === 'TIME') {
                  value = prettyMilliseconds(Number(log.value), { verbose: true, unitCount: 2 })
                }

                if (this.state.activeMetricUnit.unit === 'Distance' || this.state.activeMetricUnit.unit === 'Height') {
                  value = prettyMillimeters(Number(log.value), { verbose: true, unitCount: 2 })
                }

                return (
                  <tr key={i} onClick={() => this.handleLogModal(log.id)}>
                    <td>{moment(log.date.seconds, 'X').format('Do MMMM YYYY')}</td>

                    {
                      this.state.activeMetricUnit.graph_type !== null &&
                        <td>{value}</td>
                    }

                    <td>{log.note}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>

        <ModalAssignMetricToClient
          key={uuid()}
          trainer={this.props.trainerUID}
          client={this.props.client}
          showModal={this.state.displayAddMetricModal}
          onCloseModal={props => this.handleMetricModal(props)}
        />

        <ModalWrapper
          modalHeader={this.state.editLogData.id ? 'Edit log' : 'Create log'}
          showModal={this.props.activeModal}
          onCloseModal={this.props.onModal}
        >
          <LogMetricForm
            onCloseModal={this.props.onModal}
            onUpdateData={category => this.handleSortData({ data: this.props.clientLogs, category })}
            clientId={this.props.client.id}
            activeMetric={this.state.activeMetric}
            activeMetricUnit={this.state.activeMetricUnit}
            editData={this.state.editLogData}
          />

        </ModalWrapper>
      </div>
    )
  }
}

function mapStateToProps (globalState, ownProps) {
  const client = globalState.clients.find(client => client.id === ownProps.clientId)

  const clientMetrics = globalState.metrics.filter(metric => metric.users.includes(client.id))

  return {
    units: globalState.units,
    metrics: globalState.metrics,
    clientMetrics: clientMetrics,
    clientLogs: globalState.logs,
    client
  }
}

export default connect(mapStateToProps)(ProfileProgress)
