import {
  RECEIVE_GOALS,
  UPDATE_GOAL,
  CREATE_GOAL,
  CLEAR_GOAL
} from '../actions/goals'

const initialState = []

const goals = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_GOALS:
      return action.goals
    case UPDATE_GOAL: {
      const goalIndex = state.indexOf(state.find(goal => goal.id === action.id))
      state[goalIndex].goal = action.goal

      return state
    }
    case CREATE_GOAL: {
      return [action.goal, ...state]
    }
    case CLEAR_GOAL:
      return []
    default:
      return state
  }
}

export default goals
