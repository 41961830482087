import React, { useState } from 'react'
import AddComponent from './AddComponent'

const Checkbox = props => {
  const [marked, setMark] = useState(false)

  return (
    <>
      <div
        className='lesson_check_component'
        style={marked ? { backgroundColor: 'rgba(234, 68, 57, 0.5)' } : undefined}
      >
        <input
          type='checkbox'
        />
        <input
          type='text'
          name='label'
          onChange={e => props.onChange(e, props.componentId)}
          defaultValue={props.data.label}
        />
      </div>

      <AddComponent
        onMenu={props.onMenu}
        onDelete={props.onDelete}
        onMark={bool => setMark(bool)}
        componentId={props.componentId}
      />
    </>
  )
}

export default Checkbox

export const defaultCheckbox = {
  component: 'checkbox',
  componentId: null,
  data: {
    label: ''
  }
}
