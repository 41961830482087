import React, { Component } from "react";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import lunr from "lunr";

import Search from "../Common/Search";
import TopBar from "../Nav/TopBar";
import ModalWrapperImage from "../Common/ModalWrapperImage";
import Toast from "../Common/Toast";
import CollectionList from "./CollectionList";
import CollectionForm from "./CollectionForm";

import { deleteCollection, editCollection } from "../../actions/collections";
import { onDragEnd } from "../../util";

class CollectionListContainer extends Component {
  state = {
    activeCollection: {},
    isLoading: false,
    displayModalCollection: false,
    showFilter: false,
    collectionList: [],
    showToast: false,
  };

  componentDidMount() {
    this.createSearchIndex()

    this.setState({
      collectionList: this.props.collections,
    });
  }

  createSearchIndex = () => {
    const documents = this.props.collections;

    const searchIdx = lunr(function () {
      this.ref("id");
      this.field("name");
      this.field("desc");

      documents.forEach(function (doc) {
        this.add(doc);
      }, this);
    });

    this.setState({
      searchIdx
    })
  }

  handleModal = (e) => {
    this.setState({
      [e]: !this.state[e],
      activeCollection: undefined,
    });
  };

  handleSelect = (id) => {
    // const collection = this.props.collections.find(collection => collection.id === id)

    this.setState(
      {
        loadingCollection: id,
      },
      () => {
        this.props.history.push(`/collections/${id}/Collection`);
      }
    );
  };

  handleDelete = (id) => {
    this.props
      .dispatch(deleteCollection(id))
      .then(() => this.handleSearch(this.state.searchFilter));
  };

  handleDragEnd = (res) => {
    if (res.destination === null) return;
    if (res.destination.index === res.source.index) return;

    const sorted = onDragEnd(res, this.state.collectionList);

    sorted.forEach((collection, i) => {
      if (
        collection.index < Math.min(res.destination.index, res.source.index) ||
        collection.index > Math.max(res.destination.index, res.source.index)
      )
        return;

      collection.index = i;

      this.props.dispatch(
        editCollection({
          collectionId: collection.id,
          collection: { index: i },
        })
      );
    });

    this.setState({
      collectionList: sorted,
    });
  };

  render() {
    return (
      <div
        className="container_col"
        style={{ overflow: "hidden", height: "100vh" }}
      >
        <TopBar title="Collections" tabs={[]} />

        <Search
          data={this.props.collections}
          searchIdx={this.state.searchIdx}
          setFilteredData={(data) => this.setState({ collectionList: data })}
        />

        <div className="collection_list_item">
          <label className="handle" />
          <label style={{ gridColumn: "2 / span 2" }}>Collection Name</label>
          <label className="number">Number of Programs</label>
          <label className="visible">Visible</label>
          <label className="updated">Date Updated</label>
        </div>

        <div style={{ overflowY: "scroll", marginBottom: 85 }}>
          <CollectionList
            collections={this.state.collectionList}
            programs={this.props.programs}
            onSelect={this.handleSelect}
            onDelete={this.handleDelete}
            onDragEnd={this.handleDragEnd}
            loadingCollection={this.state.loadingCollection}
          />
        </div>

        <div className="ui_bottom_bar">
          <button
            onClick={() => this.handleModal("displayModalCollection")}
            className="button_primary"
          >
            {this.state.isLoading ? (
              <i className="fas fa-spinner fa-spin" />
            ) : (
              "Create Collection"
            )}
          </button>
        </div>

        <ModalWrapperImage
          key={uuid()}
          modalHeader={
            this.state.activeCollection
              ? "Edit collection"
              : "Create collection"
          }
          showModal={this.state.displayModalCollection}
          onCloseModal={() => this.handleModal("displayModalCollection")}
          image={this.state.activeCollection?.image_url} // eslint-disable-line
        >
          <CollectionForm
            activeCollection={this.state.activeCollection}
            history={this.props.history}
            onCloseModal={() => this.handleModal("displayModalCollection")}
            onUpdateList={this.createSearchIndex}
            onSuccess={() => this.setState({ showToast: true })}
          />
        </ModalWrapperImage>

        <Toast
          showToast={this.state.showToast}
          message="Collection Created"
          onClose={() => this.setState({ showToast: false })}
        />
      </div>
    );
  }
}

function mapStateToProps(globalState) {
  return {
    collections: globalState.collections.sort((a, b) => a.index - b.index),
    programs: globalState.programs.sort((a, b) =>
      a.header.title.localeCompare(b.header.title)
    ),
    activeUserType: globalState.user.info.type,
    activeUserId: globalState.user.auth.uid,
    activeUserName: globalState.user.auth.displayName,
  };
}

export default connect(mapStateToProps)(CollectionListContainer);
