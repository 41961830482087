import React from 'react'
import Hero from './Hero'
import Text from './Text'
import Image from './Image'
import ImageTextOverlay from './ImageTextOverlay'
import Video from './Video'
import TextInput from './TextInput'
import Checkbox from './Checkbox'
import Radio from './Radio'

const Components = {
  hero: Hero,
  text: Text,
  image: Image,
  image_text_overlay: ImageTextOverlay,
  video: Video,
  text_input: TextInput,
  checkbox: Checkbox,
  radio: Radio
}

// Receives component from content array.
// Matches the value in block.component with the component in the above object.
// Creates the component and returns it

export default block => {
  const key = block.componentId

  if (typeof Components[block.component] !== 'undefined') {
    return React.createElement(
      Components[block.component],
      { key, ...block }
    )
  }

  return React.createElement(() => (
    <div>This component has not been created yet</div>
  ))
}
