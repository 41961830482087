import React, { useEffect } from 'react'

import Typography from '@material-ui/core/Typography'

import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getClientSubscription } from '../../actions/clients'

const CheckoutSuccess = () => {
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const userId = params.userId

    dispatch(getClientSubscription(userId, 'ADD_SUBSCRIPTION'))

    setTimeout(() => {
      history.replace(`/profile/${userId}`)
    }, 2500)
  }, [])

  return (
    <div className='container_col'>
      <div className='ui_oni_bar'>
        <img src='/img/Logo-Colour-White-small.png' />
      </div>

      <div className='login'>
        <Typography variant='h4'>Purchase Successful!</Typography>
        <Typography variant='h6'>Redirecting</Typography>

        <span style={{ marginTop: 24 }}>
          <i className='fas fa-spinner fa-spin' style={{ fontSize: '2rem' }} />
        </span>
      </div>
    </div>
  )
}

export default CheckoutSuccess
