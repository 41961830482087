import {
  CREATE_FOOD,
  RECEIVE_FOOD,
  EDIT_FOOD,
  DELETE_FOOD,
  ASSIGN_FOOD,
  UNASSIGN_FOOD
} from '../actions/food'

const initialState = []

const food = (state = initialState, action) => {
  const index = state.findIndex(food => food.id === action.foodId)

  switch (action.type) {
    case RECEIVE_FOOD:
      return action.food

    case CREATE_FOOD:
      return [...state, action.food]

    case EDIT_FOOD: {
      state[index] = Object.assign(state[index], action.food)

      return state
    }

    case DELETE_FOOD:
      return state.filter(food => food.id !== action.foodId)

    case ASSIGN_FOOD: {
      state[index].sections = [...state[index].sections, action.sectionId]

      return state
    }

    case UNASSIGN_FOOD : {
      state[index].sections = state[index].sections.filter(sectionId => sectionId !== action.sectionId)

      return state
    }

    default:
      return state
  }
}

export default food
