import {
  RECEIVE_EXERCISE_METRICS
} from '../actions/exerciseMetrics'

const initialState = []

const exerciseMetrics = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_EXERCISE_METRICS:
      return action.exerciseMetrics

    default:
      return state
  }
}

export default exerciseMetrics
