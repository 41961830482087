import { RECEIVE_UNITS } from '../actions/units'

const initialState = []

const units = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_UNITS:
      return action.units
    default:
      return state
  }
}

export default units
