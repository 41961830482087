import { db, fb, storage } from '../config/firebase'

export const RECEIVE_LESSONS = 'RECEIVE_LESSONS'
export const CREATE_LESSON = 'CREATE_LESSON'
export const EDIT_LESSON = 'EDIT_LESSON'
export const LOAD_LESSON = 'LOAD_LESSON'
export const DELETE_LESSON = 'DELETE_LESSON'
export const ASSIGN_LESSON = 'ASSIGN_LESSON'
export const UNASSIGN_LESSON = 'UNASSIGN_LESSON'

export const receiveLessons = () => {
  return dispatch => {
    return db.collection('lessons').get()
      .then(querySnapshot => {
        const lessons = []

        querySnapshot.forEach(snap => {
          lessons.push({ id: snap.id, ...snap.data() })
        })
        return dispatch({
          type: RECEIVE_LESSONS,
          lessons
        })
      })
  }
}

export const createLesson = lesson => {
  return dispatch => {
    return db.collection('lessons').add({
      ...lesson
    })
      .then(docRef => {
        lesson.id = docRef.id
        return dispatch({
          type: CREATE_LESSON,
          lesson
        })
      })
      .catch(err => err)
  }
}

export const editLesson = ({ lessonId, lesson }) => {
  return dispatch => {
    return db.collection('lessons').doc(lessonId).update({
      ...lesson
    })
      .then(() => {
        return dispatch({
          type: EDIT_LESSON,
          lesson,
          lessonId
        })
      })
      .catch(err => err)
  }
}

export const deleteLesson = lessonId => {
  return dispatch => {
    return db.collection('lessons').doc(lessonId).delete()
      .then(() => {
        return storage.ref('lessons').child(lessonId).delete()
      })
      .then(() => {
        return dispatch({
          type: DELETE_LESSON,
          lessonId
        })
      })
  }
}

export const assignLesson = ({ lessonId, users }) => {
  return dispatch => {
    return db.collection('lessons').doc(lessonId).update({
      users: users
    })
      .then(() => {
        return dispatch({
          type: ASSIGN_LESSON,
          lessonId,
          users
        })
      })
      .catch(err => err)
  }
}

export const unassignLesson = ({ lessonId, userId }) => {
  return dispatch => {
    return db.collection('lessons').doc(lessonId).update({
      users: fb.firestore.FieldValue.arrayRemove(userId)
    })
      .then(() => {
        return dispatch({
          type: UNASSIGN_LESSON,
          lessonId,
          userId
        })
      })
      .catch(err => err)
  }
}
