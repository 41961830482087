import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import Distance from './Distance'
import Time from './Time'

const ExerciseMetrics = props => {
  const [activeMetrics, setActiveMetrics] = useState([])
  const [otherMetrics, setOtherMetrics] = useState([])
  const [showSets, toggleShowSets] = useState(true)
  const [setNum, setSetNum] = useState(1)
  const [more, toggleMore] = useState(false)

  useEffect(() => {
    sortMetrics(props.exerciseMetrics)
  }, [props.exerciseMetrics])

  useEffect(() => {
    setSetNum(props.sets.num)
    toggleShowSets(props.sets.display)
  }, [props.sets])

  const sortMetrics = metrics => {
    const other = props.globalExerciseMetrics.filter(metric => metrics.every(m => m.id !== metric.id))

    metrics.sort((a, b) => a.index - b.index)
    other.sort((a, b) => a.index - b.index)

    setActiveMetrics(metrics)
    setOtherMetrics(other)
  }

  const handleAddMetric = (id, name) => {
    const newMetric = {
      id,
      name,
      value: []
    }

    sortMetrics([...activeMetrics, newMetric])
    props.onChange([...activeMetrics, newMetric])
  }

  const handleRemoveMetric = id => {
    const metrics = activeMetrics.filter(metric => metric.id !== id)

    props.onChange(metrics)
    sortMetrics(metrics)
  }

  const handleChangeMetric = (e, i) => {
    const active = [...activeMetrics]
    const index = active.findIndex(m => m.name === e.target.name)

    if (index !== -1) {
      if (typeof active[index].value !== 'object') active[index].value = [e.target.value]
      else active[index].value[i] = e.target.value

      props.onChange(active)
      setActiveMetrics(active)
    }
  }

  const handleChangeSet = e => {
    const sets = Number(e.target.value)
    setSetNum(sets)

    const active = [...activeMetrics]

    active.forEach(a => {
      a.value.length = Math.min(a.value.length, sets)
    })

    props.onChangeSet(sets)
    props.onChange(active)
    setActiveMetrics(active)
  }

  const handleToggleSets = bool => {
    toggleShowSets(bool)
    handleChangeSet({ target: { value: 1 } })

    if (!bool) {
      setSetNum(1)
    }

    if (props.onToggleSet) props.onToggleSet(bool)
  }

  return (
    <div className='content' style={{ paddingBottom: 1 + 'rem', paddingTop: 1 + 'rem' }}>
      <span className='container'>

        <label htmlFor='sets'>Sets</label>
        <span className='boxes'>
          <input
            type='text'
            name='sets'
            onChange={handleChangeSet}
            value={setNum}
            disabled={!showSets || props.disable_sets}
          />
        </span>

        {
          !showSets &&
            <i className='fas fa-plus' onClick={() => handleToggleSets(true)} />
        }

        {
          showSets &&
            <i className='fas fa-trash' onClick={() => handleToggleSets(false)} />
        }
      </span>

      {
        activeMetrics.map(metric => {
          const input = []

          for (let i = 0; i < setNum; i++) {
            if ((metric.name === 'Time' || metric.name === 'Rest') && props.flag_accuracy) {
              input.push(
                <Time
                  key={i}
                  name={metric.name}
                  onChange={e => handleChangeMetric(e, i)}
                  value={setNum > 1 ? metric.value[i] : metric.value}
                />
              )
            } else if ((metric.name === 'Distance' || metric.name === 'Height') && props.flag_accuracy) {
              input.push(
                <Distance
                  key={i}
                  name={metric.name}
                  onChange={e => handleChangeMetric(e, i)}
                  value={setNum > 1 ? metric.value[i] : metric.value}
                />
              )
            } else {
              input.push(
                <input
                  key={i}
                  type='text'
                  name={metric.name}
                  onChange={e => handleChangeMetric(e, i)}
                  defaultValue={setNum > 1 ? metric.value[i] : metric.value}
                />
              )
            }
          }

          return (
            <span
              key={metric.id}
              className='container'
            >
              <label htmlFor={metric.name}>{metric.name}</label>

              <span className='boxes'>
                {input}
              </span>

              <i className='fas fa-trash' onClick={() => handleRemoveMetric(metric.id)} />
            </span>
          )
        })
      }

      <span className={`ui_accordion_header ${more ? 'expanded' : undefined}`} onClick={() => toggleMore(!more)}>
        <i className='fas fa-chevron-right' />
        <p style={{ margin: 0 }}>{`${more ? 'Less' : 'More'} metrics`}</p>
      </span>

      {
        more && otherMetrics.map(metric => {
          let input = null

          if ((metric.name === 'Time' || metric.name === 'Rest') && props.flag_accuracy) {
            input = (
              <Time
                name={metric.name}
                onChange={() => null}
                disabled
              />
            )
          } else if ((metric.name === 'Distance' || metric.name === 'Height') && props.flag_accuracy) {
            input = (
              <Distance
                name={metric.name}
                onChange={() => null}
                disabled
              />
            )
          } else {
            input = (
              <input
                type='text'
                name={metric.name}
                onChange={() => null}
                disabled
              />
            )
          }

          return (
            <span key={metric.id} className='container'>
              <label htmlFor={metric.name}>{metric.name}</label>
              <span className='boxes'>
                {input}
              </span>
              <i className='fas fa-plus' onClick={() => handleAddMetric(metric.id, metric.name)} />
            </span>
          )
        })
      }

    </div>
  )
}

function mapStateToProps (globalState) {
  return {
    globalExerciseMetrics: globalState.exerciseMetrics
  }
}

export default connect(mapStateToProps)(ExerciseMetrics)
