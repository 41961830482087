import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { v4 as uuid } from 'uuid'

import { editSection, createSection, deleteSection, getNumSections } from '../../../actions/programSections'
import { editProgram, getPhases } from '../../../actions/programs'
import { fb } from '../../../config/firebase'

import TopBar from '../../Nav/TopBar'
import PhaseContainer from './PhaseContainer'
import SectionContainer from './SectionContainer'
import SectionListContainer from './SectionListContainer'
// import ProgramDetails from './ProgramDetails'
import ProgramDetails from './ProgramDetails'
import NutritionDetails from './NutritionDetails'
import ModalCopy from './ModalCopy'

class ProgramContainer extends Component {
  state = {
    activeTab: 'Program',
    phases: [],
    sections: [],
    // lessons: [],
    activePhaseId: null,
    activeSections: [],
    sectionsToSave: [],
    sectionsToDelete: [],
    details: {},
    isLoading: null,
    isEditing: false,
    displayCopyModal: false,
    copyType: null,
    copyId: null,
    isCopying: false,
    showScrollButtons: true
  }

  componentDidMount () {
    this.setState({
      activeTab: this.props.match.params.programPage,
      phases: this.props.phases,
      activePhaseId: this.props.phases && this.props.phases[0].id,
      sections: this.props.sections,
      activeSections: this.props.activeSections,
      details: this.props.details,
      lessons: this.props.lessons
    })

    document.addEventListener('touchstart', this.handleTouchPad)
  }

  componentWillUnmount () {
    document.removeEventListener('touchstart', this.handleTouchPad)
  }

  handleTouchPad = e => {
    this.setState({
      showScrollButtons: false
    })

    if (e.pageX > 10 && e.pageX < window.innerWidth - 1) return

    e.preventDefault()
  }

  handleScroll = direction => {
    const div = document.getElementsByClassName('program_sections_container')[0]
    const width = div.offsetWidth

    div.scrollBy({
      left: direction === 'left' ? -(width / 3) : width / 3,
      behavior: 'smooth'
    })
  }

  handleChangeTab = e => {
    this.setState({
      activeTab: e
    }, () => this.props.history.replace(`/programs/${this.props.match.params.programId}/${e}`))
  }

  handleChangeDetails = e => {
    const details = { ...this.state.details }

    details[e.target.name] = e.target.value

    this.setState({
      details,
      isEditing: true
    })
  }

  handleSelectPhase = id => {
    const sections = this.state.sections.filter(section => section.phaseId === id)

    sections.sort((a, b) => a.index - b.index)

    this.setState({
      activePhaseId: id,
      activeSections: sections
    })
  }

  handleChangePhase = phase => {
    const phases = [...this.state.phases]

    const index = phases.findIndex(p => p.id === phase.id)

    if (index === -1) {
      phases.push(phase)
    } else {
      phases[index] = phase
    }

    this.setState({
      phases,
      isEditing: true
    })
  }

  handleDeletePhase = phaseId => {
    if (this.state.phases.length === 1) return

    let index = 0
    const phases = [...this.state.phases].filter(p => {
      if (p.id === phaseId) index = p.index
      else return true
    })

    // Get reference to phase sections and mark for deletion
    const deleteSections = [...this.state.sections].reduce((acc, res) => {
      if (res.phaseId === phaseId) acc.push(res.id)

      return acc
    }, [])

    // Update indexes of other phases
    phases.forEach(phase => {
      if (phase.index > index) phase.index -= 1
    })

    this.setState({
      phases,
      sectionsToDelete: [...this.state.sectionsToDelete, ...deleteSections],
      isEditing: true
    }, () => this.handleSelectPhase(this.state.phases[0].id))
  }

  handleChangeSection = section => {
    const sections = [...this.state.sections]
    const sectionsToSave = [...this.state.sectionsToSave]

    const index = sections.findIndex(s => s.id === section.id)
    if (index === -1) {
      sections.push(section)
    } else {
      sections[index] = section
    }

    if (!sectionsToSave.includes(section.id)) sectionsToSave.push(section.id)

    this.setState({
      sections,
      sectionsToSave,
      isEditing: true
    }, () => this.handleSelectPhase(this.state.activePhaseId))
  }

  handleDeleteSection = sectionId => {
    let phaseId = ''
    let index = 0

    const sectionsToSave = [...this.state.sectionsToSave].filter(id => id !== sectionId)

    const sections = [...this.state.sections].filter(section => {
      if (section.id === sectionId) {
        phaseId = section.phaseId
        index = section.index
      } else return true
    })

    // Update indexes of other sections
    sections.forEach(section => {
      if (section.phaseId === phaseId && section.index > index) {
        section.index -= 1

        if (!sectionsToSave.includes(section.id)) sectionsToSave.push(section.id)
      }
    })

    this.setState({
      sections,
      sectionsToSave,
      sectionsToDelete: [...this.state.sectionsToDelete, sectionId],
      isEditing: true
    }, () => this.handleSelectPhase(this.state.activePhaseId))
  }

  handleCopySection = ({ programId, phaseId, sectionId }) => {
    const sectionToCopy = this.state.sections.find(section => section.id === (sectionId || this.state.copyId))

    const newSection = JSON.parse(JSON.stringify(sectionToCopy))

    newSection.phaseId = phaseId
    newSection.programId = programId
    newSection.id = uuid()
    newSection.dateCreated = new fb.firestore.Timestamp.now() // eslint-disable-line

    // Save to db if different program, else update local state
    if (programId !== this.props.id) {
      this.props.dispatch(getNumSections(phaseId))
        .then(index => {
          newSection.index = index
          delete newSection.id

          this.props.dispatch(createSection(newSection))
        })
    } else {
      const items = this.state.sections.filter(section => section.phaseId === phaseId)

      newSection.index = items.length
      newSection.id = uuid()

      this.setState({
        isCopying: this.state.copyType !== 'section',
        isEditing: true,
        sections: [...this.state.sections, newSection],
        sectionsToSave: [...this.state.sectionsToSave, newSection.id]
      }, () => this.handleSelectPhase(this.state.activePhaseId))
    }
  }

  handleCopyPhase = ({ programId }) => {
    const phaseToCopy = this.state.phases.find(phase => phase.id === this.state.copyId)

    const newPhase = JSON.parse(JSON.stringify(phaseToCopy))

    newPhase.id = uuid()
    newPhase.name = `${newPhase.name} (copy)`

    if (programId !== this.props.id) {
      this.props.dispatch(getPhases(programId))
        .then(phases => {
          newPhase.index = phases.length

          this.props.dispatch(editProgram({
            programId,
            program: {
              phases: [...phases, newPhase]
            }
          }))
        })
    } else {
      newPhase.index = this.state.phases.length

      this.setState({
        isEditing: true,
        phases: [...this.state.phases, newPhase]
      })
    }

    const sectionsToCopy = this.state.sections.filter(section => section.phaseId === this.state.copyId)
    sectionsToCopy.sort((a, b) => a.index - b.index)

    let promise = Promise.resolve()

    sectionsToCopy.forEach((section, i) => {
      promise = promise.then(() => {
        this.handleCopySection({ programId: programId, phaseId: newPhase.id, sectionId: section.id })

        return new Promise(resolve => {
          setTimeout(resolve, 1000)
        })
          .then(() => {
            if (i === sectionsToCopy.length - 1) {
              this.setState({
                displayCopyModal: false,
                isCopying: false
              })
            }
          })
      })
    })
  }

  handleChangeLessons = lessons => {
    this.setState({
      isEditing: true,
      lessons
    })
  }

  handleSave = () => {
    this.setState({
      isLoading: 'submit'
    })

    if (this.state.phases) {
      this.props.dispatch(editProgram({
        programId: this.props.id,
        program: {
          lastUpdated: new fb.firestore.Timestamp.now(), // eslint-disable-line
          phases: this.state.phases
        }
      }))
    }

    if (this.state.details) {
      this.props.dispatch(editProgram({
        programId: this.props.id,
        program: {
          details: this.state.details
        }
      }))
    }

    if (this.state.lessons) {
      this.props.dispatch(editProgram({
        programId: this.props.id,
        program: {
          lessons: this.state.lessons
        }
      }))
    }

    // Handle deleted sections
    const deleteRequests = this.state.sectionsToDelete.map(sectionId => {
      return this.props.dispatch(deleteSection(sectionId))
    })

    Promise.all(deleteRequests)
      .then(() => {
        // Save section changes
        const sections = [...this.state.sections]

        const saveRequests = this.state.sectionsToSave.map(sectionId => {
          const section = { ...sections.find(s => s.id === sectionId) }

          delete section.id
          // delete section.dateCreated

          if (this.props.sections.some(s => s.id === sectionId)) {
            return this.props.dispatch(editSection({ sectionId, section: section }))
          } else {
            return this.props.dispatch(createSection(section))
          }
        })

        Promise.all(saveRequests)
          .then(() => {
            this.setState({
              sections: this.props.sections,
              sectionsToSave: [],
              isEditing: false,
              isLoading: false
            }, () => this.handleSelectPhase(this.state.activePhaseId))
          })
      })
  }

  render () {
    return (
      <div className='container_col'>
        <TopBar
          title={this.props.header.title}
          subtitle={this.props.header.program_type}
          img_url={this.props.header.image_url}
          tabs={['Program', 'Details']}
          onChangeTab={this.handleChangeTab}
        />

        {
          this.state.activeTab === 'Program' && this.props.header.program_type !== 'Lesson' &&
            <>
              <button
                className='button_scroll_y left'
                style={{ left: `calc(${this.props.drawer ? '250px' : '50px'} - 25px - 1rem)` }}
                onClick={() => this.handleScroll('left')}
              >
                <i className='fas fa-lg fa-chevron-left' />
              </button>

              <button
                className='button_scroll_y right'
                onClick={() => this.handleScroll('right')}
              >
                <i className='fas fa-lg fa-chevron-right' />
              </button>
            </>
        }

        <SwitchTransition>
          <CSSTransition
            key={this.state.activeTab}
            addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
            classNames='slide'
          >
            <>
              {
                this.state.activeTab === 'Program' &&
                  <span>
                    {
                      this.props.header.program_type === 'Nutrition' &&
                        <NutritionDetails
                          details={this.props.details}
                          onChange={this.handleChangeDetails}
                        />
                    }

                    {
                      ['Exercise', 'Nutrition'].includes(this.props.header.program_type) &&
                        <>
                          <PhaseContainer
                            programId={this.props.id}
                            phases={this.state.phases}
                            activePhaseId={this.state.activePhaseId}
                            onSelectPhase={this.handleSelectPhase}
                            onChangePhase={this.handleChangePhase}
                            onDeletePhase={this.handleDeletePhase}
                            displayCopyModal={({ type, id }) => this.setState({
                              displayCopyModal: true,
                              copyType: type,
                              copyId: id
                            })}
                          />

                          <SectionContainer
                            programId={this.props.id}
                            programType={this.props.header.program_type}
                            sections={this.state.activeSections}
                            activePhaseId={this.state.activePhaseId}
                            onChangeSection={this.handleChangeSection}
                            onDeleteSection={this.handleDeleteSection}
                            drawer={this.props.drawer}
                            displayCopyModal={({ type, id }) => this.setState({
                              displayCopyModal: true,
                              copyType: type,
                              copyId: id
                            })}
                          />
                        </>
                    }

                    {
                      this.props.header.program_type === 'Lesson' &&
                        <SectionListContainer
                          programId={this.props.id}
                          programType={this.props.header.program_type}
                          drawer={this.props.drawer}
                          lessons={this.props.lessons}
                          onChangeLessons={this.handleChangeLessons}
                        />
                    }

                    <button
                      className='button_float_save'
                      onClick={this.handleSave}
                      disabled={!this.state.isEditing}
                    >
                      {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : <i className='fas fa-lg fa-check' />}
                    </button>
                  </span>
              }

              {
                this.state.activeTab === 'Details' &&
                  <ProgramDetails
                    activeProgram={this.props.activeProgram}
                    activeProgramId={this.props.id}
                    // clientId={this.props.clientId}
                    history={this.props.history}
                    selectedImage={this.props.header.image_url}
                  />
              }
            </>
          </CSSTransition>
        </SwitchTransition>

        <ModalCopy
          showModal={this.state.displayCopyModal}
          onCloseModal={() => this.setState({ displayCopyModal: false })}
          activeUserId={this.props.activeUserId}
          programId={this.props.id}
          currentPhases={this.state.phases}
          programs={this.props.programs}
          isCopying={this.state.isCopying}
          copyType={this.state.copyType}
          onCopySection={this.handleCopySection}
          onCopyPhase={this.handleCopyPhase}
        />
      </div>
    )
  }
}

function mapStateToProps (globalState, ownProps) {
  const program = globalState.programs.find(program => program.id === ownProps.match.params.programId)

  const sections = globalState.programSections
  const activeSections = sections.filter(s => s.phaseId === program.phases[0].id)

  return {
    activeProgram: program,
    ...program,
    programs: globalState.programs,
    sections,
    activeSections,
    drawer: globalState.ui.drawer,
    activeUserId: globalState.user.auth.uid
  }
}

export default connect(mapStateToProps)(ProgramContainer)
