import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

const TopBar = (props) => {
  const [tabs, setTabs] = useState([])
  const [activeSubTab, setActiveSubTab] = useState(null)
  const [activeTab, setActiveTab] = useState('')
  const location = useLocation().pathname.substring(1).split('/')
  const history = useHistory()

  useEffect(() => {
    setTabs(props.tabs)
    setActiveTab(location[2])
  }, [])

  const handleSetTab = tab => {
    if (props.subTabs && props.subTabs[tab]) {
      setActiveSubTab(tab)
      setTabs(props.subTabs[tab])
      setActiveTab(props.subTabs[tab][0])

      props.onChangeTab(props.subTabs[tab][0])
    } else {
      setActiveTab(tab)
      props.onChangeTab(tab)
    }
  }

  const handleBack = () => {
    setTabs(props.tabs)
    setActiveTab(activeSubTab)
    setActiveSubTab(null)
  }

  return (
    <div className='ui_top_bar'>
      <div className='row'>
        <i
          className='fas fa-chevron-left'
          onClick={() => history.goBack()}
          style={{ visibility: location[1] ? 'visible' : 'hidden' }}
        />

        {
          props.img_url &&
            <img src={props.img_url} />
        }

        <span>
          <h2>{props.title}</h2>
          <p style={{ fontSize: 15 + 'px' }}>{props.subtitle}</p>
        </span>
      </div>

      <SwitchTransition>
        <CSSTransition
          key={activeSubTab}
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          classNames='slide'
        >
          <div className='tabs'>
            <i
              className='fas fa-chevron-left'
              style={{ visibility: activeSubTab ? 'visible' : 'hidden', fontSize: 'small' }}
              onClick={handleBack}
            />

            {
              activeSubTab &&
                <>
                  <p className='active'>{`${activeSubTab}:`}</p>
                </>
            }
            {
              tabs.map((tab, i) => (
                <p
                  key={i}
                  className={activeTab === tab ? 'active' : undefined}
                  onClick={() => handleSetTab(tab)}
                >
                  {tab}
                </p>
              ))
            }
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}

export default TopBar
