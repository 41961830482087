import React from 'react'
import ExerciseMetrics from '../../Util/ExerciseMetrics'

class EditExercise extends React.Component {
  state = {
    sets: { num: 1, display: true },
    metrics: [],
    notes: this.props.item?.notes || ''
  }

  componentDidMount () {
    this.setState({
      ...this.props.item
    })
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleChangeSets = num => {
    if (typeof num === 'number') {
      const sets = { ...this.state.sets }

      sets.num = num
      this.setState({
        sets
      })
    }
  }

  handleToggleSets = bool => {
    const sets = { ...this.state.sets }
    sets.display = bool
    if (!bool) sets.num = 1

    this.setState({
      sets
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const item = { ...this.state }

    this.props.onChange({ itemKey: this.props.item.key, item })
    this.props.onCloseModal()
  }

  render () {
    let movement = ''
    let muscle = ''

    this.props.itemDetails.tag_movement.forEach((tag, i) => {
      movement = movement + tag
      if (i !== this.props.itemDetails.tag_movement.length - 1) movement = movement + ' | '
    })

    this.props.itemDetails.tag_muscle.forEach((tag, i) => {
      muscle = muscle + tag
      if (i !== this.props.itemDetails.tag_muscle.length - 1) muscle = muscle + ' | '
    })

    return (
      <>
        <div className='modal_content' style={{ overflowY: 'scroll' }}>
          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Movement Type</p>
            <p>{movement}</p>
          </span>

          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Muscles Worked</p>
            <p>{muscle}</p>
          </span>

          <ExerciseMetrics
            sets={this.state.sets}
            exerciseMetrics={this.state.metrics}
            onChange={metrics => this.setState({ metrics })}
            onChangeSet={this.handleChangeSets}
            onToggleSet={this.handleToggleSets}
            flag_accuracy={false}
          />

          <label htmlFor='notes'>Additional Notes</label>
          <textarea
            name='notes'
            rows='5'
            defaultValue={this.state.notes}
            onChange={this.handleChange}
          />

        </div>
        <div className='modal_buttons'>
          <button
            className='button_primary'
            onClick={this.handleSubmit}
          >
          Submit
          </button>
        </div>
      </>

    )
  }
}

export default EditExercise
