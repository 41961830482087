
import React, { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'

import Page from './Page'

const PageContainer = props => {
  const [lesson, updateLesson] = useState({ pages: [] })

  const [drawer, toggleDrawer] = useState(props.drawer)

  useEffect(() => {
    if (props.lesson.id) updateLesson(props.lesson)
  }, [props.lesson])

  useEffect(() => {
    toggleDrawer(props.drawer)
  }, [props.drawer])

  const handleCreatePage = () => {
    const l = { ...lesson }
    const newPage = {
      pageId: uuid(),
      content: [
        {
          component: 'image_text_overlay',
          componentId: uuid(),
          data: {
            imageURL: '',
            text: 'Add text'
          }
        }
      ]
    }

    l.pages.push(newPage)
    props.onChangeLesson(l)
  }

  const handleAddComponent = ({ pageId, component, below }) => {
    const page = lesson.pages.find(page => page.pageId === pageId)

    if (below) {
      const index = page.content.findIndex(component => component.componentId === below)

      page.content.splice(index + 1, 0, component)
    } else {
      page.content.splice(0, 0, component)
    }

    props.onChangeLesson(lesson)
  }

  const handleEditComponent = ({ pageId, componentId, updatedComponent }) => {
    const page = lesson.pages.find(page => page.pageId === pageId)

    const componentIndex = page.content.findIndex(c => c.componentId === componentId)

    page.content[componentIndex] = updatedComponent

    props.onChangeLesson(lesson)
  }

  const handleDeleteComponent = ({ pageId, componentId }) => {
    const page = lesson.pages.find(page => page.pageId === pageId)

    if (page.content.length === 1) {
      const index = lesson.pages.findIndex(p => p.id === pageId)

      lesson.pages.splice(index, 1)

      props.onChangeLesson(lesson)
    } else {
      const componentIndex = page.content.findIndex(c => c.componentId === componentId)

      page.content.splice(componentIndex, 1)

      props.onChangeLesson(lesson)
    }
  }

  return (
    <div
      className='program_sections_container'
      style={{ width: `calc(100vw - ${drawer ? '250px' : '50px'})` }}
    >
      {
        lesson.pages.map(page => (
          <Page
            key={page.pageId}
            {...page}
            drawer={props.drawer}
            onAddComponent={handleAddComponent}
            onEditComponent={handleEditComponent}
            onDeleteComponent={handleDeleteComponent}
          />
        ))
      }

      <div style={{ width: 33 + 'vw', display: 'flex', justifyContent: 'center' }}>
        <button
          className='button_primary'
          onClick={handleCreatePage}
        >
          Add Page
        </button>
      </div>
    </div>
  )
}

export default PageContainer
