import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import ModalAddItem from './ModalAddItem'
import LessonItem from './LessonItem'
import { setActiveLesson } from '../../../actions/activeLesson'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

const SectionListContainer = props => {
  const [activeModal, setActiveModal] = useState(null)
  const [lessons, setLessons] = useState([])

  const history = useHistory()

  useEffect(() => {
    setLessons(props.lessons)
  }, [props.lessons])

  const handleAssignItem = ({ items }) => {
    const list = [...lessons]

    list.push(...items)
    setLessons(list)
    props.onChangeLessons(list)
  }

  const handleUnassignItem = id => {
    const list = [...lessons].filter(lessonId => lessonId !== id)

    setLessons(list)
    props.onChangeLessons(list)
  }

  const handleSelect = id => {
    props.dispatch(setActiveLesson(id))
      .then(() => {
        history.push({ pathname: `lessons/${id}/Lesson` })
      })
  }

  const handleDragEnd = ({ source, destination, draggableId }) => {
    if (destination === null) {
      return
    }

    if (destination.index === source.index) {
      return
    }

    const list = [...lessons]

    list.splice(source.index, 1)
    list.splice(destination.index, 0, draggableId)

    setLessons(list)
    props.onChangeLessons(list)
  }

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={props.programId}>
          {provided => (
            <div
              style={{ width: 100 + '%' }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {
                lessons.map((id, i) => {
                  const lesson = props.globalLessons.find(lesson => lesson.id === id)

                  return (
                    <LessonItem
                      key={id}
                      index={i}
                      {...lesson}
                      onSelect={() => handleSelect(id)}
                      onDelete={() => handleUnassignItem(id)}
                    />
                  )
                })
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div style={{ textAlign: 'center' }}>
        <button
          onClick={() => setActiveModal('addItem')}
          className='button_primary'
        >
        Add Lesson
        </button>

        <ModalAddItem
          key={uuid()}
          programType='Lesson'
          showModal={activeModal === 'addItem'}
          onCloseModal={() => setActiveModal(null)}
          onAssignItem={handleAssignItem}
          assignedLessons={props.lessons}
        />
      </div>
    </>
  )
}

function mapStateToProps (globalState) {
  return {
    globalLessons: globalState.lessons
  }
}

export default connect(mapStateToProps)(SectionListContainer)
