const mmToDistance = (distance) => {
  const millimeters = parseInt(distance % 10)
  const centimeters = parseInt((distance / 10) % 100)
  const meters = parseInt((distance / 1000) % 1000)
  const kilometers = parseInt((distance / 1000000))

  return {
    millimeters: millimeters,
    centimeters: centimeters,
    meters: meters,
    kilometers: kilometers
  }
}

const prettyMillimeters = (totalDistanceInMillimeters, options = null) => {
  /* ## Options ##
        -
        unitCount
        Type: number
        Default: Infinity

        Number of units to show. Setting compact to true overrides this option.
        -
        verbose
        Type: boolean
        Default: false

        Use full-length units: 5h 1m 45s → 5 hours 1 minute 45 seconds
    */
  const values = mmToDistance(totalDistanceInMillimeters)

  // Return all values with shorthands units by default

  // Else return number of unitCount

  const units = {
    millimeters: options?.verbose
      ? (values.millimeters === 1 ? ' millimetre' : ' millimetres')
      : 'mm',
    centimeters: options?.verbose
      ? (values.centimeters === 1 ? ' centimetre' : ' centimetres')
      : 'cm',
    meters: options?.verbose
      ? (values.meters === 1 ? ' metre' : ' metres')
      : 'm',
    kilometers: options?.verbose
      ? (values.kilometers === 1 ? ' kilometre' : ' kilometres')
      : 'km'
  }

  const fullMetrics = {
    millimeters: values.millimeters + units.millimeters,
    centimeters: values.centimeters + units.centimeters,
    meters: values.meters + units.meters,
    kilometers: values.kilometers + units.kilometers
  }

  switch (options?.unitCount) {
    case 1:
      if (values.kilometers > 0) {
        return fullMetrics.kilometers
      } else if (values.meters > 0) {
        return fullMetrics.meters
      } else if (values.centimeters > 0) {
        return fullMetrics.centimeters
      } else if (values.millimeters > 0) {
        return fullMetrics.millimeters
      } else {
        return fullMetrics.millimeters
      }
    case 2:
      if (values.kilometers > 0) {
        return fullMetrics.kilometers + ' ' + fullMetrics.meters
      } else if (values.meters > 0) {
        return fullMetrics.meters + ' ' + fullMetrics.centimeters
      } else if (values.centimeters > 0) {
        return fullMetrics.centimeters + ' ' + fullMetrics.millimeters
      } else if (values.millimeters > 0) {
        return fullMetrics.millimeters
      } else {
        return fullMetrics.millimeters
      }
    case 3:
      if (values.kilometers > 0) {
        return fullMetrics.kilometers + ' ' + fullMetrics.meters + ' ' + fullMetrics.centimeters
      } else if (values.meters > 0) {
        return fullMetrics.meters + ' ' + fullMetrics.centimeters + ' ' + fullMetrics.millimeters
      } else if (values.centimeters > 0) {
        return fullMetrics.centimeters + ' ' + fullMetrics.millimeters
      } else if (values.millimeters > 0) {
        return fullMetrics.millimeters
      } else {
        return fullMetrics.millimeters
      }
    case 4:
    default:
      if (values.kilometers > 0) {
        return fullMetrics.kilometers + ' ' + fullMetrics.meters + ' ' + fullMetrics.centimeters + ' ' + fullMetrics.millimeters
      } else if (values.meters > 0) {
        return fullMetrics.meters + ' ' + fullMetrics.centimeters + ' ' + fullMetrics.millimeters
      } else if (values.centimeters > 0) {
        return fullMetrics.centimeters + ' ' + fullMetrics.millimeters
      } else if (values.millimeters > 0) {
        return fullMetrics.millimeters
      } else {
        return fullMetrics.millimeters
      }
  }
}

export default prettyMillimeters
