import {
  RECEIVE_METRICS,
  CREATE_METRIC,
  EDIT_METRIC,
  DELETE_METRIC,
  GET_CLIENT_METRICS,
  ASSIGN_METRIC,
  UNASSIGN_METRIC
} from '../actions/metrics'

const initialState = []

const metrics = (state = initialState, action) => {
  const index = state.findIndex(metric => metric.id === action.metricId)

  switch (action.type) {
    case RECEIVE_METRICS:
      return action.metrics

    case CREATE_METRIC:
      return [...state, action.metric].sort((a, b) => a.name.localeCompare(b.name))

    case EDIT_METRIC:
      state[index] = Object.assign(state[index], action.metric)

      return state

    case DELETE_METRIC: {
      return state.filter(metric => metric.id !== action.metricId)
    }

    case GET_CLIENT_METRICS: {
      const metrics = [...state]

      action.metrics.forEach(metric => {
        const index = metrics.findIndex(m => m.id === metric.id)

        if (index === -1) {
          metrics.push(metric)
        }
      })

      return metrics.sort((a, b) => a.name.localeCompare(b.name))
    }

    case ASSIGN_METRIC: {
      state[index].users = [action.clientId, ...state[index].users]

      return state
    }

    case UNASSIGN_METRIC:
      state[index].users = state[index].users.filter(user => user !== action.clientId)

      return state

    default:
      return state
  }
}

export default metrics
