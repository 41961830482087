import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { fb } from '../../config/firebase'
import TextField from '@material-ui/core/TextField'

class PasswordRecovery extends Component {
  state = {
    showSuccess: false,
    showError: false,
    errorMessage: '',
    isLoading: null
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      showError: false
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState({
      isLoading: 'submit'
    })

    fb.auth().sendPasswordResetEmail(this.state.email)
      .then(() => {
        this.setState({
          showSuccess: true,
          successMessage: 'Recovery email sent',
          isLoading: null
        })
      })
      .catch(err => this.setState({
        showError: true,
        errorMessage: err.message,
        isLoading: null
      }))
  }

  render () {
    return (
      <div className='container_col'>
        <div className='ui_oni_bar'>
          <img src='/img/Logo-Colour-White-small.png' />
        </div>

        <div className='login'>
          <form onSubmit={this.handleSubmit}>
            <TextField
              name='email'
              label='Email Address'
              type='email'
              onChange={this.handleChange}
              required
            />
            {
              this.state.showError &&
                <p className='ui_error'>{this.state.errorMessage}</p>
            }
            {
              this.state.showSuccess &&
                <p className='ui_success'>{this.state.successMessage}</p>
            }

            <span className='ui_button_bar'>
              <button
                className='button_primary'
                onClick={this.handleSubmit}
                value='Submit'
                disabled={this.state.showSuccess}
              >
                {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
              </button>
            </span>
            <Link to='/' style={{ textAlign: 'center' }}>Back to Login</Link>
          </form>
        </div>
      </div>
    )
  }
}

export default PasswordRecovery
