import React, { useState, useEffect } from 'react'
import moment from 'moment'

const Time = props => {
  const [hours, setHours] = useState('')
  const [minutes, setMinutes] = useState('')
  const [seconds, setSeconds] = useState('')
  const [milliseconds, setMilliseconds] = useState('')

  const handleChange = e => {
    switch (e.target.name) {
      case 'hours':
        setHours(e.target.value)
        break

      case 'minutes':
        setMinutes(e.target.value)
        break

      case 'seconds':
        setSeconds(e.target.value)
        break

      case 'milliseconds':
        setMilliseconds(e.target.value)
        break

      default:
        break
    }
  }

  useEffect(() => {
    let value = props.value
    if (value.length) value = props.value[0]

    if (typeof value === 'number') {
      setHours(moment.duration(props.value).get('hours') || '')
      setMinutes(moment.duration(props.value).get('minutes') || '')
      setSeconds(moment.duration(props.value).get('seconds') || '')
      setMilliseconds(moment.duration(props.value).get('milliseconds') || '')
    }
  }, [props.value])

  useEffect(() => {
    const duration = moment.duration({
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      milliseconds: milliseconds
    }).asMilliseconds()

    if (duration !== 0 && duration !== null) props.onChange({ target: { name: props.name, value: duration } })
  }, [hours, minutes, seconds, milliseconds])

  return (
    <>
      <input
        name='hours'
        placeholder='h'
        value={hours}
        onChange={handleChange}
        disabled={props.disabled}
      />

      <input
        name='minutes'
        placeholder='m'
        value={minutes}
        onChange={handleChange}
        disabled={props.disabled}
      />

      <input
        name='seconds'
        placeholder='s'
        value={seconds}
        onChange={handleChange}
        disabled={props.disabled}
      />

      <input
        name='milliseconds'
        placeholder='ms'
        value={milliseconds}
        onChange={handleChange}
        disabled={props.disabled}
      />
    </>
  )
}

export default Time
