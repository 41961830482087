import {
  LOAD_LESSON,
  CLEAR_LESSON
} from '../actions/activeLesson'

const initialState = {}

const activeLesson = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LESSON:
      return action.lesson

    case CLEAR_LESSON:
      return initialState

    default:
      return state
  }
}

export default activeLesson
