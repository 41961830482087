import React, { Component } from "react";
import { connect } from "react-redux";
import lunr from 'lunr'

import Search from "../../Common/Search";
import TopBar from "../../Nav/TopBar";
import ModalWrapper from "../../Common/ModalWrapper";
import LessonForm from "./LessonForm";
import LessonList from "./LessonList";

import { deleteLesson, assignLesson } from "../../../actions/lessons";
import { setActiveLesson } from "../../../actions/activeLesson";
import AssignForm from "../../Util/AssignForm";

class LessonListContainer extends Component {
  state = {
    lessonList: [],
    activeLesson: {},
    activeModal: null,
    isLoading: null,
    loadingProgram: null,
  };

  componentDidMount() {
    this.createSearchIndex()

    this.setState({
      lessonList: this.props.lessons,
    });
  }

  createSearchIndex = () => {
    const documents = this.props.lessons

    const searchIdx = lunr(function () {
      this.ref("id");
      this.field("title");

      documents.forEach(function (doc) {
        this.add(doc);
      }, this);
    });

    this.setState({
      searchIdx
    })
  }

  handleSearch = (regex) => {
    const lessons = this.props.lessonList.filter((lesson) =>
      lesson.title.match(regex)
    );

    this.setState({
      lessonList: lessons,
    });
  };

  handleSelect = (id) => {
    this.setState(
      {
        loadingProgram: id,
      },
      () => {
        this.props.dispatch(setActiveLesson(id)).then(() => {
          this.props.history.push(`/lessons/${id}/Lesson`);
        });
      }
    );
  };

  handleAssignModal = (id) => {
    const activeLesson = this.props.lessonList.find(
      (lesson) => lesson.id === id
    );

    this.setState({
      activeModal: "assign_lesson",
      activeLesson,
    });
  };

  handleAssign = (users) => {
    return this.props
      .dispatch(assignLesson({ lessonId: this.state.activeLesson.id, users }))
      .then((res) => res);
  };

  handleDelete = (id) => {
    if (
      !window.confirm(
        "Are you sure you want to delete this program? This action cannot be undone"
      )
    )
      return;

    this.setState({
      loadingProgram: id,
    });

    this.props.dispatch(deleteLesson(id)).then(() => {
      const lessonList = this.state.lessonList.filter(
        (lesson) => lesson.id !== id
      );

      this.setState({
        lessonList,
        activeLesson: {},
        loadingProgram: null,
      });
    });
  };

  render() {
    return (
      <div className="container_col">
        <TopBar title="Lessons" tabs={[]} />

        <Search
          data={this.props.lessons}
          searchIdx={this.state.searchIdx}
          setFilteredData={(data) => this.setState({ lessonList: data })}
        />

        <div className="lesson_list_item">
          <label style={{ gridColumn: "1 / span 2" }}>Lesson Name</label>
          <label style={{ gridColumn: "3 / span 2" }}>Created By</label>
          <label className="updated">Date Updated</label>
        </div>

        <LessonList
          lessons={this.state.lessonList}
          clients={this.props.clients}
          activeUserId={this.props.activeUserId}
          activeUserType={this.props.activeUserType}
          onSelect={this.handleSelect}
          onDelete={this.handleDelete}
          loadingProgram={this.state.loadingProgram}
        />

        <div className="ui_bottom_bar">
          <button
            onClick={() => this.setState({ activeModal: "create_lesson" })}
            className="button_primary"
          >
            {this.state.isLoading ? (
              <i className="fas fa-spinner fa-spin" />
            ) : (
              "Create Lesson"
            )}
          </button>
        </div>

        <ModalWrapper
          showModal={this.state.activeModal === "assign_lesson"}
          modalHeader="Assign Lesson"
          onCloseModal={() => this.setState({ activeModal: null })}
        >
          <AssignForm
            active={this.state.activeLesson}
            onAssign={this.handleAssign}
            onCloseModal={() => this.setState({ activeModal: null })}
          />
        </ModalWrapper>

        <ModalWrapper
          showModal={this.state.activeModal === "create_lesson"}
          modalHeader="Create Lesson"
          onCloseModal={() => this.setState({ activeModal: null })}
        >
          <LessonForm
            onCloseModal={() => this.setState({ activeModal: null })}
            history={this.props.history}
          />
        </ModalWrapper>
      </div>
    );
  }
}

function mapStateToProps(globalState) {
  const lessons = globalState.lessons.sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  return {
    lessons,
    clients: globalState.clients,
    activeUserType: globalState.user.info.type,
    activeUserId: globalState.user.auth.uid,
  };
}

export default connect(mapStateToProps)(LessonListContainer);
