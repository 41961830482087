import React, { useState } from 'react'
import { fb } from '../../config/firebase'
import moment from 'moment'
import { Draggable } from 'react-beautiful-dnd'
import KebabMenu from '../Common/KebabMenu'

const Milestone = props => {
  const [edit, setEdit] = useState(false)
  const [update, setUpdate] = useState({
    desc: props.milestone.desc || null,
    note: props.milestone.note || null,
    timeAchieved: props.milestone.timeAchieved || null
  })

  const handleChange = e => {
    let { value, name } = e.target

    if (name === 'timeAchieved') {
      value = new fb.firestore.Timestamp.fromDate(new Date(e.target.value)) // eslint-disable-line
    }

    setUpdate(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = () => {
    setEdit(false)

    props.onEdit({ id: props.milestone.id, update, achieved: props.milestone.achieved })
  }

  return (
    <Draggable draggableId={props.milestone.id} index={props.index}>
      {provided => (
        <div
          className='user_milestone_item'
          ref={provided.innerRef}
          {...provided.draggableProps}
        >

          <i className='fas fa-grip-vertical handle' {...provided.dragHandleProps} />

          {
            !edit &&
              <>
                <span>
                  <input
                    type='checkbox'
                    id='milestone'
                    name='check'
                    defaultChecked={props.milestone.achieved}
                    onChange={e => props.onCheck({ id: props.milestone.id, checked: e.target.checked })}
                  />
                  <label htmlFor='milestone'>{props.milestone.desc}</label>
                </span>

                <p>{props.milestone.note}</p>

                <p>
                  {
                    props.milestone.achieved &&
                  moment(props.milestone.timeAchieved.seconds, 'X').format('Do MMMM YYYY')
                  }
                </p>
              </>
          }

          {
            edit &&
              <>
                <form onSubmit={handleSubmit}>
                  <input
                    type='text'
                    name='desc'
                    defaultValue={props.milestone.desc}
                    onChange={handleChange}
                    style={{ margin: 0 + 'px' }}
                  />
                </form>

                <form onSubmit={handleSubmit}>
                  <input
                    type='text'
                    name='note'
                    defaultValue={props.milestone.note}
                    placeholder='Add Note'
                    onChange={handleChange}
                    style={{ margin: 0 + 'px' }}
                  />
                </form>

                {
                  props.milestone.achieved &&
                    <input
                      type='date'
                      name='timeAchieved'
                      defaultValue={moment(props.milestone.timeAchieved.seconds, 'X').format('YYYY-MM-DD')}
                      onChange={handleChange}
                      style={{ margin: 0 }}
                    />
                }
              </>
          }

          {
            edit &&
              <>
                <span className='accept'>
                  <i className='fas fa-check' onClick={() => handleSubmit('desc')} />
                </span>

                <span className='cancel'>
                  <i className='fas fa-trash' onClick={() => setEdit(false)} />
                </span>
              </>
          }

          <span className='overflow'>
            <KebabMenu
              context='edit_delete'
              onEdit={() => setEdit(true)}
              onDelete={() => props.onDelete({ id: props.milestone.id, achieved: props.milestone.achieved })}
            />
          </span>

        </div>

      )}
    </Draggable>
  )
}

export default Milestone
