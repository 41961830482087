import React from 'react'
import { connect } from 'react-redux'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem
} from '@reach/menu-button'
import '@reach/menu-button/styles.css'

const KebabMenu = props => {
  // little workaround to stop click propagation when menu appears over clickable div
  const doTheThing = (e, thing) => {
    e.stopPropagation()

    thing()
  }

  return (
    <Menu>
      <MenuButton
        className='ui_kebab'
        onClick={e => e.stopPropagation()}
      >
        <MoreVertIcon />
      </MenuButton>
      <MenuList>
        {
          props.context === 'delete' &&
            <MenuItem onClick={e => doTheThing(e, props.onDelete)} onSelect={() => null} style={{ color: 'red' }}>Delete</MenuItem>
        }

        {
          props.context === 'edit_delete' &&
            <>
              <MenuItem onClick={e => doTheThing(e, props.onEdit)} onSelect={() => null}>Edit</MenuItem>
              <MenuItem onClick={e => doTheThing(e, props.onDelete)} onSelect={() => null} style={{ color: 'red' }}>Delete</MenuItem>
            </>
        }

        {
          props.context === 'profileCard' &&
            <>
              <MenuItem onClick={e => doTheThing(e, props.onEdit)} onSelect={() => null}>Profile</MenuItem>
              {
                props.activeUserType.includes('admin') &&
                  <>
                    <MenuItem onClick={e => doTheThing(e, props.onAssignCoach)} onSelect={() => null}>Assign to coach</MenuItem>

                    {
                      props.userType.includes('trainer') &&
                        <MenuItem onClick={e => doTheThing(e, props.onAssignClient)} onSelect={() => null}>Assign a client</MenuItem>
                    }
                  </>
              }
            </>
        }

        {
          props.context === 'lesson' &&
            <>
              <MenuItem onClick={e => doTheThing(e, props.onAssign)} onSelect={() => null}>Assign to client</MenuItem>
              <MenuItem onClick={e => doTheThing(e, props.onEdit)} onSelect={() => null}>Edit</MenuItem>
              <MenuItem style={{ color: 'red' }} onClick={e => doTheThing(e, props.onDelete)} onSelect={() => null}>Delete</MenuItem>
            </>
        }

        {
          props.context === 'user_lesson' &&
            <>
              <MenuItem onClick={e => doTheThing(e, props.onEdit)} onSelect={() => null}>Edit</MenuItem>
              <MenuItem onClick={e => doTheThing(e, props.onUnassign)} onSelect={() => null}>Unassign</MenuItem>
            </>
        }

        {
          props.context === 'program' &&
            <>
              <MenuItem onClick={e => doTheThing(e, props.onAssign)} onSelect={() => null}>Assign to client</MenuItem>
              {
                props.owner &&
                  <>
                    <MenuItem onClick={e => doTheThing(e, props.onEdit)} onSelect={() => null}>Edit</MenuItem>
                    <MenuItem onClick={e => doTheThing(e, props.onShare)} onSelect={() => null}>Share with coach</MenuItem>
                    <MenuItem onClick={e => doTheThing(e, props.onCopy)} onSelect={() => null}>Copy</MenuItem>
                    <MenuItem style={{ color: 'red' }} onClick={e => doTheThing(e, props.onDelete)} onSelect={() => null}>Delete</MenuItem>
                  </>
              }
            </>
        }

        {
          props.context === 'user_program' &&
            <>
              <MenuItem onClick={e => doTheThing(e, props.onEdit)} onSelect={() => null}>Edit</MenuItem>
              <MenuItem onClick={e => doTheThing(e, props.onUnassign)} onSelect={() => null}>Unassign</MenuItem>
            </>
        }

        {
          props.context === 'program_section' &&
            <>
              <MenuItem onSelect={props.onAddDesc}>Add description</MenuItem>
              <MenuItem onSelect={props.onCopy}>Copy section</MenuItem>
              <MenuItem onSelect={props.onDelete} style={{ color: 'red' }}>Delete</MenuItem>
            </>
        }

        {
          props.context === 'phase' &&
            <>
              <MenuItem onSelect={props.onEdit}>Rename</MenuItem>
              <MenuItem onSelect={props.onCopy}>Copy</MenuItem>
              <MenuItem onSelect={props.onDelete} style={{ color: 'red' }}>Delete</MenuItem>
            </>
        }

        {
          props.context === 'section_grouped_item' &&
            <>
              <MenuItem onSelect={props.onEdit}>Edit</MenuItem>
              <MenuItem onSelect={props.onUngroup}>Remove from group</MenuItem>
              <MenuItem onSelect={props.onDelete} style={{ color: 'red' }}>Delete</MenuItem>
            </>
        }

        {
          props.context === 'section_group' &&
            <>
              <MenuItem onSelect={props.onUngroup}>Ungroup</MenuItem>
              <MenuItem onSelect={props.onDelete} style={{ color: 'red' }}>Delete</MenuItem>
            </>
        }

      </MenuList>
    </Menu>
  )
}

function mapStateToProps (globalState) {
  return {
    activeUserType: globalState.user.info.type
  }
}

export default connect(mapStateToProps)(KebabMenu)
