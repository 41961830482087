import { fb, db } from '../config/firebase'
import { alphabetiseNames } from '../util'

export const RECEIVE_CLIENTS = 'RECEIVE_CLIENTS'
export const CREATE_CLIENT = 'CREATE_CLIENT'
export const EDIT_CLIENT = 'EDIT_CLIENT'
export const ASSIGN_CLIENT = 'ASSIGN_CLIENT'
export const UNASSIGN_CLIENT = 'UNASSIGN_CLIENT'
export const ASSIGN_COACH = 'ASSIGN_COACH'
export const DELETE_CLIENT = 'DELETE_CLIENT'
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS'
export const FREE_PRO = 'FREE_PRO'
export const REMOVE_FREE_PRO = 'REMOVE_FREE_PRO'

export const receiveClients = (clients) => {
  return {
    type: RECEIVE_CLIENTS,
    clients
  }
}

export const receiveAllClients = () => {
  return (dispatch) => {
    return db
      .collection('users')
      .get()
      .then((querySnapshot) => {
        const clients = []
        querySnapshot.forEach((snap) => {
          clients.push({ ...snap.data(), id: snap.id })
        })

        return dispatch({
          type: RECEIVE_CLIENTS,
          clients: alphabetiseNames(clients)
        })
      })
  }
}

export const receiveUserClients = (trainerId) => {
  return (dispatch) => {
    return db
      .collection('users')
      .where('trainers', 'array-contains', trainerId)
      .get()
      .then((querySnapshot) => {
        const clients = []
        querySnapshot.forEach((snap) => {
          clients.push({ ...snap.data(), id: snap.id })
        })

        return dispatch({
          type: RECEIVE_CLIENTS,
          clients: alphabetiseNames(clients)
        })
      })
  }
}

export const createClient = ({ id, client }) => {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(id)
      .set({
        ...client
      })
      .then(() => {
        client.id = id
        return dispatch({
          type: CREATE_CLIENT,
          client
        })
      })
      .catch((err) => err)
  }
}

export const editClient = ({ clientId, client }) => {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(clientId)
      .update({
        ...client
      })
      .then(() => {
        return dispatch({
          type: EDIT_CLIENT,
          client,
          clientId
        })
      })
      .catch((err) => err)
  }
}

export const assignCoach = ({ clientId, trainers }) => {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(clientId)
      .update({
        trainers
      })
      .then(() => {
        return dispatch({
          type: ASSIGN_COACH,
          clientId,
          trainers
        })
      })
      .catch((err) => err)
  }
}

export const assignClient = ({ clientId, trainerId }) => {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(clientId)
      .update({
        trainers: fb.firestore.FieldValue.arrayUnion(trainerId)
      })
      .then(() => {
        return dispatch({
          type: ASSIGN_CLIENT,
          clientId,
          trainerId
        })
      })
  }
}

export const unassignClient = ({ clientId, trainerId }) => {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(clientId)
      .update({
        trainers: fb.firestore.FieldValue.arrayRemove(trainerId)
      })
      .then(() => {
        return dispatch({
          type: UNASSIGN_CLIENT,
          clientId,
          trainerId
        })
      })
  }
}

export const deleteClient = (userId) => {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(userId)
      .delete()
      .then(() => {
        return dispatch({
          type: DELETE_CLIENT,
          userId
        })
      })
  }
}

export const getClientSubscription = (clientId, type = GET_SUBSCRIPTIONS) => {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(clientId)
      .collection('subscriptions')
      .where('status', '==', 'active')
      .onSnapshot((snapshot) => {
        if (snapshot.docs.length) {
          const data = snapshot.docs[0].data()

          const product = new Promise((resolve, reject) => {
            return data.product
              .get()
              .then((res) => resolve({ id: res.id, ...res.data() }))
          })

          const price = new Promise((resolve, reject) => {
            return data.price
              .get()
              .then((res) => resolve({ id: res.id, ...res.data() }))
          })

          const promise = Promise.all([product, price])

          return promise.then(([product, price]) => {
            data.product = product
            data.price = price

            return dispatch({
              type: type,
              clientId,
              subscription: data
            })
          })
        }
      })
  }
}

export const giveFreePro = (clientId) => {
  return (dispatch) =>
    db
      .collection('users')
      .doc(clientId)
      .update({
        freePro: true
      })
      .then((res) => {
        return dispatch({
          type: FREE_PRO,
          clientId
        })
      })
}

export const removeFreePro = (clientId) => {
  return (dispatch) =>
    db
      .collection('users')
      .doc(clientId)
      .update({
        freePro: false
      })
      .then((res) => {
        return dispatch({
          type: REMOVE_FREE_PRO,
          clientId
        })
      })
}
