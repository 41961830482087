import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/functions'

import { store } from '../store'
import { firebaseConfig } from './keys'
import { receiveLogs } from '../actions/logs'
import { receiveRecords } from '../actions/exerciseRecords'

firebase.initializeApp(firebaseConfig)

export const fb = firebase
export const db = firebase.firestore()
export const storage = firebase.storage()

export let unsubClientLogListener
export const clientLogListener = clientId => {
  unsubClientLogListener = db.collection('clientMetrics').where('userId', '==', clientId)
    .onSnapshot(querySnapshot => {
      const logs = []
      querySnapshot.forEach(snap => {
        logs.push({ ...snap.data(), id: snap.id })
      })
      store.dispatch(receiveLogs(logs))
    })
}

export let unsubClientRecordListener
export const clientRecordListener = clientId => {
  unsubClientRecordListener = db.collection('exerciseRecords').where('userId', '==', clientId)
    .onSnapshot(querySnapshot => {
      const records = []
      querySnapshot.forEach(snap => {
        records.push({ ...snap.data(), id: snap.id })
      })
      store.dispatch(receiveRecords(records))
    })
}

export function unsubscribe () {
  if (unsubClientLogListener != null) { // reset client log listener
    unsubClientLogListener()
  }
}
