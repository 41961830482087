import React from 'react'
const ComponentMenu = props => {
  let mouseout = null

  const handleMenu = () => {
    mouseout = setTimeout(() => {
      props.onClose()
    }, 500)
  }

  return (
    <div
      className={`lesson_component_menu ${props.visible ? undefined : 'hidden'}`}
      style={{ left: props.left, top: props.top }}
      onMouseLeave={handleMenu}
      onMouseEnter={() => clearTimeout(mouseout)}
    >
      <i
        className='far fa-file-alt'
        title='Text'
        onClick={() => props.onClick('text')}
      />

      <i
        className='far fa-image'
        title='Image'
        onClick={() => props.onClick('image')}
      />

      <i
        className='far fa-file-image'
        title='Image with text modal_overlay'
        onClick={() => props.onClick('image_text_overlay')}
      />

      <i
        className='fab fa-youtube'
        title='Youtube video'
        onClick={() => props.onClick('video')}
      />

      <i
        className='fas fa-font'
        title='Text box'
        onClick={() => props.onClick('text_input')}
      />

      <i
        className='far fa-check-square'
        title='Check box'
        onClick={() => props.onClick('checkbox')}
      />

      <i
        className='far fa-dot-circle'
        title='Multiple choice'
        onClick={() => props.onClick('radio')}
      />
    </div>
  )
}

export default ComponentMenu
