import { db } from '../config/firebase'

export const RECEIVE_MILESTONES = 'RECEIVE_MILESTONES'
export const CREATE_MILESTONE = 'CREATE_MILESTONE'
export const UPDATE_MILESTONE = 'UPDATE_MILESTONE'
export const DELETE_MILESTONE = 'DELETE_MILESTONE'
export const CLEAR_MILESTONES = 'CLEAR_MILESTONES'

export const receiveMilestones = clientId => {
  return dispatch => {
    return db.collection('clientMilestones').where('userId', '==', clientId).get()
      .then(querySnapshot => {
        const milestones = []
        querySnapshot.forEach(snap => {
          const data = snap.data()
          delete data.dateCreated
          milestones.push({ ...data, id: snap.id })
        })

        return dispatch({
          type: RECEIVE_MILESTONES,
          milestones
        })
      })
  }
}

export const createMilestone = (milestone) => {
  return dispatch => {
    delete milestone.id
    return db.collection('clientMilestones').add({
      ...milestone
    })
      .then(docRef => {
        milestone.id = docRef.id
        return dispatch({
          type: CREATE_MILESTONE,
          milestone
        })
      })
  }
}

export const editMilestone = milestone => {
  return dispatch => {
    delete milestone.dateCreated

    return db.collection('clientMilestones').doc(milestone.id).update({
      ...milestone
    })
      .then(() => {
        // delete milestone.id
        return dispatch({
          type: UPDATE_MILESTONE,
          milestone
        })
      })
      .catch(err => err)
  }
}

export const deleteMilestone = milestoneID => {
  return dispatch => {
    return db.collection('clientMilestones').doc(milestoneID).delete()
      .then(() => {
        return dispatch({
          type: DELETE_MILESTONE,
          milestoneID
        })
      })
      .catch(err => err)
  }
}

export const clearMilestones = () => {
  return ({
    type: CLEAR_MILESTONES
  })
}
