import React, { useEffect } from 'react'

const Toast = props => {
  useEffect(() => {
    if (props.showToast) {
      setTimeout(() => {
        props.onClose()
      }, 2500)
    }
  }, [props.showToast])

  return (
    <div className={`ui_toast ${props.showToast ? 'visible' : undefined}`}>
      <p>{props.message}</p>
    </div>
  )
}

export default Toast
