import React, { useState, useRef } from 'react'
import { uploadImage, imageValidation, renameImage } from '../../../util'

import AddComponent from './AddComponent'

const ImageTextOverlay = props => {
  const imageRef = useRef(null)
  const [marked, setMark] = useState(false)

  const handleImageSelect = id => {
    const elem = document.getElementById(id)
    if (elem && document.createEvent) {
      const evt = document.createEvent('MouseEvents')
      evt.initEvent('click', true, false)
      elem.dispatchEvent(evt)
    }
  }

  const handleSelectImage = e => {
    const image = e.target.files[0]

    const valid = imageValidation(image)

    if (typeof valid === 'string') {
      window.alert(valid)
    }

    const renamedImage = renameImage(image, props.componentId)

    uploadImage('lessons', renamedImage)
      .then(url => {
        const change = { target: { name: 'imageURL', value: url } }

        props.onChange(change, props.componentId)
      })
  }

  return (
    <>
      <div className='lesson_image_component'>
        <div className='image_container' onClick={() => handleImageSelect(`file_picker_${props.componentId}`)}>
          {
            props.data.imageURL &&
              <img
                src={props.data.imageURL}
                ref={imageRef}
                style={marked ? { filter: 'sepia(100%)' } : undefined}
              />
          }
        </div>

        <input
          type='text'
          name='text'
          defaultValue={props.data.text}
          onChange={e => props.onChange(e, props.componentId)}
          onFocus={e => e.target.select()}
        />

        <input
          type='file'
          id={`file_picker_${props.componentId}`}
          style={{ display: 'none' }}
          onChange={handleSelectImage}
        />

      </div>

      <AddComponent
        onMenu={props.onMenu}
        onDelete={props.onDelete}
        onMark={bool => setMark(bool)}
        componentId={props.componentId}
      />
    </>
  )
}

export default ImageTextOverlay

export const defaultImageOverlay = {
  component: 'image_text_overlay',
  componentId: null,
  data: {
    imageURL: '',
    text: 'Add text'
  }
}
