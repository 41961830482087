import { db, fb } from '../config/firebase'

export const RECEIVE_FOOD = 'RECEIVE_FOOD'
export const CREATE_FOOD = 'CREATE_FOOD'
export const EDIT_FOOD = 'EDIT_FOOD'
export const DELETE_FOOD = 'DELETE_FOOD'
export const ASSIGN_FOOD = 'ASSIGN_FOOD'
export const UNASSIGN_FOOD = 'UNASSIGN_FOOD'

export const receiveFood = () => {
  return dispatch => {
    return db.collection('food').get()
      .then(querySnapshot => {
        const food = []
        querySnapshot.forEach(snap => {
          food.push({ id: snap.id, ...snap.data(), type: 'Nutrition' })
        })

        return dispatch({
          type: RECEIVE_FOOD,
          food: food.sort((a, b) => a.name.localeCompare(b.name))
        })
      })
      .catch(err => err)
  }
}

export const createFood = food => {
  return dispatch => {
    return db.collection('food').add({
      ...food
    })
      .then(docRef => {
        food.id = docRef.id
        food.type = 'Nutrition'

        return dispatch({
          type: CREATE_FOOD,
          food
        })
      })
      .catch(err => err)
  }
}

export const editFood = (foodId, food) => {
  return dispatch => {
    return db.collection('food').doc(foodId).update({
      ...food
    })
      .then(() => {
        return dispatch({
          type: EDIT_FOOD,
          foodId,
          food
        })
      })
      .catch(err => err)
  }
}

export const addFood = (foodId, sectionId) => {
  return dispatch => {
    return db.collection('food').doc(foodId).update({
      sections: fb.firestore.FieldValue.arrayUnion(sectionId)
    })
      .then(() => {
        return dispatch({
          type: ASSIGN_FOOD,
          foodId,
          sectionId
        })
      })
  }
}

export const removeFood = (foodId, sectionId) => {
  return dispatch => {
    return db.collection('food').doc(foodId).update({
      sections: fb.firestore.FieldValue.arrayRemove(sectionId)
    })
      .then(() => {
        return dispatch({
          type: UNASSIGN_FOOD,
          foodId,
          sectionId
        })
      })
  }
}

export const deleteFood = foodId => {
  return dispatch => {
    return db.collection('food').doc(foodId).delete()
      .then(() => {
        return dispatch({
          type: DELETE_FOOD,
          foodId
        })
      })
  }
}
