import { db } from '../config/firebase'

export const RECEIVE_CLIENT_NOTES = 'RECEIVE_CLIENT_NOTES'
export const CREATE_NOTE = 'CREATE_NOTE'
export const UPDATE_NOTE = 'UPDATE_NOTE'
export const DELETE_NOTE = 'DELETE_NOTE'

export const receiveClientNotes = clientId => {
  return dispatch => {
    return db.collection('clientNotes').where('userId', '==', clientId)
      .get()
      .then(querySnapshot => {
        const notes = []

        querySnapshot.forEach(snap => {
          notes.push({ ...snap.data(), id: snap.id })
        })

        return dispatch({
          type: RECEIVE_CLIENT_NOTES,
          notes
        })
      })
  }
}

export const createNote = note => {
  return dispatch => {
    return db.collection('clientNotes').add({
      ...note
    })
      .then(docRef => {
        note.id = docRef.id

        return dispatch({
          type: CREATE_NOTE,
          note
        })
      })
  }
}

export const updateNote = (note, noteId) => {
  return dispatch => {
    return db.collection('clientNotes').doc(noteId).update({
      ...note
    })
      .then(() => {
        return dispatch({
          type: UPDATE_NOTE,
          note,
          noteId
        })
      })
  }
}

export const deleteNote = noteId => {
  return dispatch => {
    return db.collection('clientNotes').doc(noteId).delete()
      .then(() => {
        return dispatch({
          type: DELETE_NOTE,
          noteId
        })
      })
  }
}
