import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import ProgramItem from './ProgramItem'
import TopBar from '../Nav/TopBar'
import CollectionDetails from './CollectionDetails'
import ModalWrapper from '../Common/ModalWrapper'

import { arrayDiffs, onDragEnd } from '../../util'
import { editCollection } from '../../actions/collections'
import AssignExistingProgram from '../Programs/Program/AssignExistingProgram'

class CollectionContainer extends Component {
  state = {
    programs: [],
    showProgramModal: false
  }

  componentDidMount () {
    this.setState({
      oldPrograms: this.props.programs,
      programs: this.props.programs,
      activeTab: this.props.match.params.collectionPage
    })
  }

  handleDragEnd = res => {
    if (res.destination === null) {
      return
    }

    if (res.destination.index === res.source.index) {
      return
    }

    const sorted = onDragEnd(res, this.state.programs)

    this.setState({
      programs: sorted
    }, () => this.handleSave())
  }

  handleChangeTab = e => {
    this.setState({
      activeTab: e
    }, () => this.props.history.replace(`/collections/${this.props.match.params.collectionId}/${e}`))
  }

  changePrograms = e => {
    this.setState({
      programs: e,
      showProgramModal: false
    }, () => this.handleSave())
  }

  handleDelete = id => {
    this.setState({
      programs: this.state.programs.filter(p => p.id !== id)
    }, () => this.handleSave())
  }

  handleSave = () => {
    const newIds = this.state.programs.map(program => program.id)
    const oldIds = this.state.oldPrograms.map(program => program.id)
    const { toAdd, toRemove } = arrayDiffs(oldIds, newIds)

    this.props.dispatch(editCollection({
      collectionId: this.props.collection.id,
      collection: {
        programs: newIds
      },
      toAdd,
      toRemove
    }))

    this.setState({
      oldPrograms: this.state.programs
    })
  }

  render () {
    return (
      <div className='container_col'>
        <TopBar
          title={this.props.collection.name}
          tabs={['Collection', 'Details']}
          img_url={this.props.collection.image_url}
          onChangeTab={this.handleChangeTab}
        />

        <SwitchTransition>
          <CSSTransition
            key={this.state.activeTab}
            addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
            classNames='slide'
          >
            <>
              {
                this.state.activeTab === 'Collection' &&
                  <span>
                    <DragDropContext onDragEnd={this.handleDragEnd}>
                      <Droppable droppableId='collection'>
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {
                            this.state.programs?.map((program, i) => (
                              <ProgramItem
                                {...program}
                                key={program.id}
                                index={i}
                                onDelete={() => this.handleDelete(program.id)}
                              />
                            ))
                            }
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                    <div style={{ textAlign: 'center' }}>
                      <button
                        onClick={() => this.setState({ showProgramModal: true })}
                        className='button_primary'
                      >
                      Add Program
                      </button>
                    </div>
                  </span>
              }

              {
                this.state.activeTab === 'Details' &&
                  <CollectionDetails
                    activeCollection={this.props.collection}
                    activeCollectionId={this.props.id}
                    history={this.props.history}
                    selectedImage={this.props.collection.image_url}
                  />
              }
            </>
          </CSSTransition>
        </SwitchTransition>

        <ModalWrapper
          showModal={this.state.showProgramModal}
          onCloseModal={() => this.setState({ showProgramModal: false })}
          modalHeader='Add Program'
        >
          <AssignExistingProgram
            addedPrograms={this.state.programs}
            assignPrograms={this.changePrograms}
          />
        </ModalWrapper>

      </div>
    )
  }
}

function mapStateToProps (globalState, ownProps) {
  const id = ownProps.match.params.collectionId

  const collection = globalState.collections.find(collection => collection.id === id)

  const programs = []

  collection.programs.forEach(program => {
    programs.push(globalState.programs.find(p => p.id === program))
  })

  // const programs = globalState.programs.filter(program => collection.programs.includes(program.id))

  // const programs = globalState.programs.filter(program => program.collections?.includes(id))

  return {
    id,
    collection,
    programs
  }
}

export default connect(mapStateToProps)(CollectionContainer)
