import React, { useState } from 'react'
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd'
import { v4 as uuid } from 'uuid'

import TextareaAutosize from '@material-ui/core/TextareaAutosize'

import { onDragEnd, imageValidation } from '../../../util'

import KebabMenu from '../../Common/KebabMenu'
import SectionGroup from './SectionGroup'

const Section = props => {
  const [showDesc, setShowDesc] = useState(props.desc !== undefined && props.desc !== '')
  const [selected, setSelected] = useState([])
  const [selectionType, setSelectionType] = useState('')
  const [showHeader, toggleShowHeader] = useState(null)

  let changeTimeout

  const handleCreateGroup = () => {
    const selection = [...selected]

    // Group smallest groups into largest group
    let newGroupId = null
    let maxLength = 0

    selection.forEach((item, i) => {
      const length = props.groups.find(group => group.id === item.groupId).items.length

      if (length > maxLength) {
        newGroupId = item.groupId
        maxLength = length
      }
    })

    const groups = [...props.groups]

    const newGroupItems = [...groups.find(group => group.id === newGroupId).items]

    // Get array of item keys in new group
    selection.forEach((item, i) => {
      if (!newGroupItems.includes(item.itemKey)) newGroupItems.push(item.itemKey)
    })

    // Remove item key from old group
    groups.forEach(group => {
      group.items = group.items.filter(i => !newGroupItems.includes(i))
    })

    // Add keys to master group
    const index = groups.findIndex(group => group.id === newGroupId)
    groups[index].items = newGroupItems

    // Update index in content array
    const content = [...props.content]
    newGroupItems.forEach((itemKey, i) => {
      const index = content.findIndex(item => item.key === itemKey)

      content[index].index = i
    })

    props.onChange({ target: { name: 'content', value: content } }, props.id)

    cleanGroups(groups)
  }

  const handleRemoveFromGroup = (itemKey, groupId) => {
    const groups = [...props.groups]
    const index = groups.findIndex(g => g.id === groupId)

    itemKey.forEach(key => {
      groups[index].items = groups[index].items.filter(item => item !== key)

      if (groups[index].items.length === 0) groups.splice(index, 1)

      const newGroup = {
        id: uuid(),
        name: null,
        index: groups.length,
        items: [key]
      }

      groups.push(newGroup)
    })

    cleanGroups(groups)
  }

  const cleanGroups = groups => {
    // Remove empty groups
    groups = groups.filter(group => group.items.length !== 0)

    // Renew indexes
    groups.forEach((group, i) => { group.index = i })

    const change = { target: { name: 'groups', value: groups } }

    props.onChange(change, props.id)

    setSelected([])
  }

  // Staggered saves to mitigate input lag
  const handleChange = e => {
    clearTimeout(changeTimeout)

    const { name, value } = e.target

    changeTimeout = setTimeout(() => {
      props.onChange({ target: { name, value } }, props.id)
    }, 500)
  }

  const handleImage = e => {
    const valid = imageValidation(e.target.files[0])

    if (valid !== null) {
      window.alert(valid)
    } else {
      props.onChange({ target: { name: 'imageURL', value: e.target.files[0] } }, props.id)
    }
  }

  const handleDragEnd = res => {
    if (res.destination === null) {
      return
    }

    if (res.destination.index === res.source.index) {
      return
    }

    const sorted = onDragEnd(res, props.groups)

    props.onChange({ target: { name: 'groups', value: sorted } }, props.id)
  }

  const handleSelectItem = group => {
    setSelected(group)
    const { groupId } = group[0]

    if (group.length > 1) setSelectionType('group')

    if (group.length === 1) {
      const group = props.groups.find(g => g.id === groupId)

      setSelectionType(group.items.length === 1 ? 'header' : 'group')
    }
  }

  const handleAddHeader = () => {
    toggleShowHeader(selected[0].groupId)
    setSelected([])
  }

  return (
    <Draggable draggableId={props.id} index={props.index}>
      {provided => (
        <div
          className={props.context === 'program' ? 'box' : undefined}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <span className='row'>
            <i
              className='fas fa-grip-vertical grip'
              style={props.context.includes('diary') ? { visibility: 'hidden' } : undefined}
              {...provided.dragHandleProps}
            />

            <input
              className='program_section_header'
              name='title'
              type='text'
              onChange={handleChange}
              placeholder={`Day ${props.index + 1}`}
              defaultValue={props.title}
            />

            {
              props.context === 'program' &&
                <KebabMenu
                  context='program_section'
                  onAddDesc={() => setShowDesc(true)}
                  onCopy={() => props.displayCopyModal({ type: 'section', id: props.id })}
                  onDelete={() => props.onDelete(props.id)}
                />
            }
          </span>

          {
            (showDesc || props.context.includes('diary')) &&
              <span className='program_section_desc'>
                <TextareaAutosize
                  name='desc'
                  label='Description'
                  defaultValue={props.desc}
                  onChange={handleChange}
                  placeholder='Description'
                />
              </span>
          }

          {
            props.context.includes('diary') &&
              <span className='modal_image_form'>
                {
                  props.imageURL &&
                    <span style={{ textAlign: 'center' }}>
                      <a href={props.imageURL} target='_blank' rel='noopener noreferrer'>View Image</a>
                    </span>
                }

                <span className='col'>
                  <label htmlFor='image'>Attach Image</label>
                  <input
                    type='file'
                    name='image'
                    onChange={handleImage}
                  />
                </span>
              </span>
          }

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={props.id}>
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {
                    props.groups.sort((a, b) => a.index - b.index).map(group => {
                      const items = props.content.filter(item => group.items.includes(item.key))

                      return (
                        <SectionGroup
                          key={group.id}
                          index={group.index}
                          group={group}
                          groups={props.groups}
                          sectionId={props.id}
                          items={items}
                          content={props.content}
                          selected={selected}
                          programId={props.programId}
                          globalExercises={props.globalExercises}
                          globalExerciseMetrics={props.globalExerciseMetrics}
                          globalFood={props.globalFood}
                          onChange={e => props.onChange(e, props.id)}
                          onUnassignItem={props.onUnassignItem}
                          onSelectItem={handleSelectItem}
                          onRemoveFromGroup={handleRemoveFromGroup}
                          onModal={props.onModal}
                          context={props.context}
                          showHeader={showHeader}
                        />
                      )
                    })
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {
            selected.length === 0 &&
              <div className='ui_button_bar'>
                <button
                  type='button'
                  className='button_primary'
                  onClick={() => props.onModal({ modal: 'addItem', sectionId: props.id })}
                >
                  {`Add ${props.programType === 'Exercise' ? 'Exercise' : 'Food'}`}
                </button>
              </div>
          }

          {
            selected.length > 0 &&
              <div className='program_selection_panel'>
                <p>{`${selected.length} item${selected.length > 1 ? 's' : ''} selected`}</p>

                <span>
                  {
                    selectionType === 'header' &&
                      <button
                        className='button_square'
                        onClick={handleAddHeader}
                      >
                    Add Header
                      </button>
                  }

                  {
                    selectionType === 'group' &&
                      <button
                        className='button_square'
                        onClick={handleCreateGroup}
                        disabled={selected.every(item => item.groupId === selected[0].groupId)}
                      >
                    Group Items
                      </button>
                  }

                  <button
                    className='button_square'
                    onClick={() => setSelected([])}
                  >
                  Cancel
                  </button>

                </span>

              </div>
          }
        </div>
      )}
    </Draggable>
  )
}

export default Section
