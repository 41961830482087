import { db, storage } from '../config/firebase'

export const RECEIVE_LOGS = 'RECEIVE_LOGS'
export const UPDATE_LOGS = 'UPDATE_LOGS'

export const receiveLogs = logs => {
  return {
    type: RECEIVE_LOGS,
    logs
  }
}

export const createLog = log => {
  return dispatch => {
    return db.collection('clientMetrics').add({
      ...log
    })
      .then(docRef => docRef.id)
      .catch(err => err)
  }
}

export const updateLog = ({ logId, data }) => {
  return dispatch => {
    return db.collection('clientMetrics').doc(logId).update({
      ...data
    })
      .catch(err => err)
  }
}

export const deleteLog = logId => {
  return dispatch => {
    return db.collection('clientMetrics').doc(logId)
      .delete()
      .then(() => {
        storage.ref(`/images/records/${logId}`).delete()
        storage.ref(`/images/records/thumbs/thumb_${logId}`).delete()
      })
      .catch(err => err)
  }
}
