import React from 'react'

import TextField from '@material-ui/core/TextField'

const RegisterForm = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange
  } = props

  return (
    <>
      <TextField
        name='first_name'
        label='First Name'
        value={values.first_name || ''}
        onChange={handleChange}
        error={touched.first_name && Boolean(errors.first_name)}
        helperText={touched.first_name && errors.first_name}
        required
      />

      <TextField
        name='last_name'
        label='Last Name'
        value={values.last_name || ''}
        onChange={handleChange}
        error={touched.last_name && Boolean(errors.last_name)}
        helperText={touched.last_name && errors.last_name}
        required
      />

      <TextField
        name='email'
        label='Email'
        value={values.email || ''}
        onChange={handleChange}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
        required
      />

      <TextField
        name='password'
        label='Password'
        value={values.password || ''}
        type='password'
        onChange={handleChange}
        error={touched.password && Boolean(errors.password)}
        helperText={touched.password && errors.password}
        required
      />

      <TextField
        name='passwordConf'
        label='Confirm Password'
        value={values.passwordConf || ''}
        type='password'
        onChange={handleChange}
        error={touched.passwordConf && Boolean(errors.passwordConf)}
        helperText={touched.passwordConf && errors.passwordConf}
        required
      />

      <span className='ui_button_bar'>
        <button
          className='button_primary'
          onClick={props.onChangePage}
          type='submit'
        >
          Next
        </button>
      </span>
    </>
  )
}

export default RegisterForm
