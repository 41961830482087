import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'

import { updateOwners } from '../../../actions/programs'

import UserSelect from '../../Util/UserSelect'

class ModalShareProgram extends Component {
  state = {
    isLoading: null,
    showError: false,
    errorMessage: '',
    coaches: []
  }

  componentDidMount () {
    this.setState({
      coaches: this.props.activeProgram.ownerId
    })
  }

  handleChange = ids => {
    this.setState({
      coaches: ids
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState({
      isLoading: 'submit'
    })

    this.props.dispatch(updateOwners({ programId: this.props.activeProgram.id, ownerId: this.state.coaches }))
      .then(() => {
        this.setState({
          isLoading: null
        }, () => this.props.onCloseModal())
      })
      .catch(err => {
        this.setState({
          showError: true,
          errorMessage: err.message,
          isLoading: null
        })
      })
  }

  render () {
    return (
      <Modal
        isOpen={this.props.showModal}
        onRequestClose={this.props.onCloseModal}
        className='modal'
        overlayClassName='modal_overlay'
      >

        <div className='modal_wrapper'>
          <span className='modal_header'>
            <h3>Share program</h3>
            <i className='fas fa-times' onClick={this.props.onCloseModal} />
          </span>

          <UserSelect
            users={this.props.coaches}
            checkedUsers={this.state.coaches}
            onChange={this.handleChange}
          />

          <p className='ui_error'>{this.state.showError && this.state.errorMessage}</p>

          <div className='modal_buttons'>
            <button
              onClick={this.handleSubmit}
              className='button_primary'
            >
              {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}

            </button>
          </div>
        </div>

      </Modal>
    )
  }
}

function MapStateToProps (globalState) {
  return {
    coaches: globalState.coaches
  }
}

export default connect(MapStateToProps)(ModalShareProgram)
