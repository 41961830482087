import React, { useState } from 'react'
import { withFormik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import { fb } from '../../../config/firebase'
import { useDispatch } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'
import { launchStripe } from '../../../util'
import { deleteClient } from '../../../actions/clients'
import ConfirmationDialog from '../../Common/ConfirmationDialog'

const useStyles = makeStyles((theme) => ({
  sectionContainer: {},
  header: {
    marginTop: 6,
    marginBottom: 18,
    fontWeight: 'bold'
  },
  divider: {
    marginTop: 12,
    marginBottom: 12
  },
  dangerButton: {
    backgroundColor: theme.palette.error
  }
}))

const Details = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    dirty,
    userDetails,
    changeTab
  } = props

  const { subscription } = userDetails

  const dispatch = useDispatch()

  const classes = useStyles()
  const [sendingEmail, setSendingEmail] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [deletingAccount, setDeletingAccount] = useState(false)
  const [accountDeleted, setAccountDeleted] = useState(false)

  const [confirmDelete, setConfirmDelete] = useState(false)

  const handleResetPassword = () => {
    setSendingEmail(true)
    fb.auth()
      .sendPasswordResetEmail(values.email)
      .then(() => {
        setEmailSent(true)
        setSendingEmail(false)
      })
      .catch((err) => this.error(err.message))
  }

  const handleDeleteAccount = () => {
    setConfirmDelete(false)
    setDeletingAccount(true)

    dispatch(deleteClient(userDetails.id)).then(() => {
      setDeletingAccount(false)
      setAccountDeleted(true)
    })
  }

  return (
    <>
      <div>
        <Typography className={classes.header}>Personal Information</Typography>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={6} style={{ paddingBottom: 0 }}>
            <TextField
              name='first_name'
              label='First Name'
              value={values.first_name} // eslint-disable-line
              onChange={handleChange}
              error={touched.first_name && Boolean(errors.first_name)}
              helperText={touched.first_name && errors.first_name}
              fullWidth
            />
          </Grid>

          <Grid item xs={6} style={{ paddingBottom: 0 }}>
            <TextField
              name='last_name'
              label='Last Name'
              onChange={handleChange}
              value={values.last_name} // eslint-disable-line
              error={touched.last_name && Boolean(errors.last_name)}
              helperText={touched.last_name && errors.last_name}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name='email'
              label='Email'
              onChange={handleChange}
              value={values.email}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              fullWidth
            />

            <TextField
              name='phone'
              label='Phone'
              onChange={handleChange}
              value={values.phone}
              error={touched.phone && Boolean(errors.phone)}
              helperText={touched.phone && errors.phone}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>

      <Divider className={classes.divider} />

      <div>
        <Typography className={classes.header}>Subscription</Typography>
        <Grid container alignItems='center'>
          <Grid item xs={6}>
            <Typography>
              Current Plan: {subscription?.product.name || userDetails.freePro && 'Free Pro' || 'Free account'}{' '}
              {subscription?.cancel_at_period_end && '(cancelled)'}
            </Typography>
          </Grid>
          {subscription ? (
            <>
              <Grid item xs={6}>
                <Typography>{`${
                  subscription.cancel_at_period_end
                    ? 'Your PRO access will expire on: '
                    : 'Next payment due: '
                }${moment(subscription.current_period_end.seconds, 'X').format(
                  'Do MMMM YYYY'
                )}`}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Button variant='outlined' onClick={launchStripe} fullWidth>
                  Manage Subscription
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={6} sm={4}>
              <Button variant='outlined' onClick={() => changeTab(1)} fullWidth>
                Upgrade account
              </Button>
            </Grid>
          )}
        </Grid>
      </div>

      <Divider className={classes.divider} />

      <div>
        <Typography className={classes.header}>Account Security</Typography>
        <Grid container alignItems='center'>
          <Button variant='outlined' onClick={handleResetPassword}>
            Request a password reset
          </Button>
          {sendingEmail && (
            <i className='fas fa-spinner fa-spin' style={{ marginLeft: 18 }} />
          )}
          {emailSent && (
            <Typography variant='body1' style={{ marginLeft: 18 }}>
              Password reset email sent!
            </Typography>
          )}
        </Grid>
      </div>

      <Divider className={classes.divider} />

      <div>
        <Typography className={classes.header}>Danger Zone</Typography>
        <Grid container alignItems='center'>
          <Button
            variant='outlined'
            onClick={() => setConfirmDelete(true)}
            className={classes.dangerButton}
          >
            Delete Account
          </Button>
          {deletingAccount && (
            <i className='fas fa-spinner fa-spin' style={{ marginLeft: 18 }} />
          )}
          {accountDeleted && (
            <Typography variant='body1' style={{ marginLeft: 18 }}>
              Account Deleted!
            </Typography>
          )}
        </Grid>
      </div>

      <Grid container justify='flex-end'>
        <Grid item>
          <Button variant='contained' onClick={handleSubmit} disabled={!dirty}>
            Save Changes
          </Button>
        </Grid>
      </Grid>

      <ConfirmationDialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        title='Delete account?'
        text='Are you sure you want to delete your account? This cannot be undone'
        affirmativeAction={handleDeleteAccount}
        negativeAction={() => setConfirmDelete(false)}
      />
    </>
  )
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ userDetails }) => ({
    first_name: userDetails.first_name,
    last_name: userDetails.last_name,
    email: userDetails.email,
    phone: userDetails.phone || ''
  }),
  validationSchema: () => {
    return yup.lazy((values) => {
      return yup.object().shape(
        Object.assign({
          first_name: yup.string().required('Please enter your first name'),
          last_name: yup.string().required('Please enter your last name'),
          email: yup
            .string()
            .email('Please enter a valid email address')
            .required('Please enter your email address'),
          phone: yup.number('Must only include numbers')
        })
      )
    })
  },
  handleSubmit: ({ ...payload }, { props, setSubmitting, setErrors }) => {
    props.updateClient(props.userDetails.id, payload)
  }
})

export default formikEnhancer(Details)
