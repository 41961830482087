import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

import { unsubClientLogListener, unsubClientRecordListener } from '../../config/firebase'

import ProfileGoals from './ProfileGoals'
import ProfileProgram from './ProfileProgram'
import ProfileProgress from './ProfileProgress'
import TopBar from '../Nav/TopBar'
import ProfileRecords from './ProfileRecords'
import ProfileDiary from './ProfileDiary'
import ProfileNotes from './ProfileNotes'

class UserDashboard extends Component {
  state = {
    activeTab: 'Goals',
    activeModal: null
  }

  componentDidMount () {
    this.setState({
      activeTab: this.props.match.params.profilePage
    })
  }

  componentWillUnmount () {
    if (unsubClientLogListener != null) { // reset client log listener
      unsubClientLogListener()
      unsubClientRecordListener()
    }
  }

  handleChangeTab = e => {
    this.setState({
      activeTab: e
    }, () => this.props.history.replace(`/clients/${this.props.match.params.clientId}/${e}`))
  }

  handleModal = modal => {
    this.setState({
      activeModal: this.state.activeModal === modal ? null : modal
    })
  }

  render () {
    const user = { ...this.props.user }

    return (
      <div className='container_col' style={{ overflowX: 'hidden' }}>
        <TopBar
          title={`${user.first_name} ${user.last_name}`}
          subtitle={user.email}
          img_url={user.img_url}
          tabs={['Goals', 'Programs', 'Diary']}
          subTabs={{ Diary: ['All', 'Metrics', 'Exercises', 'Notes'] }}
          onChangeTab={this.handleChangeTab}
        />

        <SwitchTransition className='profile_content'>
          <CSSTransition
            key={this.state.activeTab}
            addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
            classNames='slide'
          >
            <>
              {
                this.state.activeTab === 'Goals' &&
                  <ProfileGoals
                    clientId={user.id}
                    activeModal={this.state.activeModal === 'goalModal'}
                    onModal={() => this.handleModal('goalModal')}
                  />
              }
              {
                this.state.activeTab === 'Programs' &&
                  <ProfileProgram
                    clientId={user.id}
                    history={this.props.history}
                    activeModal={this.state.activeModal}
                    onModal={this.handleModal}
                  />
              }
              {
                this.state.activeTab === 'All' &&
                  <ProfileDiary
                    clientId={user.id}
                    activeModal={this.state.activeModal === 'diaryModal'}
                    onModal={() => this.handleModal('diaryModal')}
                  />
              }
              {
                this.state.activeTab === 'Metrics' &&
                  <ProfileProgress
                    clientId={user.id}
                    activeModal={this.state.activeModal === 'metricModal'}
                    onModal={() => this.handleModal('metricModal')}
                  />
              }
              {
                this.state.activeTab === 'Exercises' &&
                  <ProfileRecords
                    clientId={user.id}
                    activeModal={this.state.activeModal === 'exerciseModal'}
                    onModal={() => this.handleModal('exerciseModal')}
                  />

              }
              {
                this.state.activeTab === 'Notes' &&
                  <ProfileNotes
                    clientId={user.id}
                    activeModal={this.state.activeModal === 'noteModal'}
                    onModal={() => this.handleModal('noteModal')}
                  />
              }
            </>

          </CSSTransition>
        </SwitchTransition>

        <span className='ui_bottom_bar'>
          <SwitchTransition>
            <CSSTransition
              key={this.state.activeTab}
              addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
              classNames='pop'
            >
              <>
                {
                  this.state.activeTab === 'Goals' &&
                    <button
                      className='button_primary'
                      onClick={() => this.setState({ activeModal: 'goalModal' })}
                    >
                      Set Goal
                    </button>

                }
                {
                  this.state.activeTab === 'Programs' &&
                    <div>
                      <button
                        className='button_primary'
                        onClick={() => this.setState({ activeModal: 'assignProgramModal' })}
                        style={{ transform: `translateX(${-100}%)` }}
                      >
                        Assign Program
                      </button>

                      <button
                        className='button_primary'
                        onClick={() => this.setState({ activeModal: 'programModal' })}
                        style={{ transform: `translateX(${0}%)` }}

                      >
                      Create Program
                      </button>
                    </div>
                }
                {
                  this.state.activeTab === 'All' &&
                    <button
                      className='button_primary'
                      onClick={() => this.setState({ activeModal: 'diaryModal' })}
                    >
                      Add Entry
                    </button>
                }
                {
                  this.state.activeTab === 'Metrics' &&
                    <button
                      className='button_primary'
                      onClick={() => this.setState({ activeModal: 'metricModal' })}
                    >
                      Add Log
                    </button>
                }
                {
                  this.state.activeTab === 'Exercises' &&
                    <button
                      className='button_primary'
                      onClick={() => this.setState({ activeModal: 'exerciseModal' })}
                    >
                      Add Record
                    </button>

                }
                {
                  this.state.activeTab === 'Notes' &&
                    <button
                      className='button_primary'
                      onClick={() => this.setState({ activeModal: 'noteModal' })}
                    >
                      Add Note
                    </button>
                }
              </>
            </CSSTransition>
          </SwitchTransition>
        </span>
      </div>
    )
  }
}

function mapStateToProps (globalState, ownProps) {
  const id = ownProps.match.params.clientId
  const user = globalState.clients.find(user => user.id === id)

  return {
    user
  }
}

export default connect(mapStateToProps)(UserDashboard)
