import moment from 'moment'
import prettyMilliseconds from 'pretty-ms'
import { store } from './store'
import {
  clientLogListener,
  clientRecordListener,
  fb, storage
} from './config/firebase'

import prettyMillimeters from './prettymm'
import lunr from 'lunr'

import { receiveAllMetrics, receiveUserMetrics } from './actions/metrics'
import { receiveUnits } from './actions/units'
import { receiveFood } from './actions/food'
import { receiveExercises } from './actions/exercises'
import { receiveCoaches } from './actions/coaches'
import { receiveMilestones } from './actions/milestones'
import { receiveGoals } from './actions/goals'
import { receiveAllClients, receiveUserClients } from './actions/clients'
import { receiveExerciseMetrics } from './actions/exerciseMetrics'
import { receiveAllPrograms, receiveUserPrograms } from './actions/programs'
import { receiveLessons } from './actions/lessons'
import { receiveCollections } from './actions/collections'
import { getProducts } from './actions/stripe'

export function establishListeners (uid, type, clientId) {
  if (uid != null) {
    const promise = []

    if (type.includes('admin')) {
      promise.push([
        store.dispatch(receiveAllClients()),
        store.dispatch(receiveAllMetrics()),
        store.dispatch(receiveCoaches()),
        store.dispatch(receiveAllPrograms())
      ])
    } else if (type.includes('trainer')) {
      promise.push([
        store.dispatch(receiveUserClients(uid)),
        store.dispatch(receiveUserMetrics(uid)),
        store.dispatch(receiveUserPrograms(uid)),
        store.dispatch(receiveCoaches())
      ])
    }

    if (clientId) {
      promise.push([
        clientLogListener(clientId),
        clientRecordListener(clientId),
        store.dispatch(receiveGoals(clientId)),
        store.dispatch(receiveMilestones(clientId))
      ])
    }

    promise.push([
      store.dispatch(receiveUnits()),
      store.dispatch(receiveFood()),
      store.dispatch(receiveExercises()),
      store.dispatch(receiveExerciseMetrics()),
      store.dispatch(receiveLessons()),
      store.dispatch(receiveCollections()),
      store.dispatch(getProducts())
    ])

    return Promise.all(promise.flat())
  }
}

export function validateEmail (email) {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line

  return emailRegex.test(email)
}

// Takes data and formats it for a specific graph
export function prepareMetricGraph (metric, unit, logs) {
  switch (unit.graph_type) {
    case 'line': {
      const dataFormatted = logs.map(log => {
        const data = {
          x: moment(log.date.seconds, 'X').format('YYYY-MM-DD'),
          y: log.value,
          note: log.note,
          id: log.id
        }

        if (log.checklist) {
          const checked = []
          // const keys = Object.keys(log.checklist)
          for (const item in log.checklist) {
            if (log.checklist[item]) checked.push(item)
          }

          data.tooltip = checked
        } else if (unit.type === 'TIME') {
          data.tooltip = [prettyMilliseconds(Number(log.value), { verbose: true, unitCount: 2 })]
        } else {
          data.tooltip = [`${log.value} ${unit.abbreviation || unit.name}`]
        }

        return data
      })

      const dataSorted = sortDataThenNow(dataFormatted, 'x')

      return [{ id: metric.id, data: dataSorted }]
    }

    case 'pie': {
      const data = logs.reduce((log, x) => {
        if (!log[x.value]) { log[x.value] = [] }
        log[x.value].push(x)
        return log
      }, {})

      const dataSorted = []

      for (const value in data) {
        const dataFormatted = data[value].map(log => ({
          id: log.id,
          date: moment(log.date.seconds, 'X').format('Do MMM YYYY'),
          value: log.value,
          note: log.note
        }))

        dataSorted.push({
          id: value,
          label: value,
          value: data[value].length,
          extra: dataFormatted
        })
      }

      return dataSorted
    }

    default: {
      const dataFormatted = logs.map(log => ({
        id: log.id,
        date: moment(log.date.seconds, 'X').format('Do MMMM YYYY'),
        note: log.note
      }))

      return dataFormatted
    }
  }
}

export const prepareRecordGraph = (records, metric, exercises) => {
  const graphData = []
  const temp = {}

  records.forEach(record => {
    const value = record.exerciseMetrics.find(m => m.id === metric.id).value

    const data = {
      x: moment(record.date.seconds, 'X').format('YYYY-MM-DD'),
      y: value,
      note: record.note,
      id: record.id
    }

    if (metric.name === 'Time' || metric.name === 'Rest') {
      data.tooltip = [prettyMilliseconds(Number(value), { verbose: true, unitCount: 2 })]
    } else if (metric.name === 'Distance') {
      data.tooltip = [prettyMillimeters(Number(value), { verbose: true, unitCount: 2 })]
    } else {
      data.tooltip = [`${value}`]
    }

    if (temp[record.exerciseId]) {
      temp[record.exerciseId].push(data)
    } else {
      temp[record.exerciseId] = [data]
    }
  })

  for (const exercise in temp) {
    temp[exercise] = sortDataThenNow(temp[exercise], 'x')
    const exerciseName = exercises.find(e => e.id === exercise).name

    graphData.push({ id: exerciseName, data: temp[exercise] })
  }

  return graphData
}

// Sort reverse chronological
export function sortDataNowThen (data, key) {
  return data.sort((a, b) => moment(b[key], 'Do-MMMM-YYYY') - moment(a[key], 'Do MMMM YYYY'))
}

// Sort chronological
function sortDataThenNow (data, key) {
  return data.sort((a, b) => moment(a[key], 'YYYY-MM-DD') - moment(b[key], 'YYYY-MM-DD'))
}

export function getImgUrl (firstName, lastName, type) {
  const background = type.includes('trainer') ? '140a25' : '5c68f2'

  return `https://ui-avatars.com/api/?name=${firstName[0]}+${lastName[0]}&size=128&background=${background}&color=fff`
}

export function alphabetiseNames (names) {
  return names.sort((a, b) => {
    const one = `${a.first_name} ${a.last_name}`
    const two = `${b.first_name} ${b.last_name}`

    return one.localeCompare(two)
  })
}

export function onDragEnd (res, list) {
  const { draggableId, source, destination } = res

  const moved = list.find(x => (x.id === draggableId || x.key === draggableId))

  list.splice(source.index, 1)
  list.splice(destination.index, 0, moved)

  list.forEach((item, i) => {
    item.index = i
  })

  return list.sort((a, b) => a.index - b.index)
}

export function processYoutubeURL (url) {
  if (url.includes('v=')) {
    return url.split('v=')[1]
  } else if (url.includes('.be/')) {
    return url.split('.be/')[1]
  }
}

export const imageValidation = image => {
  if (!image.type.includes('image')) {
    return ('File must be an image')
  }

  const fileSize = ((image.size / 1024) / 1024).toFixed(4)
  if (fileSize > 1) {
    return 'File is too big.  File must be less than 1MB'
  }

  return null
}

export const renameImage = (image, name) => {
  return new File([image], name, { // eslint-disable-line
    type: image.type,
    lastModified: image.lastModified
  })
}

export function uploadImage (path, image) {
  return storage.ref(`images/${path}/${image.name}`).put(image)
    .then(() => {
      return storage.ref(`images/${path}`).child(image.name).getDownloadURL()
        .then(url => url)
    })
    .catch(err => err)
}

export function uploadLesson (lesson, id) {
  return storage.ref(`lessons/${id}`).putString(lesson)
    .then(res => res)
    .catch(err => err)
}

export const cumulativeOffset = function (element) {
  let top = 0; let left = 0
  do {
    top += element.offsetTop || 0
    left += element.offsetLeft || 0
    element = element.offsetParent
  } while (element)

  return {
    top: top,
    left: left
  }
}

export const arrayDiffs = (before = [], after = []) => {
  const toRemove = before.filter(x => !after.includes(x))
  const toAdd = after.filter(x => !before.includes(x))

  return ({ toRemove, toAdd })
}

export const launchStripe = () => {
  const location = process.env.ENVIRONMENT === 'production' ? 'us-central1' : 'australia-southeast1'

  console.log(location)
  const functionRef = fb.app().functions(location).httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')

  functionRef({ returnUrl: window.location.origin })
    .then(({ data }) => {
      window.open(data.url, '_blank')
    })
}
