import {
  RECEIVE_MILESTONES,
  CREATE_MILESTONE,
  UPDATE_MILESTONE,
  DELETE_MILESTONE,
  CLEAR_MILESTONES
} from '../actions/milestones'

const initialState = []

const milestones = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_MILESTONES:
      return action.milestones
    case CREATE_MILESTONE:
      return [action.milestone, ...state]
    case UPDATE_MILESTONE: {
      const old = state.find(milestone => milestone.id === action.milestone.id)

      const index = state.indexOf(old)
      state[index] = action.milestone

      return state
    }
    case DELETE_MILESTONE: {
      const milestone = state.find(stone => stone.id === action.milestoneID)

      return state.filter(stone => stone !== milestone)
    }
    case CLEAR_MILESTONES:
      return []
    default:
      return state
  }
}

export default milestones
