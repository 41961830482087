import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getSections } from '../../actions/programSections'

class DiaryForm extends Component {
  state = {
    recordType: undefined,
    sections: [],
    phases: [],
    isLoading: null,
    showError: null,
    errorMessage: ''
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    this.props.onModal(this.state.recordType)
    this.props.onCloseModal()
  }

  handleSelectMetric = e => {
    const metric = this.props.metrics.find(m => m.id === e.target.value)

    this.props.setActiveMetric(metric)

    const unit = this.props.unitsOfMeasurement.find(unit => unit.id === metric.unitOfMeasurementId)

    this.props.setActiveMetricUnit(unit)
  }

  handleSelectProgram = e => {
    const activeProgram = this.props.programs.find(p => p.id === e.target.value)

    this.props.setActiveProgram(activeProgram)

    const phases = activeProgram.phases

    this.props.dispatch(getSections(e.target.value))
      .then(() => {
        const phaseGroups = {}

        const sections = [...this.props.sections]

        // Split sections into phases
        sections.forEach(section => {
          if (phaseGroups[section.phaseId]) phaseGroups[section.phaseId].push(section)
          else phaseGroups[section.phaseId] = [section]
        })

        const orderedPhases = []

        // Sort sections within phase groups, then sport phases within program
        for (const sections in phaseGroups) {
          phaseGroups[sections].sort((a, b) => a.index - b.index)

          const phaseIndex = phases.find(phase => phase.id === sections).index

          orderedPhases[phaseIndex] = phaseGroups[sections]
        }

        this.setState({
          activeProgram,
          phases,
          sections: orderedPhases.flat()
        })
      })
  }

  handleSelectSection = e => {
    const section = this.state.sections.find(section => section.id === e.target.value)
    this.props.setActiveSection(section)
  }

  render () {
    return (
      <div className='modal_content'>
        <label htmlFor='recordType'>Entry type</label>
        <select
          name='recordType'
          onChange={this.handleChange}
          defaultValue='placeholder'
        >
          <option value='placeholder' hidden disabled>Select one</option>
          <option value='metric'>Metric</option>
          <option value='exercise'>Exercise</option>
          <option value='workout'>Workout</option>
          <option value='note'>Note</option>
        </select>

        {
          this.state.recordType === 'metric' &&
            <>
              <label htmlFor='metric'>Select a metric</label>
              <select
                name='metric'
                onChange={this.handleSelectMetric}
                defaultValue='placeholder'
              >
                <option value='placeholder' hidden disabled>Select one</option>
                {
                  this.props.metrics.map(metric => (
                    <option value={metric.id} key={metric.id}>{metric.name}</option>
                  ))
                }
              </select>
            </>
        }

        {
          this.state.recordType === 'workout' &&
            <>
              <label htmlFor='program'>Program</label>
              <select
                name='program'
                defaultValue='placeholder'
                onChange={this.handleSelectProgram}
              >
                <option value='placeholder' hidden disabled>Select a program</option>
                {
                  this.props.programs.map(program => (
                    <option key={program.id} value={program.id}>{program.header.title}</option>
                  ))
                }
              </select>

              <label htmlFor='section'>Section</label>
              <select
                name='section'
                defaultValue='placeholder'
                onChange={this.handleSelectSection}
                disabled={this.state.sections.length === 0}
              >
                <option value='placeholder' hidden disabled>Select a section</option>
                {
                  this.state.sections.map(section => {
                    const phase = this.state.phases.find(phase => phase.id === section.phaseId)

                    return (
                      <option key={section.id} value={section.id}>{`${phase.name}: ${section.title}`}</option>
                    )
                  })
                }
              </select>
            </>
        }

        <p className='ui_error'>{this.state.showError && this.state.errorMessage}</p>

        <div className='modal_buttons'>
          <button
            onClick={this.handleSubmit}
            className='button_primary'
          >
            {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Start'}
          </button>
        </div>
      </div>
    )
  }
}

export default connect()(DiaryForm)
