import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Draggable } from 'react-beautiful-dnd'
import Distance from '../../Util/Distance'
import KebabMenu from '../../Common/KebabMenu'
import ModalWrapper from '../../Common/ModalWrapper'
import EditExercise from './EditExercise'
import Time from '../../Util/Time'

const ExerciseItem = props => {
  const [showOverflow, toggleShowOverflow] = useState('ontouchstart' in document.documentElement)
  const [showModal, toggleModal] = useState(false)
  const [placeholders, setPlaceholders] = useState([])
  const [sets, setSetNum] = useState(1)

  const selected = props.selected.find(item => item.itemKey === props.itemKey)

  useEffect(() => {
    setSetNum(props.item.sets.num)
  }, [props.item.sets.num])

  useEffect(() => {
    if (props.context.includes('diary_create')) {
      const placeholder = _.cloneDeep(props.item.metrics)

      props.item.metrics.forEach(metric => {
        metric.value.forEach((value, i) => {
          const e = {
            target: {
              name: metric.name,
              value: null
            }
          }

          handleChangeMetric({ e, i })
        })
      })

      setPlaceholders(placeholder)
    }
  }, [])

  const handleChangeSet = e => {
    const sets = { num: Number(e.target.value), display: true }
    setSetNum(sets.num)

    const change = {
      target: {
        name: 'sets',
        value: sets
      }
    }

    props.onChange({ e: change, itemKey: props.itemKey })

    handleChangeMetric({ setNum: sets.num })
  }

  const handleChangeMetric = ({ e, i, setNum }) => {
    const metrics = [...props.item.metrics]

    if (e) {
      const index = metrics.findIndex(metric => metric.name === e.target.name)
      metrics[index].value[i] = e.target.value
    }

    if (setNum && typeof setNum === 'number') {
      metrics.forEach(m => { m.value.length = Math.min(m.value.length, setNum) })
    }

    const change = {
      target: {
        name: 'metrics',
        value: metrics
      }
    }

    props.onChange({ e: change, itemKey: props.itemKey })
  }

  const handleSelectExercise = () => {
    const item = {
      itemKey: props.itemKey,
      groupId: props.groupId
    }

    let newGroup = [...props.selected]
    if (selected === undefined) newGroup.push(item)
    else newGroup = newGroup.filter(nGroup => nGroup.itemKey !== item.itemKey)

    props.onSelectItem(newGroup)
  }

  return (
    <Draggable draggableId={props.itemKey} index={props.item.index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}

        >
          <div
            className='program_section_item'
            onMouseEnter={() => toggleShowOverflow(true)}
            onMouseLeave={() => toggleShowOverflow(false)}
            style={selected ? { backgroundColor: '#DAE1F3' } : undefined}
          >

            <i
              className='fas fa-grip-vertical grip handle'
              style={props.groupLength === 1 ? { display: 'none' } : undefined}
              {...provided.dragHandleProps}
            />

            {
              selected &&
                <span className='check' onClick={handleSelectExercise}>
                  <i className='fas fa-check' />
                </span>
            }

            {
              !selected &&
                <img
                  src={props.itemDetails.thumbnail_url}
                  onClick={handleSelectExercise}
                />
            }

            {
              showOverflow &&
                <span className='overflow'>
                  <KebabMenu
                    context={props.groupLength > 1 ? 'section_grouped_item' : 'edit_delete'}
                    onAddHeader={props.onAddHeader}
                    onEdit={() => toggleModal(true)}
                    onDelete={() => props.onUnassignItem(props.itemKey, props.sectionId)}
                    onUngroup={() => props.onRemoveFromGroup([props.itemKey], props.groupId)}
                  />
                </span>
            }

            <p className='header'>{props.itemDetails.name}</p>

            <div className='content'>
              {
                props.item.sets.display &&
                  <span className='container'>
                    <label htmlFor='sets'>Sets</label>
                    <span className='boxes'>
                      <input
                        type='text'
                        name='sets'
                        onChange={e => handleChangeSet(e)}
                        value={sets}
                        maxLength={2}
                      />
                    </span>
                  </span>
              }

              {
                props.item.metrics.map((metric, i) => {
                  const boxes = []

                  for (let i = 0; i < sets; i++) {
                    let placeholder = null
                    if (props.context === 'diary_create') {
                      const match = placeholders.find(p => p.name === metric.name)
                      if (match !== undefined) placeholder = match.value[i]
                    } else if (props.context === 'diary_edit') placeholder = metric.placeholder[i]

                    if ((metric.name === 'Time' || metric.name === 'Rest') && props.context.includes('diary')) {
                      boxes.push(
                        <Time
                          key={i}
                          name={metric.name}
                          value={(props.context !== 'diary_create' && metric.value !== null) ? (metric.value[i] || '') : undefined}
                          onChange={e => handleChangeMetric({ e, i })}
                        />
                      )
                    } else if ((metric.name === 'Distance' || metric.name === 'Height') && props.context.includes('diary')) {
                      boxes.push(
                        <Distance
                          key={i}
                          name={metric.name}
                          value={(props.context !== 'diary_create' && metric.value !== null) ? (metric.value[i] || '') : undefined}
                          onChange={e => handleChangeMetric({ e, i })}
                        />
                      )
                    } else {
                      boxes.push(
                        <input
                          key={i}
                          type='text'
                          name={metric.name}
                          onChange={(e) => handleChangeMetric({ e, i })}
                          onFocus={e => e.currentTarget.select()}
                          value={props.context !== 'diary_create' ? (metric.value[i] || '') : undefined}
                          placeholder={placeholder}
                          size='1'
                        />
                      )
                    }
                  }

                  return (
                    <span key={metric.id} className='container'>
                      <label htmlFor={metric.name}>{metric.name}</label>
                      <span className='boxes'>
                        {boxes}
                      </span>
                    </span>
                  )
                })
              }

              <label>{props.item.notes}</label>
            </div>
          </div>
          <ModalWrapper
            modalHeader={`Edit ${props.itemDetails.name || 'Item'}`}
            video_url={props.itemDetails.video_url}
            showModal={showModal}
            onCloseModal={() => toggleModal(false)}
          >
            <EditExercise
              item={props.item}
              itemDetails={props.itemDetails}
              globalExerciseMetrics={props.globalExerciseMetrics}
              sectionId={props.sectionId}
              onChange={props.onChange}
              onCloseModal={() => toggleModal(false)}
            />
          </ModalWrapper>
        </div>

      )}
    </Draggable>

  )
}

export default ExerciseItem
