import React, { useState, useEffect } from 'react'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import moment from 'moment'

const Range = createSliderWithTooltip(Slider.Range)

const Filter = props => {
  const [sortDirection] = useState(props.sortDirection)
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'))
  const [dateFrom, setDateFrom] = useState(null)
  const [category, setCategory] = useState(undefined)
  const [tags, setTags] = useState([])
  const [range, setRange] = useState({})

  useEffect(() => {
    if (props.type === 'exercise') setCategory('all')
    if (props.type === 'metric') setCategory(props.defaultCategory ? props.defaultCategory.id : null)

    if (props.data.length > 0) {
      const sort = props.data.sort((a, b) => a.date.seconds - b.date.seconds)
      setDateFrom(moment(sort[0].date.seconds, 'X').format('YYYY-MM-DD'))
    } else {
      setDateFrom(moment().subtract(1, 'month').format('YYYY-MM-DD'))
    }
  }, [])

  useEffect(() => {
    filterData()
  }, [sortDirection, dateTo, dateFrom, category, tags, range])

  const filterData = () => {
    let data = [...props.data].reduce((res, log) => {
      const logDate = moment(log.date.seconds, 'X')
      const fromFilter = moment(dateFrom, 'YYYY-MM-DD')
      const toFilter = moment(dateTo, 'YYYY-MM-DD').add(1, 'day')

      const id = log[`${props.type}Id`]

      if (
        logDate >= fromFilter &&
        logDate <= toFilter &&
        (category === id || category === 'all' || category === undefined)
      ) {
        res.push(log)
      }
      return res
    }, [])

    if (props.exerciseMetrics && tags.length > 0) {
      data = filterByMetric(data)
    }

    if (Object.keys(range).length > 0) {
      data = filterByRange(data)
    }

    props.onChange({ data, category, tags })
  }

  const filterByMetric = data => {
    return data.reduce((acc, res) => {
      const metrics = []
      res.exerciseMetrics.forEach(m => metrics.push(m.id))

      if (tags.every(r => metrics.includes(r))) acc.push(res)

      return acc
    }, [])
  }

  const filterByRange = data => {
    return data.reduce((acc, res) => {
      if (res.exerciseMetrics.every(metric => {
        if (!range[metric.id]) return true
        else if (metric.value >= range[metric.id][0] && metric.value <= range[metric.id][1]) return true
      })) acc.push(res)

      return acc
    }, [])
  }

  const handleChangeTag = tag => {
    if (tags.includes(tag)) setTags(tags.filter(t => t !== tag))
    else setTags([...tags, tag])
  }

  const handleSetRange = (values, tag) => {
    const newRange = { ...range }
    newRange[tag] = values
    setRange(newRange)
  }

  return (
    <form onSubmit={e => e.preventDefault()}>
      <div className='filter_container'>
        {
          props.type !== 'notes' &&
            <span className='col'>
              <label htmlFor='category'>Type</label>
              <select
                onChange={e => setCategory(e.target.value)}
                name='category'
                defaultValue={props.type === 'metrics' ? props.categories[0].id : 'all'}
              >
                {
                  props.type === 'exercise' &&
                    <option value='all'>All</option>
                }
                {
                  props.categories.map(category => (
                    <option
                      key={category.id}
                      value={category.id}
                    >
                      {category.name}
                    </option>
                  ))
                }
              </select>
            </span>
        }

        <span className='col'>
          <label htmlFor='from'>From</label>
          <input
            type='date'
            name='from'
            onChange={e => setDateFrom(e.target.value)}
            defaultValue={dateFrom}
          />
        </span>

        <span className='col'>
          <label htmlFor='to'>To</label>
          <input
            type='date'
            name='to'
            onChange={e => setDateTo(e.target.value)}
            defaultValue={dateTo}
          />
        </span>
      </div>

      <div className='filter_container'>
        {
          props.availableTags && props.availableTags.map((metric, i) => (
            <span
              key={i}
              className={`filter_tag ${tags.includes(metric.id) ? 'selected' : undefined}`}
              onClick={() => handleChangeTag(metric.id)}
            >
              <label>{metric.name}</label>
            </span>
          ))
        }
      </div>

      {
        tags.length > 0 && tags.map(tag => {
          const values = props.data.reduce((acc, res) => {
            const index = res.exerciseMetrics.findIndex(m => m.id === tag)

            if (index !== -1) {
              acc.push(res.exerciseMetrics[index].value)
            }

            return acc
          }, [])

          const minmax = [Math.min(...values), Math.max(...values)]

          const metric = props.exerciseMetrics.find(m => m.id === tag)

          return (
            <div key={tag} className='filter_container'>
              <span className='row'>
                <label>{metric.name}</label>
                <Range
                  style={{ marginLeft: 10 + 'px' }}
                  allowCross={false}
                  min={minmax[0]}
                  max={minmax[1]}
                  defaultValue={[minmax[0], minmax[1]]}
                  onAfterChange={e => handleSetRange(e, tag)}
                />
              </span>
            </div>
          )
        })
      }

    </form>
  )
}

export default Filter
