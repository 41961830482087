import {
  RECEIVE_COLLECTIONS,
  CREATE_COLLECTION,
  EDIT_COLLECTION,
  DELETE_COLLECTION,
  ADD_PROGRAM_TO_COLLECTION,
  REMOVE_PROGRAM_FROM_COLLECTION
} from '../actions/collections'

const initialState = []

const collections = (state = initialState, action) => {
  const index = state.findIndex(collection => collection.id === action.collectionId)

  switch (action.type) {
    case RECEIVE_COLLECTIONS:
      return action.collections

    case CREATE_COLLECTION:
      return [...state, action.collection]

    case EDIT_COLLECTION:
      action.collection.id = action.collectionId
      state[index] = Object.assign(state[index], action.collection)

      return state

    case DELETE_COLLECTION:
      return state.filter(collection => collection.id !== action.collectionId)

    case ADD_PROGRAM_TO_COLLECTION:
      state[index].programs.push(action.programId)

      return state

    case REMOVE_PROGRAM_FROM_COLLECTION:
      state[index].programs.filter(p => p !== action.programId)

      return state

    default:
      return state
  }
}

export default collections
