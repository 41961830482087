import React from 'react'
import KebabMenu from '../Common/KebabMenu'

const FoodItem = React.forwardRef((props, ref) => {
  return (
    <div
      className='food_list_item box'
      ref={ref}
      onClick={() => props.onSelect(props.id)}
    >
      <img className='image' src={props.image_url} />

      <p className='title'>{props.name}</p>

      <span className='overflow'>
        <KebabMenu
          context='edit_delete'
          onEdit={() => props.onSelect(props.id)}
          onDelete={() => props.onDelete(props.id)}
        />
      </span>

    </div>
  )
})

export default FoodItem
