import React, { useEffect, useRef, useState } from 'react'
import { VariableSizeGrid as Grid } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import ProfileCard from './ProfileCard'

const UserList = props => {
  const colNum = Math.ceil(window.innerWidth / 250)
  const listRef = useRef({})
  const rowHeights = useRef({})

  const [list, setList] = useState([])

  useEffect(() => {
    setList(props.users)
  }, [props.users])

  const getRowHeight = index => {
    return rowHeights.current[index] + 16 || 82 // 16 - Add padding between rows
  }

  const cell = ({ columnIndex, rowIndex, style }) => {
    const cellRef = useRef({})

    useEffect(() => {
      if (cellRef.current) {
        setRowHeight(rowIndex, cellRef.current.clientHeight)
      }
    }, [cellRef])

    return (

      <div style={style}>
        {
          list[colNum * rowIndex + columnIndex] &&
            <ProfileCard
              user={list[colNum * rowIndex + columnIndex]}
              activeUserType={props.activeUserType}
              history={props.history}
              ref={cellRef}
              onSelect={props.onSelect}
              onEdit={props.onEdit}
            />
        }
      </div>
    )
  }

  const setRowHeight = (index, size) => {
    listRef.current.resetAfterRowIndex(0)

    // Set row height to largest card
    if (!rowHeights.current[index] || rowHeights.current[index] < size) {
      rowHeights.current = { ...rowHeights.current, [index]: size }
    }
  }

  return (
    <div style={{ flex: 1 }}>
      <AutoSizer>
        {({ height, width }) => (
          <Grid
            columnCount={list.length > colNum ? colNum : list.length}
            columnWidth={() => width / colNum - 4} // 4 - Add padding right
            rowCount={Math.ceil(list.length / colNum)}
            rowHeight={getRowHeight}
            height={height - 80} // 80 - Add padding bottom
            width={width}
            ref={listRef}
          >
            {cell}
          </Grid>
        )}
      </AutoSizer>
    </div>
  )
}

export default UserList
