import React, { useState, useEffect, useRef } from 'react'

const AddComponent = props => {
  const [color, setColor] = useState(null)
  const buttonRef = useRef(null)

  useEffect(() => {
    if (color === '#ef4136') props.onMark(true)
    else props.onMark(false)
  }, [color])

  const handleAdd = e => {
    const left = e.pageX
    const top = e.pageY

    props.onMenu({ componentId: props.componentId, top, left, menu: 'component' })
  }

  const handleMouse = e => {
    const width = buttonRef.current.offsetWidth
    const left = e.nativeEvent.offsetX

    if (left > (width / 2)) {
      setColor('#ef4136')
    } else {
      setColor('#5c68f2')
    }
  }

  return (
    <div
      className='lesson_manage_component'
      ref={buttonRef}
      onMouseEnter={handleMouse}
      onMouseLeave={() => setColor(null)}
      onClick={e => { color === '#ef4136' ? props.onDelete(props.componentId) : handleAdd(e) }}
    >
      <hr
        style={{ borderColor: color }}
      />
      <i
        className='fas fa-plus-circle'
        style={color === '#ef4136' ? { opacity: 0.1 } : undefined}
      />
      <hr
        style={{ borderColor: color }}
      />
      <i
        className='fas fa-times-circle'
        style={color === '#5c68f2' ? { opacity: 0.1 } : undefined}
      />
      <hr
        style={{ borderColor: color }}
      />
    </div>
  )
}

export default AddComponent
