import {
  RECEIVE_PROGRAMS,
  RECEIVE_CLIENT_PROGRAMS,
  REMOVE_CLIENT_PROGRAMS,
  CREATE_PROGRAM,
  EDIT_PROGRAM,
  DELETE_PROGRAM,
  ASSIGN_PROGRAM,
  ADD_PHASE,
  UPDATE_PHASE,
  CLIENT_ASSIGN_PROGRAM
} from '../actions/programs'

const initialState = []

const programs = (state = initialState, action) => {
  const activeProgramIndex = state.findIndex(program => program.id === action.programId)

  switch (action.type) {
    case RECEIVE_PROGRAMS:
      return action.programs

    case RECEIVE_CLIENT_PROGRAMS: {
      const clientPrograms = action.programs.filter(program => state.every(p => p.id !== program.id))

      return [...state, ...clientPrograms]
    }

    case REMOVE_CLIENT_PROGRAMS: {
      const programs = state.filter(program => program.ownerId.includes(action.userId || 'global'))

      return programs
    }

    case CREATE_PROGRAM:
      return [...state, action.program]

    case EDIT_PROGRAM: {
      state[activeProgramIndex] = Object.assign(state[activeProgramIndex], action.program)

      return state
    }

    case DELETE_PROGRAM:
      return state.filter(program => program.id !== action.programId)

    case ASSIGN_PROGRAM: {
      state[activeProgramIndex].users = action.users

      return state
    }

    case ADD_PHASE:
      state[activeProgramIndex].phases.push(action.phase)

      return state

    case UPDATE_PHASE:
      state[activeProgramIndex].phases = action.phases

      return state

    case CLIENT_ASSIGN_PROGRAM:
      state[activeProgramIndex].users.push(action.clientId)

      return state

    default:
      return state
  }
}

export default programs
