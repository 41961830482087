import React from 'react'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { launchStripe } from '../../../util'

const History = () => {
  return (
    <>
      <Typography variant='body1'>Vist the Stripe dashboard to view your payment history</Typography>
      <Button
        variant='contained'
        onClick={launchStripe}
      >
        Go
      </Button>
    </>
  )
}

export default History
