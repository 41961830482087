import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { fb } from '../../config/firebase'
import { updateLog, createLog, deleteLog } from '../../actions/logs'
import moment from 'moment'
import Time from '../Util/Time'
import _ from 'lodash'
import { imageValidation, renameImage, uploadImage } from '../../util'

Modal.setAppElement('#app')

class LogMetricForm extends React.Component {
  state = {
    date: new fb.firestore.Timestamp.now(), // eslint-disable-line
    value: '',
    note: '',
    showError: false,
    errorMessage: '',
    isLoading: null
  }

  componentDidMount () {
    if (this.props.editData.id) {
      this.setState({
        date: this.props.editData.date, // eslint-disable-line
        value: this.props.editData.value,
        note: this.props.editData.note
      })
    }

    if (this.props.activeMetricUnit.type === 'CHECKLIST') {
      this.setChecklistDefault()
    }
  }

  handleChange = e => {
    if (e.target.type === 'checkbox') {
      const list = { ...this.state.checklist }
      list[e.target.name] = e.target.checked

      this.setState({
        checklist: list
      })
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        showError: false
      })
    }
  }

  handleDate = e => {
    const timestamp = new fb.firestore.Timestamp.fromDate(new Date(e.target.value)) // eslint-disable-line
    this.setState({
      date: timestamp
    })
  }

  handleImage = e => {
    const valid = imageValidation(e.target.files[0])

    if (valid !== null) {
      this.setState({
        errorMessage: valid,
        showError: true
      })
    } else {
      this.setState({
        image: e.target.files[0]
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState({
      isLoading: 'submit'
    })

    const regex = new RegExp(this.props.activeMetricUnit.regex)

    if (this.state.value !== '' && !this.state.value.toString().match(regex)) {
      this.error('Invalid format')
      return
    }

    const data = _.cloneDeep(this.state)

    if (this.state.checklist) {
      let value = 0
      for (const i in this.state.checklist) {
        if (this.state.checklist[i]) { value += 1 }
      }

      data.value = `${value}/${Object.keys(this.state.checklist).length}`
    }

    delete data.showError
    delete data.errorMessage
    delete data.valueFormat
    delete data.isLoading
    delete data.image

    if (this.props.editData.id) {
      this.handleEditLog(data)
    } else {
      this.handleCreateLog(data)
    }
  }

  handleCreateLog = data => {
    data.metricId = this.props.activeMetric.id
    data.userId = this.props.clientId

    this.props.dispatch(createLog(data))
      .then((id) => {
        if (this.state.image) {
          const image = renameImage(this.state.image, id)

          uploadImage('records', image)
            .then(url => {
              this.props.dispatch(updateLog({ logId: id, data: { imageURL: url, imageName: image.name } }))
            })
        }

        this.success(data.metricId)
      })
      .catch(err => this.error(err.message))
  }

  handleEditLog = data => {
    this.props.dispatch(updateLog({ logId: this.props.editData.id, data }))
      .then(() => {
        if (this.state.image) {
          const image = renameImage(this.state.image, this.props.editData.id)

          uploadImage('records', image)
            .then(url => {
              this.props.dispatch(updateLog({ logId: this.props.editData.id, data: { imageURL: url, imageName: image.name } }))
            })
        }

        this.success(this.props.editData.metricId)
      })
      .catch(err => this.error(err.message))
  }

  handleDeleteLog = () => {
    this.setState({
      isLoading: 'delete'
    })

    this.props.dispatch(deleteLog(this.props.editData.id))
      .then(() => this.success(this.props.editData.metricId))
      .catch(err => this.error(err.message))
  }

  success = metricId => {
    this.props.onUpdateData(metricId)
    this.props.onCloseModal('displayAddLogModal')
  }

  error = err => {
    this.setState({
      isLoading: null,
      showError: true,
      errorMessage: err
    })
  }

  setChecklistDefault = () => {
    this.setState({
      checklist: this.props.editData.id ? this.props.editData.checklist : this.props.activeMetric.checklist
    })
  }

  render () {
    const { editData, activeMetric, activeMetricUnit } = this.props

    return (
      <>
        <form onSubmit={this.handleSubmit} className='modal_content'>
          <label htmlFor='date'>Date</label>
          <input
            type='date'
            name='date'
            defaultValue={moment(this.state.date.seconds, 'X').format('YYYY-MM-DD')}
            onChange={this.handleDate}
            required
          />

          {
            activeMetricUnit.type === 'TIME' &&
              <>
                <label htmlFor='value'>Value ({activeMetricUnit.unit})</label>
                <span className='metric_time_input'>
                  <Time
                    onChange={this.handleChange}
                    name='value'
                    value={this.state.value}
                    disabled={false}
                  />
                </span>
              </>
          }

          {
            activeMetricUnit.type === 'CHECKLIST' &&
            Object.keys(activeMetric.checklist).map((check, i) => (
              <span key={i} className='flex_row'>
                <input
                  type='checkbox'
                  name={check}
                  id={i}
                  onChange={this.handleChange}
                  defaultChecked={editData.checklist && editData.checklist[check]}
                />
                <label htmlFor={i}>{check}</label>
              </span>
            ))
          }

          {
            activeMetricUnit.graph_type === 'line' && activeMetricUnit.type !== 'CHECKLIST' && activeMetricUnit.type !== 'TIME' &&
              <>
                <label htmlFor='value'>Value ({activeMetricUnit.unit})</label>
                <input
                  type='text'
                  name='value'
                  defaultValue={this.state.value}
                  onChange={this.handleChange}
                  required
                />
              </>
          }
          {
            activeMetricUnit.type === 'RADIO' &&
              <>
                <p>{activeMetric.name}?</p>

                {
                  activeMetricUnit.graph_options.map((opt, i) => (
                    <span key={i}>
                      <input
                        type='radio'
                        id={opt}
                        name='value'
                        value={opt}
                        defaultChecked={editData.value === opt}
                        onChange={this.handleChange}
                      />
                      <label htmlFor={opt}>{opt}</label>
                    </span>
                  ))
                }
              </>
          }

          <span className='modal_image_form'>
            {
              this.props.editData.imageURL &&
                <span style={{ textAlign: 'center' }}>
                  <a href={this.props.editData.imageURL} target='_blank' rel='noopener noreferrer'>View Image</a>
                </span>
            }

            <span className='col'>
              <label htmlFor='image'>Attach Image</label>
              <input
                type='file'
                name='image'
                onChange={this.handleImage}
              />
            </span>
          </span>

          <label htmlFor='note'>Notes</label>
          <textarea
            name='note'
            defaultValue={this.state.note}
            onChange={this.handleChange}
          />

          <p className='ui_error'>{this.state.showError && this.state.errorMessage}</p>

        </form>
        <div className='modal_buttons'>
          {/* {
          editData.id &&
            <button
              type='button'
              className='button_warning'
              onClick={this.handleDeleteLog}
            >
              {this.state.isLoading === 'delete' ? <i className='fas fa-spinner fa-spin' /> : 'Delete Log'}
            </button>
        } */}
          <button
            onClick={this.handleSubmit}
            className='button_primary'
          >
            {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
          </button>
        </div>

      </>
    )
  }
}

export default connect()(LogMetricForm)
