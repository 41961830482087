import React from 'react'
import moment from 'moment'

import KebabMenu from '../../Common/KebabMenu'

const ProgramListItem = React.forwardRef((props, ref) => {
  return (
    <div
      className='program_list_item box'
      onClick={() => props.onSelect(props.id)}
      ref={ref}
    >
      <img className='image' src={props.header.image_url} />

      <p className='title'>{props.header.title}</p>

      <p className='type'>{props.header.program_type}</p>

      <div className='assigned'>
        {
          props.users.map((id, i) => {
            const user = props.clients.find(client => client.id === id)
            if (user !== undefined && i < 4) {
              return (
                <img
                  key={i}
                  src={user.img_url}
                  alt={user.first_name}
                  title={`${user.first_name} ${user.last_name}`}
                  style={{ marginLeft: `${i === 0 ? 0 : -10}px`, zIndex: `${5 - i}` }}
                />
              )
            }
          })
        }
        {
          props.users.length > 4 &&
            <label>{`+${props.users.length - 4} more`}</label>
        }
      </div>

      <img className='creatorImg' src={props.creatorImg} />

      <p className='creatorName'>{props.creatorName}
      </p>

      <p className='updated'>{props.lastUpdated ? moment(props.lastUpdated.seconds, 'X').fromNow() : 'Never'}</p>

      <span className='overflow'>

        <KebabMenu
          context={props.restricted ? 'user_program' : 'program'}
          owner={props.ownerId.includes(props.activeUserId) || props.activeUserType.includes('admin')}
          onSelect={() => props.onSelect(props.id)}
          onAssign={() => props.onAssign(props.id)}
          onUnassign={() => props.onUnassign(props.id)}
          onShare={() => props.onShare(props.id)}
          onEdit={() => props.onEdit(props.id)}
          onDelete={() => props.onDelete(props.id)}
          onCopy={() => props.onCopy(props.id)}
        />
      </span>

      {
        props.loading &&
          <div className='ui_loading_overlay'>
            <span>
              <i className='fas fa-spinner fa-lg fa-spin' />
            </span>
          </div>
      }
    </div>
  )
})

export default ProgramListItem
