import React from 'react'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  planItem: {
    minHeight: 200,
    padding: 12
  },
  activePlan: {
    outline: '2px solid black'
  },
  priceButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

const PlanBox = ({
  productId,
  name,
  description,
  prices,
  activeSubscription,
  loadingCheckout,
  onSelectPlan,
  sm = 4
}) => {
  const classes = useStyles()

  return (
    <Grid item sm={sm} xs={12}>
      <Paper className={`${classes.planItem} ${activeSubscription?.product.id === productId && classes.activePlan}`}>
        <Typography variant='h6'>
          {name}
        </Typography>
        <Typography>{description}</Typography>
      </Paper>

      {prices ? (
        prices.map(price => (
          <Button
            key={price.id}
            variant='outlined'
            fullWidth
            onClick={() => onSelectPlan({ productId, priceId: price.id })}
            disabled={activeSubscription?.price.id === price.id}
            endIcon={loadingCheckout === price.id &&
              <i
                className='fas fa-spinner fa-spin'
                style={{ marginLeft: 6 }}
              />}
          >
            {`${activeSubscription?.price.id === price.id ? 'Current Plan\n' : ''} $${price.unit_amount / 100} / ${price.interval}`}
          </Button>
        ))
      ) : (
        <Button
          variant='outlined'
          fullWidth
          disabled={!activeSubscription}
          onClick={onSelectPlan}
        >
          {!activeSubscription ? 'Current Plan' : 'Free'}
        </Button>
      )}
    </Grid>
  )
}

export default PlanBox
