import TextField from '@material-ui/core/TextField'
import React, { Component } from 'react'
import { fb } from '../../config/firebase'

class FinaliseAccount extends Component {
  state = {
    showError: false,
    errorMessage: '',
    success: false,
    isLoading: null
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      showError: false
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState({
      isLoading: 'submit'
    })

    if (this.state.new_password !== this.state.confirm_password) {
      this.error('Passwords do not match')
      return
    }

    fb.functions().httpsCallable('changePassword')({ uid: this.props.uid, password: this.state.confirm_password })
      .then(res => {
        if (res) {
          this.setState({
            success: true,
            isLoading: null
          })
        }
      })
      .catch(err => this.error(err))
  }

  error = err => {
    this.setState({
      showError: true,
      errorMessage: err,
      isLoading: null
    })
  }

  render () {
    return (
      <div className='container_col'>
        <div className='ui_oni_bar'>
          <img src='/img/Logo-Colour-White-small.png' />
        </div>

        {
          !this.state.success &&
            <div className='login'>

              <span className='text'>
                <h1 style={{ textAlign: 'center' }}>WELCOME.</h1>
                <p>To complete your account setup, please set a password and then download the app to log in.</p>
              </span>

              <form onSubmit={this.handleSubmit}>
                <TextField
                  name='new_password'
                  label='New Password'
                  type='password'
                  onChange={this.handleChange}
                  required
                />

                <TextField
                  name='confirm_password'
                  label='Confirm new password'
                  type='password'
                  onChange={this.handleChange}
                  required
                />

                <p className='ui_error'>{this.state.showError && this.state.errorMessage}</p>

                <span className='ui_button_bar'>
                  <button
                    className='button_primary'
                    onClick={this.handleSubmit}
                  >
                    {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Save new password'}
                  </button>
                </span>
              </form>
            </div>
        }

        {
          this.state.success &&
            <div className='login'>

              <span style={{ width: 25 + 'vw', position: 'relative', bottom: 100 + 'px' }}>
                <h1 style={{ textAlign: 'center' }}>Setup complete.</h1>
                <p>Please download the ONI app from one of the links below</p>
              </span>

              <a
                href='https://play.google.com/store/apps/details?id=nz.co.oni&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className='ui_store_badge' />
              </a>

              <a
                href='https://apps.apple.com/us/app/id1521189172'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  alt='Download on the App Store'
                  src='https://images.squarespace-cdn.com/content/v1/592944cb86e6c0040d3c3d27/1598831819442-8VGE3648DF2EGDGK0R5D/ke17ZwdGBToddI8pDm48kBsyi-COFZU5C_z5vF24gIBZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpyep3vS4dziTU_5J2OvIW1WCvr1zec-iWR68JdT1x4znmbL57nk6gydGjHHH8qmiBc/image-asset.jpeg?format=2500w'
                  style={{ width: 180 + 'px', height: 'auto', margin: 20 + 'px' }}
                />
              </a>
            </div>
        }

      </div>

    )
  }
}

export default FinaliseAccount
