import React, { useState } from 'react'

import ProgramForm from '../List/ProgramForm'
import ModalWrapper from '../../Common/ModalWrapper'
import ImageGallery from '../../Common/ImageGallery'

const ProgramDetails = props => {
  const [image, setImage] = useState((props.activeProgram?.header.image_url) || 'https://firebasestorage.googleapis.com/v0/b/oni-nutrition-test.appspot.com/o/images%2Fexercises%2FK1S_4571Kelvin.jpg?alt=media&token=f69727ed-beb1-431c-aa13-783350e71c2d') // eslint-disable-line

  const [selectImage, toggleSelectImage] = useState(false)

  const handleSelectImage = e => {
    setImage(e.target.value)
    toggleSelectImage(false)
  }

  return (
    <div className='row' style={{ margin: 1 + 'rem' }}>
      <div className='modal' style={{ zIndex: 1 }}>
        <img
          src={image}
          className='modal_img'
        />

        <span className='button_edit_image' onClick={() => toggleSelectImage(true)}>
          <i className='fas fa-pencil-alt' />
        </span>

        <div className='modal_wrapper'>
          <span className='modal_header' style={{ flex: 0 }}>
            <h3>Program Details</h3>
          </span>

          <ProgramForm
            activeProgram={props.activeProgram}
            activeProgramId={props.activeProgram.id}
            // clientId={props.clientId}
            history={props.history}
            selectedImage={image}
          />

          <ModalWrapper
            showModal={selectImage}
            onCloseModal={() => toggleSelectImage(false)}
            header='Select Image'
          >
            <div className='modal_content'>
              <ImageGallery
                show={selectImage}
                onChange={handleSelectImage}
                path={props.activeProgram?.header.program_type === 'Nutrition' ? '/images/nutrition/' : '/images/exercises/'} // eslint-disable-line
              />
            </div>

          </ModalWrapper>

        </div>
      </div>
    </div>
  )
}

// function mapStateToProps (globalState, ownProps) {
//   const program = globalState.programs.find(program => program.id === ownProps.programId)

//   const header = program.header
//   header.ownerId = program.ownerId

//   return {
//     users: program.users,
//     programId: program.id,
//     data: header
//   }
// }

export default ProgramDetails
