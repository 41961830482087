import React, { useState, useEffect, useRef } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList as List } from 'react-window'
import DiaryItem from './DiaryItem'

const ProfileRecordsList = props => {
  const listRef = useRef({})
  const rowHeights = useRef({})
  const [list, setList] = useState([])

  useEffect(() => {
    setList(props.records)
  }, [props.records])

  function getRowHeight (index) {
    return rowHeights.current[index] || 58
  }

  const row = ({ index, style }) => {
    const rowRef = useRef({})

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight)
      }
    }, [rowRef])

    return (
      <div key={index} style={style}>
        <DiaryItem
          key={list[index].id}
          {...list[index]}
          index={index}
          ref={rowRef}
          globalExercises={props.exercises}
          expandItem={setRowHeight}
          category='exercise'
          onSelect={(category, item) => props.onModal(item || list[index])}
          onDelete={() => props.onDelete(list[index].id)}
        />
      </div>
    )
  }

  const setRowHeight = (index, size) => {
    listRef.current.resetAfterIndex(0)
    rowHeights.current = { ...rowHeights.current, [index]: size }
  }

  return (
    <>
      <div className='user_diary_item' style={{ height: 57 + 'px', fontWeight: 'bold' }}>
        <p className='date'>Date</p>
        <p className='type'>Type</p>
        <p className='value'>Value</p>
        <p className='note'>Note</p>
      </div>
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            itemCount={list.length}
            itemSize={getRowHeight}
            ref={listRef}
            width={width}
          >
            {row}
          </List>
        )}
      </AutoSizer>
    </>
  )
}

export default ProfileRecordsList
