import React, { useState, useEffect } from 'react'
import { storage } from '../../config/firebase'

const ImageGallery = props => {
  const defaultIndex = 12
  const [imgUrls, setImgUrls] = useState([])
  const [imgIndex, setImgIndex] = useState(defaultIndex)
  const [pageToken, setPageToken] = useState(null)

  useEffect(() => {
    getImages()
  }, [props.path])

  const getImages = token => {
    const storageRef = storage.ref(`${props.path}thumbs`)

    storageRef.list({ maxResults: defaultIndex, pageToken: token })
      .then(res => {
        setPageToken(res.nextPageToken)

        let urls = []
        if (token) urls = [...imgUrls]

        const requests = res.items.map(item => {
          return item.getDownloadURL()
            .then(url => {
              const master = item.name.replace('thumb_', '')

              return urls.push({ thumb: url, master: `${props.path}${master}` })
            })
        })

        Promise.all(requests)
          .then(() => {
            setImgUrls(urls)
          })
      })
  }

  const changeImagePage = direction => {
    if (direction === 'right') {
      if (imgIndex === imgUrls.length && pageToken) {
        getImages(pageToken)
      }

      setImgIndex(imgIndex + defaultIndex)
    }

    if (direction === 'left') {
      setImgIndex(imgIndex - defaultIndex)
    }
  }

  const handleSelectImage = path => {
    storage.ref().child(path).getDownloadURL()
      .then(url => {
        const e = {
          target: {
            name: 'image_url',
            value: url
          }
        }

        props.onChange(e)
      })
  }

  return (
    <div>
      {
        props.show &&
          <>
            <div className='ui_image_gallery'>
              {
                imgUrls.map((img, i) => {
                  if (i < imgIndex && i >= imgIndex - defaultIndex) {
                    return (
                      <img
                        key={i}
                        src={img.thumb}
                        onClick={() => handleSelectImage(img.master)}
                      />
                    )
                  }
                })
              }
            </div>

            <div className='ui_button_bar'>
              <button
                type='button'
                className='button_navigational'
                disabled={imgIndex === defaultIndex}
                onClick={() => changeImagePage('left')}
              >
                <i className='fas fa-chevron-left' />
              </button>

              <button
                type='button'
                className='button_navigational'
                disabled={(imgIndex - imgUrls.length >= 0) && !pageToken}
                onClick={() => changeImagePage('right')}
              >
                <i className='fas fa-chevron-right' />
              </button>
            </div>
          </>
      }
    </div>
  )
}

export default ImageGallery
