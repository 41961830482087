import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList as List } from 'react-window'

import { deleteNote } from '../../actions/notes'
import Filter from '../Common/Filter'

import ModalWrapper from '../Common/ModalWrapper'
import LogNoteForm from './LogNoteForm'
import NoteItem from './NoteItem'

const ProfileNotes = props => {
  const listRef = useRef({})
  const rowHeights = useRef({})
  const [list, setList] = useState([])
  const [editData, setEditData] = useState({})

  useEffect(() => {
    setList(props.notes)
  }, [props.notes])

  function getRowHeight (index) {
    return rowHeights.current[index] + 9 || 82
  }

  const setRowHeight = (index, size) => {
    listRef.current.resetAfterIndex(index)
    rowHeights.current = { ...rowHeights.current, [index]: size }
  }

  const row = ({ index, style }) => {
    const rowRef = useRef({})

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight)
      }
    }, [rowRef])

    return (
      <div key={index} style={style} onClick={() => handleSelect(list[index])}>
        <NoteItem
          key={list[index.id]}
          {...list[index]}
          index={index}
          ref={rowRef}
          onDelete={() => handleDelete(list[index].id)}
          onSelect={() => handleSelect(list[index])}
        />
      </div>
    )
  }

  const handleSelect = note => {
    setEditData(note)
    props.onModal()
  }

  const handleModal = () => {
    setEditData({})
    props.onModal()
  }

  const handleDelete = id => {
    props.dispatch(deleteNote(id))
      .then(() => {
        setList(list.filter(note => note.id !== id))
      })
  }

  return (
    <div className='user_profile_container' style={{ flex: 1 }}>
      <h1>Notes</h1>

      <Filter
        type='notes'
        data={props.notes}
        onChange={({ data }) => setList(data.sort((a, b) => b.date.seconds - a.date.seconds))}
      />

      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            itemCount={list.length}
            itemSize={getRowHeight}
            ref={listRef}
            width={width}
          >
            {row}
          </List>
        )}
      </AutoSizer>

      <ModalWrapper
        modalHeader={editData.id ? 'Edit note' : 'Create note'}
        showModal={props.activeModal}
        onCloseModal={handleModal}
      >
        <LogNoteForm
          clientId={props.clientId}
          onCloseModal={handleModal}
          editData={editData}
        />
      </ModalWrapper>
    </div>
  )
}

function mapStateToProps (globalState, ownProps) {
  return {
    notes: globalState.notes
  }
}

export default connect(mapStateToProps)(ProfileNotes)
