import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createExercise, editExercise } from '../../actions/exercises'
import { fb } from '../../config/firebase'
import { processYoutubeURL } from '../../util'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import CustomSelect from '../Common/CustomSelect'
import ExerciseTagSelectors from '../Util/ExerciseTagSelectors'

class ExerciseForm extends Component {
  defaultIndex = 16

  state = {
    isLoading: null,
    selectImage: false,
    metrics: [],
    tag_movement: [],
    tag_muscle: []
  }

  componentDidMount () {
    if (this.props.selectedExercise) {
      this.setState({
        metrics: this.props.selectedExercise.metrics || [],
        tag_movement: this.props.selectedExercise.tag_movement || [],
        tag_muscle: this.props.selectedExercise.tag_muscle || []
      })
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      selectImage: e.target.name === 'image_url' ? false : this.state.selectImage
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState({
      isLoading: 'submit'
    })

    const exercise = { ...this.state }
    delete exercise.isLoading
    delete exercise.selectImage

    if (exercise.video_url) {
      exercise.video_url = processYoutubeURL(exercise.video_url)

      exercise.thumbnail_url = `https://img.youtube.com/vi/${exercise.video_url}/mqdefault.jpg`
    }

    if (this.props.selectedExercise) {
      this.editExercise(exercise)
    } else {
      this.createExercise(exercise)
    }
  }

  createExercise = exercise => {
    exercise.ownerId = [this.props.activeUserId, 'global']
    exercise.sections = []
    exercise.dateCreated = new fb.firestore.Timestamp.now() // eslint-disable-line

    this.props.dispatch(createExercise(exercise))
      .then(({ exercise }) => {
        if (this.props.onAssignExercise) {
          this.props.onAssignExercise({ items: [exercise.id], type: 'Exercise' })
        }
        this.success()
      })
      .catch(err => this.error(err))
  }

  editExercise = exercise => {
    exercise.lastUpdated = new fb.firestore.Timestamp.now() //eslint-disable-line
    this.props.dispatch(editExercise(this.props.selectedExercise.id, exercise))
      .then(() => this.success())
      .catch(err => this.error(err))
  }

  success = () => {
    if (this.props.onUpdateList) this.props.onUpdateList()
    this.props.onCloseModal()
    this.props.onSuccess()
  }

  error = err => {
    this.setState({
      isLoading: null,
      showError: true,
      errorMessage: err.message
    })
  }

  render () {
    return (
      <>
        <form
          onSubmit={this.handleSubmit}
          className='modal_content'
          // style={{ overflowY: 'visible'}}
        >
          <TextField
            name='name'
            label='Exercise Name'
            onChange={this.handleChange}
            defaultValue={this.props.selectedExercise?.name}
            required
          />

          <TextField
            name='desc'
            label='Description'
            defaultValue={this.props.selectedExercise?.desc}
            onChange={this.handleChange}
            multiline
            rows={5}
          />

          <ExerciseTagSelectors
            onChange={this.handleChange}
            dataMovement={this.state.tag_movement} // eslint-disable-line
            dataMuscle={this.state.tag_muscle} // eslint-disable-line
          />

          <CustomSelect
            name='metrics'
            label='Default Metric'
            onChange={this.handleChange}
            value={this.state.metrics || []}
            multiple
            required
          >
            {
              this.props.exerciseMetrics.map(metric => (
                <MenuItem key={metric.id} value={metric.id}>
                  {metric.name}
                </MenuItem>
              ))
            }
          </CustomSelect>

          <TextField
            name='video_url'
            label='Youtube Link'
            onChange={this.handleChange}
            defaultValue={this.props.selectedExercise?.video_url && 'https://www.youtube.com/watch?v=' + this.props.selectedExercise.video_url} // eslint-disable-line
          />

        </form>

        <div className='modal_buttons'>
          {
            this.props.onChangePage &&
              <button
                className='button_navigational'
                type='button'
                onClick={this.props.onChangePage}
              >
                <span className='row'>
                  <i className='fas fa-chevron-left' />
            Back
                </span>
              </button>
          }

          <button
            className='button_primary'
            onClick={this.handleSubmit}
            disabled={(!this.props.selectedExercise && this.state.name === undefined) || this.state.name === ''}
          >
            {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
          </button>
        </div>
      </>
    )
  }
}

function mapStateToProps (globalState) {
  const exerciseMetrics = globalState.exerciseMetrics.sort((a, b) => a.name.localeCompare(b.name))

  return {
    activeUserId: globalState.user.auth.uid,
    activeUserName: globalState.user.auth.displayName,
    exerciseMetrics
  }
}

export default connect(mapStateToProps)(ExerciseForm)
