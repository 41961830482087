import React from 'react'
import { ResponsivePie } from '@nivo/pie'

const colors = { Green: '#36EF41', Yes: '#36EF41', Pass: '#36EF41', Yellow: '#EF9D36', Red: '#EF4136', No: '#EF4136', Fail: '#EF4136' }
const getColor = wedge => {
  return colors[wedge.id]
}

const PieGraph = (props) => (
  <ResponsivePie
    data={props.data}
    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
    padAngle={2}
    cornerRadius={3}
    colors={getColor}
    borderWidth={1}
    borderColor={{ from: 'color', modifiers: [['darker', '0']] }}
    innerRadius={0.5}
    radialLabelsSkipAngle={10}
    radialLabelsTextXOffset={6}
    radialLabelsTextColor='#333333'
    radialLabelsLinkOffset={0}
    radialLabelsLinkDiagonalLength={16}
    radialLabelsLinkHorizontalLength={24}
    radialLabelsLinkStrokeWidth={1}
    radialLabelsLinkColor={{ from: 'color' }}
    slicesLabelsSkipAngle={10}
    slicesLabelsTextColor='#333333'
    animate
    motionStiffness={90}
    motionDamping={15}
  />
)

export default PieGraph
