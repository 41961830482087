import React, { useState } from 'react'

const ImageMenu = props => {
  const [url, setUrl] = useState('')
  let mouseout = null

  const handleMenu = () => {
    mouseout = setTimeout(() => {
      props.onClose()
    }, 500)
  }

  const handleSubmit = e => {
    e.preventDefault()

    props.onChangeImage(url)
    props.onClose()
  }

  return (
    <form
      className={`lesson_image_menu ${props.visible ? undefined : 'hidden'}`}
      style={{ left: props.left, top: props.top }}
      onMouseLeave={handleMenu}
      onMouseEnter={() => clearTimeout(mouseout)}
      onSubmit={handleSubmit}
    >
      <input
        type='text'
        placeholder='Enter URL'
        // defaultValue={props.data.imageURL}
        onChange={e => setUrl(e.target.value)}
      />

      <input
        type='submit'
        value='Submit'
      />
    </form>
  )
}

export default ImageMenu
