import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { makeStyles } from "@material-ui/styles";

import Details from "./Details";
import Plan from "./Plan";
import History from "./History";
import { getClientSubscription, editClient } from "../../../actions/clients";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 500,
    width: "100%",
  },
  content: {
    paddingTop: 24,
  },
}));

const UserDetails = ({ open, onClose, userDetails }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const handleTab = (e, value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    if (userDetails.id && !userDetails.subscription) {
      dispatch(getClientSubscription(userDetails.id));
    }
  }, [userDetails.id]);

  const updateClient = (clientId, data) => {
    dispatch(editClient({ clientId, client: data }));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        classes: { root: classes.container },
      }}
    >
      <DialogTitle>{`${userDetails.first_name} ${userDetails.last_name}`}</DialogTitle>
      <Tabs value={activeTab} onChange={handleTab}>
        <Tab value={0} label="Details" />
        <Tab value={1} label="Plan" />
        {userDetails.stripeLink && <Tab value={2} label="Payment History" />}
      </Tabs>

      <DialogContent className={classes.content}>
        {activeTab === 0 && (
          <Details
            userDetails={userDetails}
            changeTab={(val) => setActiveTab(val)}
            updateClient={updateClient}
          />
        )}

        {activeTab === 1 && <Plan userDetails={userDetails} />}

        {activeTab === 2 && <History />}
      </DialogContent>
    </Dialog>
  );
};

export default UserDetails;
