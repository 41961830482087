import { LOGIN_USER } from '../actions/user'

const initialState = {
  auth: {
    uid: null
  },
  info: {
    config: {
      type: null
    }
  }
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        auth: action.user,
        info: action.info
      }
    case 'ADD_SUBSCRIPTION':
      state.info.subscription = action.subscription
      return state
    default:
      return state
  }
}

export default user
