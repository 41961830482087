import { alphabetiseNames } from '../util'
import {
  RECEIVE_CLIENTS,
  CREATE_CLIENT,
  EDIT_CLIENT,
  ASSIGN_COACH,
  ASSIGN_CLIENT,
  UNASSIGN_CLIENT,
  DELETE_CLIENT,
  GET_SUBSCRIPTIONS,
  FREE_PRO,
  REMOVE_FREE_PRO
} from '../actions/clients'

const initialState = []

const clients = (state = initialState, action) => {
  const clientIndex = state.indexOf(state.find(client => client.id === action.clientId))

  switch (action.type) {
    case RECEIVE_CLIENTS:
      return action.clients

    case CREATE_CLIENT:
      return alphabetiseNames([...state, action.client])

    case EDIT_CLIENT:
      state[clientIndex] = Object.assign(state[clientIndex], action.client)

      return state

    case ASSIGN_COACH: {
      state[clientIndex].trainers = action.trainers

      return state
    }

    case ASSIGN_CLIENT: {
      state[clientIndex].trainers.push(action.trainerId)

      return state
    }

    case UNASSIGN_CLIENT: {
      state[clientIndex].trainers = state[clientIndex].trainers.filter(trainer => trainer !== action.trainerId)

      return state
    }

    case DELETE_CLIENT:
      return state.filter(client => client.id !== action.userId)

    case GET_SUBSCRIPTIONS: {
      state[clientIndex].subscription = action.subscription

      return state
    }

    case FREE_PRO: {
      state[clientIndex].freePro = true

      return state
    }

    case REMOVE_FREE_PRO: {
      state[clientIndex].freePro = false

      return state
    }

    default:
      return state
  }
}

export default clients
