import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import ComponentMenu from '../BuildingBlock/ComponentMenu'

import Parse from '../BuildingBlock/Parse'
import { defaultText } from '../BuildingBlock/Text'
import { defaultImage } from '../BuildingBlock/Image'
import { defaultImageOverlay } from '../BuildingBlock/ImageTextOverlay'
import { defaultVideo } from '../BuildingBlock/Video'
import { defaultTextInput } from '../BuildingBlock/TextInput'
import { defaultCheckbox } from '../BuildingBlock/Checkbox'
import { defaultRadio } from '../BuildingBlock/Radio'
import ImageMenu from '../BuildingBlock/ImageMenu'

const Page = props => {
  const [activeMenu, setActiveMenu] = useState(null)
  const [activeComponent, setActiveComponent] = useState(null)
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)

  const handleMenu = ({ componentId, top, left, menu }) => {
    if (activeMenu !== null) {
      setActiveMenu(null)

      return
    }

    if (top && left) {
      setTop(top)
      setLeft(left - (props.drawer ? 250 : 50) + 20)
      setActiveComponent(componentId)
    }

    setActiveMenu(menu)
  }

  const handleAddComponent = type => {
    handleMenu({})

    const component = {
      text: defaultText,
      image: defaultImage,
      image_text_overlay: defaultImageOverlay,
      video: defaultVideo,
      text_input: defaultTextInput,
      checkbox: defaultCheckbox,
      radio: defaultRadio
    }

    const newComponent = _.cloneDeep(component[type])

    newComponent.componentId = uuid()

    props.onAddComponent({ pageId: props.pageId, component: newComponent, below: activeComponent })
  }

  const handleDeleteComponent = id => {
    props.onDeleteComponent({ pageId: props.pageId, componentId: id })
  }

  const handleChangeImage = (url) => {
    const component = props.content.find(c => c.componentId === activeComponent)

    component.data.imageURL = url

    props.onEditComponent({ pageId: props.pageId, componentId: props.componentId, updatedComponent: component })
  }

  const handleChangeComponent = (e, componentId) => {
    const component = props.content.find(c => c.componentId === componentId)

    component.data[e.target.name] = e.target.value

    props.onEditComponent({ pageId: props.pageId, componentId, updatedComponent: component })
  }

  return (
    <div className='lesson_page_container'>
      {
        props.content.map((content, i) => {
          content.onMenu = handleMenu
          content.index = i
          content.onChange = handleChangeComponent
          content.onDelete = handleDeleteComponent

          return (
            Parse(content)
          )
        })
      }

      <ComponentMenu
        visible={activeMenu === 'component'}
        top={top}
        left={left}
        onClick={handleAddComponent}
        onClose={() => setActiveMenu(null)}
      />

      <ImageMenu
        visible={activeMenu === 'image'}
        top={top}
        left={left}
        onChangeImage={handleChangeImage}
        activeComponent={activeComponent}
        image={props.image}
        onClose={() => setActiveMenu(null)}
      />
    </div>
  )
}

export default Page
