import React, { useState } from 'react'

import KebabMenu from '../Common/KebabMenu'
import ModalAssignCoach from './ModalAssignCoach'
import ModalAssignClient from './ModalAssignClient'

const ProfileCard = React.forwardRef((props, ref) => {
  const [overflow, toggleOverflow] = useState('ontouchstart' in document.documentElement)
  const [assignTrainer, toggleAssignTrainer] = useState(false)
  const [assignClient, toggleAssignClient] = useState(false)

  const user = props.user
  return (
    <div
      className='box'
      onMouseEnter={() => toggleOverflow(true)}
      onMouseLeave={() => toggleOverflow(false)}
      ref={ref}
    >

      <div className='user_card' onClick={() => props.onSelect(user.id)}>
        <img className='user_profile_img' src={user.img_url} />
        <span>
          <p>{user.first_name} {user.last_name}</p>
          <p style={{ fontSize: 15 + 'px' }}>{user.email}</p>
        </span>

        {
          overflow &&
            <KebabMenu
              onEdit={() => props.onEdit(user)}
              onAssignCoach={() => toggleAssignTrainer(true)}
              onAssignClient={() => toggleAssignClient(true)}
              context='profileCard'
              userType={user.type} // The type of user the card belongs to
              activeUserType={props.activeUserType}
            />
        }
      </div>

      {
        props.activeUserType.includes('admin') &&
          <>
            {
              user.type.includes('client') &&
                <ModalAssignCoach
                  showModal={assignTrainer}
                  onCloseModal={() => toggleAssignTrainer(false)}
                  assignedTrainers={user.trainers}
                  clientId={user.id}
                />
            }

            {
              user.type.includes('trainer') &&
                <ModalAssignClient
                  showModal={assignClient}
                  onCloseModal={() => toggleAssignClient(false)}
                  assignedClients={user.clients}
                  trainerId={user.id}
                />
            }
          </>
      }
    </div>
  )
})

export default ProfileCard
