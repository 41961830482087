import {
  RECEIVE_LESSONS,
  CREATE_LESSON,
  EDIT_LESSON,
  DELETE_LESSON,
  ASSIGN_LESSON,
  UNASSIGN_LESSON
} from '../actions/lessons'

const initialState = []

const lessons = (state = initialState, action) => {
  const index = state.findIndex(lesson => lesson.id === action.lessonId)

  switch (action.type) {
    case RECEIVE_LESSONS:
      return action.lessons

    case CREATE_LESSON:
      return [action.lesson, ...state]

    case EDIT_LESSON: {
      state[index] = Object.assign(state[index], action.lesson)

      return state
    }

    case DELETE_LESSON:
      state.splice(index, 1)
      return state

    case ASSIGN_LESSON:
      state[index].users = action.users

      return state

    case UNASSIGN_LESSON:
      state[index].users = state[index].users.filter(u => u !== action.userId)

      return state

    default:
      return state
  }
}

export default lessons
