import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { assignClient, unassignClient } from '../../actions/clients'
import UserSelect from '../Util/UserSelect'

class ModalAssignClient extends Component {
  state = {
    currentClients: [],
    updatedClients: {},
    showError: false,
    errorMessage: '',
    isLoading: null
  }

  componentDidMount () {
    this.getAssignedClients()
  }

  getAssignedClients = () => {
    const assigned = this.props.clients.reduce((res, client) => {
      if (client.trainers.includes(this.props.trainerId)) {
        res.push(client.id)
      }
      return res
    }, [])

    this.setState({
      currentClients: assigned,
      updatedClients: []
    })
  }

  handleChange = e => {
    this.setState({
      updatedClients: e
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    // this.setState({
    //   isLoading: 'submit'
    // })

    const toRemove = this.state.currentClients.filter(user => !this.state.updatedClients.includes(user))
    const toAdd = this.state.updatedClients.filter(user => !this.state.currentClients.includes(user))

    toRemove.forEach(user => {
      this.props.dispatch(unassignClient({ clientId: user, trainerId: this.props.trainerId }))
        // .then(() => this.success())
        .catch(err => this.error(err))
    })

    toAdd.forEach(user => {
      this.props.dispatch(assignClient({ clientId: user, trainerId: this.props.trainerId }))
        // .then(() => this.success())
        .catch(err => this.error(err))
    })

    this.props.onCloseModal()
  }

  success = e => {
    this.props.onCloseModal()
  }

  error = err => {
    this.setState({
      showError: true,
      errorMessage: err.message,
      isLoading: null
    })
  }

  render () {
    return (
      <Modal
        isOpen={this.props.showModal}
        onRequestClose={this.props.onCloseModal}
        className='modal'
        overlayClassName='modal_overlay'
      >

        <div className='modal_wrapper'>
          <span className='modal_header'>
            <h3>Assign client</h3>
            <i className='fas fa-times' onClick={this.props.onCloseModal} />
          </span>

          <UserSelect
            users={this.props.clients}
            checkedUsers={this.state.currentClients}
            onChange={this.handleChange}
          />

          <p className='ui_error'>{this.state.showError && this.state.errorMessage}</p>

          <span className='modal_buttons'>
            <button
              onClick={this.handleSubmit}
              className='button_primary'
            >
              {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
            </button>
          </span>
        </div>

      </Modal>
    )
  }
}

function mapStateToProps (globalState) {
  return {
    clients: globalState.clients
  }
}

export default connect(mapStateToProps)(ModalAssignClient)
