import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, isAuth, Render, ...rest }) => {
  return (
    <>
      {!Render && (
        <Route
          {...rest}
          render={(routerProps) =>
            isAuth ? (
              <Component {...routerProps} />
            ) : (
              <Redirect to={{ pathname: "/" }} />
            )
          }
        />
      )}
      {Render && <Render />}
    </>
  );
};

export default ProtectedRoute;
