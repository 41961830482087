import React from 'react'
import KebabMenu from '../Common/KebabMenu'
import { Draggable } from 'react-beautiful-dnd'

const ProgramItem = props => {
  return (
    <Draggable draggableId={props.id} index={props.index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className='program_list_item_small box'
        >
          <i
            className='fas fa-grip-vertical grip handle'
            style={props.groupLength === 1 ? { display: 'none' } : undefined}
            {...provided.dragHandleProps}
          />

          <img className='image' src={props.header.image_url} />

          <p className='title'>{props.header.title}</p>

          <span className='overflow'>
            <KebabMenu
              context='delete'
              onDelete={props.onDelete}
            />
          </span>
        </div>
      )}
    </Draggable>
  )
}

export default ProgramItem
