import { db } from '../config/firebase'

export const RECEIVE_COACHES = 'RECEIVE_COACHES'

export const receiveCoaches = () => {
  return dispatch => {
    return db.collection('users').where('type', 'array-contains', 'trainer')
      .get()
      .then(querySnapshot => {
        const coaches = []
        querySnapshot.forEach(snap => {
          const data = snap.data()
          coaches.push({
            first_name: data.first_name,
            last_name: data.last_name,
            id: snap.id
          })
        })

        return dispatch({
          type: RECEIVE_COACHES,
          coaches
        })
      })
  }
}
