import React, { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import KebabMenu from '../../Common/KebabMenu'
import ModalWrapper from '../../Common/ModalWrapper'
import EditFood from './EditFood'

const FoodItem = props => {
  const [showOverflow, toggleShowOverflow] = useState('ontouchstart' in document.documentElement)
  const [showModal, toggleModal] = useState(false)

  const selected = props.selected.find(item => item.itemKey === props.itemKey)

  const handleSelectExercise = () => {
    const item = {
      itemKey: props.itemKey,
      groupId: props.groupId
    }

    let newGroup = [...props.selected]
    if (selected === undefined) newGroup.push(item)
    else newGroup = newGroup.filter(nGroup => nGroup.itemKey !== item.itemKey)

    props.onSelectItem(newGroup)
  }

  return (
    <Draggable draggableId={props.itemKey} index={props.item.index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}

        >
          <div
            className='program_section_item'
            onMouseEnter={() => toggleShowOverflow(true)}
            onMouseLeave={() => toggleShowOverflow(false)}
            style={selected ? { backgroundColor: '#DAE1F3' } : undefined}
          >
            <i
              className='fas fa-grip-vertical grip handle' {...provided.dragHandleProps}
            />

            {
              selected &&
                <span className='check' onClick={handleSelectExercise}>
                  <i className='fas fa-check' />
                </span>
            }

            {
              !selected &&
                <img
                  src={props.itemDetails.image_url}
                  onClick={handleSelectExercise}
                />
            }

            {
              showOverflow &&
                <span className='overflow'>
                  <KebabMenu
                    context={props.groupLength > 1 ? 'section_grouped_item' : 'edit_delete'}
                    onAddHeader={props.onAddHeader}
                    onEdit={() => toggleModal(true)}
                    onDelete={() => props.onUnassignItem(props.itemKey, props.sectionId)}
                    onUngroup={() => props.onRemoveFromGroup([props.itemKey], props.groupId)}
                  />
                </span>
            }

            <p className='header'>{props.itemDetails.name}</p>

            <div className='content'>
              <span className='container'>
                <label htmlFor='portion'>Portion</label>
                <span className='boxes'>
                  <input
                    type='text'
                    name='portion'
                    value={props.item.portion}
                    onChange={e => props.onChange({ e, itemKey: props.itemKey })}
                  />
                </span>
              </span>

              <label>{props.item.notes}</label>
            </div>
          </div>

          <ModalWrapper
            modalHeader={`Edit ${props.itemDetails.name || 'Item'}`}
            video_url={props.itemDetails.video_url}
            showModal={showModal}
            onCloseModal={() => toggleModal(false)}
          >

            <EditFood
              item={props.item}
              itemDetails={props.itemDetails}
              sectionId={props.sectionId}
              onChange={props.onChange}
              onCloseModal={() => toggleModal(false)}
            />
          </ModalWrapper>

        </div>

      )}
    </Draggable>

  )
}

export default FoodItem
