import { db } from '../config/firebase'

export const RECEIVE_EXERCISE_METRICS = 'RECEIVE_EXERCISE_METRICS'

export const receiveExerciseMetrics = () => {
  return dispatch => {
    return db.collection('exerciseMetrics').get()
      .then(querySnapshot => {
        const exerciseMetrics = []

        querySnapshot.forEach(snap => {
          exerciseMetrics.push({ ...snap.data(), id: snap.id })
        })

        return dispatch({
          type: RECEIVE_EXERCISE_METRICS,
          exerciseMetrics: exerciseMetrics.sort((a, b) => a.name.localeCompare(b.name))
        })
      })
  }
}
