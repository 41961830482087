import React, { Component } from 'react'
import { connect } from 'react-redux'

class AssignExistingFood extends Component {
  state = {
    food: []
  }

  handleChange = e => {
    let food = [...this.state.food]

    if (e.target.checked) {
      food.push(e.target.value)
    } else {
      food = food.filter(f => f !== e.target.value)
    }

    this.setState({
      food
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    this.props.onAssignFood({ items: this.state.food, type: 'Food' })
    this.props.onCloseModal()
  }

  render () {
    return (
      <>
        <form onSubmit={this.handleSubmit} className='modal_content'>
          {
            this.props.food.map(food => {
              if (food.name.match(this.props.searchFilter)) {
                return (

                  <label htmlFor={food.id} key={food.id}>
                    <div
                      className='program_section_item'
                      style={{ paddingBottom: 0, margin: 0 }, this.state.food.includes(food.id) ? { backgroundColor: 'rgb(218, 225, 243)' } : undefined} // eslint-disable-line
                    >
                      <input
                        type='checkbox'
                        name={food.name}
                        value={food.id}
                        id={food.id}
                        onChange={this.handleChange}
                        defaultChecked={this.state.food.includes(food.id)}
                      />

                      <img src={food.image_url} />

                      <p className='header'>{food.name}</p>
                    </div>
                  </label>
                )
              }
            })
          }
        </form>

        <div className='modal_buttons'>
          <button
            className='button_primary'
            onClick={this.handleSubmit}
          >
            {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : `Add Food (${this.state.food.length}) to Plan`}
          </button>

          <button
            className='button_navigational'
            type='button'
            onClick={this.props.onChangePage}
          >
            <span className='row'>
            Add new food
              <i className='fas fa-chevron-right' />
            </span>
          </button>
        </div>
      </>
    )
  }
}

function mapStateToProps (globalState) {
  return {
    food: globalState.food.sort((a, b) => a.name.localeCompare(b.name))
  }
}

export default connect(mapStateToProps)(AssignExistingFood)
