import { db, fb } from '../config/firebase'

export const RECEIVE_COLLECTIONS = 'RECEIVE_COLLECTIONS'
export const CREATE_COLLECTION = 'CREATE_COLLECTION'
export const EDIT_COLLECTION = 'EDIT_COLLECTION'
export const DELETE_COLLECTION = 'DELETE_COLLECTION'
export const ADD_PROGRAM_TO_COLLECTION = 'ADD_PROGRAM_TO_COLLECTION'
export const REMOVE_PROGRAM_FROM_COLLECTION = 'REMOVE_PROGRAM_FROM_COLLECTION'

export const receiveCollections = () => {
  return dispatch => {
    return db.collection('collections').get()
      .then(querySnapshot => {
        const collections = []

        querySnapshot.forEach(snap => {
          collections.push({ ...snap.data(), id: snap.id })
        })

        return dispatch({
          type: RECEIVE_COLLECTIONS,
          collections
        })
      })
      .catch(err => err)
  }
}

export const createCollection = collection => {
  const collectionRef = db.collection('collections').doc()

  return dispatch => {
    const batch = db.batch()

    collection.programs && collection.programs.forEach(program => {
      const programRef = db.collection('programs').doc(program)
      batch.update(programRef, {
        collections: fb.firestore.FieldValue.arrayUnion(collectionRef.id)
      })
    })

    batch.set(collectionRef, collection)

    return batch.commit().then(() => {
      collection.id = collectionRef.id

      return dispatch({
        type: CREATE_COLLECTION,
        collection
      })
    })
      .catch(err => err)
  }
}

export const editCollection = ({ collectionId, collection, toAdd, toRemove }) => {
  const collectionRef = db.collection('collections').doc(collectionId)
  return dispatch => {
    const batch = db.batch()

    toAdd && toAdd.forEach(add => {
      const addRef = db.collection('programs').doc(add)
      batch.update(addRef, {
        collections: fb.firestore.FieldValue.arrayUnion(collectionRef.id)
      })
    })

    toRemove && toRemove.forEach(remove => {
      const removeRef = db.collection('programs').doc(remove)
      batch.update(removeRef, {
        collections: fb.firestore.FieldValue.arrayRemove(collectionRef.id)
      })
    })

    batch.update(collectionRef, collection)

    return batch.commit().then(() => {
      return dispatch({
        type: EDIT_COLLECTION,
        collectionId,
        collection
      })
    })
      .catch(err => err)
  }
}

export const deleteCollection = collectionId => {
  return dispatch => {
    return db.collection('collections').doc(collectionId).delete()
      .then(() => {
        return dispatch({
          type: DELETE_COLLECTION,
          collectionId
        })
      })
      .catch(err => err)
  }
}

export const addProgramToCollection = ({ collectionId, programId }) => {
  return dispatch => {
    return db.collection('collections').doc(collectionId).update({
      programs: fb.firestore.FieldValue.arrayUnion(programId)
    })
      .then(() => {
        return dispatch({
          type: ADD_PROGRAM_TO_COLLECTION,
          collectionId,
          programId
        })
      })
      .catch(err => err)
  }
}

export const removeProgramFromCollection = ({ collectionId, programId }) => {
  return dispatch => {
    return db.collection('collections').doc(collectionId).update({
      programs: fb.firestore.FieldValue.arrayRemove(programId)
    })
      .then(() => {
        return dispatch({
          type: REMOVE_PROGRAM_FROM_COLLECTION,
          collectionId,
          programId
        })
      })
  }
}
