import React from 'react'
import KebabMenu from '../Common/KebabMenu'

const ExerciseItem = React.forwardRef((props, ref) => {
  let movement = ''
  let muscle = ''

  props.tag_movement.forEach((tag, i) => {
    movement = movement + tag
    if (i !== props.tag_movement.length - 1) movement = movement + ' | '
  })

  props.tag_muscle.forEach((tag, i) => {
    muscle = muscle + tag
    if (i !== props.tag_muscle.length - 1) muscle = muscle + ' | '
  })

  return (
    <div className='exercise_list_item box' onClick={() => props.onSelect(props.id)} ref={ref}>
      <img className='image' src={props.thumbnail_url} />

      <p className='title'>{props.name}</p>

      <p className='movement'>{movement}</p>

      <p className='muscle'>{muscle}</p>

      <span className='overflow'>
        <KebabMenu
          context='edit_delete'
          onEdit={() => props.onSelect(props.id)}
          onDelete={() => props.onDelete(props.id)}
        />
      </span>
    </div>
  )
})

export default ExerciseItem
