import { db, fb } from '../config/firebase'

export const RECEIVE_METRICS = 'RECEIVE_METRICS'
export const CREATE_METRIC = 'CREATE_METRIC'
export const EDIT_METRIC = 'EDIT_METRIC'
export const DELETE_METRIC = 'DELETE_METRIC'
export const GET_CLIENT_METRICS = 'GET_CLIENT_METRICS'
export const ASSIGN_METRIC = 'ASSIGN_METRIC'
export const UNASSIGN_METRIC = 'UNASSIGN_METRIC'

export const receiveAllMetrics = () => {
  return dispatch => {
    return db.collection('metrics').get()
      .then(querySnapshot => {
        const metrics = []
        querySnapshot.forEach(snap => {
          metrics.push({ ...snap.data(), id: snap.id })
        })

        return dispatch({
          type: RECEIVE_METRICS,
          metrics: metrics.sort((a, b) => a.name.localeCompare(b.name))
        })
      })
  }
}

export const receiveUserMetrics = trainer => {
  return dispatch => {
    return db.collection('metrics').where('ownerId', 'array-contains-any', [trainer, 'global']).get()
      .then(querySnapshot => {
        const metrics = []
        querySnapshot.forEach(snap => {
          metrics.push({ ...snap.data(), id: snap.id })
        })

        return dispatch({
          type: RECEIVE_METRICS,
          metrics: metrics.sort((a, b) => a.name.localeCompare(b.name))
        })
      })
  }
}

export const editMetric = (metricId, metric) => {
  return dispatch => {
    return db.collection('metrics').doc(metricId).update({
      ...metric
    })
      .then(() => {
        return dispatch({
          type: EDIT_METRIC,
          metricId,
          metric
        })
      })
  }
}

export const createMetric = metric => {
  return dispatch => {
    return db.collection('metrics').add({
      ...metric
    })
      .then(docRef => {
        metric.id = docRef.id
        return dispatch({
          type: CREATE_METRIC,
          metric
        })
      })
  }
}

export const deleteMetric = metricId => {
  return dispatch => {
    return db.collection('metrics').doc(metricId).delete()
      .then(() => {
        return db.collection('clientMetrics').where('metricId', '==', metricId)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(snap => {
              snap.ref.delete()
            })
          })
      })
      .then(() => {
        return dispatch({
          type: DELETE_METRIC,
          metricId
        })
      })
  }
}

export const getClientMetrics = clientId => {
  return dispatch => {
    db.collection('metrics').where('users', 'array-contains', clientId)
      .get()
      .then(querySnapshot => {
        const metrics = []
        querySnapshot.forEach(snap => {
          metrics.push({ ...snap.data(), id: snap.id })
        })

        return dispatch({
          type: GET_CLIENT_METRICS,
          metrics
        })
      })
  }
}

export const assignMetric = ({ metricId, clientId }) => {
  return dispatch => {
    return db.collection('metrics').doc(metricId).update({
      users: fb.firestore.FieldValue.arrayUnion(clientId)
    })
      .then(() => {
        return dispatch({
          type: ASSIGN_METRIC,
          metricId,
          clientId
        })
      })
      .catch(err => console.log(err.message))
  }
}

export const unassignMetric = ({ metricId, clientId }) => {
  return dispatch => {
    return db.collection('metrics').doc(metricId).update({
      users: fb.firestore.FieldValue.arrayRemove(clientId)
    })
      .then(() => {
        return dispatch({
          type: UNASSIGN_METRIC,
          metricId,
          clientId
        })
      })
      .catch(err => err)
  }
}
