import React, { useState, useEffect, lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'

import Details from './Details'
// import Plan from './Plan'
import History from './History'

import { editClient, getClientSubscription } from '../../../actions/clients'
import { logoutUser } from '../../../actions/user'
import { fb } from '../../../config/firebase'

const Plan = lazy(() => import('./Plan'))

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: 12,
    minHeight: 'calc(100vh - 124px)'
  },
  logoutButton: {
    position: 'absolute',
    top: 27,
    right: 24,
    height: 36,
    margin: 6,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    }
  }
}))

const StandaloneContainer = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  const classes = useStyles()
  const history = useHistory()

  // useEffect(() => {
  //   props.dispatch(getClientSubscription(props.userDetails.id, "ADD_SUBSCRIPTION"))
  //   console.log(props.userDetails?.id)
  // }, [props.userDetails?.id])

  const handleTab = (e, value) => {
    setActiveTab(value)
  }

  const updateClient = (clientId, data) => {
    props.dispatch(editClient({ clientId, client: data }))
  }

  const handleLogout = () => {
    history.replace('/')
    fb.auth()
      .signOut()
      .then(() => {
        props.dispatch(logoutUser())
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div>
      <div className='ui_oni_bar'>
        <img src='/img/Logo-Colour-White-small.png' />
      </div>

      <Paper className={classes.paper}>
        <Tabs value={activeTab} onChange={handleTab}>
          <Tab value={0} label='Details' />
          <Tab value={1} label='Plan' />
          {props.userDetails.subscription && (
            <Tab value={2} label='Payment History' />
          )}
        </Tabs>

        <Button
          variant='outlined'
          className={classes.logoutButton}
          onClick={handleLogout}
        >
          Log out
        </Button>

        <div style={{ padding: 12 }}>
          <Suspense fallback={<div />}>
            {activeTab === 0 && (
              <Details
                userDetails={props.userDetails}
                changeTab={(val) => setActiveTab(val)}
                {...props.userDetails}
                updateClient={updateClient}
              />
            )}

            {activeTab === 1 && <Plan userDetails={props.userDetails} />}

            {activeTab === 2 && <History />}
          </Suspense>
        </div>
      </Paper>
    </div>
  )
}

function mapStateToProps (globalState) {
  const userDetails = globalState.user.info
  userDetails.id = globalState.user.auth.uid

  console.log(userDetails)

  return {
    userDetails
  }
}

export default connect(mapStateToProps)(StandaloneContainer)
