import React, { useEffect, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList as List } from 'react-window'

import ProgramListItem from './ProgramListItem'

const ProgramList = props => {
  const listRef = useRef({})
  const rowHeights = useRef({})

  const [list, setList] = useState([])

  useEffect(() => {
    setList(props.programs)
  }, [props.programs])

  function getRowHeight (index) {
    return rowHeights.current[index] + 8 || 82
  }

  const row = ({ index, style }) => {
    const rowRef = useRef({})

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight)
      }
    }, [rowRef])

    return (
      <div key={index} style={style}>
        <ProgramListItem
          key={list[index].id}
          {...list[index]}
          ref={rowRef}
          activeUserId={props.activeUserId}
          activeUserType={props.activeUserType}
          onSelect={props.onSelect}
          onEdit={props.onEdit}
          onAssign={props.onAssign}
          onShare={props.onShare}
          onDelete={props.onDelete}
          onCopy={props.onCopy}
          clients={props.clients}
          creator={props.clients.find(user => list[index].ownerId.includes(user.id))}
          loading={props.loadingProgram === list[index].id}
        />
      </div>
    )
  }

  const setRowHeight = (index, size) => {
    listRef.current.resetAfterIndex(0)
    rowHeights.current = { ...rowHeights.current, [index]: size }
  }

  return (
    <div style={{ flex: 1 }}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height - 84}
            itemCount={list.length}
            itemSize={getRowHeight}
            ref={listRef}
            width={width}
          >
            {row}
          </List>
        )}
      </AutoSizer>
    </div>
  )
}

export default ProgramList
