import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { assignCoach } from '../../actions/clients'
import UserSelect from '../Util/UserSelect'

class ModalAssignCoach extends Component {
  state = {
    isLoading: null,
    showError: false,
    errorMessage: ''
  }

  componentDidMount () {
    this.setState({
      trainers: this.props.assignedTrainers || []
    })
  }

  handleChange = e => {
    this.setState({
      trainers: e
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState({
      isLoading: 'submit'
    })

    this.props.dispatch(assignCoach({ clientId: this.props.clientId, trainers: this.state.trainers }))
      .then(() => {
        this.setState({
          isLoading: null
        })

        this.props.onCloseModal()
      })
      .catch(err => {
        this.setState({
          showError: true,
          errorMessage: err.message,
          isLoading: null
        })
      })
  }

  render () {
    return (
      <Modal
        isOpen={this.props.showModal}
        onRequestClose={this.props.onCloseModal}
        className='modal'
        overlayClassName='modal_overlay'
      >
        <div className='modal_wrapper'>
          <span className='modal_header'>
            <h3>Assign coach</h3>
            <i className='fas fa-times' onClick={this.props.onCloseModal} />
          </span>

          <UserSelect
            users={this.props.coaches}
            checkedUsers={this.props.assignedTrainers}
            onChange={this.handleChange}
          />

          <p className='ui_error'>{this.state.showError && this.state.errorMessage}</p>

          <div className='modal_buttons'>
            <button
              onClick={this.handleSubmit}
              className='button_primary'
            >
              {this.state.isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}

function mapStateToProps (globalState) {
  const coaches = globalState.clients.filter(user => user.type.includes('trainer'))

  return {
    coaches
  }
}

export default connect(mapStateToProps)(ModalAssignCoach)
