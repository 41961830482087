import {
  GET_SECTIONS,
  CREATE_SECTION,
  EDIT_SECTION,
  DELETE_SECTION,
  DELETE_ALL_SECTIONS,
  CLEAR_SECTIONS
} from '../actions/programSections'

const initialState = []

const programSections = (state = initialState, action) => {
  switch (action.type) {
    case GET_SECTIONS:
      return action.sections

    case CREATE_SECTION:
      return [...state, action.section]

    case EDIT_SECTION: {
      const section = state.find(section => section.id === action.sectionId)
      const sectionIndex = state.indexOf(section)

      const newSection = Object.assign(section, action.section)
      state[sectionIndex] = newSection

      return state
    }

    case DELETE_SECTION:
      return state.filter(section => section.id !== action.sectionId)

    case DELETE_ALL_SECTIONS:
      return state.filter(section => section.program !== action.programId)

    case CLEAR_SECTIONS:
      return initialState

    default:
      return state
  }
}

export default programSections
