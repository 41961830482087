import React, { useState } from 'react'

import CollectionForm from './CollectionForm'
import ModalWrapper from '../Common/ModalWrapper'
import ImageGallery from '../Common/ImageGallery'

const CollectionDetails = props => {
  const [image, setImage] = useState((props.activeCollection?.image_url) || 'https://firebasestorage.googleapis.com/v0/b/oni-nutrition-test.appspot.com/o/images%2Fexercises%2FK1S_4571Kelvin.jpg?alt=media&token=f69727ed-beb1-431c-aa13-783350e71c2d') // eslint-disable-line

  const [selectImage, toggleSelectImage] = useState(false)

  const handleSelectImage = e => {
    setImage(e.target.value)
    toggleSelectImage(false)
  }

  return (
    <div className='row' style={{ margin: 1 + 'rem' }}>
      <div className='modal' style={{ zIndex: 1 }}>
        <img
          src={image}
          className='modal_img'
        />

        <span className='button_edit_image' onClick={() => toggleSelectImage(true)}>
          <i className='fas fa-pencil-alt' />
        </span>

        <div className='modal_wrapper'>
          <span className='modal_header' style={{ flex: 0 }}>
            <h3>Collection Details</h3>
          </span>

          <CollectionForm
            activeCollection={props.activeCollection}
            activeCollectionId={props.activeCollectionId}
            activeUserId={props.activeUserId}
            activeUserName={props.activeUserName}
            activeUserType={props.activeUserType}
            programs={props.programs}
            selectedImage={image}
            onSuccess={() => {}}
          />

          <ModalWrapper
            showModal={selectImage}
            onCloseModal={() => toggleSelectImage(false)}
            modalHeader='Select Image'
          >
            <div className='modal_content'>
              <ImageGallery
                show={selectImage}
                onChange={handleSelectImage}
                path='/images/exercises/'
              />
            </div>
          </ModalWrapper>

        </div>
      </div>
    </div>
  )
}

export default CollectionDetails
