import {
  RECEIVE_RECORDS
} from '../actions/exerciseRecords'

const initialState = []

const exerciseRecords = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_RECORDS:
      return action.exerciseRecords

    default:
      return state
  }
}

export default exerciseRecords
