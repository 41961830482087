import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalGoalForm from './ModalGoalForm'
import MilestoneContainer from './MilestoneContainer'

class ProfileGoals extends Component {
  state = {
    displayNewGoalModal: false,
    newMilestone: '',
    milestones: []
  }

  componentDidMount () {
    this.setState({
      activeGoal: this.props.activeGoal
    })
  }

  handleModal = () => {
    this.setState({
      displayNewGoalModal: !this.state.displayNewGoalModal
    })
  }

  render () {
    return (
      <div className='col fullheight' style={{ flex: 1 }}>
        <div className='user_goal_container'>
          <h1>Goals</h1>

          {
            this.props.activeGoal &&
              <p style={{ whiteSpace: 'pre-wrap' }}>{this.props.activeGoal.goal}</p>
          }
        </div>

        {
          this.props.milestones &&
            <MilestoneContainer
              key={this.props.milestones.length}
              clientId={this.props.client.id}
              milestones={this.props.milestones}
            />
        }

        <ModalGoalForm
          showModal={this.props.activeModal}
          onCloseModal={this.props.onModal}
          clientId={this.props.clientId}
          {...this.props.activeGoal}
        />

      </div>
    )
  }
}

function mapStateToProps (globalState, ownProps) {
  const client = globalState.clients.find(client => client.id === ownProps.clientId)

  const activeGoal = globalState.goals.find(goal => goal.active)

  return {
    client,
    activeGoal,
    milestones: globalState.milestones
  }
}

export default connect(mapStateToProps)(ProfileGoals)
