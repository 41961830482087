import React, { useState, useEffect } from 'react'
import Select from 'react-select'

const UserSelect = props => {
  const [users, selectUsers] = useState([])
  const [otherClients, setOtherClients] = useState([])

  useEffect(() => {
    const oC = []

    selectUsers(props.checkedUsers.map(id => {
      const user = props.users.find(user => user.id === id)

      if (user !== undefined) {
        return { label: `${user.first_name} ${user.last_name}`, value: user.id }
      } else {
        oC.push(id)
      }
    }))

    setOtherClients(oC)
  }, [])

  const handleChange = users => {
    selectUsers(users)

    if (users !== null) {
      const ids = users.reduce((acc, res) => {
        acc.push(res.value)
        return acc
      }, [])

      props.onChange([...ids, ...otherClients])
    } else props.onChange(otherClients)
  }

  return (
    <div className='modal_content' style={{ overflow: 'visible', height: 50 + 'vh' }}>
      <Select
        options={props.users.map(user => ({
          label: `${user.first_name} ${user.last_name}`, value: user.id
        }))}
        onChange={handleChange}
        value={users}
        isMulti
        isSearchable
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'black'
          }
        })}
      />
    </div>
  )
}

export default UserSelect
