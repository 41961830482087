import React, { useState } from 'react'

const AssignExistingProgram = props => {
  const [program, setProgram] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setLoading('submit')

    props.onAssign(program)
  }

  return (
    <>
      <form className='modal_content' onSubmit={handleSubmit}>
        <label htmlFor='program'>Program</label>
        <select
          name='program'
          id='program'
          onChange={e => setProgram(e.target.value)}
          defaultValue='placeholder'
        >
          <option value='placeholder' hidden disabled>Select a program</option>

          {
            props.otherPrograms.map(program => (
              <option key={program.id} value={program.id}>{program.header.title}</option>
            ))
          }
        </select>
      </form>

      <div className='modal_buttons'>
        <button
          onClick={handleSubmit}
          className='button_primary'
        >
          {isLoading === 'submit' ? <i className='fas fa-spinner fa-spin' /> : 'Submit'}
        </button>
      </div>
    </>
  )
}

export default AssignExistingProgram
