import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { fb } from '../../../config/firebase'

import { makeStyles } from '@material-ui/styles'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import PlanBox from './PlanBox'
import PlanButton from './PlanButton'
import { giveFreePro, removeFreePro } from '../../../actions/clients'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

const Plan = ({ userDetails, plans, admin }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [freePro, setPro] = useState(userDetails.freePro || false)

  const handleFreePro = () => {
    if (!userDetails.freePro) {
      dispatch(giveFreePro(userDetails.id))
      setPro(true)
    } else {
      dispatch(removeFreePro(userDetails.id))
      setPro(false)
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <PlanBox
            name='Free Plan'
            description='Limited access'
            active={!userDetails.subscription && !freePro}
            activeSubscription={userDetails.subscription}
          />
          <Button
            variant='outlined'
            fullWidth
            disabled={(Boolean(userDetails.subscription) || freePro) && (!admin && freePro)}
            onClick={handleFreePro}
          >
            {`${!userDetails.subscription && !userDetails.freePro ? 'Current Plan' : 'Free'}`}
          </Button>
        </Grid>

        {admin && (
          <Grid item sm={4} xs={12}>
            <PlanBox
              name='Free Pro'
              description='All the benefits of ONI Pro Membership, with none of the cost'
              // freePro={userDetails.freePro}
              active={freePro}
            />
            <Button
              variant='outlined'
              fullWidth
              disabled={(Boolean(userDetails.subscription) || freePro) && !admin}
              onClick={handleFreePro}
            >
              {`${freePro ? 'Current Plan' : 'Give Free Pro'}`}
            </Button>
          </Grid>
        )}

        {plans.map(({ id, product, prices }) => (
          <Grid item sm={4} xs={12}>
            <PlanBox
              key={id}
              productId={id}
              name={product.name}
              description={product.description}
              prices={prices}
              // activeSubscription={userDetails.subscription}
              active={userDetails.subscription?.product.id === id}
            />
            <div className={classes.buttonContainer}>
              {prices.map((price, key) => (
                <PlanButton
                  key={key}
                  price={price}
                  activeSubscription={userDetails.subscription}
                  userDetails={userDetails}
                />
              ))}
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

function mapStateToProps (globalState) {
  return {
    plans: globalState.stripe,
    admin: globalState.user.info.type.includes('admin')
  }
}

export default connect(mapStateToProps)(Plan)
