import React, { Component } from "react";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import lunr from "lunr";

import {
  createProgram,
  deleteProgram,
  editProgram,
  removeClientPrograms,
  removeOwner,
  assignProgram,
} from "../../../actions/programs";
import { createSection, getSections } from "../../../actions/programSections";

import ModalShareProgram from "./ModalShareProgram";
import Search from "../../Common/Search";
import ProgramList from "./ProgramList";
import TopBar from "../../Nav/TopBar";
import { fb } from "../../../config/firebase";
import ModalWrapperImage from "../../Common/ModalWrapperImage";
import ModalWrapper from "../../Common/ModalWrapper";
import ProgramForm from "./ProgramForm";
import AssignForm from "../../Util/AssignForm";
import ConfirmDialog from "../../Common/ConfirmationDialog";

class ProgramListContainer extends Component {
  state = {
    isLoading: null,
    activeModal: null,
    activeProgramId: null,
    activeProgram: {},
    programList: [],
    showFilter: false,
    loadingProgram: null,
    confirmTitle: null,
    confirmText: null,
    confirmAction: null,
  };

  componentDidMount() {
    let programList = this.props.programs;

    if (!this.props.activeUserType.includes("admin")) {
      this.props.dispatch(removeClientPrograms(this.props.activeUserId));

      programList = this.props.programs.filter((p) =>
        p.ownerId.includes(this.props.activeUserId || "global")
      );
    }

    this.createSearchIndex()
    
    this.setState({
      programList,
      loadingProgram: null,
    });
  }

  createSearchIndex = () => {
    const documents = this.props.programs.map((program) => {
      return {
        id: program.id,
        title: program.header.title,
        type: program.header.program_type,
        creatorName: program.creatorName,
      };
    });

    const searchIdx = lunr(function () {
      this.ref("id");
      this.field("title");
      this.field("type");
      this.field("creatorName");

      documents.forEach(function (doc) {
        this.add(doc);
      }, this);
    });

    this.setState({
      searchIdx
    })

  }

  handleModal = (modal) => {
    this.setState({
      activeModal: modal,
      activeProgram: modal ? this.state.activeModal : {},
    });
  };

  handleSelect = (id) => {
    this.setState(
      {
        loadingProgram: id,
      },
      () => {
        this.props
          .dispatch(getSections(id))
          .then(() =>
            this.props.history.push({ pathname: `/programs/${id}/Program` })
          );
      }
    );
  };

  handleAssignModal = (id) => {
    const activeProgram = this.props.programs.find(
      (program) => program.id === id
    );

    this.setState({
      activeModal: "assignProgram",
      activeProgram,
    });
  };

  handleEditProgram = (id) => {
    const activeProgram = this.props.programs.find(
      (program) => program.id === id
    );

    this.setState({
      activeProgramId: id,
      activeProgram: activeProgram,
      activeModal: "createProgram",
    });
  };

  handleAssignProgram = (users) => {
    return this.props.dispatch(
      assignProgram({ programId: this.state.activeProgram.id, users })
    );
  };

  handleShareProgram = (id) => {
    const activeProgram = this.props.programs.find(
      (program) => program.id === id
    );

    this.setState({
      activeProgram,
      activeModal: "shareProgram",
    });
  };

  handleCopyProgram = (id) => {
    this.setState({
      loadingProgram: id,
    });

    this.props.dispatch(getSections(id)).then(() => {
      const activeProgram = this.props.programs.find(
        (program) => program.id === id
      );

      const newProgram = JSON.parse(JSON.stringify(activeProgram));

      delete newProgram.id;
      newProgram.header.title = `${newProgram.header.title} (copy)`;
      newProgram.lastUpdated = null;
      newProgram.users = [];
      newProgram.ownerId = [this.props.activeUserId];
      newProgram.dateCreated = new fb.firestore.Timestamp.now(); // eslint-disable-line

      this.props.dispatch(createProgram(newProgram)).then((res) => {
        const programId = res.program.id;

        newProgram.phases.forEach((phase) => {
          const key = uuid();

          const sections = this.props.sections.filter(
            (section) => section.phaseId === phase.id
          );

          sections.forEach((section) => {
            section.programId = programId;
            section.phaseId = key;
            section.dateCreated = new fb.firestore.Timestamp.now(); // eslint-disable-line
            delete section.id;

            this.props.dispatch(createSection(section));
          });

          phase.id = key;
        });

        this.props
          .dispatch(editProgram({ programId, program: newProgram }))
          .then(() => {
            this.handleSearch(this.state.searchFilter);

            this.setState({
              loadingProgram: null,
            });
          });
      });
    });
  };

  handleDeleteProgram = (id) => {
    const activeProgram = this.props.programs.find(
      (program) => program.id === id
    );
    if (activeProgram.users.length) {
      this.setState({
        confirmTitle: "Cannot delete",
        confirmText:
          "This program has been assigned to a client.\nPlease unassign before deleting.",
        confirmAction: null,
      });
    } else {
      this.setState({
        confirmTitle: "Delete program?",
        confirmText:
          "Are you sure you want to delete this program? This action cannot be undone",
        confirmAction: () => this.handleDelete(activeProgram, id),
      });
    }
  };

  handleDelete = (activeProgram, id) => {
    this.setState({
      loadingProgram: id,
    });

    const owners = activeProgram.ownerId.filter((id) => id !== "global");

    if (owners.length > 1) {
      this.props.dispatch(
        removeOwner({ programId: id, userId: this.props.activeUserId })
      );
    } else {
      if (activeProgram.users.length > 0) {
      } else {
        this.setState({
          activeProgram: {},
        });
        this.props.dispatch(deleteProgram(id)).then(() => {
          this.createSearchIndex()
          this.handleCloseConfirm();

          this.setState({
            programList: this.props.programs
          })
        });
      }
    }
  };

  handleCloseConfirm = () => {
    this.setState({
      confirmTitle: null,
      confirmText: null,
      confirmAction: null,
    });
  };

  render() {
    return (
      <div className="container_col">
        <TopBar title="Programs" tabs={[]} />

        <Search
          data={this.props.programs}
          searchIdx={this.state.searchIdx}
          setFilteredData={(data) => this.setState({ programList: data })}
        />

        <div className="program_list_item">
          <label style={{ gridColumn: "1 / span 2" }}>Program Name</label>
          <label className="assigned">Assigned</label>
          <label className="type">Type</label>
          <label style={{ gridColumn: "5 / span 2" }}>Created By</label>
          <label className="updated">Date Updated</label>
        </div>

        <ProgramList
          programs={this.state.programList}
          activeUserId={this.props.activeUserId}
          activeUserType={this.props.activeUserType}
          onSelect={this.handleSelect}
          onEdit={this.handleEditProgram}
          onAssign={this.handleAssignModal}
          onShare={this.handleShareProgram}
          onCopy={this.handleCopyProgram}
          onDelete={this.handleDeleteProgram}
          clients={this.props.clients}
          loadingProgram={this.state.loadingProgram}
        />

        <div className="ui_bottom_bar">
          <button
            onClick={() => this.handleModal("createProgram")}
            className="button_primary"
          >
            {this.state.isLoading ? (
              <i className="fas fa-spinner fa-spin" />
            ) : (
              "Create Program"
            )}
          </button>
        </div>

        <ModalWrapper
          showModal={this.state.activeModal === "assignProgram"}
          onCloseModal={() => this.handleModal(null)}
          modalHeader="Assign Program"
        >
          <AssignForm
            onCloseModal={() => this.handleModal(null)}
            active={this.state.activeProgram}
            onAssign={this.handleAssignProgram}
          />
        </ModalWrapper>

        {this.state.activeProgram?.id && (
          <ModalShareProgram
            showModal={this.state.activeModal === "shareProgram"}
            onCloseModal={() => this.handleModal(null)}
            activeProgram={this.state.activeProgram}
          />
        )}

        <ModalWrapperImage
          modalHeader={
            this.state.activeProgram ? "Edit Program" : "Create Program"
          }
          showModal={this.state.activeModal === "createProgram"}
          onCloseModal={() => this.handleModal(null)}
          history={this.props.history}
          image={this.state.activeProgram?.image_url} // eslint-disable-line
        >
          <ProgramForm
            activeProgram={this.state.activeProgram}
            activeProgramId={this.state.activeProgramId}
            clientId={this.props.clientId}
            history={this.props.history}
            onCloseModal={() => this.handleModal(null)}
          />
        </ModalWrapperImage>

        <ConfirmDialog
          open={Boolean(this.state.confirmTitle)}
          onClose={this.handleCloseConfirm}
          title={this.state.confirmTitle}
          text={this.state.confirmText}
          affirmativeAction={this.state.confirmAction}
          negativeAction={this.handleCloseConfirm} // eslint-disable-line
        />
      </div>
    );
  }
}

function mapStateToProps(globalState) {
  const programs = globalState.programs.sort((a, b) =>
    a.header.title.localeCompare(b.header.title)
  );

  return {
    programs,
    sections: globalState.programSections,
    numPrograms: globalState.programs.length,
    activeUserType: globalState.user.info.type,
    activeUserId: globalState.user.auth.uid,
    clients: globalState.clients,
  };
}

export default connect(mapStateToProps)(ProgramListContainer);
