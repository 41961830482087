import { TOGGLE_DRAWER } from '../actions/ui'

const initialState = { drawer: true }

const ui = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DRAWER:
      state.drawer = action.drawer
      return state

    default:
      return state
  }
}

export default ui
